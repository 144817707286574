import React from 'react';
import { useTranslation } from 'react-i18next';

import { Outlet } from '@tanstack/react-router';
import clsx from 'clsx';
import { produce } from 'immer';

import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { AppLocalStorageKeys, LAYOUT_ROOT_ID } from '~/shared/constants';
import { useLocalStorage } from '~/shared/hooks/useLocalStorage';

import { useBreakpointLarge1280Up } from '~/styles/__generated__/useBreakpoints';

import { NavigationMenuState } from '../../../../types';
import { Brand } from '../../../Brand';
import { NavigationMenu } from '../NavigationMenu';
import styles from './index.module.scss';

interface Props extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, shows the menu (default - true)
   */
  withMenu?: boolean;
}

export const BaseLayout: React.FC<Props> = ({
  className,
  withMenu = true,
  children = <Outlet />,
}) => {
  const [menuState, setMenuState] = useLocalStorage<NavigationMenuState>(
    AppLocalStorageKeys.navigationMenuState,
    {
      isOpen: false,
      itemsIsOpenMap: {},
    }
  );

  const toggleMenu = () => {
    setMenuState(current =>
      produce(current, draft => {
        draft.isOpen = !current.isOpen;
      })
    );
  };

  const toggleMenuItem = (key: string, newIsOpen?: boolean) => {
    setMenuState(current =>
      produce(current, draft => {
        draft.itemsIsOpenMap[key] = newIsOpen ?? !current.itemsIsOpenMap[key];
      })
    );
  };

  const isDesktopMenu = useBreakpointLarge1280Up();

  const isMenuOpen = !isDesktopMenu && menuState.isOpen;
  const isMenuCollapsed = isDesktopMenu && menuState.isOpen;

  // Preload some translations to avoid glitch in modals
  useTranslation([MaslovNamespaces.enums, MaslovNamespaces.validation]);

  return (
    <div
      id={LAYOUT_ROOT_ID}
      className={clsx(
        styles.root,
        {
          [styles.noMenu]: !withMenu,
          [styles.open]: withMenu && !isDesktopMenu && menuState.isOpen,
          [styles.collapsed]:
            withMenu && (isDesktopMenu ? menuState.isOpen : !menuState.isOpen),
          [styles.expanded]: withMenu && isDesktopMenu && !menuState.isOpen,
        },
        className,
        // TODO This class is used in MOverlay component, remove it, when we completely switch to new popovers
        'm-application-shell-container'
      )}
    >
      {withMenu && !isDesktopMenu && (
        <div
          {...{
            className: menuState.isOpen ? styles.overlay : styles.hiddenOverlay,
            onMouseUp: () => toggleMenu(),
            onTouchEnd: () => toggleMenu(),
          }}
        />
      )}
      {withMenu && (
        <div className={styles.mobileHeader}>
          <FunctionButton
            {...{
              iconVariant: IconVariants.menu,
              onPress: () => toggleMenu(),
            }}
          />
          <Brand />
        </div>
      )}
      {withMenu && (
        <NavigationMenu
          {...{
            className: styles.menu,
            toggleMenu,
            onToggleItem: toggleMenuItem,
            isMenuOpen,
            isMenuCollapsed,
            menuState,
          }}
        />
      )}
      <main className={styles.content}>{children}</main>
    </div>
  );
};
