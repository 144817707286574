import React from 'react';

import clsx from 'clsx';

import { Label } from '~/shared/components/Label';
import {
  DateRange,
  formatDate,
  formatDateForBackend,
} from '~/shared/helpers/date';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { BaseFieldProps } from '~/shared/types/controls';

import { FieldFeedback } from '../FieldFeedback';
import { Typography, TypographyVariants } from '../Typography';
import styles from './index.module.scss';

/**
 * Possible date range picker display variants
 */
export enum DateRangePickerThemes {
  light = 'light',
  dark = 'dark',
}

interface Props extends BaseFieldProps<DateRange> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Picker display theme
   */
  theme?: DateRangePickerThemes;
}

const DATE_PLACEHOLDER = 'дд.мм.гггг';

/**
 * Default state of the range picker
 */
export const DEFAULT_DATE_RANGE_VALUE: DateRange = {
  since: '',
  till: '',
};

const DateRangePickerInner = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,

      theme = DateRangePickerThemes.dark,

      name,
      isRequired,
      hasError,

      value: valueProp,
      onValueChange,
      defaultValue = DEFAULT_DATE_RANGE_VALUE,

      label,
      labelProps,
      feedback,
      feedbackProps,
    }: Props,
    ref
  ) => {
    const [value, setValue] = useControllableState(
      valueProp,
      onValueChange,
      defaultValue
    );

    const valueSince = formatDate(value.since);
    const valueTill = formatDate(value.till);

    const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
      (e.target as HTMLInputElement).showPicker();
    };

    const renderDate = (date: string) => (
      <Typography
        variant={TypographyVariants.bodySmall}
        className={clsx(!date && 'text-muted')}
      >
        {date || DATE_PLACEHOLDER}
      </Typography>
    );

    return (
      <div className={clsx(className, styles.rootWrapper, styles[theme])}>
        {!!label && (
          <Label
            {...{
              className: 'mb-4',
              isRequired,
              ...labelProps,
            }}
          >
            {label}
          </Label>
        )}
        <div className={clsx(styles.root, hasError && styles.error)}>
          <div className={styles.input}>
            {renderDate(valueSince)}
            <input
              {...{
                ref,
                name: `${name}.since`,
                type: 'date',
                value: formatDateForBackend(value.since, true),
                onClick: handleInputClick,
                onChange: e =>
                  setValue?.(current => ({
                    ...current,
                    since: e.target.value,
                  })),
              }}
            />
          </div>

          <Typography
            variant={TypographyVariants.bodySmall}
            className={clsx((!valueSince || !valueTill) && 'text-muted')}
          >
            &ndash;
          </Typography>

          <div className={styles.input}>
            {renderDate(valueTill)}
            <input
              {...{
                name: `${name}.till`,
                type: 'date',
                value: formatDateForBackend(value.till, true),
                onClick: handleInputClick,
                onChange: e =>
                  setValue?.(current => ({ ...current, till: e.target.value })),
              }}
            />
          </div>
        </div>
        {!!feedback && (
          <FieldFeedback
            className="mt-4"
            content={feedback}
            {...feedbackProps}
          />
        )}
      </div>
    );
  }
);

export const DateRangePicker = withOptionalFormController<Props, DateRange>({
  defaultValue: DEFAULT_DATE_RANGE_VALUE,
})(DateRangePickerInner);
