import React, { ReactNode } from 'react';

import './MModal.scss';

interface Props {
  children?: ReactNode;
  close: () => void;

  wide?: boolean;
}

export const MModal: React.FC<Props> = props => {
  const { children, close, wide } = props;

  return (
    <div className="m-modal">
      <div className="m-modal-backdrop" onClick={close} />
      <div className={`m-modal-content ${wide ? 'wide' : ''}`}>{children}</div>
    </div>
  );
};
