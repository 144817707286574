import React from 'react';

import { Typography, TypographyVariants } from '~/shared/components/Typography';
import {
  DateFormats,
  formatDate,
  formatSecondsDuration,
} from '~/shared/helpers/date';
import { formatInt, formatNumber } from '~/shared/helpers/number';

import { formatCow } from '~/entities/cows';
import { formatPenGroup } from '~/entities/penGroups';

import {
  FLOW_RATE_FORMAT_PRECISION,
  MILKING_MISTAKES_DICT,
  UNIDENTIFIED_COW_TEXT,
} from '../../constants';
import { MilkingParlorChartDataPoint } from '../../types';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Cart data point to render
   */
  dataPoint: MilkingParlorChartDataPoint;
}

export const MilkingParlorChartTooltip: React.FC<Props> = ({
  className,
  dataPoint,
}) => {
  const { flatMistake } = dataPoint;

  const flatMilking = flatMistake
    ? flatMistake.flatMilking
    : dataPoint.flatMilking;

  if (!flatMilking) {
    return null;
  }

  const cow = flatMilking?.milking.cow ?? flatMistake?.flatMilking.milking.cow;

  return (
    <div className={className}>
      <Typography
        {...{
          variant: TypographyVariants.descriptionLargeStrong,
          tag: 'h4',
        }}
      >
        {formatCow(cow, { noCowMessage: UNIDENTIFIED_COW_TEXT })}
      </Typography>
      {cow && (
        <Typography
          {...{
            variant: TypographyVariants.descriptionMedium,
            className: 'text-soft',
          }}
        >
          {formatPenGroup(flatMilking.milking.penGroup)}
        </Typography>
      )}

      {!!flatMistake && (
        <div className={styles.infoTable}>
          <Typography variant={TypographyVariants.descriptionLarge}>
            Ошибка
          </Typography>
          <Typography variant={TypographyVariants.descriptionLarge}>
            {MILKING_MISTAKES_DICT[flatMistake.mistake.kind]}
          </Typography>

          <Typography variant={TypographyVariants.descriptionLarge}>
            Продолжит. переподкл.
          </Typography>
          <Typography variant={TypographyVariants.descriptionLarge}>
            {formatSecondsDuration(flatMistake.mistake.durationSec)}
          </Typography>
        </div>
      )}

      {!flatMistake && (
        <div className={styles.infoTable}>
          <Typography variant={TypographyVariants.descriptionLarge}>
            Поток
          </Typography>
          <Typography variant={TypographyVariants.descriptionLarge}>
            {formatNumber(
              flatMilking.milking.flowRate,
              FLOW_RATE_FORMAT_PRECISION
            )}
            &nbsp;кг/мин.
          </Typography>

          <Typography variant={TypographyVariants.descriptionLarge}>
            Надой
          </Typography>
          <Typography variant={TypographyVariants.descriptionLarge}>
            {formatInt(flatMilking.milking.weightKilograms)}
            &nbsp;кг
          </Typography>

          <Typography variant={TypographyVariants.descriptionLarge}>
            Отклон. от ожид. надоя
          </Typography>
          <Typography variant={TypographyVariants.descriptionLarge}>
            {formatInt(flatMilking.milking.deviationWeightKilograms)}
            &nbsp;кг
          </Typography>

          <Typography variant={TypographyVariants.descriptionLarge}>
            Аппарат
          </Typography>
          <Typography variant={TypographyVariants.descriptionLarge}>
            {formatInt(flatMilking.stallNumber)}
          </Typography>

          <Typography variant={TypographyVariants.descriptionLarge}>
            Время подключения
          </Typography>
          <Typography variant={TypographyVariants.descriptionLarge}>
            {formatDate(flatMilking.milking.happenedAt, DateFormats.onlyTime)}
          </Typography>

          <Typography variant={TypographyVariants.descriptionLarge}>
            Продолжит. доения
          </Typography>
          <Typography variant={TypographyVariants.descriptionLarge}>
            {formatSecondsDuration(flatMilking.milking.durationSec)}
          </Typography>
        </div>
      )}
    </div>
  );
};
