import {
  AnalyticsDashboardFilters,
  AnalyticsService,
  AnalyticTargetFilters,
  AppAnalyticTarget,
  AppHerdState,
  HerdMetricData,
  MetricFilters,
} from '~/~legacy/services/AnalyticsService';
import { StandardService } from '~/~legacy/services/implementations/StandardService';

import { API_URL } from '~/shared/constants';
import {
  AnalyticalMetricEnum,
  GetAnalyticTargetsQuery,
  GetAnalyticTargetsQueryVariables,
  GetHerdMetricQuery,
  GetHerdMetricQueryVariables,
  GetHerdStateQuery,
  GetHerdStateQueryVariables,
  PageInfo,
  SetAnalyticalMetricTargetInput,
  SetAnalyticalMetricTargetMutation,
  SetAnalyticalMetricTargetMutationVariables,
  SetAnalyticalSettingSettingInput,
  SetAnalyticalSettingsMutation,
  SetAnalyticalSettingsMutationVariables,
} from '~/shared/graphql';

import {
  GetAnalyticTargetsGQL,
  GetHerdMetricGQL,
  GetHerdStateGQL,
  SetAnalyticalMetricTargetGQL,
  SetAnalyticalSettingGQL,
} from '~/services/gql/queries/analytics.gql';

import { AnalyticsResponseConvertor } from './AnalyticsResponseConvertor';

export class AppAnalyticsService
  extends StandardService
  implements AnalyticsService
{
  private readonly convertor = new AnalyticsResponseConvertor();

  async getHerdState(
    filters: AnalyticsDashboardFilters
  ): Promise<AppHerdState> {
    const herdStateMetrics: AnalyticalMetricEnum[] = [
      AnalyticalMetricEnum.SoldCount,
      AnalyticalMetricEnum.BullsCount,
      AnalyticalMetricEnum.DeadCount,
      AnalyticalMetricEnum.CalvedCowsCount,
      AnalyticalMetricEnum.CowsInDryCount,
      AnalyticalMetricEnum.CowsInMilkingCount,
      AnalyticalMetricEnum.HeifersCount,
      AnalyticalMetricEnum.CowsCount,
    ];

    const response = await this.http.gql<
      GetHerdStateQuery,
      GetHerdStateQueryVariables
    >(API_URL, {
      query: GetHerdStateGQL,
      variables: {
        groupingPeriod: filters.groupingPeriod,
        metricsPeriod: filters.metricsPeriod,
        farmIds: filters.farmId ? [filters.farmId] : undefined,
        since: filters.since || undefined,
        till: filters.till || undefined,
        metrics: herdStateMetrics,
      },
    });

    const result = this.convertor.fromGetHerdStateQuery(response);

    return result;
  }

  async getHerdMetricDetails(filters: MetricFilters): Promise<HerdMetricData> {
    const response = await this.http.gql<
      GetHerdMetricQuery,
      GetHerdMetricQueryVariables
    >(API_URL, {
      query: GetHerdMetricGQL,
      variables: {
        metric: filters.metric,
        groupingPeriod: filters.groupingPeriod,
        farmIds: filters.farmId ? [filters.farmId] : undefined,
        since: filters.since || undefined,
        till: filters.till || undefined,
      },
    });

    const herdMerticData = this.convertor.fromGetHerdMetricQuery(response);

    return herdMerticData;
  }

  async getTargets(
    filters: AnalyticTargetFilters
  ): Promise<[AppAnalyticTarget[], PageInfo]> {
    const response = await this.http.gql<
      GetAnalyticTargetsQuery,
      GetAnalyticTargetsQueryVariables
    >(API_URL, {
      query: GetAnalyticTargetsGQL,
      variables: {
        after: filters.from,
        metric: filters.metric,
      },
    });

    const [targetsData, pageInfo] =
      this.convertor.fromGetAnalyticTargets(response);

    return [targetsData, pageInfo];
  }

  async addTarget(input: SetAnalyticalMetricTargetInput): Promise<void> {
    await this.http.gql<
      SetAnalyticalMetricTargetMutation,
      SetAnalyticalMetricTargetMutationVariables
    >(API_URL, {
      query: SetAnalyticalMetricTargetGQL,
      variables: {
        input,
      },
    });
  }

  async setAnalyticalSetting(
    input: SetAnalyticalSettingSettingInput
  ): Promise<void> {
    await this.http.gql<
      SetAnalyticalSettingsMutation,
      SetAnalyticalSettingsMutationVariables
    >(API_URL, {
      query: SetAnalyticalSettingGQL,
      variables: {
        input,
      },
    });
  }
}
