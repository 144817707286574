import { ReactElement, useEffect } from 'react';

import { useRouterTabsContext } from '../components/RouterTabs';

interface UseLayoutStateProps {
  /**
   * Content to render on the right part of the router tab header.
   */
  rightContent?: ReactElement | null;
  /**
   * Used to trigger rightContent rerender
   */
  dependencies?: unknown[];
}

/**
 * Hook for controlling the upper level page layout from the nested pages
 */
export const useLayoutState = ({
  rightContent,
  dependencies = [],
}: UseLayoutStateProps) => {
  const { routerTabsRightContent, setRouterTabsRightContent } =
    useRouterTabsContext();

  useEffect(() => {
    setRouterTabsRightContent(rightContent);

    return () => {
      // Return to initial mount value after unmount
      setRouterTabsRightContent(routerTabsRightContent);
    };
  }, dependencies);
};

/**
 * Hacky component to allow to conditionally reset layout state,
 * such as in case, when we have an AsyncList with no items, and
 * we need to render the button at the center of the page, instead of in the layout.
 *
 * TODO rework AsyncList component, to decouple it from filters, when we create actual Filters component
 */
export const LayoutStateReset = () => {
  useLayoutState({
    rightContent: undefined,
  });

  return null;
};
