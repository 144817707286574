import * as Types from '../../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ConceptionRateChartInseminationNumberCandleFragment = { __typename: 'ConceptionRateChartInseminationNumberCandle', meanValue: number, maxValue: number, inseminationNumber: number };

export const ConceptionRateChartInseminationNumberCandleFragmentDoc = gql`
    fragment ConceptionRateChartInseminationNumberCandle on ConceptionRateChartInseminationNumberCandle {
  __typename
  meanValue
  maxValue
  inseminationNumber
}
    `;