import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type BlueprintFragment = { __typename: 'Blueprint', id: string, name: string, description: string, viewSettings: { __typename?: 'BlueprintViewSettings', kind?: Types.ViewKindEnum | null }, blueprintRoles: Array<{ __typename?: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> };

export const BlueprintFragmentDoc = gql`
    fragment Blueprint on Blueprint {
  __typename
  id
  name
  description
  viewSettings {
    kind
  }
  blueprintRoles {
    id
    name
    color
  }
}
    `;