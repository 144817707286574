import React, { ReactNode, useState } from 'react';

import clsx from 'clsx';
import { lightFormat } from 'date-fns';

import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { AppAnalyticTarget } from '~/~legacy/services/AnalyticsService';

import { MInput } from '~/shared/components/MInput';
import { MLinkButton } from '~/shared/components/MLinkButton';

import { Modal, ModalSizes } from '~/services/modals';

import { getFormInputVal } from '../../../../helpers';
import styles from './index.module.scss';

interface Props extends ApiFeedbackProps {
  data: AppAnalyticTarget[];
  lable: string;
  parametrSection?: ReactNode;

  addTarget: (val: number) => Promise<boolean>;
  loadMore?: () => void;
}

export const SetMetricTargetModalContent: React.FC<Props> = ({
  addTarget,
  loadMore,
  data,
  lable,
  errors,
  loading,
  parametrSection,
}) => {
  const [target, setTarget] = useState<number>();

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const loadMoreCmpnt = loadMore ? (
    <div>
      <MLinkButton onClick={loadMore}>Загрузить ещё</MLinkButton>
    </div>
  ) : null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEmpty = e.target.value === '';
    const parsed = Number(e.target.value);
    const valToSet: number | undefined =
      Number.isNaN(parsed) || isEmpty ? undefined : parsed;

    setTarget(valToSet);
  };

  const handleSubmit = async () => {
    if (target !== undefined) {
      const success = await addTarget(target);
      if (success) {
        setTarget(undefined);
      }
    }
  };

  return (
    <Modal
      {...{
        title: 'Установка целевого показателя',
        size: ModalSizes.medium950,
        onSubmit: handleSubmit,
        submitButtonProps: {
          children: 'Сохранить изменения',
        },
      }}
    >
      {parametrSection}
      <div>
        <MInput
          name="target"
          className={styles.input}
          value={getFormInputVal(target)}
          onChange={handleChange}
          type="number"
          label={`Целевоей показатель ${lable}`}
        />
      </div>

      <table className={clsx('my-16', styles.table)}>
        <thead>
          <tr>
            <th>Дата изменений</th>
            <th>Значение показателя</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              <td>{lightFormat(new Date(item.validSince), 'dd.MM.yyyy')}</td>
              <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {loadMoreCmpnt}
      {errorMessage}
      {loader}
    </Modal>
  );
};
