import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { Button } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';

import { PageHeader } from '~/services/layouts';

import {
  TestMilkingUploadsTable,
  useLoadTestMilkingCsvModal,
  useTestMilkingUploadsPaginatedQuery,
} from '~/entities/testMilkings';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

const TEST_MILKING_UPLOADS_ROWS_DEFAULT_COUNT = 8;

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/milkings/'
)({
  wrapInSuspense: true,
  component: TestMilkingUploadsPage,
});

function TestMilkingUploadsPage() {
  const { open: openLoadTestMilkingCsvModal } = useLoadTestMilkingCsvModal();

  const queryVariables = {};

  const { items: testMilkingUploads, ...asyncProps } =
    useTestMilkingUploadsPaginatedQuery({
      variables: queryVariables,
    });

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Контрольные дойки',
          rightContent: (
            <Button
              {...{
                iconVariant: IconVariants.upload,
                onPress: () => openLoadTestMilkingCsvModal(),
              }}
            >
              Загрузить результаты
            </Button>
          ),
        }}
      />

      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <TestMilkingUploadsTable
          {...{
            testMilkingUploads,
            skeletonItemsCount: TEST_MILKING_UPLOADS_ROWS_DEFAULT_COUNT,
            queryVariables,
            ...asyncProps,
          }}
        />
      </div>
    </div>
  );
}
