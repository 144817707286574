import React from 'react';

import clsx from 'clsx';

import { DateInput } from '~/shared/components/DateInput';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { CSV_FILE_ACCEPT, FilePicker } from '~/services/files';
import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { SelectTestMilkingFileFormType } from '../../hooks/useSelectTestMilkingFileForm';

interface Props
  extends Pick<
    UseModalStepFormInterface<SelectTestMilkingFileFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const SelectTestMilkingFileForm: React.FC<Props> = ({
  className,
  formProps,
}) => {
  return (
    <Form
      {...{
        className: clsx('grid gap-12', className),
        ...formProps,
      }}
    >
      <Typography variant={TypographyVariants.bodyMediumStrong} tag="h3">
        Укажите дату и прикрепите файл
      </Typography>
      <DateInput
        {...{
          name: 'happenedAt',
          label: 'Дата взятия пробы',
        }}
      />
      <FilePicker name="file" accept={CSV_FILE_ACCEPT} />
    </Form>
  );
};
