import { createContext, useContext } from 'react';

import { CowDetailedFragment } from '~/entities/cows/gql/fragments/cowDetailed.graphql';

import { CowCardEventsTableEntry } from '~/features/cowCardEvents/index';

interface MilkDataTabContextType {
  /**
   * Loading status of cowLactationGraphs query
   */
  isCowLactationGraphsLoading: boolean;
  /**
   * Entries for CowCardEvents table
   */
  cowEventsTableEntries: CowCardEventsTableEntry[];
  /**
   * Cow fragment
   */
  cow: CowDetailedFragment | undefined;
}

export const MilkDataTabContext = createContext<MilkDataTabContextType>({
  isCowLactationGraphsLoading: false,
  cowEventsTableEntries: [],
  cow: undefined,
});

export const useMilkDataTabContext = () => {
  const context = useContext(MilkDataTabContext);

  if (!context) {
    throw new Error(
      'useMilkDataTabContext() should be used in MilkDataTabContext.Provider'
    );
  }

  return context;
};
