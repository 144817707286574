import React, { useMemo } from 'react';

import { SkeletonBarChart } from './components/SkeletonBarChart';
import { SkeletonBlock } from './components/SkeletonBlock';
import { SkeletonLineChart } from './components/SkeletonLineChart';
import { SkeletonText } from './components/SkeletonText';
import {
  SkeletonContext,
  SkeletonContextType,
  useSkeletonContext,
} from './context';

interface Props extends React.PropsWithChildren {
  /**
   * If true, skeleton provides loading state for its children
   */
  isLoading?: boolean;
}

export const Skeleton = ({
  isLoading: isLoadingProp = false,
  children,
}: Props) => {
  const { isLoading: currentContextIsLoading } = useSkeletonContext();

  // Sometimes we may have a case of rendering nested Skeletons,
  // but we shouldn't end up resetting initial loading state
  const isLoading = currentContextIsLoading || isLoadingProp;

  const contextValue = useMemo<SkeletonContextType>(
    () => ({
      isLoading,
      renderWithSkeleton: (skeleton, content, isStaticContent = false) =>
        isLoading && !isStaticContent ? skeleton : content,
      getSkeletonClassNames: (skeletonClassNames, contentClassNames = '') =>
        isLoading ? skeletonClassNames : contentClassNames,
      renderWithoutSkeleton: content => (isLoading ? null : content),
    }),
    [isLoading, currentContextIsLoading]
  );

  return (
    <SkeletonContext.Provider value={contextValue}>
      {children}
    </SkeletonContext.Provider>
  );
};

Skeleton.Block = SkeletonBlock;
Skeleton.Text = SkeletonText;
Skeleton.BarChart = SkeletonBarChart;
Skeleton.LineChart = SkeletonLineChart;

export * from './context';
export * from './helpers';
export * from './types';
export * from './hooks';
export { TextSkeletonSizes } from './components/SkeletonText';
