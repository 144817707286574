import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/custom-reports/$customReportId/'
)({
  beforeLoad: ({ params: { customReportId } }) => {
    return redirect({
      to: '/$companyId/user/analytics/custom-reports',
      from: Route.fullPath,
      state: {
        data: { customReportId },
      },
    });
  },
});
