import { InferValidatedSchema } from '~/services/forms';

import { CalculateCustomMilkingReportMutation } from '~/entities/customMilkingReports/gql/mutations/calculateCustomMilkingReport.graphql';

import {
  CUSTOM_MILKING_REPORT_FILTER_SCHEMA,
  CUSTOM_MILKING_REPORT_GROUPING_SCHEMA,
  CUSTOM_MILKING_REPORT_SETTINGS_FORM_SCHEMA,
  CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_SCHEMA,
} from './constants';

/**
 * Form type for custom milking report settings
 */
export type CustomMilkingReportSettingsFormType = InferValidatedSchema<
  typeof CUSTOM_MILKING_REPORT_SETTINGS_FORM_SCHEMA
>;

/**
 * Form type for custom milking report y axis field
 */
export type CustomMilkingReportYAxisFieldFormType = InferValidatedSchema<
  typeof CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_SCHEMA
>;

/**
 * Form type for custom milking report grouping
 */
export type CustomMilkingReportGroupingFormType = InferValidatedSchema<
  typeof CUSTOM_MILKING_REPORT_GROUPING_SCHEMA
>;

/**
 * Form type for custom milking report filter
 */
export type CustomMilkingReportFilterFormType = InferValidatedSchema<
  typeof CUSTOM_MILKING_REPORT_FILTER_SCHEMA
>;

/**
 * Items for adding filters and grouping
 */
export enum FiltersAndGroupingsMenuItems {
  filter = 'filter',
  grouping = 'grouping',
}

/**
 * Possible filters and grouping modes
 */
export enum FiltersAndGroupingsModes {
  actual = 'actual',
  historic = 'historic',
}

/**
 * Possible calculate report mutation results
 */
export type CustomMilkingReportDataType =
  CalculateCustomMilkingReportMutation['calculateCustomMilkingReport'];

/**
 * Rendering and logic config for y axis fields of custom milking report
 */
export interface CustomMilkingReportYAxisFieldConfig {
  label: string;
  measurementUnit: string;
  precision: number;
  isAvailableForMilkingParlors?: boolean;
  shouldHaveGroupingFormula?: boolean;
}
