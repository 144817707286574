import { ChartOptions, TimeUnit } from 'chart.js';
import { ru } from 'date-fns/locale';

import 'chartjs-adapter-date-fns';

// TODO rework to new charts module
export function getChartDashboardOptions(timeUnit: TimeUnit) {
  const options: ChartOptions = {
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        position: 'cursor',
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        type: 'time',
        time: {
          unit: timeUnit,
          displayFormats: {
            month: 'MMM yy',
            day: 'd MMM',
            week: 'd MMM',
          },
        },
        bounds: 'ticks',
        adapters: {
          date: {
            locale: ru,
          },
        },
      },
      y: {
        min: 0,
        ticks: {},
        grid: {
          display: false,
        },
        grace: '0%',
      },
    },
    layout: {
      autoPadding: false,
    },
  };
  return options;
}
