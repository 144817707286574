import React, { useCallback } from 'react';

import { RunType } from '@graphql-types';
import R from 'ramda';

import {
  BlueprintExecutionInfo,
  BlueprintExecutionResult,
  DataRow,
  ExecutionResultRow,
  ExecutionResultRowTypes,
  GroupedRow,
} from '~/~legacy/services/BlueprintExecutionService';

import { Badge } from '~/shared/components/Badge';
import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { CSV_SEPARATOR } from '~/shared/constants';
import { exportToCsv } from '~/shared/helpers/exportToCsv';
import { formatInt } from '~/shared/helpers/number';
import { usePrint } from '~/shared/hooks/usePrint';

import { PageHeader } from '~/services/layouts';
import { useArkaNavigation } from '~/services/navigation';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { useTableResultComponentsMap } from '../../hooks';
import styles from './index.module.scss';

interface Props {
  executionInfo: BlueprintExecutionInfo;
  results: BlueprintExecutionResult;
  cancel: () => void;
}

export const TableExecutionResult: React.FC<Props> = ({
  results,
  executionInfo,
}) => {
  const componentsMap = useTableResultComponentsMap(results.dataColumns.length);

  const headers = results.dataColumns.map(column => (
    <th key={column}>{column}</th>
  ));
  const body = results.rows.map(dataRow => {
    const getRow = componentsMap[dataRow.__typename];
    const row = getRow(dataRow);
    return row;
  });

  const { containerRef, contentRef, handlePrint } = usePrint();

  const downloadCsv = useCallback(() => {
    const newLine = '\n';
    const csv: string[] = [];

    const dataRowProcessing = (row: ExecutionResultRow): string => {
      const typedRow = row as DataRow;
      // probably can be optimized to avoid numerous parsing transformations
      const parsedRow = JSON.parse(typedRow.row) as any;
      const values = Object.values(parsedRow).map(value => {
        let resultValue = R.isNil(value)
          ? ''
          : `"${value?.toString().replace('"', '\\"')}"`;

        if (typeof value === 'number') {
          resultValue = resultValue.replace('.', ',');
        }

        return resultValue;
      });
      return values.join(CSV_SEPARATOR);
    };

    const groupedRowProcessing = (row: ExecutionResultRow): string => {
      const typedRow = row as GroupedRow;
      const data = typedRow.rows.map(dataRow => {
        const processRow =
          dataRow.__typename === 'BlueprintLaunchDataRow'
            ? dataRowProcessing
            : groupedRowProcessing;

        return processRow(dataRow);
      });

      return data.join(newLine);
    };

    const rowGetterMap: Record<
      ExecutionResultRowTypes,
      (row: ExecutionResultRow) => string
    > = {
      [ExecutionResultRowTypes.BlueprintLaunchGroupedRow]: groupedRowProcessing,
      [ExecutionResultRowTypes.BlueprintLaunchDataRow]: dataRowProcessing,
    };

    csv.push(results.dataColumns.join(CSV_SEPARATOR));

    results.rows.forEach(row => {
      const action = rowGetterMap[row.__typename];
      const paresdRow = action(row);
      csv.push(paresdRow);
    });

    const csvString = csv.join('\n');
    const filename = `${
      executionInfo.name
    }_${new Date().toLocaleDateString()}.csv`;

    exportToCsv(csvString, filename);
  }, [results, executionInfo]);

  const { urlCompanyId } = useArkaNavigation();

  const renderHeader = (isForPrint: boolean) => (
    <PageHeader
      {...{
        title: executionInfo.name,
        titleBadge: <Badge isPill>{formatInt(results.rowsCount)}</Badge>,
        backLinkProps: {
          to:
            executionInfo.runType === RunType.Schedule
              ? '/$companyId/user/production-calendar'
              : '/$companyId/user/incidents',
          params: {
            companyId: urlCompanyId,
          },
        },
        rightContent: !isForPrint && (
          <>
            <Button
              {...{
                variant: ButtonVariants.secondary,
                onPress: downloadCsv,
                iconVariant: IconVariants.download,
              }}
            >
              Скачать csv
            </Button>

            <Button
              {...{
                variant: ButtonVariants.secondary,
                iconVariant: IconVariants.printer,
                onPress: handlePrint,
              }}
            >
              Напечатать
            </Button>
          </>
        ),
      }}
    />
  );

  return (
    <div className={layoutStyles.limitedContainer} ref={containerRef}>
      {renderHeader(false)}

      <table className={styles.table} ref={contentRef}>
        <thead>
          <tr className="only-for-print">
            <th colSpan={results.dataColumns.length}>{renderHeader(true)}</th>
          </tr>
          <tr>{headers}</tr>
        </thead>
        <tfoot />
        <tbody>{body}</tbody>
      </table>
    </div>
  );
};
