import {
  MilkingParlorManufacturerEnum,
  ParlorExportStatusFileTagFieldEnum,
} from '@graphql-types';

/**
 * Possible milking parlors manufacturers display names
 */
export const MILKING_PARLOR_MANUFACTURERS_DICT: Record<
  MilkingParlorManufacturerEnum,
  string
> = {
  [MilkingParlorManufacturerEnum.Gea]: 'GEA',
  [MilkingParlorManufacturerEnum.Boumatic]: 'BouMatic',
};

/**
 * Possible parlor tag fields display names
 */
export const TAG_FIELDS_DICT = {
  [ParlorExportStatusFileTagFieldEnum.Eid]: 'Номер электронной бирки (EID)',
  [ParlorExportStatusFileTagFieldEnum.Trans]: 'Номер транспондера (TRANS)',
};
