import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { BlueprintRoleColor } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import {
  Button,
  ButtonProps,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Input } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { getTmpId } from '~/shared/helpers/string';

import { BLUEPRINT_ROLE_SCHEMA } from '~/entities/blueprintRoles';

import panelStyles from '~/styles/modules/panel.module.scss';

import { EditCompanyFormType } from '../..';
import companyFieldArraysStyles from '../../companyFieldArrays.module.scss';
import { BlueprintRoleColorSelect } from '../BlueprintRoleColorSelect';
import { getNextRoleColor } from './helpers';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditRolesArrayFieldsForm: React.FC<Props> = ({ className }) => {
  const formContext = useFormContext<EditCompanyFormType>();

  const { fields: rolesArrayItems, append: appendToRolesArray } = useFieldArray(
    {
      control: formContext.control,
      name: 'blueprintRoles',
      keyName: 'uniqKey',
    }
  );

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    theme: ButtonThemes.accent,
    variant: ButtonVariants.secondary,
    onPress: () =>
      appendToRolesArray({
        ...BLUEPRINT_ROLE_SCHEMA.getDefault(),
        id: getTmpId('role'),
        color: getNextRoleColor(
          (
            formContext.getValues('blueprintRoles') ?? []
          ).map<BlueprintRoleColor>(R.prop('color'))
        ),
      }),
    children: 'Добавить должность',
  } satisfies ButtonProps;

  if (!rolesArrayItems.length) {
    return (
      <div className={clsx('p-24', className, panelStyles.panel)}>
        <DataBlockedMessage
          {...{
            className: 'p-24',
            message: 'Должности пока не добавлены',
            buttonProps: addButtonProps,
          }}
        />
      </div>
    );
  }

  return (
    <div className={clsx(styles.root, className)}>
      <div className={companyFieldArraysStyles.tableHeader}>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Цвет
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Название
        </Typography>
      </div>
      {rolesArrayItems.map((roleFields, roleIndex) => (
        <div key={roleFields.id} className={companyFieldArraysStyles.tableRow}>
          <BlueprintRoleColorSelect
            {...{
              name: `blueprintRoles.${roleIndex}.color`,
            }}
          />
          <Input
            {...{
              name: `blueprintRoles.${roleIndex}.name`,
            }}
          />
        </div>
      ))}
      <Button
        {...{
          ...addButtonProps,
          size: ButtonSizes.small24,
        }}
      />
    </div>
  );
};
