import { VitalityFilter } from '@graphql-types';
import R from 'ramda';

import { SelectThemes } from '~/shared/components/Select';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { useCowSelect } from '~/entities/cows';
import { useFarmsFilter } from '~/entities/farms';

import { AnalyticsSearchParams } from '~/features/analytics';

/**
 * Hook for reusing analytics filters
 */
export const useAnalyticsFilters = () => {
  const { farmId, renderFarmsSelectElement } = useFarmsFilter();

  const { cowIds, setCowIds } = useSearchParamsState<AnalyticsSearchParams>();

  // Cows filter
  const { renderSelectElement: renderCowSelectElement } = useCowSelect({
    queryOptions: {
      variables: {
        vitalityFilter: VitalityFilter.All,
      },
    },
    selectProps: {
      label: 'По животным',
      className: 'default-control',
      name: 'cowIds',
      theme: SelectThemes.light,
      isMulti: true,
      isClearable: true,
      rawValue: cowIds,
      onValueChange: newValue => setCowIds(newValue.map(R.prop('id'))),
    },
  });

  return {
    farmId,
    renderFarmsSelectElement,

    cowIds,
    renderCowSelectElement,
  };
};
