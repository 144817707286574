import {
  CustomReportOrderEnum,
  CustomReportSortByKindEnum,
  CustomReportValueFormulaKindEnum,
  CustomReportValueViewKindEnum,
} from '@graphql-types';
import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { BLUEPRINT_SOURCE_FIELD_VALUE_SCHEMA } from '~/entities/blueprintSourceFields';

/**
 * Form id for pivot table edit form
 */
export const CUSTOM_REPORT_PIVOT_SETTINGS_FORM_ID =
  'CustomReportPivotSettingsForm';

/**
 * Column view kind, with float numbers that should be formatted with percent
 */
export const PERCENT_FORMATTING_VIEW_KINDS: (
  | CustomReportValueViewKindEnum
  | undefined
)[] = [
  CustomReportValueViewKindEnum.PercentByRow,
  CustomReportValueViewKindEnum.PercentByColumn,
  CustomReportValueViewKindEnum.PercentByTotal,
];

/**
 * Available value formulas dict
 */
export const CUSTOM_REPORT_VALUE_FORMULA_KIND_DICT: Record<
  CustomReportValueFormulaKindEnum,
  string
> = {
  [CustomReportValueFormulaKindEnum.Sum]: 'Сумма',
  [CustomReportValueFormulaKindEnum.Average]: 'Среднее',
  [CustomReportValueFormulaKindEnum.Count]: 'Количество числ. элементов',
  [CustomReportValueFormulaKindEnum.Counta]: 'Количество всех элементов',
  [CustomReportValueFormulaKindEnum.CountUnique]: 'Количество уник. значений',
  [CustomReportValueFormulaKindEnum.Max]: 'Максимум',
  [CustomReportValueFormulaKindEnum.Min]: 'Минимум',
  [CustomReportValueFormulaKindEnum.Median]: 'Медиана',
};

/**
 * Available value view kinds dict
 */
export const CUSTOM_REPORT_VALUE_VIEW_KIND_DICT: Record<
  CustomReportValueViewKindEnum,
  string
> = {
  [CustomReportValueViewKindEnum.Default]: 'По умолчанию',
  [CustomReportValueViewKindEnum.PercentByRow]: 'Процент от строки',
  [CustomReportValueViewKindEnum.PercentByColumn]: 'Процент от колонки',
  [CustomReportValueViewKindEnum.PercentByTotal]: 'Процент от общего итога',
};

/**
 * Available row or column sorting order dict
 */
export const CUSTOM_REPORT_ORDER_DICT: Record<CustomReportOrderEnum, string> = {
  [CustomReportOrderEnum.Asc]: 'По возрастанию',
  [CustomReportOrderEnum.Desc]: 'По убыванию',
};

/**
 * Form schema for pivot table value config
 */
export const PIVOT_TABLE_VALUE_SCHEMA = yup.object({
  formula: oneOfEnum(CustomReportValueFormulaKindEnum)
    .default(CustomReportValueFormulaKindEnum.Count)
    .required(),
  view: oneOfEnum(CustomReportValueViewKindEnum)
    .default(CustomReportValueViewKindEnum.Default)
    .required(),
  blueprintSourceFieldID: yup.string().required(), // ID!
});

const PIVOT_TABLE_SORT_BY_SCHEMA = yup.object({
  kind: oneOfEnum(CustomReportSortByKindEnum)
    .default(CustomReportSortByKindEnum.Default)
    .required(),
  sortingValue: yup
    .object({
      valueKey: PIVOT_TABLE_VALUE_SCHEMA.required(),
      blueprintSourceFieldValue: BLUEPRINT_SOURCE_FIELD_VALUE_SCHEMA.nullable(),
    })
    .nullable()
    .default(null),
});

/**
 * Form schema for pivot table row or column config
 */
export const PIVOT_TABLE_ROW_OR_COLUMN_SCHEMA = yup.object({
  order: oneOfEnum(CustomReportOrderEnum)
    .default(CustomReportOrderEnum.Asc)
    .required(),
  blueprintSourceFieldID: yup.string().required(), // ID!
  withTotal: yup.boolean().default(true),
  sortBy: PIVOT_TABLE_SORT_BY_SCHEMA,
});

/**
 * Form schema for pivot table settings
 */
export const PIVOT_TABLE_FORM_SCHEMA = yup.object({
  rows: yup.array(PIVOT_TABLE_ROW_OR_COLUMN_SCHEMA).default([]),
  columns: yup.array(PIVOT_TABLE_ROW_OR_COLUMN_SCHEMA).default([]),
  values: yup.array(PIVOT_TABLE_VALUE_SCHEMA).default([]),
});
