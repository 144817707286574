import { AnalyticalMetricEnum, FemaleAnimalKind } from '@graphql-types';
import { lightFormat, sub } from 'date-fns';

import {
  AnalyticsGroupingPeriodEnum,
  AnalyticsMetricPeriodEnum,
} from '~/shared/graphql';
import { DateRange } from '~/shared/helpers/date';

import { MetricConfig } from './types';

interface FilteringPeriod {
  groupingPeriod: AnalyticsGroupingPeriodEnum;
  timespan: DateRange;
}

export const FILTER_PERIODS_MAP: Record<
  AnalyticsMetricPeriodEnum,
  FilteringPeriod
> = {
  [AnalyticsMetricPeriodEnum.Week]: {
    groupingPeriod: AnalyticsGroupingPeriodEnum.Day,
    timespan: {
      since: lightFormat(sub(new Date(), { days: 7 }), 'yyyy-MM-dd'),
      till: lightFormat(new Date(), 'yyyy-MM-dd'),
    },
  },
  [AnalyticsMetricPeriodEnum.Month]: {
    groupingPeriod: AnalyticsGroupingPeriodEnum.Week,
    timespan: {
      since: lightFormat(sub(new Date(), { months: 1 }), 'yyyy-MM-dd'),
      till: lightFormat(new Date(), 'yyyy-MM-dd'),
    },
  },
  [AnalyticsMetricPeriodEnum.Year]: {
    groupingPeriod: AnalyticsGroupingPeriodEnum.Month,
    timespan: {
      since: lightFormat(sub(new Date(), { years: 1 }), 'yyyy-MM-dd'),
      till: lightFormat(new Date(), 'yyyy-MM-dd'),
    },
  },
};

/**
 * Names of the metric categories
 */
export const METRIC_CATEGORIES_DICT: Record<FemaleAnimalKind, string> = {
  [FemaleAnimalKind.Cow]: 'Коровы',
  [FemaleAnimalKind.Heifer]: 'Тёлки',
};

/**
 * Available metric configs dict
 */
export const METRIC_CONFIGS: Record<AnalyticalMetricEnum, MetricConfig> = {
  // herd state
  [AnalyticalMetricEnum.CalvedCowsCount]: {
    name: 'Отелившиеся коровы',
    category: FemaleAnimalKind.Cow,
  },
  [AnalyticalMetricEnum.CowsCount]: {
    name: 'Общее количество голов',
    category: FemaleAnimalKind.Cow,
  },
  [AnalyticalMetricEnum.CowsInDryCount]: {
    category: FemaleAnimalKind.Cow,
    name: 'В сухостое',
  },
  [AnalyticalMetricEnum.CowsInMilkingCount]: {
    name: 'В доении',
    category: FemaleAnimalKind.Cow,
  },
  [AnalyticalMetricEnum.DeadCount]: {
    name: 'Умерло',
    category: FemaleAnimalKind.Cow,
  },
  [AnalyticalMetricEnum.HeifersCount]: {
    name: 'Тёлки',
    category: FemaleAnimalKind.Cow,
  },
  [AnalyticalMetricEnum.BullsCount]: {
    name: 'Быки',
    category: FemaleAnimalKind.Cow,
  },
  [AnalyticalMetricEnum.SoldCount]: {
    name: 'Продано',
    category: FemaleAnimalKind.Cow,
  },

  // reproduction
  [AnalyticalMetricEnum.HeatDetectionRate]: {
    name: 'Выявление (HDR)',
    detailDataPageUrl: 'hdr-cows-table',
    category: FemaleAnimalKind.Cow,
    dataField: 'hdrCowsData',
  },
  [AnalyticalMetricEnum.HeatDetectionRateHeifers]: {
    name: 'Выявление (HDR)',
    detailDataPageUrl: 'hdr-heifers-table',
    category: FemaleAnimalKind.Heifer,
    dataField: 'hdrHeifersData',
  },
  [AnalyticalMetricEnum.PregnancyRate]: {
    name: 'Стельность (PR)',
    detailDataPageUrl: 'pr-cows-table',
    category: FemaleAnimalKind.Cow,
    dataField: 'prCowsData',
  },
  [AnalyticalMetricEnum.PregnancyRateHeifers]: {
    name: 'Стельность (PR)',
    detailDataPageUrl: 'pr-heifers-table',
    category: FemaleAnimalKind.Heifer,
    dataField: 'prHeifersData',
  },
  [AnalyticalMetricEnum.ConceptionRate]: {
    name: 'Плодотворные осеменения (CR)',
    shortName: 'Плодотворн. осемен. (CR)',
    detailDataPageUrl: 'cr-cows-table',
    category: FemaleAnimalKind.Cow,
    dataField: 'crCowsData',
  },
  [AnalyticalMetricEnum.ConceptionRateHeifers]: {
    name: 'Плодотворные осеменения (CR)',
    shortName: 'Плодотворн. осемен. (CR)',
    detailDataPageUrl: 'cr-heifers-table',
    category: FemaleAnimalKind.Heifer,
    dataField: 'crHeifersData',
  },
};
