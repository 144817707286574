import React, { ReactNode } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { PressProps, usePress } from '@react-aria/interactions';
import clsx from 'clsx';

import { Icon, IconProps, IconVariants } from '~/shared/components/Icon';
import { Loader } from '~/shared/components/Loader';
import { Tooltip, TooltipProps } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import COMPONENT_TOKENS from '~/styles/__generated__/component-tokens.json';
import { ButtonSizes } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';

import styles from './index.module.scss';

/**
 * Possible button display variants
 */
export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  ghost = 'ghost',
}

/**
 * Possible button color themes
 */
export enum ButtonThemes {
  accent = 'accent',
  neutral = 'neutral',
  destructive = 'destructive',
}

const BUTTON_TYPOGRAPHY: Record<ButtonSizes, TypographyVariants> = {
  [ButtonSizes.large48]: TypographyVariants.bodyMediumStrong,
  [ButtonSizes.medium36]: TypographyVariants.bodySmallStrong,
  [ButtonSizes.small24]: TypographyVariants.descriptionLargeStrong,
};

export interface ButtonProps
  extends Omit<React.ComponentProps<'button'>, 'disabled'> {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Button display variant
   */
  variant?: ButtonVariants;
  /**
   * Button color theme
   */
  theme?: ButtonThemes;
  /**
   * Button size
   */
  size?: ButtonSizes;

  /**
   * If true, than the button is disabled
   */
  isDisabled?: boolean;
  /**
   * If true, the loader is shown instead of children
   */
  isLoading?: boolean;

  /**
   * Tooltip for the button
   */
  tooltip?: ReactNode;
  /**
   * Additional props for the tooltip
   */
  tooltipProps?: Partial<TooltipProps>;
  /**
   * If passed, button is rendered with icon
   */
  iconVariant?: IconVariants;
  /**
   * If passed, button is rendered with icon on the right
   */
  rightIconVariant?: IconVariants;
  /**
   * Additional props for all icons
   */
  iconProps?: Partial<Omit<IconProps, 'ref' | 'tooltip' | 'tooltipProps'>>;
  /**
   * If true, skeleton is not rendered, the button is always shown (default - true)
   */
  isStaticContent?: boolean;

  /**
   * Called, when button is pressed via mouse or keyboard
   */
  onPress?: PressProps['onPress'];

  /**
   * enable button style in press mode
   */
  isPressed?: boolean;
  /**
   * Don't use this prop, use onPress for accessibility reasons
   */
  onClick?: undefined;
}

export type ButtonPropsWithoutRef = Omit<ButtonProps, 'ref'>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,

      variant = ButtonVariants.primary,
      theme = variant === ButtonVariants.primary
        ? ButtonThemes.accent
        : ButtonThemes.neutral,
      size = ButtonSizes.medium36,

      isDisabled,
      isLoading,

      tooltip,
      tooltipProps,
      iconVariant,
      rightIconVariant,
      iconProps,
      isStaticContent = true,

      onPress,
      isPressed: isPressedProp,

      children,

      ...other
    },
    ref
  ) => {
    const withOnlyIcon = !!iconVariant && !children;

    const { pressProps, isPressed } = usePress({
      isDisabled: isDisabled || isLoading,
      onPress,
      isPressed: isPressedProp,
    });

    // :focus-visible is not working with usePress correctly, so we use react-aria solution
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
      <Tooltip content={tooltip} isDisabled={!tooltip} {...tooltipProps}>
        <button
          {...{
            ref,
            disabled: isDisabled,
            type: 'button',
            className: clsx(
              styles.root,
              className,
              styles[variant],
              styles[theme],
              styles[size],
              {
                [styles.withOnlyIcon]: withOnlyIcon,
                [styles.loading]: isLoading,
                [styles.pressed]: isPressed,
                [styles.focused]: isFocusVisible,
                [styles.disabled]: isDisabled,
              }
            ),
            style: {
              '--button-height': COMPONENT_TOKENS.button.size[size],
            } as React.CSSProperties,
            ...mergeProps(pressProps, focusProps, other),
          }}
        >
          {iconVariant && (
            <Icon
              {...{
                className: styles.icon,
                variant: iconVariant,
                size: COMPONENT_TOKENS.button.iconSize[size],
                isStaticContent,
                ...iconProps,
              }}
            />
          )}
          {!!children && (
            <Typography
              {...{
                className: styles.children,
                variant: BUTTON_TYPOGRAPHY[size],
                tag: 'div',
                isStaticContent,
              }}
            >
              {children}
            </Typography>
          )}
          {rightIconVariant && (
            <Icon
              {...{
                className: styles.icon,
                variant: rightIconVariant,
                size: COMPONENT_TOKENS.button.iconSize[size],
                isStaticContent,
                ...iconProps,
              }}
            />
          )}
          {isLoading && (
            <Loader
              {...{
                color:
                  variant === ButtonVariants.primary
                    ? TOKENS.colorAccentOnAccent
                    : TOKENS.colorFgSoft,
                className: styles.loader,
              }}
            />
          )}
        </button>
      </Tooltip>
    );
  }
);

export { ButtonSizes } from '~/styles/__generated__/token-variants';
