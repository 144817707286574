import React from 'react';

import { useRouter } from '@tanstack/react-router';

import { ContextMenu } from '~/shared/components/ContextMenu';
import { MenuItemType } from '~/shared/components/Menu';
import { useAccordion } from '~/shared/hooks/useAccordion';

import { NavigationMenuSectionConfig } from '~/services/layouts/types';
import { useArkaNavigation } from '~/services/navigation';

import { NavigationMenuItem } from '../NavigationMenuItem';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, menu is rendered in collapsed mode with popups for selecting nested links
   */
  isMenuCollapsed: boolean;
  /**
   * If true, item with accordion is open (default - true)
   */
  isOpen?: boolean;
  /**
   * Config to render
   */
  config: NavigationMenuSectionConfig;
  /**
   * Part of parent item path to construct full item path
   */
  parentPath?: string;
  /**
   * Called, when a menu item with a link is pressed
   */
  onLinkPress?: () => void;
  /**
   * Called, when a menu item with children is toggled
   */
  onToggleItem?: (key: string, newIsOpen?: boolean) => void;
}

export const NavigationMenuSection: React.FC<Props> = ({
  className,
  isMenuCollapsed,
  isOpen: isOpenProp = true,
  config,
  parentPath = '',
  onLinkPress,
  onToggleItem,
}) => {
  const isNestedItem = !!parentPath;

  const hasChildren = !!config.children;

  const { urlCompanyId } = useArkaNavigation();
  const { matchRoute } = useRouter();

  const itemChildren = config.children?.map(childConfig => {
    return (
      <NavigationMenuSection
        key={childConfig.path}
        {...{
          config: childConfig,
          parentPath: config.path,
          isMenuCollapsed,
          onLinkPress,
        }}
      />
    );
  });

  const isOpen = !isMenuCollapsed && isOpenProp;

  const {
    shouldRenderContent,

    childrenContainerRef,

    openButtonProps,
    childrenWrapperProps,
  } = useAccordion({
    isDisabled: isMenuCollapsed,
    isOpen,
    onToggle: newIsOpen => onToggleItem?.(config.path, newIsOpen),
  });

  const isSomeChildActiveByIndex = (config.children ?? []).map(
    childConfig => !!matchRoute({ to: childConfig.path }, { fuzzy: true })
  );
  const isSomeChildActive = isSomeChildActiveByIndex.some(Boolean);

  return (
    <div className={className}>
      <div className={styles.itemContainer}>
        <ContextMenu
          {...{
            className,
            tooltip: config.label,
            isDisabled: !isMenuCollapsed,
            tooltipProps: {
              placement: 'right',
            },
            popoverProps: {
              placement: 'right-start',
            },
            items: (config.children ?? []).map(
              (childConfig, childConfigIndex): MenuItemType => {
                return {
                  isSelected: !!isSomeChildActiveByIndex[childConfigIndex],
                  content: childConfig.label,
                  navigateProps: {
                    to: childConfig.path,
                    params: { companyId: urlCompanyId },
                  },
                };
              }
            ),
          }}
        >
          {({ isOpen: isContextMenuOpen, setIsOpen, setIsTooltipOpen }) => {
            return (
              <NavigationMenuItem
                {...{
                  label: config.label,
                  iconVariant: config.iconVariant,
                  isNestedItem,
                  hasChildren,
                  isOpen,
                  isContextMenuOpen,
                  isSomeChildActive,
                  containerProps: hasChildren ? openButtonProps : undefined,
                  linkTo: hasChildren ? undefined : config.path,
                  onPress: () => {
                    if (!hasChildren) {
                      onLinkPress?.();
                    }
                    if (isMenuCollapsed) {
                      setIsTooltipOpen(false);
                      setIsOpen(current => !current);
                    }
                  },
                }}
              />
            );
          }}
        </ContextMenu>
      </div>

      {hasChildren && (
        <div
          {...{
            className: styles.children,
            ...childrenWrapperProps,
          }}
        >
          <div ref={childrenContainerRef}>
            {shouldRenderContent && itemChildren}
          </div>
        </div>
      )}
    </div>
  );
};
