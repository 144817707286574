import { useRef } from 'react';

import { NetworkStatus, useFragment } from '@apollo/client';

import { DocumentNode } from 'graphql';
import R from 'ramda';

import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import {
  AnyFragment,
  DefaultIdInput,
  IdsQueryVariables,
  UsePaginatedQuery,
} from '../../types';
import { getFragmentDocumentName } from './getFragmentDocumentName';

interface MakeUseFragmentFromCacheOrQueryProps<
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends IdsQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
> {
  /**
   * Type name of the fragment
   */
  typeName: string;
  /**
   * Fragment definition doc
   */
  fragment: DocumentNode;
  /**
   * Generated api hook for requesting paginated data
   */
  useQuery: UsePaginatedQuery<QueryData, QueryVariables>;
  /**
   * Getter for actual fragment from the query result
   */
  getItemFromQueryData: (data: QueryData) => Fragment | undefined;
}

/**
 * Fabric to make a hook that retrieves a fragment from the cache or,
 * if it is not in the cache, queries an entity from the backend.
 */
export const makeUseFragmentFromCacheOrQuery = <
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends IdsQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
>({
  typeName,
  fragment,
  useQuery,
  getItemFromQueryData,
}: MakeUseFragmentFromCacheOrQueryProps<
  Fragment,
  QueryData,
  QueryVariables,
  IdInput
>) => {
  const fragmentName = getFragmentDocumentName(fragment);

  return (id?: string | null) => {
    const { complete, data: fragmentData } = useFragment<Fragment>({
      fragment,
      fragmentName,
      from: {
        __typename: typeName,
        id,
      },
    });

    const cacheFragment =
      complete && !R.isEmpty(fragmentData) ? fragmentData : undefined;

    // Try to get the fragment from backend if there is no fragment in cache.
    const {
      data: queryData,
      loading: isLoading,
      refetch,
      networkStatus,
    } = useQuery({
      variables: {
        // For now we don't support id inputs other than strings here
        ids: normalizeToArrayOrUndefined(id) ?? [],
      } as QueryVariables,
      skip: !!cacheFragment || !id,
      notifyOnNetworkStatusChange: true,
    });

    const queryFragment = queryData
      ? (getItemFromQueryData(queryData) ?? null)
      : null;

    // Workaround for first refetch returning undefined data and wrong networkStatus
    // https://github.com/apollographql/apollo-client/issues/10391
    const queryFragmentRef = useRef(queryFragment ?? undefined);
    if (queryFragment) {
      queryFragmentRef.current = queryFragment;
    }

    return {
      fragment: queryFragment ?? cacheFragment ?? queryFragmentRef.current,
      refetch,
      isLoading: networkStatus === NetworkStatus.loading && isLoading,
      isRefetching:
        (networkStatus === NetworkStatus.refetch ||
          networkStatus === NetworkStatus.setVariables) &&
        isLoading,
    };
  };
};
