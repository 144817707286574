/**
 * Possible modal names, registered in the system
 */
export enum ModalNames {
  confirmModal = 'confirmModal',
  systemInfoModal = 'systemInfoModal',
  loadTestMilkingPartialResultsModal = 'loadTestMilkingPartialResultsModal',
  loadTestMilkingCsvModal = 'loadTestMilkingCsvModal',
  editSemenDoseModal = 'editSemenDoseModal',
  editEventModal = 'editEventModal',
  editCustomReportModal = 'editCustomReportModal',
  editCustomMilkingReportModal = 'editCustomMilkingReportModal',
  uploadCowFileModal = 'uploadCowFileModal',
  setHerriotSettingsModal = 'setHerriotSettingsModal',
  editCompanyModal = 'editCompanyModal',
  editCowModal = 'editCowModal',
  monitorScheduleSettingsModal = 'monitorScheduleSettingsModal',
  createMonitorGroupModal = 'createMonitorGroupModal',
  editMonitorEntryModal = 'editMonitorEntryModal',
  calculateMonitorModal = 'calculateMonitorModal',
  editMilkingStartModal = 'editMilkingStartModal',
  editMilkingParlorModal = 'editMilkingParlorModal',
  editMilkingParlorIntervalModal = 'editMilkingParlorIntervalModal',
  applyCutCodeModal = 'applyCutCodeModal',
  editLivestockForecastSettingModal = 'editLivestockForecastSettingModal',
  customReportSettingsModal = 'customReportSettingsModal',
  moveCowsResultModal = 'moveCowsResultModal',
  moveCowsModal = 'moveCowsModal',
  addEventIdsToListModal = 'addEventIdsToListModal',
  addDiseaseIdsToListModal = 'addDiseaseIdsToListModal',
  addCowIdsToListModal = 'addCowIdsToListModal',
  addCowByKeyModal = 'addCowByKeyModal',
  setMetricTargetModal = 'setMetricTargetModal',
  uploadCowsCsvModal = 'uploadCowsCsvModal',
  editPenGroupModal = 'editPenGroupModal',
  editBullModal = 'editBullModal',
  deleteBullModal = 'deleteBullModal',
  downloadCowEventsCsvModal = 'downloadCowEventsCsvModal',
  cowChartSettingsModal = 'cowChartSettingsModal'
}