import React from 'react';

import { Link } from '@tanstack/react-router';
import clsx from 'clsx';

import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import {
  isSkeletonPlaceholder,
  Skeleton,
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatTimeRange } from '~/shared/helpers/date';

import { useArkaNavigation } from '~/services/navigation';

import { MilkingParlorIntervalFragment } from '../../../../gql/fragments/milkingParlorInterval.graphql';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Interval to render
   */
  interval: MilkingParlorIntervalFragment | SkeletonPlaceholder;
}

const SKELETON_HEIGHT_PX = 48;

export const MilkingParlorInterval: React.FC<Props> = ({
  className,
  interval,
}) => {
  const { urlCompanyId } = useArkaNavigation();
  const { renderWithSkeleton } = useSkeletonContext();

  return renderWithSkeleton(
    <Skeleton.Block height={SKELETON_HEIGHT_PX} />,

    <Link
      {...{
        to: '/$companyId/user/entities/milking-parlors/intervals/$intervalId',
        params: {
          intervalId: !isSkeletonPlaceholder(interval) ? interval.id : '',
          companyId: urlCompanyId,
        },
        className: clsx(styles.root, className),
      }}
    >
      <Typography variant={TypographyVariants.bodySmallStrong}>
        {formatTimeRange(interval.intervalStart, interval.intervalEnd)}
      </Typography>
      <Icon
        {...{
          className: 'text-accent',
          variant: IconVariants.chevronDown,
          rotate: RotateVariants.left,
        }}
      />
    </Link>
  );
};
