import * as Types from '../../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ConceptionRateChartDaysInMilkCandleFragment = { __typename: 'ConceptionRateChartDaysInMilkCandle', meanValue: number, maxValue: number, daysInMilk: number };

export const ConceptionRateChartDaysInMilkCandleFragmentDoc = gql`
    fragment ConceptionRateChartDaysInMilkCandle on ConceptionRateChartDaysInMilkCandle {
  __typename
  meanValue
  maxValue
  daysInMilk
}
    `;