import * as Types from '../../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ConceptionRateChartInseminationSchemeCandleFragment = { __typename: 'ConceptionRateChartInseminationSchemeCandle', meanValue: number, maxValue: number, inseminationScheme?: { __typename: 'InseminationScheme', id: string, name: string } | null };

export const ConceptionRateChartInseminationSchemeCandleFragmentDoc = gql`
    fragment ConceptionRateChartInseminationSchemeCandle on ConceptionRateChartInseminationSchemeCandle {
  __typename
  meanValue
  maxValue
  inseminationScheme {
    __typename
    id
    name
  }
}
    `;