import { useCallback } from 'react';

import { useBlueprintSourceFields } from '~/entities/blueprintSourceFields';
import { formatSourceFieldValue } from '~/entities/customReports';
import { CustomReportChartYAxisFragment } from '~/entities/customReports/gql/fragments/customReportChartYAxis.graphql';

import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';
import {
  getValueColumnName,
  isCustomReportHeaderColumn,
  isValidPivotTable,
} from '~/features/customReportPivot';

import { CustomReportXAxisFormType, CustomReportYAxisFormType } from '../types';

interface UseCustomReportChartAxesProps {
  /**
   * Launch result to get blueprint source field values from nested columns that can be added to axes
   */
  customReportLaunchResult: CustomReportLaunchResultFragment;
}

/**
 * Hook for creating custom report chart axes names
 */
export const useCustomReportChartAxes = ({
  customReportLaunchResult,
}: UseCustomReportChartAxesProps) => {
  const { blueprintLaunchResult } = customReportLaunchResult;
  const availableSourceFields = blueprintLaunchResult.columnSourceFields;
  const { getSourceFieldById } = useBlueprintSourceFields(
    availableSourceFields
  );

  const { pivotTable } = customReportLaunchResult;

  const launchHeaderColumn = isValidPivotTable(pivotTable)
    ? pivotTable.columns.find(isCustomReportHeaderColumn)
    : undefined;

  const launchHeaderColumnName =
    getSourceFieldById(launchHeaderColumn?.blueprintSourceField.id)?.name ?? '';

  const getXAxisName = useCallback(
    (xAxisConfig: CustomReportXAxisFormType | null) => {
      if (!xAxisConfig) {
        return '';
      }

      return xAxisConfig.valueKey
        ? getValueColumnName(xAxisConfig.valueKey, getSourceFieldById)
        : getSourceFieldById(xAxisConfig.blueprintSourceFieldID)?.name;
    },
    [getSourceFieldById]
  );

  const getYAxisName = useCallback(
    (
      yAxisConfig:
        | Partial<CustomReportChartYAxisFragment | CustomReportYAxisFormType>
        | undefined,
      isFull = true
    ) => {
      if (!yAxisConfig) {
        return '';
      }
      if ('valueKey' in yAxisConfig && yAxisConfig.valueKey) {
        return getValueColumnName(yAxisConfig.valueKey, getSourceFieldById);
      }

      if ('blueprintSourceFieldValue' in yAxisConfig) {
        const sourceFieldName = isFull ? launchHeaderColumnName : '';
        return `${sourceFieldName} ${formatSourceFieldValue(yAxisConfig.blueprintSourceFieldValue)}`;
      }

      return '';
    },
    [getSourceFieldById, launchHeaderColumn]
  );

  return {
    launchHeaderColumnName,
    getXAxisName,
    getYAxisName,
  };
};

/**
 * Return type for the hook with custom report chart axes logic
 */
export type UseCustomReportChartAxesInterface = ReturnType<
  typeof useCustomReportChartAxes
>;
