import { capitalize } from '~/shared/helpers/string';

import { ColorVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';

/**
 * Each color has this basic tints, but using ColorShades is more preferable
 */
type ColorTints =
  | 0
  | 10
  | 30
  | 50
  | 75
  | 100
  | 150
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 925
  | 950
  | 990
  | 1000;

/**
 * Each color variant has the same shades, so we can get its name by mixing the variant and the shade
 * @public -- this is a design system contract, so we ignore some unused members for knip
 */
export enum ColorShades {
  default = 'default',
  soft = 'soft',
  muted = 'muted',
  hover = 'hover',
  active = 'active',
  containerDefault = 'containerDefault',
  containerSoft = 'containerSoft',
  containerMuted = 'containerMuted',
  containerHover = 'containerHover',
  containerActive = 'containerActive',
  opaqueContainerDefault = 'opaqueContainerDefault',
  opaqueContainerSoft = 'opaqueContainerSoft',
  opaqueContainerMuted = 'opaqueContainerMuted',
  opaqueContainerHover = 'opaqueContainerHover',
  opaqueContainerActive = 'opaqueContainerActive',
}

/**
 * Gets a color value of the design system color by passed variant
 * and tint (number) or shade (pre-defined common use cases)
 */
export const getColorTokenValue = (
  variant: ColorVariants,
  shadeOrTint: ColorShades | ColorTints
) => {
  // Color variants with number at the end has a '_' delimiter when used with number tints
  const shadeDelimiter =
    typeof shadeOrTint === 'number' && variant.match(/\d$/) ? '_' : '';
  return TOKENS[
    `color${capitalize(variant)}${shadeDelimiter}${capitalize(shadeOrTint.toString())}` as keyof typeof TOKENS
  ] as string;
};

/**
 * Creates an iterator, that yields all possible combinations of passed variants and shades.
 * First it iterates through variants and yields each possible variant with the first shade, then with the second etc.
 * If you pass true into the .next() call, iterator will start from the beginning.
 */
export const makeColorIterator = (
  variants: ColorVariants[],
  shadeOrTints: (ColorShades | ColorTints)[]
) => {
  const totalCombinations = variants.length * shadeOrTints.length;

  const generator = function* () {
    let callsCount = 0;

    while (true) {
      const colorIndex = callsCount % totalCombinations;
      const shadeIndex = Math.floor(colorIndex / variants.length);
      const variantIndex = colorIndex - shadeIndex * variants.length;

      const shouldReset: boolean | undefined = yield getColorTokenValue(
        variants[variantIndex],
        shadeOrTints[shadeIndex]
      );

      callsCount += 1;
      if (shouldReset) {
        callsCount = 0;
      }
    }
  };

  return generator();
};
