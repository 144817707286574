import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DiseaseFragmentDoc } from '../../../events/gql/fragments/disease.graphql';
import { InjectionFragmentDoc } from '../../../events/gql/fragments/injection.graphql';
import { InseminationSchemeFragmentDoc } from '../../../events/gql/fragments/inseminationScheme.graphql';
import { ProtocolFragmentDoc } from '../../../events/gql/fragments/protocol.graphql';
import { UserEventFragmentDoc } from '../../../events/gql/fragments/userEvent.graphql';
import { FarmFragmentDoc } from '../../../farms/gql/fragments/farm.graphql';
import { PenGroupShortFragmentDoc } from '../../../penGroups/gql/fragments/penGroupShort.graphql';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
import { BullFragmentDoc } from '../../../bulls/gql/fragments/bull.graphql';
import { EmployeeFragmentDoc } from '../../../employees/gql/fragments/employee.graphql';
import { CalvingFragmentDoc } from '../../../calvings/gql/fragments/calving.graphql';
import { InseminationShortFragmentDoc } from '../../../inseminations/gql/fragments/inseminationShort.graphql';
import { SemenDoseFragmentDoc } from '../../../semenDoses/gql/fragments/semenDose.graphql';
import { DateHardcodedValueFragmentDoc } from './dateHardcodedValue.graphql';
import { DatetimeHardcodedValueFragmentDoc } from './datetimeHardcodedValue.graphql';
import { FloatHardcodedValueFragmentDoc } from './floatHardcodedValue.graphql';
import { IntHardcodedValueFragmentDoc } from './intHardcodedValue.graphql';
import { StrHardcodedValueFragmentDoc } from './strHardcodedValue.graphql';
export type SourceFieldValue_Bull_Fragment = { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null };

export type SourceFieldValue_Calving_Fragment = { __typename: 'Calving', id: string, happenedAt: string, isAbortion: boolean, difficultyScore: number, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, employee?: { __typename: 'Employee', id: string, firstName: string, lastName: string } | null };

export type SourceFieldValue_Cow_Fragment = { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string };

export type SourceFieldValue_Disease_Fragment = { __typename: 'Disease', id: string, name: string, kind: Types.EventKindEnum, protocols: Array<{ __typename: 'Protocol', id: string }> };

export type SourceFieldValue_Employee_Fragment = { __typename: 'Employee', id: string, comment: string, number: string, firstName: string, middleName: string, lastName: string, company: { __typename: 'Company', id: string } };

export type SourceFieldValue_Farm_Fragment = { __typename: 'Farm', id: string, name: string, number: string };

export type SourceFieldValue_Injection_Fragment = { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean };

export type SourceFieldValue_Insemination_Fragment = { __typename: 'Insemination', id: string, happenedAt: string, movedToGroupWithBullAt?: string | null, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } };

export type SourceFieldValue_InseminationScheme_Fragment = { __typename: 'InseminationScheme', id: string, name: string, kind: Types.EventKindEnum, code: string, description: string };

export type SourceFieldValue_PenGroup_Fragment = { __typename: 'PenGroup', id: string, name: string, identifier: number };

export type SourceFieldValue_Protocol_Fragment = { __typename: 'Protocol', id: string, name: string, kind: Types.EventKindEnum, expectedDaysOnProtocol?: number | null, daysUntilCheckAfterLastThreatment?: number | null, daysMeatWithhold: number, daysMilkWithhold: number, totalTreatmentCost?: number | null, penGroup?: { __typename: 'PenGroup', id: string } | null, protocolInjections: Array<{ __typename: 'ProtocolInjection', id: string, comment: string, dayNumber: number, injection?: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } | null }> };

export type SourceFieldValue_SemenDose_Fragment = { __typename: 'SemenDose', id: string, deliveryDate?: string | null, studCode: string, dosesCount: number, batchNumber: string, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } };

export type SourceFieldValue_UserEvent_Fragment = { __typename: 'UserEvent', id: string, name: string, kind: Types.EventKindEnum };

export type SourceFieldValue_StrHardcodedValue_Fragment = { __typename: 'StrHardcodedValue', strValue: string };

export type SourceFieldValue_IntHardcodedValue_Fragment = { __typename: 'IntHardcodedValue', intValue: number };

export type SourceFieldValue_FloatHardcodedValue_Fragment = { __typename: 'FloatHardcodedValue', floatValue: number };

export type SourceFieldValue_DatetimeHardcodedValue_Fragment = { __typename: 'DatetimeHardcodedValue', datetimeValue: string };

export type SourceFieldValue_DateHardcodedValue_Fragment = { __typename: 'DateHardcodedValue', dateValue: string };

export type SourceFieldValueFragment = SourceFieldValue_Bull_Fragment | SourceFieldValue_Calving_Fragment | SourceFieldValue_Cow_Fragment | SourceFieldValue_Disease_Fragment | SourceFieldValue_Employee_Fragment | SourceFieldValue_Farm_Fragment | SourceFieldValue_Injection_Fragment | SourceFieldValue_Insemination_Fragment | SourceFieldValue_InseminationScheme_Fragment | SourceFieldValue_PenGroup_Fragment | SourceFieldValue_Protocol_Fragment | SourceFieldValue_SemenDose_Fragment | SourceFieldValue_UserEvent_Fragment | SourceFieldValue_StrHardcodedValue_Fragment | SourceFieldValue_IntHardcodedValue_Fragment | SourceFieldValue_FloatHardcodedValue_Fragment | SourceFieldValue_DatetimeHardcodedValue_Fragment | SourceFieldValue_DateHardcodedValue_Fragment;

export const SourceFieldValueFragmentDoc = gql`
    fragment SourceFieldValue on SourceFieldValue {
  ...Disease
  ...Injection
  ...InseminationScheme
  ...Protocol
  ...UserEvent
  ...Farm
  ...PenGroupShort
  ...CowShort
  ...Bull
  ...Employee
  ...Calving
  ...InseminationShort
  ...SemenDose
  ...DateHardcodedValue
  ...DatetimeHardcodedValue
  ...FloatHardcodedValue
  ...IntHardcodedValue
  ...StrHardcodedValue
}
    ${DiseaseFragmentDoc}
${InjectionFragmentDoc}
${InseminationSchemeFragmentDoc}
${ProtocolFragmentDoc}
${UserEventFragmentDoc}
${FarmFragmentDoc}
${PenGroupShortFragmentDoc}
${CowShortFragmentDoc}
${BullFragmentDoc}
${EmployeeFragmentDoc}
${CalvingFragmentDoc}
${InseminationShortFragmentDoc}
${SemenDoseFragmentDoc}
${DateHardcodedValueFragmentDoc}
${DatetimeHardcodedValueFragmentDoc}
${FloatHardcodedValueFragmentDoc}
${IntHardcodedValueFragmentDoc}
${StrHardcodedValueFragmentDoc}`;