import React, { useMemo } from 'react';

import R from 'ramda';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { BlueprintInputNode } from '~/features/blueprintEdit';

import { SortableBlueprintOrderableEntitiesList } from '../SortableBlueprintOrderableEntitiesList';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Blueprint input nodes to display
   */
  inputNodes: BlueprintInputNode[];
  /**
   * Inputs ordering from bluepring general settings
   */
  orderedInputIDs: string[];
  /**
   * Called, when the order of the fields in the list is changed
   */
  onInputsOrderChange: (inputIds: string[]) => void;
}

export const ActionView: React.FC<Props> = ({
  className,

  inputNodes,
  orderedInputIDs,

  onInputsOrderChange,
}) => {
  const sortedInputs = useMemo(
    () => R.sortBy(input => orderedInputIDs.indexOf(input.id), inputNodes),
    [orderedInputIDs, inputNodes]
  );

  return (
    <div className={className}>
      <Typography variant={TypographyVariants.heading4} className="mb-8">
        Порядок ввода данных
      </Typography>

      {!sortedInputs.length && (
        <Typography
          variant={TypographyVariants.bodySmall}
          className="text-muted"
        >
          Не создано ни одного вводимого параметра
        </Typography>
      )}
      <div className="flex flex-col">
        <SortableBlueprintOrderableEntitiesList
          {...{
            withSelectableEntities: false,
            entities: sortedInputs,
            onEntitiesOrderChange: onInputsOrderChange,
          }}
        />
      </div>
    </div>
  );
};
