import React from 'react';

import clsx from 'clsx';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { BlueprintRoleBadges } from '~/entities/blueprintRoles';
import { CustomReportFragment } from '~/entities/customReports/gql/fragments/customReport.graphql';
import { CustomReportsQueryVariables } from '~/entities/customReports/gql/queries/customReports.graphql';

import { useCustomReportSettingsModal } from '~/features/customReportLaunch';

import panelStyles from '~/styles/modules/panel.module.scss';

import { CustomReportCardMenu } from '../CustomReportCardMenu';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render
   */
  customReport: CustomReportFragment | SkeletonPlaceholder;
  /**
   * Gql custom reports query variables
   */
  queryVariables: CustomReportsQueryVariables;
}

export const CustomReportCard: React.FC<Props> = ({
  className,
  customReport,
  queryVariables,
}) => {
  const { open: openCustomReportSettingsModal } =
    useCustomReportSettingsModal();

  return (
    <div
      {...{
        className: clsx(styles.root, panelStyles.panel, className),
        onClick: () => {
          if (isSkeletonPlaceholder(customReport)) return;

          openCustomReportSettingsModal({ customReportId: customReport.id });
        },
      }}
    >
      <div className="flex items-start">
        <Typography
          {...{
            className: 'mr-12',
            variant: TypographyVariants.bodyMediumStrong,
          }}
        >
          {customReport.name}
        </Typography>
        {!isSkeletonPlaceholder(customReport) && (
          <CustomReportCardMenu
            {...{
              className: 'ml-a',
              customReport,
              queryVariables,
            }}
          />
        )}
      </div>
      <div className="mt-a flex flex-col gap-8">
        <Typography
          {...{
            className: 'text-muted',
            variant: TypographyVariants.bodySmall,
          }}
        >
          {customReport.blueprint?.name}
        </Typography>
        <BlueprintRoleBadges blueprintRoles={customReport.blueprintRoles} />
      </div>
    </div>
  );
};
