import React from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { DateInput } from '~/shared/components/DateInput';
import { Input, InputVariants } from '~/shared/components/Input';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { BullAsyncSelect, readBullFragment } from '~/entities/bulls';

import formStyles from '~/styles/modules/form.module.scss';

import { SemenDoseFragment } from '../../gql/fragments/semenDose.graphql';
import { useCreateSemenDoseMutation } from '../../gql/mutations/createSemenDose.graphql';
import { useUpdateSemenDoseMutation } from '../../gql/mutations/updateSemenDose.graphql';
import { updateSemenDoseFragment } from '../../helpers';

export interface EditSemenDoseModalProps
  extends InjectedModalProps<EditSemenDoseModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing semen dose, if not passed, a new one is created
   */
  semenDose?: SemenDoseFragment;
}

const FORM_ID = 'EditSemenDoseForm';

const SCHEMA = yup.object({
  deliveryDate: yup.string().default(null).required(), // Date!
  studCode: yup.string().default('').required(),
  batchNumber: yup.string().default('').required(),
  dosesCount: yup.number().required().default(null),
  bullID: yup.string().required(), // ID!
});

type EditSemenDoseFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type EditSemenDoseFormTransformedType = InferValidatedSchema<typeof SCHEMA>;

export const EditSemenDoseModal: React.FC<EditSemenDoseModalProps> = ({
  className,
  semenDose,
  close,
}) => {
  const isEditing = !!semenDose;

  const formContext = useForm<
    EditSemenDoseFormType,
    EditSemenDoseFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        ['deliveryDate', 'studCode', 'batchNumber', 'dosesCount'],
        semenDose ?? ({} as SemenDoseFragment)
      ),
      bullID: semenDose?.bull.id,
    },
  });

  const [createSemenDose, { loading: isCreateSemenDoseLoading }] =
    useCreateSemenDoseMutation();

  const [updateSemenDose, { loading: isUpdateSemenDoseLoading }] =
    useUpdateSemenDoseMutation();

  const handleSubmit = async (form: EditSemenDoseFormTransformedType) => {
    if (isEditing) {
      await updateSemenDose({
        variables: {
          id: semenDose.id,
          input: form,
        },
        optimisticResponse: { updateSemenDose: null },
        update: updateSemenDoseFragment(semenDose.id, (draft, { cache }) => {
          draft.deliveryDate = form.deliveryDate;
          draft.studCode = form.studCode;
          draft.batchNumber = form.batchNumber;
          draft.dosesCount = form.dosesCount;

          const bull = readBullFragment(cache, form.bullID);
          if (bull) {
            draft.bull = bull;
          }
        }),
      });
    } else {
      await createSemenDose({
        variables: {
          input: form,
        },
        refetchQueries: ['semenDoses'],
      });
    }
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: isEditing
          ? 'Редактирование поставки семени'
          : 'Новая поставка семени',
        submitButtonProps: {
          form: FORM_ID,
          isLoading: isCreateSemenDoseLoading || isUpdateSemenDoseLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
          className: formStyles.singleColumnForm,
        }}
      >
        <DateInput
          {...{
            name: 'deliveryDate',
            label: 'Дата поставки',
          }}
        />
        <Input
          {...{
            name: 'studCode',
            label: 'Название или код поставщика',
          }}
        />
        <Input
          {...{
            name: 'batchNumber',
            label: 'Номер партии',
          }}
        />
        <Input
          {...{
            name: 'dosesCount',
            label: 'Количество доз',
            variant: InputVariants.int,
          }}
        />
        <BullAsyncSelect
          {...{
            name: 'bullID',
            label: 'Бык',
          }}
        />
      </Form>
    </Modal>
  );
};
