import React, { useMemo } from 'react';

import {
  CategoryScale,
  Chart,
  ChartOptions,
  ChartType,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  TimeScale,
  Tooltip,
} from 'chart.js';

import { Skeleton } from '~/shared/components/Skeleton';

import { LINE_CHART_OPTIONS, MISSING_SEGMENTS_DASH } from '../../constants';
import { getChartOptions, isSegmentSkipped } from '../../helpers';
import {
  ReactChart,
  ReactChartDatasetConfig,
  ReactChartProps,
} from '../ReactChart';

Chart.register(...registerables);
Chart.register(LineController);
Chart.register(Tooltip);
Chart.register(Filler);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(CategoryScale);
Chart.register(TimeScale);
Chart.register(LinearScale);

/**
 * Data item for the line chart
 */
type LineChartDataPoint =
  | {
      x: string | undefined;
      y: number | null;
    }
  | number
  | null;

interface Props
  extends Omit<
    ReactChartProps<ChartType, LineChartDataPoint[]>,
    'type' | 'data' | 'datasetConfigs' | 'skeleton'
  > {
  /**
   * Chart data
   */
  datasets: ReactChartDatasetConfig<ChartType, LineChartDataPoint[]>[];
  /**
   * Labels, used in the chart x axis
   */
  labels?: string[];
  /**
   * Additional options for the chart
   */
  chartOptions?: ChartOptions;
}

export const LineChart: React.FC<Props> = ({
  datasets,
  labels,
  chartOptions: chartOptionsProp,

  ...chartProps
}) => {
  const chartOptions = getChartOptions<ChartType>(
    LINE_CHART_OPTIONS,
    chartOptionsProp
  );

  const datasetConfigs = useMemo<
    ReactChartDatasetConfig<ChartType, LineChartDataPoint[]>[]
  >(
    () =>
      datasets.map((dataset, index) => ({
        ...dataset,
        borderColor: dataset.color,
        pointStyle: false,
        order: dataset.order ?? index,
        segment: {
          borderColor: ctx =>
            isSegmentSkipped(ctx) ? dataset.skippedColor : undefined,
          borderDash: ctx =>
            isSegmentSkipped(ctx) ? MISSING_SEGMENTS_DASH : undefined,
        },
        spanGaps: true,
      })),
    [datasets, labels]
  );

  return (
    <ReactChart
      {...{
        options: chartOptions,
        type: 'line',
        datasetIdKey: 'first',
        labels,
        datasetConfigs,
        skeleton: <Skeleton.LineChart />,
        ...chartProps,
      }}
    />
  );
};
