import { createFileRoute } from '@tanstack/react-router';

import { PageHeader } from '~/services/layouts';
import { makeBeforeLoadRedirect, RouterTabs } from '~/services/navigation';

import layoutStyles from '~/styles/modules/layout.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/milking'
)({
  component: AnalyticsMilkingPage,
  beforeLoad: makeBeforeLoadRedirect<{ companyId: string }>({
    from: '/$companyId/user/analytics/milking',
    to: '/$companyId/user/analytics/milking/by-milking-parlor',
  }),
});

function AnalyticsMilkingPage() {
  return (
    <>
      <PageHeader className={layoutStyles.limitedContainer} title="Надои" />

      <RouterTabs
        {...{
          className: layoutStyles.stickyPageTabs,
          tabs: [
            {
              href: '/$companyId/user/analytics/milking/by-milking-parlor',
              title: 'По залам',
            },
            {
              href: '/$companyId/user/analytics/milking/by-herd',
              title: 'По стаду',
            },
            {
              href: '/$companyId/user/analytics/milking/somatic-cells',
              title: 'Соматические клетки',
            },
          ],
        }}
      />
    </>
  );
}
