import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AnalyticalMetricEnum,
  AnalyticalSettingEnum,
  PageInfo,
} from '@graphql-types';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import {
  AnalyticsService,
  AnalyticTargetFilters,
  AppAnalyticTarget,
  MetricSetting,
} from '~/~legacy/services/AnalyticsService';
import { MaslovServices } from '~/~legacy/types/services';

import { InjectedModalProps } from '~/services/modals';

import { METRIC_CONFIGS } from '../../constants';
import { MetricParameterSection } from './components/MetricParameterSection';
import { SetMetricTargetModalContent } from './components/SetMetricTargetModalContent';

export interface SetMetricTargetModalProps
  extends InjectedModalProps<SetMetricTargetModalProps> {
  refresh: () => void;
  metric: AnalyticalMetricEnum;

  parameter?: AnalyticalSettingEnum;
  parameterValue?: string;
  updateSetting?: (setting: MetricSetting) => void;
}

const METRIC_SETTING_NAME_MAP: Record<
  AnalyticalSettingEnum | 'Unknown',
  string
> = {
  [AnalyticalSettingEnum.DaysInMilk]: 'Дни в доении',
  [AnalyticalSettingEnum.MonthsNotPreg]: 'Возраст',
  [AnalyticalSettingEnum.VoluntaryWaitingPeriodDaysCows]:
    'Добровольный период ожидания коров',
  [AnalyticalSettingEnum.VoluntaryWaitingPeriodDaysHeifers]:
    'Добровольный период ожидания тёлок',
  Unknown: '',
};

export const SetMetricTargetModal: React.FC<SetMetricTargetModalProps> = ({
  refresh,
  metric,
  parameter,
  parameterValue,
  updateSetting = () => {},

  close,
}) => {
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const { name } = METRIC_CONFIGS[metric];
  const parametrName = METRIC_SETTING_NAME_MAP[parameter || 'Unknown'];

  const [targetsData, setTargetsData] = useState<AppAnalyticTarget[]>([]);
  const [localParamValue, setParamValue] = useState(parameterValue || '');

  const analyticsSvc = useService<AnalyticsService>(
    MaslovServices.AnalyticsService
  );
  const memoizedGet = useMemo(() => {
    return analyticsSvc.getTargets.bind(analyticsSvc);
  }, [analyticsSvc]);

  const {
    errors: targetsLoadingErrors,
    loading: loadingTargets,
    reload: loadData,
  } = useApiData(memoizedGet);

  const loadTargets = useCallback(
    async (filters: AnalyticTargetFilters) => {
      const result = await loadData(filters);

      if (result.success) {
        const [data, newPageInfo] = result.data;

        setTargetsData(data);
        setPageInfo(newPageInfo);
      }
    },
    [loadData]
  );

  const memoizedAdd = useMemo(() => {
    return analyticsSvc.addTarget.bind(analyticsSvc);
  }, [analyticsSvc]);

  const {
    errors: addTargetErrors,
    loading: adding,
    reload: addTarget,
  } = useApiData(memoizedAdd);

  const memoizedSetParam = useMemo(() => {
    return analyticsSvc.setAnalyticalSetting.bind(analyticsSvc);
  }, [analyticsSvc]);

  const {
    errors: settingErrors,
    loading: settingUpdateing,
    reload: setSetting,
  } = useApiData(memoizedSetParam);

  const addTargetCallback = useCallback(
    async (value: number) => {
      if (parameter && Number.isInteger(Number.parseInt(localParamValue, 10))) {
        const newSetting = {
          setting: parameter,
          value: Number(localParamValue),
        };

        await setSetting(newSetting);
        updateSetting(newSetting);
      }

      await addTarget({
        metric,
        value,
      });
      refresh();
      close();
      return true;
    },
    [memoizedAdd, metric, localParamValue]
  );

  useEffect(() => {
    loadTargets({
      metric,
    });
  }, [metric]);

  const loadMoreCallback = useCallback(() => {
    loadTargets({
      from: pageInfo.endCursor,
      metric,
    });
  }, [pageInfo, loadTargets, metric]);

  const errors = useMemo(() => {
    return [...targetsLoadingErrors, ...addTargetErrors, ...settingErrors];
  }, [targetsLoadingErrors, addTargetErrors, settingErrors]);

  const loading = useMemo(() => {
    return adding || loadingTargets || settingUpdateing;
  }, [adding, loadingTargets, settingUpdateing]);

  const parametrSection = parameter ? (
    <MetricParameterSection
      lable={parametrName}
      value={localParamValue}
      onChange={setParamValue}
    />
  ) : undefined;

  return (
    <SetMetricTargetModalContent
      parametrSection={parametrSection}
      addTarget={addTargetCallback}
      loadMore={pageInfo.hasNextPage ? loadMoreCallback : undefined}
      errors={errors}
      loading={loading}
      lable={name}
      data={targetsData}
    />
  );
};
