import { ReactNode } from 'react';

import { AsyncListProps } from '~/shared/components/AsyncList';
import { FunctionButtonProps } from '~/shared/components/FunctionButton';
import {
  TypographyProps,
  TypographyVariants,
} from '~/shared/components/Typography';
import { Falsy } from '~/shared/types/utility';

/**
 * Possible table display themes
 */
export enum TableThemes {
  tertiary = 'tertiary',
  largeSecondary = 'largeSecondary',
  smallSecondary = 'smallSecondary',
}

/**
 * Getter for a cell prop prop
 */
export type GetCellProp<Item, PropValue> = (
  item: Item,
  index: number,
  array: Item[]
) => PropValue | Falsy;

/**
 * Config value for a cell prop which can be defined statically or with a function
 */
export type CellPropOrGetCellProp<Item, PropValue> =
  | PropValue
  | GetCellProp<Item, PropValue>;

/**
 * Column config which defines, how a column of data is rendered
 */
export interface TableColumnConfig<T extends object> {
  /**
   * Key for the column
   */
  key: React.Key | null;
  /**
   * Title for the column
   */
  title?: ReactNode;
  /**
   * Header cell may render an additional action icon
   */
  functionButtonProps?: Partial<Omit<FunctionButtonProps, 'ref'>>;
  /**
   * className, applied to both column header (th) and column cell (td)
   */
  columnClassName?: string;
  /**
   * className, applied to the th element of the column header
   */
  headerClassName?: string;
  /**
   * Additional typography props for the cell header
   */
  headerTypographyProps?: Partial<TypographyProps>;
  /**
   * className, applied to the td element of the cell, or a getter to calculate it based on an item
   */
  cellClassName?: CellPropOrGetCellProp<T, string>;
  /**
   * Additional typography props for the cell content
   */
  cellTypographyProps?: CellPropOrGetCellProp<T, Partial<TypographyProps>>;
  /**
   * Tooltip text, displayed, when the cell is hovered
   */
  cellTooltip?: CellPropOrGetCellProp<T, string>;
  /**
   * Text for the default title cell attribute
   */
  cellTitle?: CellPropOrGetCellProp<T, string>;
  /**
   * Field of the item, that should be displayed in the cell
   */
  itemField?: keyof T;
  /**
   * Custom render for the contents of the cell
   */
  renderCellContent?: (item: T, index: number, array: T[]) => ReactNode;
  /**
   * If a number greater than zero is returned, than the td tag will have colSpan attribute
   * and the specified number of columns in the config will be skipped
   */
  getColSpan?: (item: T) => number;
  /**
   * Width of the column
   */
  width?: string | number;
  /**
   * If passed, this config is used as a grouping title and renders nested columns as usual
   * Now we support only one nesting level
   */
  nestedColumns?: TableColumnConfig<T>[];
  /**
   * If true, renders separate cells in header, when using nested columns, even if this column is not nested
   */
  isSplittedHeader?: boolean;
  /**
   * If true, renders a sticky column
   */
  isSticky?: boolean;
}

/**
 * Inner representation of column configs with additional info for more convenient rendering
 */
export interface TableColumnConfigInner<T extends object>
  extends TableColumnConfig<T> {
  /**
   * If true, the column is rendered inside of the grouping header
   */
  isNested: boolean;
  /**
   * If true, it means, that the column is the left border of the nesting group
   */
  isNestedLeft: boolean;
  /**
   * If true, it means, that the column is the right border of the nesting group
   */
  isNestedRight: boolean;
  /**
   * To allow flat rendering, we reverse order of the
   */
  groupingColumnConfig?: TableColumnConfig<T>;
}

/**
 * Row config can be used as a table item in case we have dynamic columns and static rows,
 * or if we need to additionally map the rows to the unified format
 */
export interface TableRowConfig<T extends object, CellData = undefined> {
  /**
   * Key for the row
   */
  id: string;
  /**
   * If passed, render cell content is called only for second and other columns,
   * first column is rendered as static content
   */
  firstColumnContent?: ReactNode;
  /**
   * Custom render for the contents of the cell
   */
  renderCellContent?: (cellData: CellData) => ReactNode;
  /**
   * className, applied to the whole row
   */
  rowClassName?: string;
  /**
   * Typography variant, applied to the whole row
   */
  rowTypographyVariant?: TypographyVariants;
  /**
   * If passed, the row can be expanded and the rows are added in the table
   */
  expandableRows?: TableRowConfig<T, CellData>[];
}

type TableAsyncProps<T> = Pick<AsyncListProps<T>, 'noItemsMessage'> &
  Partial<AsyncListProps<T>>;

export interface TableProps<T extends object, CellData = undefined>
  extends TableAsyncProps<T> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Table display theme
   */
  theme?: TableThemes;

  /**
   *  Array of rows for the table
   */
  items: T[] | undefined;
  /**
   *  Return key for item
   */
  getItemKey?: (item: T, index: number) => string | number;
  /**
   * Called, when an item row is clicked
   */
  onItemClick?: (
    item: T,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  /**
   * Table row can display some actions for an item, that will be displayed on the row hover
   */
  renderItemActions?: (item: T) => ReactNode;
  /**
   * className applied to the item actions cell
   */
  itemActionCellClassName?: string | ((item: T) => string | Falsy);
  /**
   * Table header, that is shown only when printing
   */
  printableTitle?: ReactNode;
  /**
   * Getter for an additional className, applied to the whole item row
   */
  getRowClassName?: (item: T) => string | Falsy;

  /**
   * Array with column configurations
   */
  columnConfigs: TableColumnConfig<T>[];
  /**
   * If true, renders additional column for an expand row icon
   */
  isTableWithExpandableRows?: boolean;
  /**
   * If passed, this prop is used to determine, if an item can be expanded
   */
  hasExpandableRowContent?: (item: T) => boolean;
  /**
   * If passed, table is rendered with expandable rows
   */
  getExpandableRows?: (item: T) => TableRowConfig<T, CellData>[] | undefined;
  /**
   * If a row can be expanded, this render prop should return the expanded element
   */
  renderExpandableRowContent?: (item: T) => ReactNode;

  /**
   * If true, thead will be rendered with sticky styles
   */
  withStickyHeader?: boolean;
  /**
   * If true, the noItemsMessage is rendered inside the table, without table otherwise (default - true)
   */
  shouldWrapNoItemsMessage?: boolean;
  /**
   * If true, renders default border around table using box-shadow, can be set to false,
   * if you want to render border at the wrapper container (default - true)
   */
  withBorder?: boolean;
  /**
   * If true, expandable rows are rendered even when they're collapsed,
   * useful for dynamic columns width (default - true)
   */
  shouldUnmountExpandableRows?: boolean;
  /**
   * If true, renders a table with wrapped in useCustomScrollWrapper (default - true)
   */
  withCustomScroll?: boolean;
  /**
   * If true, doesn't render table scrollbars, when table is not overflown (default - true)
   */
  shouldHideScrollBarsForNoOverflow?: boolean;
  /**
   * If true, prevents immediate render of all the rows or columns, when there're to many of them
   * and renders a caption with a button to render all the content
   */
  shouldHideLargeData?: boolean;
}
