import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSkeletonPlaceholders,
  Skeleton,
} from '~/shared/components/Skeleton';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { PageHeader } from '~/services/layouts';
import { WithSearchParamsValidation } from '~/services/navigation';

import { FarmFilterSearchParams, useFarmsFilter } from '~/entities/farms';

import {
  MilkingParlorCard,
  useEditMilkingParlorModal,
} from '~/features/milkingParlors';
import { useMilkingParlorsQuery } from '~/features/milkingParlors/gql/queries/milkingParlors.graphql';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';
import layoutStyles from '~/styles/modules/layout.module.scss';

const MILKING_PARLORS_CARDS_DEFAULT_COUNT = 4;

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/milking-parlors/'
)({
  wrapInSuspense: true,
  component: MilkingParlorsPage,
  validateSearch: ({
    farmId,
  }: WithSearchParamsValidation<FarmFilterSearchParams>) => ({
    farmId: farmId ?? undefined,
  }),
});

function MilkingParlorsPage() {
  const navigate = Route.useNavigate();

  const { farmId, renderFarmsSelectElement } = useFarmsFilter(false);

  const { open: openEditMilkingParlorModal } = useEditMilkingParlorModal();

  const queryVariables = {
    farmIDs: normalizeToArrayOrUndefined(farmId),
  };

  const { data: milkingParlorsData, loading: isMilkingParlorsLoading } =
    useMilkingParlorsQuery({
      variables: queryVariables,
      skip: !farmId,
    });

  const milkingParlors =
    milkingParlorsData?.milkingParlors.edges.map(R.prop('node')) ??
    getSkeletonPlaceholders(MILKING_PARLORS_CARDS_DEFAULT_COUNT);

  const isLoading = !farmId || isMilkingParlorsLoading;

  return (
    <div className={clsx(layoutStyles.limitedContainer)}>
      <PageHeader
        {...{
          title: 'Доильные залы',
          rightContent: (
            <>
              <Button
                {...{
                  className: 'ml-a',
                  variant: ButtonVariants.secondary,
                  isDisabled: !farmId,
                  iconVariant: IconVariants.clock,
                  onPress: () => {
                    if (!farmId) return;

                    navigate({
                      to: '/$companyId/user/entities/milking-parlors/$farmId/schedule',
                      params: prev => ({ ...prev, farmId }),
                    });
                  },
                }}
              >
                Планирование доений
              </Button>
              <Button
                {...{
                  iconVariant: IconVariants.plus,
                  onPress: () => openEditMilkingParlorModal(),
                }}
              >
                Добавить зал
              </Button>
            </>
          ),
        }}
      />

      {renderFarmsSelectElement({ className: 'justify-self-start mb-24' })}
      <Skeleton isLoading={isLoading}>
        <div className={contentGridStyles.autoGridDynamicItems}>
          {milkingParlors.map(milkingParlor => (
            <MilkingParlorCard
              key={milkingParlor.id}
              {...{
                milkingParlor,
                queryVariables,
              }}
            />
          ))}
        </div>
      </Skeleton>
    </div>
  );
}
