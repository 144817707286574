import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CustomReportOrderEnum,
  CustomReportSortByKindEnum,
} from '@graphql-types';
import R from 'ramda';

import { Checkbox } from '~/shared/components/Checkbox';
import {
  makeUseEnumSelect,
  Select,
  SelectItemWithId,
  SelectThemes,
} from '~/shared/components/Select';

import { omitTypename } from '~/services/gql';
import { ReportCardBuilderFormArrayItem } from '~/services/reportCardBuilder';

import { UseBlueprintSourceFieldsInterface } from '~/entities/blueprintSourceFields';
import { formatSourceFieldValue } from '~/entities/customReports';

import { CustomReportPivotTableFragment } from '~/features/customReportLaunch/gql/fragments/customReportPivotTable.graphql';

import { CUSTOM_REPORT_ORDER_DICT } from '../../../../constants';
import {
  getCustomReportValueConfigKey,
  getValueColumnName,
  isCustomReportHeaderColumn,
  isTotalSourceFieldValue,
} from '../../../../helpers';
import {
  CustomReportPivotSettingsFormType,
  RowOrColumnConfigArrayItemFormType,
  ValueConfigArrayItemFormType,
} from '../../../../types';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Field name prefix to use for form fields in array
   */
  fieldPrefix: `${'rows' | 'columns'}.${number}.`;
  /**
   * If true, renders row config, column config otherwise
   * The differences are how the sorting values are calculated and the total checkbox text
   */
  isRow: boolean;
  /**
   * Row config to render
   */
  rowOrColumnConfig: RowOrColumnConfigArrayItemFormType;
  /**
   * Called, when user pressed delete button to remove the config
   */
  onDelete?: () => void;
  /**
   * Logic helpers for blueprint source fields
   */
  useBlueprintSourceFieldsInterface: UseBlueprintSourceFieldsInterface;
  /**
   * Added value configs for sort settings
   */
  valueConfigFieldArrayItems: ValueConfigArrayItemFormType[];
  /**
   * Actual calculated pivot data, cause we can select search values, based on results
   */
  pivotData: CustomReportPivotTableFragment;
}

const useCustomReportOrderEnumSelect = makeUseEnumSelect(
  CustomReportOrderEnum,
  enumValue => CUSTOM_REPORT_ORDER_DICT[enumValue as CustomReportOrderEnum]
);

const SORT_KIND_POPOVER_WIDTH_PX = 330;

export const RowOrColumnConfigArrayItemCard: React.FC<Props> = ({
  className,
  isRow,
  fieldPrefix,
  rowOrColumnConfig,
  onDelete,
  useBlueprintSourceFieldsInterface: { getSourceFieldById },
  valueConfigFieldArrayItems,
  pivotData,
}) => {
  const formContext = useFormContext<CustomReportPivotSettingsFormType>();

  const sourceFieldName =
    getSourceFieldById(rowOrColumnConfig.blueprintSourceFieldID)?.name ?? '';

  const { renderSelectElement: renderCustomReportOrderSelectElement } =
    useCustomReportOrderEnumSelect({
      name: `${fieldPrefix}order`,
      label: 'Формула',
      theme: SelectThemes.light,
    });

  const sortByFieldName = `${fieldPrefix}sortBy` as const;

  const currentSortKind = formContext.watch(sortByFieldName);
  const currentSortingValueKey = currentSortKind.sortingValue?.valueKey;
  const selectedSortKind = currentSortingValueKey
    ? getCustomReportValueConfigKey(currentSortingValueKey)
    : CustomReportSortByKindEnum.Default;

  const headerColumn = pivotData.columns.find(isCustomReportHeaderColumn);

  const blueprintSourceFieldValueSortingValues = useMemo(() => {
    let sourceFieldSortingValues;
    if (isRow) {
      // If we don't have defined columns in the pivot, we can't select blueprint value for sort
      sourceFieldSortingValues = headerColumn
        ? headerColumn.blueprintSourceFieldValues
        : [];
    } else {
      sourceFieldSortingValues = pivotData.rows.map(
        R.prop('blueprintSourceFieldValue')
      );
    }

    return sourceFieldSortingValues
      .filter(R.complement(isTotalSourceFieldValue))
      .map(omitTypename);
  }, [currentSortingValueKey, pivotData]);

  const blueprintSourceFieldValueFieldName =
    `${fieldPrefix}sortBy.sortingValue.blueprintSourceFieldValue` as const;
  const currentBlueprintSourceFieldValue = formContext.watch(
    blueprintSourceFieldValueFieldName
  );

  const sortingSourceFieldId =
    formContext.watch(
      `${isRow ? 'columns' : 'rows'}.0.blueprintSourceFieldID`
    ) ?? '';

  const selectedBlueprintSourceFieldValueIndex =
    blueprintSourceFieldValueSortingValues.findIndex(
      sourceFieldValue =>
        currentBlueprintSourceFieldValue &&
        formatSourceFieldValue(currentBlueprintSourceFieldValue) ===
          formatSourceFieldValue(sourceFieldValue)
    );

  return (
    <ReportCardBuilderFormArrayItem
      {...{
        className,
        title: sourceFieldName,
        onDelete,
      }}
    >
      <div className="grid grid-cols-2 gap-12">
        {renderCustomReportOrderSelectElement()}
        <Select<SelectItemWithId<string>>
          {...{
            name: `${fieldPrefix}sortKind`,
            label: 'Сортировать по',
            theme: SelectThemes.light,
            withFormContext: false,
            popoverWidth: SORT_KIND_POPOVER_WIDTH_PX,
            rawValue: selectedSortKind,
            onValueChange: newValue => {
              if (!newValue) return;

              if (newValue.id === CustomReportSortByKindEnum.Default) {
                formContext.setValue(sortByFieldName, {
                  kind: CustomReportSortByKindEnum.Default,
                  sortingValue: null,
                });
              } else {
                const valueKey = valueConfigFieldArrayItems.find(
                  valueConfig =>
                    getCustomReportValueConfigKey(valueConfig) === newValue.id
                );

                if (!valueKey) return;

                formContext.setValue(sortByFieldName, {
                  kind: CustomReportSortByKindEnum.Value,
                  sortingValue: {
                    valueKey,
                    blueprintSourceFieldValue:
                      blueprintSourceFieldValueSortingValues[0] ?? null,
                  },
                });
              }
            },
            items: [
              {
                id: CustomReportSortByKindEnum.Default,
                name: sourceFieldName,
              },
              ...valueConfigFieldArrayItems.map(valueConfig => ({
                id: getCustomReportValueConfigKey(valueConfig),
                name: getValueColumnName(valueConfig, getSourceFieldById),
              })),
            ],
          }}
        />

        {currentSortingValueKey && headerColumn && (
          <Select<SelectItemWithId<number>>
            {...{
              name: blueprintSourceFieldValueFieldName,
              className: 'col-span-full',
              label: getSourceFieldById(sortingSourceFieldId)?.name,
              theme: SelectThemes.light,
              withFormContext: false,
              rawValue: selectedBlueprintSourceFieldValueIndex,
              onValueChange: newValue => {
                if (!newValue) return;

                formContext.setValue(
                  blueprintSourceFieldValueFieldName,
                  blueprintSourceFieldValueSortingValues[newValue.id]
                );
              },
              items: blueprintSourceFieldValueSortingValues.map(
                (sourceFieldValue, index) => ({
                  id: index,
                  name: formatSourceFieldValue(sourceFieldValue),
                })
              ),
            }}
          />
        )}

        <Checkbox
          {...{
            name: `${fieldPrefix}withTotal`,
            className: 'col-span-full',
            label: isRow
              ? 'Показать строку «Всего»'
              : 'Показать колонку «Итого»',
          }}
        />
      </div>
    </ReportCardBuilderFormArrayItem>
  );
};
