import React, { useId, useRef } from 'react';

import clsx from 'clsx';
import dayjs from 'dayjs';

import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Label } from '~/shared/components/Label';
import { Skeleton, useSkeletonContext } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import {
  DateFormats,
  formatDate,
  formatDateForBackend,
} from '~/shared/helpers/date';
import { BaseFieldProps } from '~/shared/types/controls';

import TOKENS from '~/styles/__generated__/tokens.json';

import styles from './index.module.scss';

/**
 * Possible Date switcher display variants
 */
export enum DateSwitcherThemes {
  light = 'light',
  dark = 'dark',
}

interface Props extends BaseFieldProps<string> {
  /**
   * Display theme of the component
   */
  theme?: DateSwitcherThemes;
  /**
   * If true, the selected date is displayed with a highlight box
   */
  isHighlighted?: boolean;
}

const INPUT_SKELETON_HEIGHT_PX = TOKENS.size36;

export const DateSwitcher: React.FC<Props> = ({
  name,
  label,
  labelProps,
  className,
  value,
  theme = DateSwitcherThemes.dark,
  isHighlighted = false,
  onValueChange,
}) => {
  const { renderWithSkeleton, getSkeletonClassNames } = useSkeletonContext();

  const dateValue = dayjs(value);

  const inputRef = useRef<React.ElementRef<'input'>>(null);

  const inputId = useId();

  return (
    <div
      className={clsx(
        className,
        'grid',
        getSkeletonClassNames('gap-8', 'gap-4'),
        styles.root
      )}
    >
      {!!label && (
        <Label
          {...{
            htmlFor: inputId,
            ...labelProps,
          }}
        >
          {label}
        </Label>
      )}
      {renderWithSkeleton(
        <Skeleton.Block height={INPUT_SKELETON_HEIGHT_PX} />,
        <div
          {...{
            className: clsx(styles.inputContainer, styles[theme]),
            onClick: () => {
              inputRef.current?.showPicker();
            },
          }}
        >
          <input
            {...{
              name,
              ref: inputRef,
              className: styles.input,
              value: formatDateForBackend(dateValue, true),
              onChange: e => {
                onValueChange?.(formatDateForBackend(e.target.value));
              },
              type: 'date',
            }}
          />
          <Icon
            {...{
              className: 'p-8 text-accent',
              variant: IconVariants.chevronDown,
              rotate: RotateVariants.right,
              onPress: () => {
                onValueChange?.(
                  formatDateForBackend(dateValue.subtract(1, 'day'))
                );
              },
            }}
          />

          <Typography
            className={isHighlighted ? styles.highlightedDate : styles.date}
            variant={TypographyVariants.bodyMedium}
          >
            {formatDate(dateValue, DateFormats.shortWithWeekDay)}
          </Typography>

          <Icon
            {...{
              className: 'p-8 text-accent',
              variant: IconVariants.chevronDown,
              rotate: RotateVariants.left,
              onPress: () => {
                onValueChange?.(formatDateForBackend(dateValue.add(1, 'day')));
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
