import React, { useEffect } from 'react';

import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { useService } from '~/~legacy/hooks/useService';
import { useServiceInitialization } from '~/~legacy/hooks/useServiceInitialization';
import { HTTPTransport } from '~/~legacy/services/HttpTransport';
import { AppBlueprintsService } from '~/~legacy/services/implementations/AppBlueprintsService';
import { MaslovServices, StandardServices } from '~/~legacy/types/services';

import { Loader } from '~/shared/components/Loader';

import { AppBlueprintEditService } from '~/features/blueprintEdit';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { BlueprintEdit } from './components/BlueprintEdit';
import styles from './index.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/integrator/blueprints/edit/$blueprintId'
)({
  wrapInSuspense: true,
  component: BlueprintEditPage,
});

function BlueprintEditPage() {
  const { blueprintId } = Route.useParams();
  const navigate = Route.useNavigate();

  const { initService, initialized } = useServiceInitialization();
  const http = useService<HTTPTransport>(StandardServices.Http);

  useEffect(() => {
    const blueprintEditSvc = new AppBlueprintEditService(http);
    const blueprintsSvc = new AppBlueprintsService(http);
    initService(MaslovServices.BlueprintEditService, blueprintEditSvc);
    initService(MaslovServices.BlueprintsService, blueprintsSvc);
  }, []);

  const editComponent = blueprintId ? (
    <BlueprintEdit
      blueprintId={blueprintId}
      run={() =>
        navigate({
          to: '/$companyId/user/blueprint/$blueprintId',
        })
      }
    />
  ) : (
    <>Redirect to list page by react router</>
  );

  const content = initialized ? (
    editComponent
  ) : (
    <Loader className={layoutStyles.fullHeightContainer} />
  );

  return (
    <div
      className={clsx(
        layoutStyles.limitedContainer,
        layoutStyles.fullHeightContainer,
        styles.root
      )}
    >
      {content}
    </div>
  );
}
