import R from 'ramda';

import { CustomAppHeaders } from '~/services/gql';

import {
  CreateBlueprintRoleMutationOptions,
  useCreateBlueprintRoleMutation,
} from '../gql/mutations/createBlueprintRole.graphql';
import { useUpdateBlueprintRoleMutation } from '../gql/mutations/updateBlueprintRole.graphql';
import { updateBlueprintRoleFragment } from '../helpers';
import { BlueprintRoleFormType } from '../types';

const getBlueprintRoleInputFromForm = (form: BlueprintRoleFormType) =>
  R.omit(['id'], form);

/**
 * Hook for CRUD (without delete logic for a while) actions on a blueprint role
 */
export const useBlueprintRolesCRUD = () => {
  // Blueprint role create logic
  const [createBlueprintRoleMutation] = useCreateBlueprintRoleMutation();

  const createBlueprintRole = (
    companyID: string,
    form: BlueprintRoleFormType,
    mutationOptions?: Partial<CreateBlueprintRoleMutationOptions>
  ) =>
    createBlueprintRoleMutation({
      variables: {
        input: { companyID, ...getBlueprintRoleInputFromForm(form) },
      },
      context: {
        headers: {
          [CustomAppHeaders.activeCompany]: companyID,
        },
      },
      ...mutationOptions,
    });

  // Blueprint role update logic
  const [updateBlueprintRoleMutation] = useUpdateBlueprintRoleMutation();

  const updateBlueprintRole = (
    companyID: string,
    role: BlueprintRoleFormType
  ) =>
    updateBlueprintRoleMutation({
      variables: {
        id: role.id,
        input: getBlueprintRoleInputFromForm(role),
      },
      context: {
        headers: {
          [CustomAppHeaders.activeCompany]: companyID,
        },
      },
      optimisticResponse: {
        updateBlueprintRole: null,
      },
      update: updateBlueprintRoleFragment(role.id, draft => {
        draft.name = role.name;
        draft.color = role.color;
      }),
    });

  return {
    createBlueprintRole,
    updateBlueprintRole,
  };
};
