import React, { useMemo, useState } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { Select } from '~/shared/components/Select';
import { TabsVariants } from '~/shared/components/Tabs';
import { Tooltip } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { RouterTabs } from '~/services/navigation';

import { getCowEventEventId } from '~/entities/cowEvents';
import { CowDetailedFragment } from '~/entities/cows/gql/fragments/cowDetailed.graphql';
import { getEventId, useEventSelect } from '~/entities/events';

import {
  CowCardEventsTableEntry,
  getCowCardEventsTableEntries,
  MilkDataTabContext,
} from '~/features/cowCardEvents';
import {
  COW_CHART_SETTINGS_SCHEMA,
  CowChart,
  CowChartLegend,
  CowChartSettings,
} from '~/features/cowChart';
import { useCowLactationGraphsQuery } from '~/features/cowChart/gql/queries/cowLactationGraphs.graphql';
import { CowPinnedFields } from '~/features/cowFields';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import { useCowChartSettingsModal } from '../../modals';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Cow to render
   */
  cow: CowDetailedFragment;
}

export const MilkDataTabContent: React.FC<Props> = ({ className, cow }) => {
  const defaultSelectedLactation = R.last(cow.lactationsConnection.edges)?.node
    .id;

  const [lactationIDs, setLactationIDs] = useState<string[]>(
    normalizeToArrayOrUndefined(defaultSelectedLactation) ?? []
  );
  const [eventIDs, setEventIDs] = useState<string[]>([]);
  const [chartSettings, setChartSettings] = useState<CowChartSettings>(
    COW_CHART_SETTINGS_SCHEMA.getDefault()
  );

  const { renderSelectElement: renderEventSelectElement } = useEventSelect({
    selectProps: {
      name: 'eventIDs',
      className: styles.filter,
      isMulti: true,
      rawValue: eventIDs,
      onValueChange: newValue => setEventIDs(newValue.map(getEventId)),
    },
  });

  const { open: openCowChartSettingsModal } = useCowChartSettingsModal({
    onSave: setChartSettings,
  });

  const lastLactationId = useMemo(
    () =>
      R.last(
        R.sortBy(R.prop('lactationNumber'), cow.lactationsConnection.edges)
      )?.node.id,
    [cow.lactationsConnection.edges]
  );

  const { data: cowLactationGraphsData, loading: isCowLactationGraphsLoading } =
    useCowLactationGraphsQuery({
      variables: {
        id: cow.id,
        lactationIDs,
      },
    });
  const { lactationGraphs = [] } = cowLactationGraphsData?.cow ?? {};

  const cowEventsTableEntries: CowCardEventsTableEntry[] = useMemo(() => {
    return R.reverse(lactationGraphs).flatMap((graph, graphIndex) => [
      graph,
      ...getCowCardEventsTableEntries(
        graph.events
          .map(R.prop('event'))
          .filter(
            cowEvent =>
              !eventIDs.length ||
              eventIDs.includes(getCowEventEventId(cowEvent))
          ),
        {
          lactationId: graph.lactation.id,
          isLastLactation: graphIndex === 0,
        }
      ),
    ]);
  }, [lactationGraphs, eventIDs]);

  const MilkDataTabValue = useMemo(
    () => ({
      isCowLactationGraphsLoading,
      cowEventsTableEntries,
      cow,
    }),
    [isCowLactationGraphsLoading, cowEventsTableEntries, cow]
  );

  return (
    <MilkDataTabContext.Provider value={MilkDataTabValue}>
      <div className={className}>
        <div className={clsx(styles.chartContainer, 'grid grid-cols-3 gap-24')}>
          <div className="flex gap-16 items-end col-span-2">
            <Select
              {...{
                name: 'lactationIDs',
                className: styles.filter,
                label: 'Номер лактации',
                placeholder: 'Выберите лактацию',
                isMulti: true,
                items: cow.lactationsConnection.edges.map(edge => ({
                  id: edge.node.id,
                  name: edge.lactationNumber.toString(),
                })),
                rawValue: lactationIDs,
                onValueChange: newValue =>
                  setLactationIDs(newValue.map(R.prop('id'))),
              }}
            />
            {renderEventSelectElement()}
            <Button
              {...{
                className: 'ml-a',
                onPress: () =>
                  openCowChartSettingsModal({ defaultSettings: chartSettings }),
                variant: ButtonVariants.secondary,
              }}
            >
              Настройки графика
            </Button>
          </div>

          <CowChart
            {...{
              className: 'col-start-1 col-span-2',
              lactationGraphs,
              lastLactationId,
              eventIDs,
              settings: chartSettings,
            }}
          />

          <CowPinnedFields cow={cow} />
        </div>

        <Tooltip content={<CowChartLegend />} placement="bottom">
          <Typography
            variant={TypographyVariants.bodySmall}
            className="inline-flex items-center gap-8 mt-12 mb-24"
          >
            <Icon size={SizeVariants.size16} variant={IconVariants.chart} />
            Условные обозначения на графике
          </Typography>
        </Tooltip>

        <RouterTabs
          {...{
            variant: TabsVariants.secondary,
            tabs: [
              {
                href: '/$companyId/user/cows/$cowId/info/events',
                title: 'События',
              },
              {
                href: '/$companyId/user/cows/$cowId/info/milk-parlors',
                title: 'Доильные залы',
              },
              {
                href: '/$companyId/user/cows/$cowId/info/genealogy',
                title: 'Генеалогия',
              },
            ],
          }}
        />
      </div>
    </MilkDataTabContext.Provider>
  );
};
