import { EventKindEnum } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { DateRangePicker } from '~/shared/components/DateRangePicker';
import { IconVariants } from '~/shared/components/Icon';
import {
  normalizeToArrayOrUndefined,
  normalizeToValueOrUndefined,
} from '~/shared/helpers/normalize';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { PageHeader } from '~/services/layouts';
import { WithSearchParamsValidation } from '~/services/navigation';

import { CowEventsTable } from '~/entities/cowEvents';
import {
  getEventId,
  getEventIdsFilter,
  useEventKindsSelect,
  useEventSelect,
} from '~/entities/events';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { useExportEventsCsv } from './hooks';
import { useDownloadCowEventsCsvModal } from './modals';

interface CowEventsSearchParams {
  since: string;
  till: string;
  eventKinds: EventKindEnum[];
  eventIds: string[];
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/events/cow-events/'
)({
  wrapInSuspense: true,
  component: CowEventsPage,
  validateSearch: ({
    till,
    since,
    eventKinds,
    eventIds,
  }: WithSearchParamsValidation<CowEventsSearchParams>) => ({
    till: till ?? '',
    since: since ?? '',
    eventKinds: Array.isArray(eventKinds) ? eventKinds : [],
    eventIds: Array.isArray(eventIds) ? eventIds : [],
  }),
});

function CowEventsPage() {
  const {
    since,
    setSince,
    till,
    setTill,
    eventKinds,
    setEventKinds,
    eventIds,
    setEventIds,
  } = useSearchParamsState<typeof Route>();

  const queryVariables = {
    since: normalizeToValueOrUndefined(since),
    till: normalizeToValueOrUndefined(till),
    kinds: normalizeToArrayOrUndefined(eventKinds) as EventKindEnum[],
    eventIDs: normalizeToArrayOrUndefined(eventIds.map(getEventIdsFilter)),
  };

  const { renderSelectElement: renderEventSelectElement } = useEventSelect({
    selectProps: {
      name: 'eventId',
      className: 'default-control',
      label: 'Событие',
      placeholder: 'Выберите событие',
      isMulti: true,
      isClearable: true,
      rawValue: eventIds,
      onValueChange: newValue => setEventIds(newValue.map(getEventId)),
    },
  });

  const { renderSelectElement: renderEventKindsSelectElement } =
    useEventKindsSelect({
      name: 'eventKind',
      className: 'default-control',
      label: 'Тип события',
      placeholder: 'Выберите тип события',
      isClearable: true,
      isMulti: true,
      rawValue: eventKinds,
      onValueChange: newValue =>
        setEventKinds(newValue.map(({ id }) => id as EventKindEnum)),
    });

  const { open: openDownloadCowEventsCsvModal } =
    useDownloadCowEventsCsvModal();

  const { exportEventsCsv, isExportCsvLoading } = useExportEventsCsv();

  const selectedPeriod = { since, till };

  const handleDownloadCsv = () => {
    if (!selectedPeriod.since || !selectedPeriod.till) {
      openDownloadCowEventsCsvModal({
        defaultPeriod: selectedPeriod,
        queryVariables,
      });
    } else {
      exportEventsCsv(queryVariables);
    }
  };

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'История событий',
          rightContent: (
            <Button
              {...{
                variant: ButtonVariants.secondary,
                iconVariant: IconVariants.download,
                onPress: handleDownloadCsv,
                isLoading: isExportCsvLoading,
              }}
            >
              Скачать в CSV
            </Button>
          ),
        }}
      />
      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <div className="flex gap-16 items-end mb-24">
          <DateRangePicker
            {...{
              className: 'default-control',
              label: 'Период',
              name: 'period',
              value: selectedPeriod,
              onValueChange: range => {
                setTill(range.till ?? '');
                setSince(range.since ?? '');
              },
            }}
          />
          {renderEventSelectElement()}
          {renderEventKindsSelectElement()}
        </div>

        <CowEventsTable
          {...{
            queryVariables,
          }}
        />
      </div>
    </div>
  );
}
