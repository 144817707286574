import React from 'react';

import { BlueprintRoleColor } from '@graphql-types';
import R from 'ramda';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { BlueprintRole } from './components';

const BLUEPRINT_ROLE_COLOR_POPOVER_WIDTH_PX = 192;

/**
 * Select for blueprint role color
 */
const useBlueprintRoleColorSelect = makeUseEnumSelect(
  BlueprintRoleColor,
  () => '',
  [],
  {
    popoverWidth: BLUEPRINT_ROLE_COLOR_POPOVER_WIDTH_PX,
    withSearch: false,
    renderSelectedItem: item => {
      if (R.isNil(item)) return null;

      return <BlueprintRole value={item.id as BlueprintRoleColor} />;
    },
    renderItemText: item => {
      if (R.isNil(item)) return null;

      return <BlueprintRole value={item.id as BlueprintRoleColor} withText />;
    },
  }
);

export const BlueprintRoleColorSelect = makeSelectComponentFromHook(
  useBlueprintRoleColorSelect
);
