import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { DateRangePicker } from '~/shared/components/DateRangePicker';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputVariants } from '~/shared/components/Input';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatDate } from '~/shared/helpers/date';
import { formatFullNameWithInitials } from '~/shared/helpers/nameFormat';
import { normalizeToValueOrUndefined } from '~/shared/helpers/normalize';
import { formatInt } from '~/shared/helpers/number';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { PageHeader } from '~/services/layouts';
import { useConfirm } from '~/services/modals';
import { WithSearchParamsValidation } from '~/services/navigation';

import { useBlueprintLaunchesPaginatedQuery } from '~/entities/blueprintLaunches';
import { BlueprintLaunchFragment } from '~/entities/blueprintLaunches/gql/fragments/blueprintLaunch.graphql';
import { useRollbackLaunchBlueprintMutation } from '~/entities/blueprintLaunches/gql/mutations/rollbackLaunchBlueprint.graphql';
import { BlueprintLaunchesDocument } from '~/entities/blueprintLaunches/gql/queries/blueprintLaunches.graphql';
import { CowEventsTable } from '~/entities/cowEvents';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

const DATE_COLUMN_WIDTH_PX = 112;
const NAME_COLUMN_WIDTH_PX = 320;
const ANIMALS_COUNT_COLUMN_WIDTH_PX = 160;

const COMMAND_ROWS_SKELETON_COUNT = 8;

interface CommandsSearchParams {
  since: string;
  till: string;
  search: string | undefined;
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/events/commands/'
)({
  wrapInSuspense: true,
  component: CommandsPage,
  validateSearch: ({
    search,
    till,
    since,
  }: WithSearchParamsValidation<CommandsSearchParams>) => ({
    since: since ?? '',
    till: till ?? '',
    search: search ?? undefined,
  }),
});

function CommandsPage() {
  const { since, setSince, till, setTill, search, setSearch } =
    useSearchParamsState<typeof Route>();

  const queryVariables = {
    since: normalizeToValueOrUndefined(since),
    till: normalizeToValueOrUndefined(till),
    search: normalizeToValueOrUndefined(search),
  };

  const { items: blueprintLaunches, ...asyncProps } =
    useBlueprintLaunchesPaginatedQuery({
      variables: queryVariables,
    });

  const columnConfigs: TableColumnConfig<BlueprintLaunchFragment>[] = [
    {
      title: 'Дата',
      key: 'date',
      renderCellContent: launch => formatDate(launch.createdAt),
      width: DATE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Команда',
      key: 'name',
      renderCellContent: launch => launch.blueprint?.name,
      width: NAME_COLUMN_WIDTH_PX,
    },
    {
      title: 'Количество животных',
      key: 'animalsCount',
      renderCellContent: launch => formatInt(launch.cowsCount),
      width: ANIMALS_COUNT_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Кем запущена',
      key: 'userName',
      renderCellContent: launch => formatFullNameWithInitials(launch.user),
    },
  ];

  const [rollbackLaunchBlueprint] = useRollbackLaunchBlueprintMutation();
  const confirmDelete = useConfirm();

  const handleRollbackLaunchBlueprint = async (
    launch: BlueprintLaunchFragment
  ) => {
    const isConfirmed = await confirmDelete({
      title: 'Удаление запуска команды',
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить запуск команды{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {launch.blueprint?.name}
            </Typography>{' '}
            от{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {formatDate(launch.createdAt)}
            </Typography>
            ?
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>
      ),
      isDelete: true,
    });
    if (!isConfirmed) return;

    rollbackLaunchBlueprint({
      variables: {
        id: launch.id,
      },
      optimisticResponse: { rollbackLaunchBlueprint: null },
      update: R.juxt([
        makeDeleteFragmentFromQuery({
          typeName: 'BlueprintLaunch',
          query: BlueprintLaunchesDocument,
          variables: queryVariables,
          queryName: 'blueprintLaunches',
        })(launch.id),
        makeDeleteQueriesByNameWithoutVariables(
          'blueprintLaunches',
          queryVariables
        ),
      ]),
    });
  };

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader title="История команд" />
      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <div className="flex items-end mb-24">
          <DateRangePicker
            {...{
              className: 'default-control',
              label: 'Период',
              name: 'period',
              value: { since, till },
              onValueChange: range => {
                setSince(range.since ?? '');
                setTill(range.till ?? '');
              },
            }}
          />

          <Input
            {...{
              name: 'search',
              className: clsx('ml-a', 'default-control'),
              placeholder: 'Поиск по названию команды',
              value: search,
              onValueChange: setSearch,
              variant: InputVariants.search,
            }}
          />
        </div>
        <Table<BlueprintLaunchFragment>
          {...{
            theme: TableThemes.largeSecondary,
            className: 'min-w-full w-min',
            items: blueprintLaunches,
            columnConfigs,
            noItemsMessage: 'Нет данных для отображения',
            noSearchItemsMessage: 'Команды не найдены',
            skeletonItemsCount: COMMAND_ROWS_SKELETON_COUNT,
            renderItemActions: launch => (
              <FunctionButton
                {...{
                  iconVariant: IconVariants.delete,
                  onPress: () => handleRollbackLaunchBlueprint(launch),
                }}
              />
            ),
            hasExpandableRowContent: R.prop('hasEvents'),
            renderExpandableRowContent: launch => (
              <CowEventsTable
                {...{
                  isNested: true,
                  queryVariables: {
                    blueprintLaunchID: launch.id,
                  },
                }}
              />
            ),
            ...asyncProps,
          }}
        />
      </div>
    </div>
  );
}
