import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { InseminationFragmentDoc } from '../fragments/insemination.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InseminationsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  since?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  till?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  cowIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  category?: Types.InputMaybe<Types.FemaleAnimalKind>;
}>;


export type InseminationsQuery = { __typename?: 'Query', inseminations: { __typename?: 'InseminationConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'InseminationEdge', cursor: string, node: { __typename: 'Insemination', id: string, result: Types.InseminationResult, daysInMilk: number, happenedAt: string, cow: { __typename: 'Cow', retiredAt?: string | null, id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null }, employee?: { __typename: 'Employee', id: string, firstName: string, lastName: string } | null, scheme?: { __typename: 'InseminationScheme', id: string, name: string } | null } }> } };


export const InseminationsDocument = gql`
    query inseminations($first: Int, $since: Date, $till: Date, $cowIDs: [ID!], $farmIDs: [ID!], $category: FemaleAnimalKind) {
  inseminations(
    first: $first
    since: $since
    till: $till
    cowIDs: $cowIDs
    farmIDs: $farmIDs
    category: $category
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Insemination
      }
    }
  }
}
    ${InseminationFragmentDoc}`;

/**
 * __useInseminationsQuery__
 *
 * To run a query within a React component, call `useInseminationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInseminationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInseminationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      since: // value for 'since'
 *      till: // value for 'till'
 *      cowIDs: // value for 'cowIDs'
 *      farmIDs: // value for 'farmIDs'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useInseminationsQuery(baseOptions?: Apollo.QueryHookOptions<InseminationsQuery, InseminationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InseminationsQuery, InseminationsQueryVariables>(InseminationsDocument, options);
      }
export function useInseminationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InseminationsQuery, InseminationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InseminationsQuery, InseminationsQueryVariables>(InseminationsDocument, options);
        }
export function useInseminationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InseminationsQuery, InseminationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InseminationsQuery, InseminationsQueryVariables>(InseminationsDocument, options);
        }
export type InseminationsQueryHookResult = ReturnType<typeof useInseminationsQuery>;
export type InseminationsLazyQueryHookResult = ReturnType<typeof useInseminationsLazyQuery>;
export type InseminationsSuspenseQueryHookResult = ReturnType<typeof useInseminationsSuspenseQuery>;
export type InseminationsQueryResult = Apollo.QueryResult<InseminationsQuery, InseminationsQueryVariables>;