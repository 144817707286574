import React from 'react';

import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatDate } from '~/shared/helpers/date';
import { formatShortName } from '~/shared/helpers/nameFormat';

import { useMyUser } from '~/services/auth';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useArkaNavigation } from '~/services/navigation';
import { Callout, NotificationVariants } from '~/services/notifications';

import { CopyKeyItem } from '../../components';
import { MoveCowsKeyInfoIcons } from '../../components/MoveCowsKeyInfoIcons';
import { useFormatMoveToCompanyTitle } from '../../hooks';
import { MoveCowsCompany } from '../../types';
import { useMoveCowsModal } from '../index';
import styles from './index.module.scss';

export interface MoveCowsResultModalProps
  extends InjectedModalProps<MoveCowsResultModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Created move cows key
   */
  moveCowsKey: string;
  /**
   * Date of moving / selling
   */
  departDate: string;
  /**
   * Company object with all possible variants (id, name or inn)
   */
  company: MoveCowsCompany;
  /**
   * Amount of moving cows
   */
  cowsAmount: number;
}

export const MoveCowsResultModal: React.FC<MoveCowsResultModalProps> = ({
  className,
  close,
  moveCowsKey,
  departDate,
  company,
  cowsAmount,
}) => {
  const { myUser } = useMyUser();

  const { navigate, urlCompanyId } = useArkaNavigation();

  const { open: openMoveCowsModal } = useMoveCowsModal();

  const companyTitle = useFormatMoveToCompanyTitle(company);

  return (
    <Modal
      {...{
        className,
        title: 'Перемещение животных',
        submitButtonProps: {
          children: 'Перейти к списку ключей',
          onPress: () => {
            close();
            navigate({
              to: '/$companyId/user/herd/cows/moveCowsKeys',
              params: { companyId: urlCompanyId },
            });
          },
        },
        cancelButtonProps: {
          children: 'Переместить ещё',
          onPress: () => {
            close();
            openMoveCowsModal();
          },
        },
      }}
    >
      <Callout
        {...{
          message: (
            <Typography variant={TypographyVariants.bodySmallStrong}>
              Ключ сформирован
            </Typography>
          ),
          variant: NotificationVariants.success,
          className: 'mb-24',
        }}
      />
      <div {...{ className: styles.keyContent }}>
        <CopyKeyItem moveCowsKey={moveCowsKey} />
        <MoveCowsKeyInfoIcons
          {...{
            cowsAmount,
            companyTitle,
            departDate: formatDate(departDate),
            fullName: formatShortName(myUser),
          }}
        />
      </div>
    </Modal>
  );
};
