import * as Types from '../../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ConceptionRateChartEmployeeCandleFragment = { __typename: 'ConceptionRateChartEmployeeCandle', meanValue: number, maxValue: number, employee?: { __typename: 'Employee', id: string, firstName: string, middleName: string, lastName: string } | null };

export const ConceptionRateChartEmployeeCandleFragmentDoc = gql`
    fragment ConceptionRateChartEmployeeCandle on ConceptionRateChartEmployeeCandle {
  __typename
  meanValue
  maxValue
  employee {
    __typename
    id
    firstName
    middleName
    lastName
  }
}
    `;