import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { Button } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';

import { PageHeader } from '~/services/layouts';

import {
  useEditSemenDoseModal,
  useSemenDosesPaginatedQuery,
} from '~/entities/semenDoses';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { SemenDosesTable } from './components/SemenDosesTable';

const SEMEN_DOSES_ROWS_DEFAULT_COUNT = 8;

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/semenDoses/'
)({
  wrapInSuspense: true,
  component: SemenDosesPage,
});

function SemenDosesPage() {
  const { open: openEditSemenDoseModal } = useEditSemenDoseModal();

  const queryVariables = {};

  const { items: semenDoses, ...asyncProps } = useSemenDosesPaginatedQuery({
    variables: queryVariables,
  });

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Поставка семени',
          rightContent: (
            <Button
              {...{
                iconVariant: IconVariants.plus,
                onPress: () => openEditSemenDoseModal(),
              }}
            >
              Добавить поставку
            </Button>
          ),
        }}
      />

      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <SemenDosesTable
          {...{
            semenDoses,
            skeletonItemsCount: SEMEN_DOSES_ROWS_DEFAULT_COUNT,
            queryVariables,
            noSearchItemsDescription:
              'По вашему запросу не нашлось поставок семени.',
            ...asyncProps,
          }}
        />
      </div>
    </div>
  );
}
