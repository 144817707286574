import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../../../../entities/farms/gql/fragments/farm.graphql';
import { MilkingParlorIntervalFragmentDoc } from './milkingParlorInterval.graphql';
export type MilkingParlorFragment = { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, tagField: Types.ParlorExportStatusFileTagFieldEnum, farm: { __typename: 'Farm', id: string, name: string, number: string }, intervals: Array<{ __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string }> };

export const MilkingParlorFragmentDoc = gql`
    fragment MilkingParlor on MilkingParlor {
  __typename
  id
  name
  farm {
    ...Farm
  }
  manufacturer
  kind
  capacity
  capacityStart
  capacityEnd
  sortingGatesCount
  connectionInfo
  tagField
  intervals {
    ...MilkingParlorInterval
  }
}
    ${FarmFragmentDoc}
${MilkingParlorIntervalFragmentDoc}`;