import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
import { BullFragmentDoc } from '../../../bulls/gql/fragments/bull.graphql';
import { EmployeeShortFragmentDoc } from '../../../employees/gql/fragments/employeeShort.graphql';
export type InseminationFragment = { __typename: 'Insemination', id: string, result: Types.InseminationResult, daysInMilk: number, happenedAt: string, cow: { __typename: 'Cow', retiredAt?: string | null, id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null }, employee?: { __typename: 'Employee', id: string, firstName: string, lastName: string } | null, scheme?: { __typename: 'InseminationScheme', id: string, name: string } | null };

export const InseminationFragmentDoc = gql`
    fragment Insemination on Insemination {
  __typename
  id
  cow {
    ...CowShort
    retiredAt
  }
  bull {
    ...Bull
  }
  employee {
    ...EmployeeShort
  }
  scheme {
    __typename
    id
    name
  }
  result
  daysInMilk
  happenedAt
}
    ${CowShortFragmentDoc}
${BullFragmentDoc}
${EmployeeShortFragmentDoc}`;