import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomMilkingReportFragmentDoc } from '../fragments/customMilkingReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomMilkingReportMutationVariables = Types.Exact<{
  input: Types.CreateCustomMilkingReportInput;
}>;


export type CreateCustomMilkingReportMutation = { __typename?: 'Mutation', createCustomMilkingReport: { __typename: 'CustomMilkingReport', id: string, name: string, blueprintRoles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> } };


export const CreateCustomMilkingReportDocument = gql`
    mutation createCustomMilkingReport($input: CreateCustomMilkingReportInput!) {
  createCustomMilkingReport(input: $input) {
    ...CustomMilkingReport
  }
}
    ${CustomMilkingReportFragmentDoc}`;
export type CreateCustomMilkingReportMutationFn = Apollo.MutationFunction<CreateCustomMilkingReportMutation, CreateCustomMilkingReportMutationVariables>;

/**
 * __useCreateCustomMilkingReportMutation__
 *
 * To run a mutation, you first call `useCreateCustomMilkingReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomMilkingReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomMilkingReportMutation, { data, loading, error }] = useCreateCustomMilkingReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomMilkingReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomMilkingReportMutation, CreateCustomMilkingReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomMilkingReportMutation, CreateCustomMilkingReportMutationVariables>(CreateCustomMilkingReportDocument, options);
      }
export type CreateCustomMilkingReportMutationHookResult = ReturnType<typeof useCreateCustomMilkingReportMutation>;
export type CreateCustomMilkingReportMutationResult = Apollo.MutationResult<CreateCustomMilkingReportMutation>;
export type CreateCustomMilkingReportMutationOptions = Apollo.BaseMutationOptions<CreateCustomMilkingReportMutation, CreateCustomMilkingReportMutationVariables>;