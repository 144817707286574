import React, { useCallback, useMemo } from 'react';

import { AnalyticsDashboardFilters } from '~/~legacy/services/AnalyticsService';
import { ListItem } from '~/~legacy/types/keyValue';

import { MDropDown } from '~/shared/components/MDropDown';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { AnalyticsMetricPeriodEnum } from '~/shared/graphql';

import { FarmFragment } from '~/entities/farms/gql/fragments/farm.graphql';

import { FILTER_PERIODS_MAP } from '../../constants';
import styles from './index.module.scss';

interface Props {
  farms: FarmFragment[];
  filters: AnalyticsDashboardFilters;
  change: (filters: AnalyticsDashboardFilters) => void;
}

export const AnalyticsDashboardFiltersPanel: React.FC<Props> = ({
  change,
  filters,
  farms,
}) => {
  const periodItems = useMemo(() => {
    const items: ListItem<AnalyticsMetricPeriodEnum>[] = [
      {
        value: AnalyticsMetricPeriodEnum.Year,
        content: 'Последний год',
      },
      {
        value: AnalyticsMetricPeriodEnum.Month,
        content: 'За последний месяц',
      },
      {
        value: AnalyticsMetricPeriodEnum.Week,
        content: 'За последнюю неделю',
      },
    ];

    return items;
  }, []);

  const periodChangeCallback = useCallback(
    (metricsPeriod: AnalyticsMetricPeriodEnum) => {
      const filteringPeriod = FILTER_PERIODS_MAP[metricsPeriod];

      change({
        ...filters,
        metricsPeriod,
        groupingPeriod: filteringPeriod.groupingPeriod,
        since: filteringPeriod.timespan.since,
        till: filteringPeriod.timespan.till,
      });
    },
    [change, filters]
  );

  const farmItems = useMemo(() => {
    const items: ListItem<string>[] = farms.map(farm => {
      return {
        value: farm.id,
        content: `${farm.name}`,
      };
    });

    const allFarmsItem: ListItem<string | undefined> = {
      value: undefined,
      content: 'Все фермы',
    };

    return [allFarmsItem, ...items];
  }, [farms]);

  const farmChangeCallback = useCallback(
    (farmId: string) => {
      change({
        ...filters,
        farmId,
      });
    },
    [change, filters]
  );

  return (
    <table className={styles.root}>
      <thead>
        <tr>
          <th>
            <Typography variant={TypographyVariants.descriptionLarge}>
              Отображение за
            </Typography>
          </th>
          <th>
            <Typography variant={TypographyVariants.descriptionLarge}>
              По фермам
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <MDropDown
              name="period"
              items={periodItems}
              selectedValue={filters.metricsPeriod}
              onChange={periodChangeCallback}
              noReset
            />
          </td>
          <td>
            <MDropDown
              name="farmId"
              items={farmItems}
              selectedValue={filters.farmId}
              onChange={farmChangeCallback}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
