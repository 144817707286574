import React from 'react';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';

import { PageHeader } from '~/services/layouts';
import { useConfirm } from '~/services/modals';
import { useArkaNavigation } from '~/services/navigation';

import { CowStateBadge, formatCow, getCowIdentifier } from '~/entities/cows';
import { CowDetailedFragment } from '~/entities/cows/gql/fragments/cowDetailed.graphql';
import { useDeleteCowMutation } from '~/entities/cows/gql/mutations/deleteCow.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Cow to render
   */
  cow: CowDetailedFragment;
}

export const CowPageHeader: React.FC<Props> = ({ className, cow }) => {
  const cowIdentifier = getCowIdentifier(cow);

  const { navigate, urlCompanyId, isIntegratorInSelectedCompany } =
    useArkaNavigation();

  const [deleteCow, { loading: isDeleteCowLoading }] = useDeleteCowMutation();
  const confirmDelete = useConfirm();

  const handleDeleteCow = async () => {
    const isConfirmed = await confirmDelete({
      message: `Хотите удалить животное ${cowIdentifier}`,
      isDelete: true,
    });
    if (!cow?.id || !isConfirmed) return;

    await deleteCow({
      variables: {
        id: cow.id,
      },
      refetchQueries: ['cows'],
    });

    navigate({
      to: '/$companyId/user/herd/cows',
      params: { companyId: urlCompanyId },
    });
  };

  return (
    <PageHeader
      {...{
        className,
        title: formatCow(cow),
        titleBadge: <CowStateBadge isFull state={cow.state} />,
        rightContent: isIntegratorInSelectedCompany && (
          <Button
            {...{
              isLoading: isDeleteCowLoading,
              onPress: handleDeleteCow,
              variant: ButtonVariants.secondary,
              iconVariant: IconVariants.delete,
            }}
          >
            Удалить животное
          </Button>
        ),
      }}
    />
  );
};
