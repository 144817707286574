import { BarController } from 'chart.js';

import { formatDate } from '~/shared/helpers/date';

import TOKENS from '~/styles/__generated__/tokens.json';

export class TodayBarSpecialController extends BarController {
  static id = 'todayBarSpecial';

  draw(): void {
    super.draw();
    const meta = this.getMeta();
    const dataset = this.getDataset();
    const { data } = meta;

    const { ctx } = this.chart;
    ctx.save();

    data.forEach((item, index: number) => {
      const fillStyle = item.options.backgroundColor;
      ctx.fillStyle = fillStyle;
      ctx.fillRect(item.x, 25, (item as any).width, item.y);

      const { date } = dataset.data[index] as any;

      ctx.fillStyle = TOKENS.colorNeutral700;
      ctx.font = '12px Graphik';
      ctx.fillText(formatDate(date), item.x - 30, 24);
    });

    ctx.restore();
  }
}
