import { makeUseModal, ModalNames } from '~/services/modals';

import { EditCompanyModalProps } from './EditCompanyModal';
import { SetHerriotSettingsModalProps } from './SetHerriotSettingsModal';
import { UploadCowFileModalProps } from './UploadCowFileModal';

export const useEditCompanyModal = makeUseModal<EditCompanyModalProps>(
  ModalNames.editCompanyModal
);

export const useUploadCowFileModal = makeUseModal<UploadCowFileModalProps>(
  ModalNames.uploadCowFileModal
);

export const useSetHerriotSettingsModal =
  makeUseModal<SetHerriotSettingsModalProps>(
    ModalNames.setHerriotSettingsModal
  );
