import { useEffect, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import {
  CustomReportSortByKindEnum,
  CustomReportValueFormulaKindEnum,
} from '@graphql-types';

import { useForm } from '~/services/forms';
import { useReportCardBuilderForm } from '~/services/reportCardBuilder';

import {
  PIVOT_TABLE_FORM_SCHEMA,
  PIVOT_TABLE_ROW_OR_COLUMN_SCHEMA,
} from '../../../constants';
import {
  getCustomReportValueConfigKey,
  mapCustomReportSettingsToForm,
} from '../../../helpers';
import {
  CustomReportPivotSettingsFormType,
  RowOrColumnConfigArrayItemFormType,
} from '../../../types';
import { getAvailableForAddingValueConfigs } from '../helpers';
import { CustomReportPivotSettingsFormProps } from '../types';

export const useCustomReportPivotSettingsForm = ({
  onSettingsFormChange,
  customReport,
  blueprintLaunchResult,
}: CustomReportPivotSettingsFormProps) => {
  const availableSourceFields = blueprintLaunchResult.columnSourceFields;

  const defaultValues = useMemo(
    () => mapCustomReportSettingsToForm(customReport),
    [customReport]
  );

  const formContext = useForm<CustomReportPivotSettingsFormType>({
    schema: PIVOT_TABLE_FORM_SCHEMA,
    defaultValues,
  });

  const {
    fields: rowConfigFieldArrayItems,
    remove: removeFromRowConfigsArray,
    append: appendToRowConfigsArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'rows',
  });

  const {
    fields: columnConfigFieldArrayItems,
    remove: removeFromColumnConfigsArray,
    append: appendToColumnConfigsArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'columns',
  });

  const {
    remove: removeFromValueConfigsArray,
    append: appendToValueConfigsArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'values',
  });

  const { formValues } = useReportCardBuilderForm({
    formContext,
    defaultValues,
    onSettingsFormChange,
  });

  const valueConfigFieldArrayItems = formValues.values;

  // useWatch doesn't return correct array values after deletion for some reason,
  // but formContext.watch doesn't return new array reference, when changes
  // so we use this hack only as a trigger for rerender
  const valueConfigFieldArrayItemsWatcher = useWatch({
    control: formContext.control,
    name: 'values',
  });

  const availableForAddingValueConfigs = useMemo(
    () =>
      getAvailableForAddingValueConfigs(
        availableSourceFields,
        valueConfigFieldArrayItems,
        blueprintLaunchResult.rows
      ),
    [availableSourceFields, valueConfigFieldArrayItemsWatcher]
  );

  const addToRowConfigsArray = (blueprintSourceFieldID: string) => {
    appendToRowConfigsArray({
      ...PIVOT_TABLE_ROW_OR_COLUMN_SCHEMA.getDefault(),
      blueprintSourceFieldID,
    });
  };

  const addToColumnConfigsArray = (blueprintSourceFieldID: string) => {
    appendToColumnConfigsArray({
      ...PIVOT_TABLE_ROW_OR_COLUMN_SCHEMA.getDefault(),
      blueprintSourceFieldID,
    });
  };

  const addToValueConfigsArray = (blueprintSourceFieldID: string) => {
    const availableFormulas =
      availableForAddingValueConfigs[blueprintSourceFieldID];

    if (!availableFormulas) {
      return;
    }
    const [newValueItemFormula, availableViewKinds] =
      Object.entries(availableFormulas)[0];

    if (!newValueItemFormula) {
      return;
    }

    const newItemViewKind = availableViewKinds.at(0);

    if (!newItemViewKind) {
      return;
    }

    appendToValueConfigsArray({
      formula: newValueItemFormula as CustomReportValueFormulaKindEnum,
      view: newItemViewKind,
      blueprintSourceFieldID,
    });
  };

  const makeConfigWithDefaultSort = (
    config: RowOrColumnConfigArrayItemFormType
  ) => ({
    ...config,
    sortBy: {
      kind: CustomReportSortByKindEnum.Default,
      sortingValue: null,
    },
  });

  // If we removed all rows, we should reset sorting by value in columns,
  // cause row info is used for sortingValue there
  useEffect(() => {
    if (rowConfigFieldArrayItems.length) return;

    formContext.setValue(
      'columns',
      formContext.getValues('columns').map(config => {
        if (config.sortBy.kind === CustomReportSortByKindEnum.Default) {
          return config;
        }

        return makeConfigWithDefaultSort(config);
      })
    );
  }, [rowConfigFieldArrayItems]);

  // If we removed all columns, we should reset sorting by value in rows,
  // cause column info is used for sortingValue there
  useEffect(() => {
    if (columnConfigFieldArrayItems.length) return;

    formContext.setValue(
      'rows',
      formContext.getValues('rows').map(config => {
        if (
          config.sortBy.kind === CustomReportSortByKindEnum.Default ||
          config.sortBy.sortingValue?.blueprintSourceFieldValue === null
        ) {
          return config;
        }

        return makeConfigWithDefaultSort(config);
      })
    );
  }, [columnConfigFieldArrayItems]);

  // If we changed value configs, we should check, if some sort kinds are no longer available and reset them
  useEffect(() => {
    (['rows', 'columns'] as const).forEach(fieldName => {
      formContext.setValue(
        fieldName,
        formContext.getValues(fieldName).map(config => {
          const currentSortKey = config.sortBy.sortingValue?.valueKey;
          if (
            !currentSortKey ||
            valueConfigFieldArrayItems.some(
              valueConfig =>
                getCustomReportValueConfigKey(valueConfig) ===
                getCustomReportValueConfigKey(currentSortKey)
            )
          ) {
            return config;
          }

          return makeConfigWithDefaultSort(config);
        })
      );
    });
  }, [valueConfigFieldArrayItemsWatcher]);

  return {
    availableSourceFields,

    formContext,

    availableForAddingValueConfigs,

    rowConfigFieldArrayItems,
    addToRowConfigsArray,
    removeFromRowConfigsArray,

    columnConfigFieldArrayItems,
    addToColumnConfigsArray,
    removeFromColumnConfigsArray,

    valueConfigFieldArrayItems,
    addToValueConfigsArray,
    removeFromValueConfigsArray,
  };
};
