import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorFragmentDoc } from '../fragments/milkingParlor.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type MilkingParlorsQuery = { __typename?: 'Query', milkingParlors: { __typename?: 'MilkingParlorConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'MilkingParlorEdge', cursor: string, node: { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, tagField: Types.ParlorExportStatusFileTagFieldEnum, farm: { __typename: 'Farm', id: string, name: string, number: string }, intervals: Array<{ __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string }> } }> } };


export const MilkingParlorsDocument = gql`
    query milkingParlors($first: Int, $after: String, $farmIDs: [ID!]) {
  milkingParlors(first: $first, after: $after, farmIDs: $farmIDs) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...MilkingParlor
      }
    }
  }
}
    ${MilkingParlorFragmentDoc}`;

/**
 * __useMilkingParlorsQuery__
 *
 * To run a query within a React component, call `useMilkingParlorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      farmIDs: // value for 'farmIDs'
 *   },
 * });
 */
export function useMilkingParlorsQuery(baseOptions?: Apollo.QueryHookOptions<MilkingParlorsQuery, MilkingParlorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorsQuery, MilkingParlorsQueryVariables>(MilkingParlorsDocument, options);
      }
export function useMilkingParlorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorsQuery, MilkingParlorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorsQuery, MilkingParlorsQueryVariables>(MilkingParlorsDocument, options);
        }
export function useMilkingParlorsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorsQuery, MilkingParlorsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorsQuery, MilkingParlorsQueryVariables>(MilkingParlorsDocument, options);
        }
export type MilkingParlorsQueryHookResult = ReturnType<typeof useMilkingParlorsQuery>;
export type MilkingParlorsLazyQueryHookResult = ReturnType<typeof useMilkingParlorsLazyQuery>;
export type MilkingParlorsSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorsSuspenseQuery>;
export type MilkingParlorsQueryResult = Apollo.QueryResult<MilkingParlorsQuery, MilkingParlorsQueryVariables>;