import React, { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { Button } from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { Input, InputThemes } from '~/shared/components/Input';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { Callout, NotificationVariants } from '~/services/notifications';

import { CowsCopyKeyFragment } from '~/features/cowsMovement/gql/fragments/CowsCopyKey.graphql';

import styles from './index.module.scss';

interface Props {
  /**
   * Is key info currently loading
   */
  isLoading?: boolean;
  /**
   * submit action whose result affects the error display in the Callout
   */
  submit: (key: string) => Promise<CowsCopyKeyFragment | undefined>;
}

const FORM_ID = 'ActivateKeyForm';

const SCHEMA = yup.object({
  key: yup.string().required(),
});

type ActivateKeyFormType = InferValidatedSchema<typeof SCHEMA>;

export const ActivateKeyForm: React.FC<Props> = ({ isLoading, submit }) => {
  const [shouldShowError, setShouldShowError] = useState(false);
  const formContext = useForm<ActivateKeyFormType>({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const handleSubmit = async ({ key }: ActivateKeyFormType) => {
    const cowsCopyKey = await submit(key);
    setShouldShowError(R.isNil(cowsCopyKey));
  };

  return (
    <div className={styles.root}>
      {shouldShowError && (
        <Callout
          {...{
            message: 'Введённый ключ не найден',
            variant: NotificationVariants.error,
          }}
        />
      )}
      <Form
        {...{
          formContext,
          id: FORM_ID,
          className: styles.activateKeyForm,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Input
          {...{
            name: 'key',
            placeholder: 'Введите ключ',
            className: 'default-control',
            theme: InputThemes.light,
          }}
        />
        <Button
          {...{
            isLoading,
            form: FORM_ID,
            type: 'submit',
          }}
        >
          Активировать
        </Button>
      </Form>
      <div className={styles.blockedMessage}>
        <DataBlockedMessage
          {...{
            className: 'p-24',
            message: 'Активируйте ключ, чтобы увидеть информацию о животных',
          }}
        />
      </div>
    </div>
  );
};
