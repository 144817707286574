import React from 'react';

import * as yup from 'yup';

import {
  Button,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { FilePicker } from '~/services/files';
import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { FarmAsyncSelect } from '~/entities/farms';

import { useLoadDcImportArchiveMutation } from '../../gql/mutations/loadDCImportArchive.graphql';
import { useCompaniesDetailedQuery } from '../../gql/queries/companiesDetailed.graphql';
import { updateCompanyShortFragment } from '../../helpers';
import { AnyCompanyFragment } from '../../types';

export interface UploadCowFileModalProps
  extends InjectedModalProps<UploadCowFileModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Company to upload file
   */
  company: AnyCompanyFragment;
}

const FORM_ID = 'uploadCowFileForm';

const SCHEMA = yup.object({
  file: yup.mixed().required(), // Upload!
  farmID: yup.string().required(), // ID!
});

type UploadCowsFileFormType = InferValidatedSchema<typeof SCHEMA>;

export const UploadCowFileModal: React.FC<UploadCowFileModalProps> = ({
  className,

  company,

  close,
}) => {
  const [loadDcImportArchive, { loading: isLoadDcImportLoading }] =
    useLoadDcImportArchiveMutation();

  const {
    data: companiesDetailedQueryData,
    loading: isCompanyDetailedLoading,
  } = useCompaniesDetailedQuery({
    variables: { ids: normalizeToArrayOrUndefined(company.id) },
  });
  const companyDetailed = companiesDetailedQueryData?.companies.nodes[0];

  const formContext = useForm<UploadCowsFileFormType>({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const handleSubmit = (fileInput: UploadCowsFileFormType) => {
    loadDcImportArchive({
      variables: {
        fileInput: {
          ...fileInput,
          companyID: company.id,
        },
      },
      update: updateCompanyShortFragment(company.id, draft => {
        draft.isLockedForWrite = true;
      }),
    }).then(() => {
      close();
    });
  };

  return (
    <Modal
      {...{
        className,
        title: `Загрузка данных в компанию ${company.name}`,
        submitButtonProps: {
          isLoading: isLoadDcImportLoading,
          children: 'Загрузить',
          form: FORM_ID,
        },
        isLoading: isCompanyDetailedLoading,
      }}
    >
      <Form
        formContext={formContext}
        id={FORM_ID}
        onSubmit={formContext.handleSubmit(handleSubmit)}
      >
        <Typography variant={TypographyVariants.bodySmall}>
          Используйте инструкцию, чтобы правильно подготовить файл для загрузки
        </Typography>
        <Button
          {...{
            className: 'mt-16',
            size: ButtonSizes.small24,
            iconVariant: IconVariants.newTab,
            theme: ButtonThemes.accent,
            variant: ButtonVariants.secondary,
            onPress: () => {
              window.open(
                process.env.REACT_APP_COW_FILE_INSTRUCTION_URL,
                '_blank'
              );
            },
          }}
        >
          Перейти к инструкции
        </Button>

        <FarmAsyncSelect
          {...{
            name: 'farmID',
            className: 'mt-24',
            label: 'Ферма',
            items: companyDetailed?.farms.nodes,
          }}
        />

        <FilePicker className="mt-16" name="file" />
      </Form>
    </Modal>
  );
};
