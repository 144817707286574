import {
  CustomReportValueFormulaKindEnum,
  CustomReportValueViewKindEnum,
} from '@graphql-types';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { SourceFieldFragment } from '~/entities/blueprintSourceFields/gql/fragments/sourceField.graphql';

import { CustomReportBlueprintLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportBlueprintLaunchResult.graphql';

import { ValueConfigArrayItemFormType } from '../../types';
import { AVAILABLE_FORMULAS_BY_VALUE_TYPES } from './constants';
import {
  AvailableForAddingToSourceFieldValueConfigs,
  AvailableForAddingValueConfigs,
} from './types';

/**
 * Gets aggregated dict of value configs, that can be added by user.
 * All already added values are filtered out
 */
export const getAvailableForAddingValueConfigs = (
  availableSourceFields: SourceFieldFragment[],
  customReportValueConfigs: ValueConfigArrayItemFormType[],
  blueprintLaunchResultRows: CustomReportBlueprintLaunchResultFragment['rows']
): AvailableForAddingValueConfigs => {
  return availableSourceFields.reduce(
    (sourceFieldAcc, sourceField, sourceFieldIndex) => {
      const sameBlueprintSourceFieldValueConfigs =
        customReportValueConfigs.filter(
          valueConfig => valueConfig.blueprintSourceFieldID === sourceField.id
        );

      // TODO rework to column types, when they will be done on backend
      const firstRowWithValue = blueprintLaunchResultRows.find(row => {
        const rowValue = row.values[sourceFieldIndex];
        return (
          !R.isNil(rowValue.datetimeValue) ||
          !R.isNil(rowValue.floatValue) ||
          !R.isNil(rowValue.intValue) ||
          !R.isNil(rowValue.strValue)
        );
      });

      const sourceFieldAvailableFormulas = match(
        firstRowWithValue?.values[sourceFieldIndex]
      )
        .with(
          { intValue: P.not(P.nullish) },
          R.always(AVAILABLE_FORMULAS_BY_VALUE_TYPES.intValue)
        )
        .with(
          { floatValue: P.not(P.nullish) },
          R.always(AVAILABLE_FORMULAS_BY_VALUE_TYPES.floatValue)
        )
        .with(
          { datetimeValue: P.not(P.nullish) },
          R.always(AVAILABLE_FORMULAS_BY_VALUE_TYPES.datetimeValue)
        )
        .otherwise(R.always(AVAILABLE_FORMULAS_BY_VALUE_TYPES.strValue));

      const availableFormulas = Object.values(
        CustomReportValueFormulaKindEnum
      ).reduce((formulaAcc, formula) => {
        if (!sourceFieldAvailableFormulas.includes(formula)) {
          return formulaAcc;
        }

        const sameFormulaValueConfigs =
          sameBlueprintSourceFieldValueConfigs.filter(
            valueConfig => valueConfig.formula === formula
          );

        const availableViewKinds = Object.values(
          CustomReportValueViewKindEnum
        ).filter(
          viewKind =>
            !sameFormulaValueConfigs.some(
              valueConfig => valueConfig.view === viewKind
            )
        );

        if (availableViewKinds.length) {
          formulaAcc[formula] = availableViewKinds;
        }

        return formulaAcc;
      }, {} as AvailableForAddingToSourceFieldValueConfigs);

      if (Object.keys(availableFormulas).length) {
        sourceFieldAcc[sourceField.id] = availableFormulas;
      }
      return sourceFieldAcc;
    },
    {} as AvailableForAddingValueConfigs
  );
};
