import React, { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { Outlet } from '@tanstack/react-router';

import { usePrevious } from '~/shared/hooks/usePrevious';

import { useArkaNavigation } from '~/services/navigation';

import { CompanyLoadingBlocker } from '~/entities/companies';

import { BaseLayout } from '../BaseLayout';

interface Props extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, shows the menu (default - true)
   */
  withMenu?: boolean;
}

export const AuthBaseLayout: React.FC<Props> = ({
  className,
  withMenu = true,
  children = <Outlet />,
}) => {
  const apolloClient = useApolloClient();
  const { selectedCompanyId, selectedCompany } = useArkaNavigation();
  const prevCompanyId = usePrevious(selectedCompanyId);

  // Clear cache on selectedCompanyId change,
  // cause all queries should be requested with the new x-active-company header
  useEffect(() => {
    if (prevCompanyId && prevCompanyId !== selectedCompanyId) {
      apolloClient.resetStore();
    }
  }, [selectedCompanyId]);

  return (
    <BaseLayout className={className} withMenu={withMenu}>
      {selectedCompany?.isLockedForWrite ? <CompanyLoadingBlocker /> : children}
    </BaseLayout>
  );
};
