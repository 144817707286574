import * as Types from '../../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BullFragmentDoc } from '../../../../../../../../../entities/bulls/gql/fragments/bull.graphql';
export type ConceptionRateChartBullCandleFragment = { __typename: 'ConceptionRateChartBullCandle', meanValue: number, maxValue: number, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } };

export const ConceptionRateChartBullCandleFragmentDoc = gql`
    fragment ConceptionRateChartBullCandle on ConceptionRateChartBullCandle {
  __typename
  meanValue
  maxValue
  bull {
    ...Bull
  }
}
    ${BullFragmentDoc}`;