import React, { ReactNode } from 'react';

import { ReactComponent as ShrinkSVG } from '~/~legacy/icons/shrink.svg';

import { MModal } from '../MModal';
import { MPanel } from '../MPanel';
import { Typography, TypographyVariants } from '../Typography';

interface Props {
  children?: ReactNode;
  close: () => void;
  title?: string;
}

export const MChartModal: React.FC<Props> = props => {
  const { close, children, title } = props;

  const titleComponent = title ? (
    <>
      <div className="flex items-center justify-between">
        <Typography variant={TypographyVariants.heading2}>{title}</Typography>
        <div onClick={close} className="text-muted">
          Свернуть{' '}
          <ShrinkSVG className="ml-8" style={{ verticalAlign: 'middle' }} />
        </div>
      </div>
      <hr className="my-16" />
    </>
  ) : null;

  return (
    <MModal wide close={close}>
      <MPanel className="p-16">
        {titleComponent}
        {children}
      </MPanel>
    </MModal>
  );
};
