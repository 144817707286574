import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CustomReportValueFormulaKindEnum,
  CustomReportValueViewKindEnum,
} from '@graphql-types';

import { makeUseEnumSelect, SelectThemes } from '~/shared/components/Select';

import { ReportCardBuilderFormArrayItem } from '~/services/reportCardBuilder';

import { UseBlueprintSourceFieldsInterface } from '~/entities/blueprintSourceFields';

import {
  CUSTOM_REPORT_VALUE_FORMULA_KIND_DICT,
  CUSTOM_REPORT_VALUE_VIEW_KIND_DICT,
} from '../../../../constants';
import {
  CustomReportPivotSettingsFormType,
  ValueConfigArrayItemFormType,
} from '../../../../types';
import { AvailableForAddingToSourceFieldValueConfigs } from '../../types';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Field name prefix to use for form fields in array
   */
  fieldPrefix: `values.${number}.`;
  /**
   * Value config to render
   */
  valueConfig: ValueConfigArrayItemFormType;
  /**
   * Called, when user pressed delete button to remove the config
   */
  onDelete?: () => void;
  /**
   * Logic helpers for blueprint source fields
   */
  useBlueprintSourceFieldsInterface: UseBlueprintSourceFieldsInterface;
  /**
   * Available for adding formulas and viewKinds for this source field
   */
  availableForAddingToSourceFieldValueConfigs: AvailableForAddingToSourceFieldValueConfigs;
}

const FORMULA_POPOVER_WIDTH_PX = 211;
const VIEW_KIND_POPOVER_WIDTH_PX = 189;

const useCustomReportValueFormulaKindSelect = makeUseEnumSelect(
  CustomReportValueFormulaKindEnum,
  enumValue =>
    CUSTOM_REPORT_VALUE_FORMULA_KIND_DICT[
      enumValue as CustomReportValueFormulaKindEnum
    ]
);

const useCustomReportValueViewKindEnumSelect = makeUseEnumSelect(
  CustomReportValueViewKindEnum,
  enumValue =>
    CUSTOM_REPORT_VALUE_VIEW_KIND_DICT[
      enumValue as CustomReportValueViewKindEnum
    ]
);

export const ValueConfigArrayItemCard: React.FC<Props> = ({
  className,
  fieldPrefix,
  valueConfig,
  onDelete,
  useBlueprintSourceFieldsInterface: { getSourceFieldById },
  availableForAddingToSourceFieldValueConfigs,
}) => {
  const formContext = useFormContext<CustomReportPivotSettingsFormType>();

  const formulaFieldName = `${fieldPrefix}formula` as const;

  const {
    items: valueFormulaSelectItems,
    renderSelectElement: renderValueFormulaKindSelectElement,
  } = useCustomReportValueFormulaKindSelect({
    name: formulaFieldName,
    label: 'Формула',
    theme: SelectThemes.light,
    popoverWidth: FORMULA_POPOVER_WIDTH_PX,
  });

  const selectedFormula = formContext.watch(formulaFieldName);

  const valueFormulaSelectFilteredItems = valueFormulaSelectItems.filter(
    item =>
      (item.id as CustomReportValueFormulaKindEnum) === selectedFormula ||
      !!availableForAddingToSourceFieldValueConfigs[
        item.id as CustomReportValueFormulaKindEnum
      ]?.length
  );

  const viewKindFieldName = `${fieldPrefix}view` as const;

  const selectedViewKind = formContext.watch(viewKindFieldName);

  const {
    items: valueViewKindSelectItems,
    renderSelectElement: renderValueViewKindEnumSelectElement,
  } = useCustomReportValueViewKindEnumSelect({
    name: viewKindFieldName,
    label: 'Отображать как',
    theme: SelectThemes.light,
    popoverWidth: VIEW_KIND_POPOVER_WIDTH_PX,
  });

  const valueViewKindSelectFilteredItems = valueViewKindSelectItems.filter(
    item =>
      (item.id as CustomReportValueViewKindEnum) === selectedViewKind ||
      availableForAddingToSourceFieldValueConfigs[selectedFormula]?.includes(
        item.id
      )
  );

  return (
    <ReportCardBuilderFormArrayItem
      {...{
        className,
        title: getSourceFieldById(valueConfig.blueprintSourceFieldID)?.name,
        onDelete,
      }}
    >
      <div className="grid grid-cols-2 gap-12">
        {renderValueFormulaKindSelectElement({
          items: valueFormulaSelectFilteredItems,
        })}
        {renderValueViewKindEnumSelectElement({
          items: valueViewKindSelectFilteredItems,
        })}
      </div>
    </ReportCardBuilderFormArrayItem>
  );
};
