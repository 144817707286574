import React, { useMemo } from 'react';

import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';

import { useSkeletonContext } from '~/shared/components/Skeleton';
import { Tooltip } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { maxByInList, minByInList } from '~/shared/helpers/array';
import { formatInt } from '~/shared/helpers/number';

import { formatPenGroup } from '~/entities/penGroups';

import {
  CHART_DATASET_COLORS_ITERATOR,
  CHART_DATASET_HOVER_COLORS_ITERATOR,
  CHART_DATASET_PRESSED_COLORS_ITERATOR,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
  getChartOptions,
  getScaleOptions,
  ReactChart,
} from '~/features/charts';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import { SizeVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';
import panelStyles from '~/styles/modules/panel.module.scss';

import { MilkingParlorScheduleReportStallEntryFragment } from '../../gql/fragments/milkingParlorScheduleReportStallEntry.graphql';
import { MilkingParlorChartTooltip } from './components/MilkingParlorChartTooltip';
import { SkeletonMilkingParlorChart } from './components/SkeletonMilkingParlorChart';
import {
  CHART_HEIGHT_PX,
  CHART_TIME_AXIS_PADDING_MS,
  MIN_STALL_ZOOM_RANGE_COUNT,
  MIN_TIME_ZOOM_RANGE_MS,
  MISTAKES_DATASET_BACKGROUND_COLOR,
  MISTAKES_DATASET_BORDER_COLOR,
  UNIDENTIFIED_COW_TEXT,
  UNIDENTIFIED_DATASET_COLOR,
  UNIDENTIFIED_DATASET_HOVER_COLOR,
} from './constants';
import { MilkingParlorController } from './controller';
import styles from './index.module.scss';
import { MilkingParlorIntervalElement } from './milkingParlorIntervalElement';
import {
  FlatReportMilkingEntry,
  FlatReportMistakeEntry,
  MilkingParlorChartDataPoint,
  MilkingParlorChartDataset,
} from './types';

Chart.register(zoomPlugin);

Chart.register(MilkingParlorController);
Chart.register(MilkingParlorIntervalElement);

const SCALE_TOOLTIP_MAX_WIDTH_PX = 264;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Stall entries to render on the chart
   */
  milkingParlorScheduleReportEntries: MilkingParlorScheduleReportStallEntryFragment[];
}

export const MilkingParlorChart: React.FC<Props> = ({
  className,
  milkingParlorScheduleReportEntries,
}) => {
  const { renderWithoutSkeleton } = useSkeletonContext();

  // Prepare data aggregations
  const {
    flatMilkings,
    unidentifiedFlatMilkings,
    flatMistakes,
    groupedFlatMilkings,
  } = useMemo(() => {
    const flatMilkingsInternal: FlatReportMilkingEntry[] =
      milkingParlorScheduleReportEntries.flatMap(entry =>
        entry.milkings.map(milking => ({
          milking,
          stallNumber: entry.stallNumber,
        }))
      );

    const [unidentifiedFlatMilkingsInternal, identifiedFlatMilkings] =
      R.partition(
        flatMilking => !flatMilking.milking.penGroup?.id,
        flatMilkingsInternal
      );

    const flatMistakesInternal: FlatReportMistakeEntry[] = flatMilkingsInternal
      .flatMap(flatMilking => {
        if (!flatMilking.milking.mistakes.length) return null;

        return flatMilking.milking.mistakes.map(mistake => ({
          mistake,
          flatMilking,
        }));
      })
      .filter(Boolean);

    const groupedFlatMilkingsInternal = R.groupBy(
      flatMilking => flatMilking.milking.penGroup?.id ?? '',
      identifiedFlatMilkings
    );

    return {
      flatMilkings: flatMilkingsInternal,
      unidentifiedFlatMilkings: unidentifiedFlatMilkingsInternal,
      flatMistakes: flatMistakesInternal,
      groupedFlatMilkings: groupedFlatMilkingsInternal,
    };
  }, [milkingParlorScheduleReportEntries]);

  // Calculate chart options
  const chartOptions = useMemo(() => {
    const minDate = minByInList(
      flatMilking => flatMilking.milking.happenedAt,
      flatMilkings
    )?.milking.happenedAt;
    const maxDate = maxByInList(
      flatMilking => flatMilking.milking.endedAt,
      flatMilkings
    )?.milking.endedAt;

    const minStallNumber =
      (milkingParlorScheduleReportEntries.at(0)?.stallNumber ?? 1) - 1;
    const maxStallNumber =
      (milkingParlorScheduleReportEntries.at(-1)?.stallNumber ??
        minStallNumber) + 1;

    return getChartOptions<'milkingParlor'>({
      scales: {
        x: getScaleOptions<'time'>({
          type: 'time',
          title: {
            display: true,
            text: 'Время доения',
          },
          time: {
            displayFormats: {
              day: 'd MMM',
              hour: 'HH:mm',
              minute: 'HH:mm',
            },
          },
          grid: {
            display: true,
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: NUMBER_TOKENS.spacing20,
            maxRotation: 0,
          },
          min: minDate
            ? dayjs(minDate).valueOf() - CHART_TIME_AXIS_PADDING_MS
            : undefined,
          max: maxDate
            ? dayjs(maxDate).valueOf() + CHART_TIME_AXIS_PADDING_MS
            : undefined,
        }),

        y: getScaleOptions<'category'>({
          type: 'category',
          title: {
            display: true,
            text: 'Доильные аппараты',
          },
          labels: [
            '',
            ...milkingParlorScheduleReportEntries
              .map(entry => formatInt(entry.stallNumber))
              .toReversed(),
            '',
          ],
          grid: {
            display: true,
            color: context => {
              return !context.tick.label && !context.index
                ? 'transparent'
                : TOKENS.colorBorderMuted;
            },
          },
          border: {
            display: true,
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: -NUMBER_TOKENS.spacing4,
          },
        }),
      },
      interaction: {
        mode: 'nearest',
        intersect: true,
      },
      plugins: {
        tooltip: {
          position: 'nearest',
        },
        zoom: {
          limits: {
            x: {
              min: 'original',
              max: 'original',
              minRange: MIN_TIME_ZOOM_RANGE_MS,
            },
            y: {
              min: minStallNumber,
              max: maxStallNumber,
              minRange: MIN_STALL_ZOOM_RANGE_COUNT,
            },
          },
          zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
          pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
        },
      },
    });
  }, [milkingParlorScheduleReportEntries]);

  // Create datasets
  const { datasetConfigs, mistakesDataset } = useMemo(() => {
    // Create mistakes dataset
    const mistakesDatasetInternal: MilkingParlorChartDataset = {
      key: 'mistakes',
      label: 'Переподкл. аппарата',
      isMistake: true,
      borderColor: MISTAKES_DATASET_BORDER_COLOR,
      hoverBorderWidth: NUMBER_TOKENS.borderWidth2,
      color: MISTAKES_DATASET_BACKGROUND_COLOR,
      backgroundColor: MISTAKES_DATASET_BACKGROUND_COLOR,
      hoverBackgroundColor: MISTAKES_DATASET_BACKGROUND_COLOR,
      data: flatMistakes.map(flatMistake => ({
        happenedAt: flatMistake.mistake.happenedAt,
        endedAt: flatMistake.mistake.endedAt,
        stallNumber: formatInt(flatMistake.flatMilking.stallNumber),
        flatMistake,
      })),
    };

    // Create unidentified milkings dataset
    const unidentifiedMilkingsDataset: MilkingParlorChartDataset = {
      key: 'unidentified',
      label: UNIDENTIFIED_COW_TEXT,
      color: UNIDENTIFIED_DATASET_COLOR,
      hoverColor: UNIDENTIFIED_DATASET_HOVER_COLOR,
      hoverBackgroundColor: UNIDENTIFIED_DATASET_HOVER_COLOR,
      backgroundColor: UNIDENTIFIED_DATASET_COLOR,
      borderColor: 'transparent',
      isTogglable: true,
      data: unidentifiedFlatMilkings.map(flatMilking => ({
        happenedAt: flatMilking.milking.happenedAt,
        endedAt: flatMilking.milking.endedAt,
        stallNumber: formatInt(flatMilking.stallNumber),
        flatMilking,
      })),
    };

    // Create other pen groups datasets
    const penGroupDatasets: MilkingParlorChartDataset[] = Object.values(
      groupedFlatMilkings
    )
      .filter(Boolean)
      .map((penGroupFlatMilkings, penGroupIndex) => {
        const firstMilking = penGroupFlatMilkings[0];

        const color = CHART_DATASET_COLORS_ITERATOR.next(!penGroupIndex).value;
        const hoverColor =
          CHART_DATASET_HOVER_COLORS_ITERATOR.next(!penGroupIndex).value;
        const pressedColor =
          CHART_DATASET_PRESSED_COLORS_ITERATOR.next(!penGroupIndex).value;

        const penGroupId = firstMilking.milking.penGroup?.id ?? '';
        return {
          key: penGroupId,
          label: formatPenGroup(firstMilking.milking.penGroup),
          borderColor: 'transparent',
          color,
          isTogglable: true,
          hoverColor,
          pressedColor,
          backgroundColor: color,
          hoverBackgroundColor: hoverColor,
          data: penGroupFlatMilkings.map(flatMilking => ({
            happenedAt: flatMilking.milking.happenedAt,
            endedAt: flatMilking.milking.endedAt,
            stallNumber: formatInt(flatMilking.stallNumber),
            flatMilking,
          })),
        };
      });

    return {
      datasetConfigs: [
        mistakesDatasetInternal,
        unidentifiedMilkingsDataset,
        ...penGroupDatasets,
      ],
      mistakesDataset: mistakesDatasetInternal,
    };
  }, [milkingParlorScheduleReportEntries]);

  return (
    <div className={clsx(styles.root, panelStyles.largePanel, className)}>
      <Typography
        {...{
          variant: TypographyVariants.bodyLargeStrong,
          tag: 'h3',
          className: 'mb-24 flex items-center',
          isStaticContent: true,
        }}
      >
        График доильного зала
        {renderWithoutSkeleton(
          <Tooltip
            {...{
              className: 'ml-8',
              size: SizeVariants.size24,
              maxWidth: SCALE_TOOLTIP_MAX_WIDTH_PX,
              content:
                'Для изменения масштаба графика используйте колёсико мыши или тачпад',
            }}
          />
        )}
      </Typography>

      <ReactChart<'milkingParlor', MilkingParlorChartDataPoint[]>
        {...{
          legendClassName: 'mb-32',
          chartClassName: styles.chart,
          type: 'milkingParlor',
          height: CHART_HEIGHT_PX,
          datasetIdKey: '1',
          options: chartOptions,
          datasetConfigs,
          skeleton: <SkeletonMilkingParlorChart />,
          isSkeletonWithHeight: false,
          onLegendItemToggle: (datasetIndex, isSelected, chartInstance) => {
            // We should hide not only dataset itself,
            // but milking mistakes, associated with toggled milking group, from separate dataset
            const mistakesDatasetIndex =
              datasetConfigs.indexOf(mistakesDataset);

            const toggledDataset = datasetConfigs[datasetIndex];
            const toggledDatasetMistakes = toggledDataset.data.flatMap(
              dataPoint => dataPoint.flatMilking?.milking.mistakes ?? []
            );

            const mistakesDatasetMeta =
              chartInstance.getDatasetMeta(mistakesDatasetIndex);

            mistakesDataset.data.forEach((mistakePoint, pointIndex) => {
              if (!mistakePoint.flatMistake) return;

              if (
                toggledDatasetMistakes.includes(
                  mistakePoint.flatMistake.mistake
                )
              ) {
                // Modify internal chartjs field from hide() and show() methods implementations,
                // cause api methods show() and hide() only work for single point
                // and update chart for every call and cause lags.
                (mistakesDatasetMeta.data[pointIndex] as any).hidden =
                  !isSelected;
              }
            });
          },
          renderTooltip: dataPoints => {
            return (
              <MilkingParlorChartTooltip
                {...{
                  dataPoint: dataPoints[0].raw as MilkingParlorChartDataPoint,
                }}
              />
            );
          },
        }}
      />
    </div>
  );
};
