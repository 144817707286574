import * as Types from '../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CalendarEntryFragment = { __typename: 'CalendarEntry', date: string, blueprint: { __typename: 'Blueprint', id: string, name: string, description: string, blueprintRoles: Array<{ __typename?: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }>, viewSettings: { __typename?: 'BlueprintViewSettings', kind?: Types.ViewKindEnum | null } } };

export const CalendarEntryFragmentDoc = gql`
    fragment CalendarEntry on CalendarEntry {
  __typename
  date
  blueprint {
    __typename
    id
    name
    description
    blueprintRoles {
      id
      name
      color
    }
    viewSettings {
      kind
    }
  }
}
    `;