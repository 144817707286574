import { gql } from '~/fakeGql';

import {
  BlueprintValueFragmentGQL,
  ReadOnlyArgumentFragmentGQL,
  WritableArgumentFragmentGQL,
} from './shared.gql';

export const GetMasterBlueprintIdGQL = gql`
  query GetMasterBlueprintId {
    blueprints(runType: MASTER) {
      nodes {
        id
        name
      }
    }
  }
`;

export const CreateSectionNodeGQL = gql`
  mutation CraeteSectionNode($input: CreateSourceSectionInput!) {
    createSourceSection(input: $input) {
      id
      kind
      verboseName
      fields {
        kind
        verboseName
        returnValueKind
        name
        id
        arguments {
          name
        }
      }
    }
  }
`;

export const BlueprintEditingInitializationGQL = gql`
  ${BlueprintValueFragmentGQL}
  ${WritableArgumentFragmentGQL}
  ${ReadOnlyArgumentFragmentGQL}

  query BlueprintEditingInitialization($id: ID!) {
    blueprintSourceSectionTypes {
      kind
      verboseName
      fields {
        kind
        verboseName
        arguments {
          name
          kind
          verboseName
          isRequired
        }
      }
    }

    blueprintActionTypes {
      kind
      verboseName
    }

    blueprint(id: $id) {
      id
      name

      isForIntegratorOnly
      priority

      generalSettings {
        vitalityFilter
        orderedInputIDs
      }

      runSettings {
        runType
      }

      viewSettings {
        kind
        kindSettings {
          tableSettings {
            masterFields {
              id
              name
            }
            fields {
              id
              name
            }
            groupByFields {
              id
              name
            }
          }

          graphSettings {
            kind
          }
        }
      }

      inputs {
        id
        name
        prompt
        cycleInput {
          id
        }
      }

      cycleInputs {
        id
      }

      unions {
        id
        name
      }

      filterGroups {
        id
        logicOperator
        parentFilterGroup {
          id
        }

        union {
          id
        }

        filters {
          id
          compareOperator
          field {
            id
            kind
            verboseName
            name
            returnValueKind
          }
          value {
            __typename
            ...BlueprintValueFragment
          }
        }
      }

      actions {
        id
        kind
        verboseName
        parentFilterGroup {
          id
        }

        arguments {
          __typename
          ...WritableArgumentFragment
          ...ReadOnlyArgumentFragment
        }
      }

      sourceSections {
        kind
        verboseName
        id
        fields {
          id
          kind
          verboseName
          returnValueKind
          name
          arguments {
            ...WritableArgumentFragment
          }
        }
      }

      # duplication because nested fragments causes trouble and empty data

      masterSourceSections {
        kind
        verboseName
        id
        fields {
          id
          kind
          verboseName
          returnValueKind
          name
          arguments {
            ...WritableArgumentFragment
          }
        }
      }
    }
  }
`;

export const GetBlueprintSourceFieldsDefinitionBySectionNodeGQL = gql`
  query GetFieldsDefinitionsBySection($kind: SourceSectionKindEnum) {
    blueprintSourceSectionTypes(kind: $kind) {
      kind
      verboseName
      fields {
        kind
        verboseName
        returnValueKind
        arguments {
          name
          kind
          verboseName
          isRequired
        }
      }
    }
  }
`;

export const CreateFieldNodeGQL = gql`
  ${BlueprintValueFragmentGQL}
  ${WritableArgumentFragmentGQL}

  mutation CreateFieldNode($input: CreateSourceFieldInput!) {
    createSourceField(input: $input) {
      id
      name
      kind
      verboseName
      returnValueKind

      arguments {
        ...WritableArgumentFragment
      }
    }
  }
`;

export const UpdateFieldNodeGQL = gql`
  mutation UpdateFiledNode($id: ID!, $input: UpdateSourceFieldInput!) {
    updateSourceField(id: $id, input: $input)
  }
`;

export const CreateInputNodeGQL = gql`
  mutation CreateInputNode($input: CreateBlueprintInputInput!) {
    createBlueprintInput(input: $input) {
      id
      name
      prompt
      cycleInput {
        id
      }
    }
  }
`;

export const UpdateInputNodeGQL = gql`
  mutation UpdateInputNode($id: ID!, $input: UpdateBlueprintInputInput!) {
    updateBlueprintInput(id: $id, input: $input)
  }
`;

export const DeleteNodeGQL = gql`
  mutation DeleteNode($id: ID!) {
    deleteBlueprintNode(id: $id)
  }
`;

export const CreateActionNodeGQL = gql`
  ${BlueprintValueFragmentGQL}
  ${WritableArgumentFragmentGQL}
  ${ReadOnlyArgumentFragmentGQL}

  mutation CreateAction($input: CreateBlueprintActionInput!) {
    createBlueprintAction(input: $input) {
      id
      kind
      verboseName
      parentFilterGroup {
        id
      }

      arguments {
        __typename
        ...WritableArgumentFragment
        ...ReadOnlyArgumentFragment
      }
    }
  }
`;

export const SetArgumentGQL = gql`
  mutation SetArgument($id: ID!, $input: SetArgumentInput!) {
    setArgument(id: $id, input: $input)
  }
`;

export const UnsetArgumentGQL = gql`
  mutation UnsetArgument($id: ID!) {
    unsetArgument(id: $id)
  }
`;

export const CreateFilterGroupGQL = gql`
  mutation CreateFilterGroup($input: CreateBlueprintFilterGroupInput!) {
    createBlueprintFilterGroup(input: $input) {
      id
      logicOperator
      parentFilterGroup {
        id
      }
    }
  }
`;

export const UpdateFilterGroupGQL = gql`
  mutation UpdateFilterGroup(
    $id: ID!
    $input: UpdateBlueprintFilterGroupInput!
  ) {
    updateBlueprintFilterGroup(id: $id, input: $input)
  }
`;

export const CreateFilterConditionGQL = gql`
  ${BlueprintValueFragmentGQL}

  mutation CreateFilterCondition($input: CreateBlueprintFilterInput!) {
    createBlueprintFilter(input: $input) {
      id
      compareOperator
      field {
        id
        name
        kind
        verboseName
        returnValueKind
      }
      value {
        __typename
        ...BlueprintValueFragment
      }
    }
  }
`;

export const UpdateFilterConditionGQL = gql`
  mutation UpdateFilterCondition(
    $id: ID!
    $input: UpdateBlueprintFilterInput!
  ) {
    updateBlueprintFilter(id: $id, input: $input)
  }
`;

export const UpdateViewSettingsGQL = gql`
  mutation UpdateViewSettings(
    $id: ID!
    $settings: SetBlueprintViewSettingsInput!
  ) {
    setBlueprintViewSettings(blueprintID: $id, viewSettings: $settings)
  }
`;

export const CreateBlueprintCycleInputGQL = gql`
  mutation CreateBlueprintCycleInput($input: CreateBlueprintCycleInputInput!) {
    createBlueprintCycleInput(input: $input) {
      id
    }
  }
`;

export const CreateUnionGQL = gql`
  mutation CreateBlueprintUnion($input: CreateBlueprintUnionInput!) {
    createBlueprintUnion(input: $input) {
      id
      name
      filterGroup {
        id
        logicOperator
      }
    }
  }
`;

export const UpdateUnionGQL = gql`
  mutation UpdateBlueprintUnion($id: ID!, $input: UpdateBlueprintUnionInput!) {
    updateBlueprintUnion(id: $id, input: $input)
  }
`;
