import React from 'react';

import {
  CustomMilkingReportDataSource,
  CustomMilkingReportXAxisMode,
} from '@graphql-types';
import clsx from 'clsx';

import { Checkbox } from '~/shared/components/Checkbox';
import { DateRangePicker } from '~/shared/components/DateRangePicker';

import { emptySwitchableFormFields, Form } from '~/services/forms';
import { ReportCardBuilderFormSection } from '~/services/reportCardBuilder';

import { BlueprintAsyncSelect } from '~/entities/blueprints';

import contentGriStyles from '~/styles/modules/contentGrid.module.scss';

import {
  CUSTOM_MILKING_REPORT_SETTINGS_FORM_ID,
  CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS,
  MAX_Y_AXIS_FIELDS,
  MAX_Y_AXIS_FIELDS_FOR_GROUPING,
  MAX_Y_AXIS_FIELDS_FOR_HISTORIC_COMPARISON,
} from '../../constants';
import { CustomMilkingReportDataSourceSelect } from './components/CustomMilkingReportDataSourceSelect';
import { CustomMilkingReportPeriodSelect } from './components/CustomMilkingReportPeriodSelect';
import { CustomMilkingReportXAxisModeSelect } from './components/CustomMilkingReportXAxisModeSelect';
import { CustomMilkingReportXAxisStepSelect } from './components/CustomMilkingReportXAxisStepSelect';
import { FilterConfigArrayItemCard } from './components/FilterConfigArrayItemCard';
import { GroupingConfigArrayItemCard } from './components/GroupingConfigArrayItemCard';
import { YAxisFieldConfigArrayItemCard } from './components/YAxisFieldConfigArrayItemCard';
import { useCustomMilkingReportSettingsForm } from './hooks/useCustomMilkingReportSettingsForm';
import { CustomMilkingReportSettingsFormProps } from './types';

const ADDING_DISABLED_TOOLTIP = 'Добавление недоступно';
const ADDING_DISABLED_FOR_HISTORIC_COMPARISON_TOOLTIP =
  'Недоступно добавление более одного показателя на ось Y в режиме сравнения с предыдущим периодом. Отключите этот режим, чтобы добавить другие показатели';
const ADDING_DISABLED_FOR_GROUPING_TOOLTIP =
  'Недоступно добавление более одного показателя на ось Y, когда добавлен параметр группировки';

export const CustomMilkingReportSettingsForm: React.FC<
  CustomMilkingReportSettingsFormProps
> = props => {
  const { className, onSettingsFormSave } = props;

  const {
    formContext,
    formValues,

    yAxisFieldsArrayItems,
    availableForYAxisMenuItems,
    addToYAxisFieldsArray,
    removeFromYAxisFieldsArray,

    filtersArrayItems,
    availableForFiltersAndGroupingItems,
    removeFromFiltersArray,
  } = useCustomMilkingReportSettingsForm(props);

  const isMaxYAxisFields = formValues.yAxisFields.length >= MAX_Y_AXIS_FIELDS;
  const isMaxYAxisFieldsForGrouping =
    !!formValues.grouping &&
    formValues.yAxisFields.length >= MAX_Y_AXIS_FIELDS_FOR_GROUPING;
  const isMaxYAxisFieldsForHistoricComparison =
    formValues.shouldCompareWithHistoricData &&
    formValues.yAxisFields.length >= MAX_Y_AXIS_FIELDS_FOR_HISTORIC_COMPARISON;

  const isAddingYAxesDisabled =
    !availableForYAxisMenuItems.length ||
    isMaxYAxisFields ||
    isMaxYAxisFieldsForGrouping ||
    isMaxYAxisFieldsForHistoricComparison;

  const isDataSourceParlors =
    formValues.dataSource === CustomMilkingReportDataSource.MilkingParlors;

  let addingDisabledTooltip = isAddingYAxesDisabled
    ? ADDING_DISABLED_TOOLTIP
    : undefined;
  if (isMaxYAxisFieldsForHistoricComparison) {
    addingDisabledTooltip = ADDING_DISABLED_FOR_HISTORIC_COMPARISON_TOOLTIP;
  } else if (isMaxYAxisFieldsForGrouping) {
    addingDisabledTooltip = ADDING_DISABLED_FOR_GROUPING_TOOLTIP;
  }

  return (
    <Form
      {...{
        className: clsx(
          'items-start',
          contentGriStyles.autoGridStaticItems,
          className
        ),
        formContext,
        id: CUSTOM_MILKING_REPORT_SETTINGS_FORM_ID,
        onSubmit: formContext.handleSubmit(onSettingsFormSave),
      }}
    >
      <ReportCardBuilderFormSection title="Основное">
        <DateRangePicker
          {...{
            name: 'period',
            label: 'Период',
          }}
        />
        <CustomMilkingReportDataSourceSelect
          {...{
            name: 'dataSource',
            label: 'Источник данных',
            onValueChange: newValue => {
              formContext.setValue('mode', CustomMilkingReportXAxisMode.ByDate);

              const isNewValueMilkingParlors =
                (newValue?.id as CustomMilkingReportDataSource) ===
                CustomMilkingReportDataSource.MilkingParlors;

              emptySwitchableFormFields(formContext, isNewValueMilkingParlors, {
                shouldCompareWithHistoricData: false,
                step: null,
                compareWithHistoricDataPeriod: null,
              });

              if (isNewValueMilkingParlors) {
                formContext.setValue(
                  'yAxisFields',
                  formContext
                    .getValues('yAxisFields')
                    .filter(
                      field =>
                        CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS[field.kind]
                          .isAvailableForMilkingParlors
                    )
                );
              }
            },
          }}
        />
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection title="Ось X">
        <CustomMilkingReportXAxisModeSelect
          {...{
            name: 'mode',
            label: 'Режим отображения',
            dataSource: formValues.dataSource,
            onValueChange: newValue => {
              emptySwitchableFormFields(
                formContext,
                (newValue?.id as CustomMilkingReportXAxisMode) ===
                  CustomMilkingReportXAxisMode.ByDate,
                {
                  shouldCompareWithHistoricData: false,
                  compareWithHistoricDataPeriod: null,
                }
              );
            },
          }}
        />
        {isDataSourceParlors && (
          <CustomMilkingReportXAxisStepSelect
            {...{
              name: 'step',
              label: 'Временной шаг',
            }}
          />
        )}
        <Checkbox
          {...{
            name: 'shouldCompareWithHistoricData',
            label: 'Сравнить с предыдущим периодом',
            isDisabled:
              formValues.yAxisFields.length >
                MAX_Y_AXIS_FIELDS_FOR_HISTORIC_COMPARISON ||
              !!formValues.grouping,
            disabledTooltip:
              'Сравнение недоступно, если на ось Y добавлено более одного показателя или добавлен параметр группировки',
            onValueChange: newValue => {
              emptySwitchableFormFields(formContext, newValue, {
                compareWithHistoricDataPeriod: null,
              });
            },
          }}
        />
        {formValues.shouldCompareWithHistoricData && (
          <CustomMilkingReportPeriodSelect
            {...{
              name: 'compareWithHistoricDataPeriod',
              label: 'Режим сравнения',
            }}
          />
        )}
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection<(typeof availableForYAxisMenuItems)[number]>
        {...{
          title: 'Ось Y',
          withChildrenCount: true,
          noChildrenMessage: 'Список значений оси Y пока пуст',
          contextMenuButtonProps: {
            items: availableForYAxisMenuItems,
            isDisabled: isAddingYAxesDisabled,
            tooltip: addingDisabledTooltip,
            menuProps: {
              onItemPress: addToYAxisFieldsArray,
            },
          },
        }}
      >
        {yAxisFieldsArrayItems.map((yAxisFieldConfig, index) => (
          <YAxisFieldConfigArrayItemCard
            key={yAxisFieldConfig.id}
            {...{
              fieldPrefix: `yAxisFields.${index}.`,
              yAxisFieldConfig,
              onDelete: () => {
                removeFromYAxisFieldsArray(index);
              },
            }}
          />
        ))}
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection<
        (typeof availableForFiltersAndGroupingItems)[number]
      >
        {...{
          title: 'Фильтры и группировка',
          contextMenuButtonProps: {
            items: availableForFiltersAndGroupingItems,
          },
        }}
      >
        <BlueprintAsyncSelect
          {...{
            name: 'blueprintID',
            label: 'Список',
            isClearable: true,
            queryOptions: {
              variables: {
                canBeUsedAsCowsFilter: true,
              },
            },
          }}
        />
        {filtersArrayItems.map((filterConfig, index) => (
          <FilterConfigArrayItemCard
            key={filterConfig.id}
            {...{
              fieldPrefix: `filters.${index}.`,
              filterConfig: formContext.watch(`filters.${index}`),
              onDelete: () => {
                removeFromFiltersArray(index);
              },
            }}
          />
        ))}
        {!!formValues.grouping && (
          <GroupingConfigArrayItemCard
            {...{
              groupingConfig: formValues.grouping,
              onDelete: () => {
                formContext.setValue('grouping', null);
              },
            }}
          />
        )}
      </ReportCardBuilderFormSection>
    </Form>
  );
};
