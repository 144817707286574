import { useMemo } from 'react';

import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { Button } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputVariants } from '~/shared/components/Input';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { PageHeader } from '~/services/layouts';
import { WithSearchParamsValidation } from '~/services/navigation';

import { usePenGroupsPaginatedQuery } from '~/entities/penGroups/hooks/usePenGroupsPaginatedQuery';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { PenGroupTable } from './components';
import { getPenGroupFieldsFromAttributes } from './helpers';
import { usePenGroupAttributesSelect } from './hooks';
import { useEditPenGroupModal } from './modals';
import { PenGroupAttributeFields, PenGroupFilter } from './types';

export const Route = createFileRoute(
  '/$companyId/_layout/user/herd/penGroups/'
)({
  wrapInSuspense: true,
  component: HerdPenGroupsPage,
  validateSearch: ({
    search,
    attributes,
  }: WithSearchParamsValidation<PenGroupFilter>) => ({
    search: search ?? '',
    attributes: Array.isArray(attributes) ? attributes : [],
  }),
});

function HerdPenGroupsPage() {
  const { open: openEditPenGroupModal } = useEditPenGroupModal();
  const {
    search: debouncedSearch,
    setSearch: setDebouncedSearch,
    attributes,
    setAttributes,
  } = useSearchParamsState<typeof Route>();

  const penGroupsQueryVariables = useMemo(
    () => ({
      search: debouncedSearch,
      ...getPenGroupFieldsFromAttributes(attributes, null),
    }),
    [debouncedSearch, attributes]
  );

  const { items: penGroupItems, ...asyncProps } = usePenGroupsPaginatedQuery({
    variables: penGroupsQueryVariables,
  });

  const { search, setSearch } = useDebouncedSearch({
    defaultSearch: debouncedSearch,
    onDebouncedSearchChange: setDebouncedSearch,
  });

  const { renderSelectElement: renderPenGroupAttributesSelectElement } =
    usePenGroupAttributesSelect({
      name: 'attribute',
      label: 'Признак',
      className: 'default-control',
      isMulti: true,
      rawValue: attributes.filter(item =>
        Object.values(PenGroupAttributeFields).includes(item)
      ),
      onValueChange: newAttributes =>
        setAttributes(
          newAttributes.map(R.prop('id')) as PenGroupAttributeFields[]
        ),
    });

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Группы животных',
          rightContent: (
            <Button
              iconVariant={IconVariants.plus}
              onPress={() => openEditPenGroupModal()}
            >
              Создать группу
            </Button>
          ),
        }}
      />

      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <div className="flex justify-between items-end mb-24">
          {renderPenGroupAttributesSelectElement()}
          <Input
            {...{
              name: 'search',
              placeholder: 'Поиск',
              value: search,
              onValueChange: setSearch,
              className: 'default-control',
              variant: InputVariants.search,
            }}
          />
        </div>
        <PenGroupTable
          {...{
            penGroupItems,
            penGroupsQueryVariables,
            ...asyncProps,
          }}
        />
      </div>
    </div>
  );
}
