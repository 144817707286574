import React, { useCallback } from 'react';

import { AppEmployee } from '~/~legacy/types/entities';
import { EmployeeSelector } from '~/~legacy/value-editors/editors/EmployeeSelector';
import { ChangeValueCallbackType } from '~/~legacy/value-editors/types/valueEditorTypes';

import { DateInput, DateInputThemes } from '~/shared/components/DateInput';
import { MFormField } from '~/shared/components/MFormField';

import styles from './index.module.scss';

interface Props {
  happenedAt?: string;
  employee?: AppEmployee;

  update: (happenedAt?: string, employee?: AppEmployee) => void;
}

export const FreshActionInputCommonForm: React.FC<Props> = ({
  update,
  happenedAt,
  employee,
}) => {
  const employeeChanged: ChangeValueCallbackType = useCallback(
    (newEmployee: AppEmployee) => {
      update(happenedAt, newEmployee);
      return Promise.resolve(true);
    },
    [update, happenedAt]
  );

  return (
    <div className="flex gap-8 default-control">
      <DateInput
        {...{
          name: 'calvingDate',
          label: 'Дата отёла',
          value: happenedAt ?? null,
          theme: DateInputThemes.light,
          isOnlyDate: false,
          onValueChange: newHappenedAt =>
            update(newHappenedAt ?? undefined, employee),
        }}
      />
      <MFormField
        title="Техник"
        editor={
          <EmployeeSelector
            name="employee"
            className={styles.control}
            placeholder="Выберите техника"
            value={{
              id: employee?.id || '',
              comment: '',
              firstName: '',
              lastName: '',
              middleName: '',
              number: '',
            }}
            edit
            noForm
            onChange={employeeChanged}
            caption="Выберите техника"
            hideControls
          />
        }
      />
    </div>
  );
};
