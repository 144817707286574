import { CustomMilkingReportYAxisFieldKind } from '@graphql-types';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import { formatInt } from '~/shared/helpers/number';

import { getSourceFieldValueForEdit } from '~/entities/blueprintSourceFields';
import { formatBlueprintValue } from '~/entities/blueprintValues';
import { CustomMilkingReportChartFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportChart.graphql';
import { CustomMilkingReportDetailedFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportDetailed.graphql';
import { CustomMilkingReportSettingsFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportSettings.graphql';
import { CustomMilkingReportSettingsHistoricValueFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportSettingsHistoricValue.graphql';
import { formatPenGroup } from '~/entities/penGroups';

import {
  CUSTOM_MILKING_REPORT_LACTATION_GROUP_NUMBERS_DICT,
  CUSTOM_MILKING_REPORT_SETTINGS_FORM_SCHEMA,
  CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS,
  CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_SCHEMA,
} from './constants';
import {
  CustomMilkingReportSettingsFormType,
  FiltersAndGroupingsModes,
} from './types';

/**
 * Checks, if a calculated milking report has actual data and not empty
 */
export const isCustomMilkingReportDataEmpty = (
  calculatedReport:
    | CustomMilkingReportDetailedFragment['calculatedReport']
    | SkeletonPlaceholder
    | undefined
) => calculatedReport?.__typename === 'CustomMilkingReportChartEmpty';

const isCustomMilkingReportActualFilter = (
  filterConfig: CustomMilkingReportSettingsFragment['filters'][number]
) => filterConfig.__typename === 'CustomMilkingReportSettingsActualFilter';

const isCustomMilkingReportActualGrouping = (
  groupingConfig: CustomMilkingReportSettingsFragment['grouping']
) => groupingConfig?.__typename === 'CustomMilkingReportSettingsGroupingActual';

const isCustomMilkingReportSettingsHistoricValueLactationNumbers = (
  historicValue: CustomMilkingReportSettingsHistoricValueFragment
) =>
  historicValue.__typename ===
  'CustomMilkingReportSettingsHistoricValueLactationNumbers';

const isCustomMilkingReportSettingsHistoricValueLactationGroupNumbers = (
  historicValue: CustomMilkingReportSettingsHistoricValueFragment
) =>
  historicValue.__typename ===
  'CustomMilkingReportSettingsHistoricValueLactationGroupNumbers';

const isCustomMilkingReportSettingsHistoricValuePenGroups = (
  historicValue: CustomMilkingReportSettingsHistoricValueFragment
) =>
  historicValue.__typename ===
  'CustomMilkingReportSettingsHistoricValuePenGroups';

const mapCustomMilkingReportHistoricValueToForm = (
  historicValue:
    | CustomMilkingReportSettingsHistoricValueFragment
    | null
    | undefined
) =>
  match(historicValue)
    .with(P.nullish, R.always(null))
    .with(
      P.when(isCustomMilkingReportSettingsHistoricValueLactationNumbers),
      matchedValue => ({
        lactationNumbers: matchedValue.lactationNumbers ?? [],
        lactationGroupNumbers: null,
        penGroupIDs: null,
      })
    )
    .with(
      P.when(isCustomMilkingReportSettingsHistoricValueLactationGroupNumbers),
      matchedValue => ({
        lactationNumbers: null,
        lactationGroupNumbers: matchedValue.lactationGroupNumbers ?? [],
        penGroupIDs: null,
      })
    )
    .with(
      P.when(isCustomMilkingReportSettingsHistoricValuePenGroups),
      matchedValue => ({
        lactationNumbers: null,
        lactationGroupNumbers: null,
        penGroupIDs: matchedValue.penGroups.map(R.prop('id')) ?? [],
      })
    )
    .exhaustive();

const mapCustomMilkingReportGroupingToForm = (
  groupingValue: CustomMilkingReportSettingsFragment['grouping']
) => {
  if (!groupingValue) {
    return null;
  }

  if (isCustomMilkingReportActualGrouping(groupingValue)) {
    return {
      mode: FiltersAndGroupingsModes.actual,
      kind: null,
      masterBlueprintSourceFieldID:
        groupingValue.masterBlueprintSourceField?.id ?? null,
    };
  }

  return {
    mode: FiltersAndGroupingsModes.historic,
    kind: groupingValue.kind ?? null,
    masterBlueprintSourceFieldID: null,
  };
};

/**
 * Maps custom milking report settings fragment into settings form fields representation
 */
export const mapCustomMilkingReportSettingsToForm = (
  reportSettings: CustomMilkingReportSettingsFragment | SkeletonPlaceholder
): CustomMilkingReportSettingsFormType => ({
  ...CUSTOM_MILKING_REPORT_SETTINGS_FORM_SCHEMA.getDefault(),
  ...(R.pick(
    [
      'dataSource',
      'mode',
      'step',
      'shouldCompareWithHistoricData',
      'compareWithHistoricDataPeriod',
    ],
    reportSettings ?? {}
  ) as CustomMilkingReportSettingsFormType),
  period: {
    since: reportSettings.since ?? '',
    till: reportSettings.till ?? '',
  },
  blueprintID: reportSettings.blueprint?.id ?? null,
  yAxisFields:
    reportSettings.yAxisFields?.map(fieldConfig => ({
      ...CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_SCHEMA.getDefault(),
      ...R.pick(['groupBy', 'kind', 'withRightScale'], fieldConfig),
    })) ?? [],
  filters:
    reportSettings.filters?.map(filterConfig => {
      if (isCustomMilkingReportActualFilter(filterConfig)) {
        return {
          mode: FiltersAndGroupingsModes.actual,
          actualFilter: {
            masterBlueprintSourceFieldID:
              filterConfig.masterBlueprintSourceField?.id ?? null,
            value: getSourceFieldValueForEdit(filterConfig.value) ?? null,
          },
          historicFilter: null,
        };
      }
      return {
        mode: FiltersAndGroupingsModes.historic,
        actualFilter: null,
        historicFilter: {
          kind: filterConfig.kind ?? null,
          value:
            mapCustomMilkingReportHistoricValueToForm(filterConfig.value) ??
            null,
        },
      };
    }) ?? [],
  grouping: mapCustomMilkingReportGroupingToForm(reportSettings.grouping),
});

/**
 * Formats custom milking report y axis field for display
 */
export const formatYAxisFieldName = (
  fieldKind: CustomMilkingReportYAxisFieldKind,
  withMeasurementUnit = true
) => {
  const { label, measurementUnit } =
    CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS[fieldKind];
  const measurementUnitWithDelimiter =
    withMeasurementUnit && !!measurementUnit ? `, ${measurementUnit}` : '';
  return `${label}${measurementUnitWithDelimiter}`;
};

/**
 * Formats custom milking report dataset label based on its type
 */
export const formatDatasetLabel = (
  datasetLabel: CustomMilkingReportChartFragment['yAxisDatasetLabels'][number],
  withMeasurementUnit = true
): string =>
  match(datasetLabel)
    .with({ __typename: 'CustomMilkingReportDataset' }, ({ field }) =>
      formatYAxisFieldName(field.kind, withMeasurementUnit)
    )
    .with(
      { __typename: 'CustomMilkingReportDatasetGroupingActual' },
      ({ value: sourceFieldValue, fieldKind, valueKind }) =>
        sourceFieldValue
          ? formatBlueprintValue(sourceFieldValue, fieldKind, valueKind)
          : 'Нет значения'
    )
    .with(
      { __typename: 'CustomMilkingReportDatasetHistoricLactationGroupNumber' },
      ({ lactationGroupNumber }) =>
        lactationGroupNumber
          ? CUSTOM_MILKING_REPORT_LACTATION_GROUP_NUMBERS_DICT[
              lactationGroupNumber
            ]
          : 'Нет значения'
    )
    .with(
      { __typename: 'CustomMilkingReportDatasetHistoricLactationNumber' },
      ({ lactationNumber }) => formatInt(lactationNumber)
    )
    .with(
      { __typename: 'CustomMilkingReportDatasetHistoricPenGroup' },
      ({ penGroup }) => formatPenGroup(penGroup)
    )
    .with(
      { __typename: 'CustomMilkingReportDatasetPeriodComparison' },
      ({ isActual }) => (isActual ? 'Текущий период' : 'Период сравнения')
    )
    .exhaustive();
