import React from 'react';

import { useApolloClient } from '@apollo/client';

import R from 'ramda';
import * as yup from 'yup';

import { Input } from '~/shared/components/Input';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { makeDeleteQueriesByNameWithoutVariables } from '~/services/gql';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { BlueprintRoleAsyncSelect } from '~/entities/blueprintRoles';
import { readBlueprintRoleFragment } from '~/entities/blueprintRoles/helpers';

import formStyles from '~/styles/modules/form.module.scss';

import { CustomMilkingReportFragment } from '../../gql/fragments/customMilkingReport.graphql';
import { useCreateCustomMilkingReportMutation } from '../../gql/mutations/createCustomMilkingReport.graphql';
import { useUpdateCustomMilkingReportMutation } from '../../gql/mutations/updateCustomMilkingReport.graphql';
import { CustomMilkingReportsQueryVariables } from '../../gql/queries/customMilkingReports.graphql';
import { updateCustomMilkingReportFragment } from '../../helpers';

export interface EditCustomMilkingReportModalProps
  extends InjectedModalProps<EditCustomMilkingReportModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing custom milking report, if not passed, a new one is created
   */
  customMilkingReport?: CustomMilkingReportFragment;
  /**
   * Gql custom reports query variables
   */
  queryVariables: CustomMilkingReportsQueryVariables;
}

const FORM_ID = 'EditCustomMilkingReportForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
  blueprintRoleIDs: yup.array(yup.string().default('')).default([]), // [ID]
});

type EditCustomMilkingReportFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type EditCustomMilkingReportFormTransformedType = InferValidatedSchema<
  typeof SCHEMA
>;

export const EditCustomMilkingReportModal: React.FC<
  EditCustomMilkingReportModalProps
> = ({ className, customMilkingReport, queryVariables, close }) => {
  const client = useApolloClient();

  const { sendSuccessToast } = useNotifications();

  const isEditing = !!customMilkingReport;

  const formContext = useForm<
    EditCustomMilkingReportFormType,
    EditCustomMilkingReportFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        ['name'],
        customMilkingReport ?? ({} as CustomMilkingReportFragment)
      ),
      blueprintRoleIDs: customMilkingReport?.blueprintRoles.map(R.prop('id')),
    },
  });

  const [
    createCustomMilkingReport,
    { loading: isCreateCustomMilkingReportLoading },
  ] = useCreateCustomMilkingReportMutation();

  const [
    updateCustomMilkingReport,
    { loading: isUpdateCustomMilkingReportLoading },
  ] = useUpdateCustomMilkingReportMutation();

  const handleSubmit = async (
    form: EditCustomMilkingReportFormTransformedType
  ) => {
    if (isEditing) {
      await updateCustomMilkingReport({
        variables: {
          id: customMilkingReport.id,
          input: form,
        },
        optimisticResponse: { updateCustomMilkingReport: null },
        update: R.juxt([
          updateCustomMilkingReportFragment(customMilkingReport.id, draft => {
            draft.name = form.name;
            draft.blueprintRoles = form.blueprintRoleIDs
              .map(roleId => readBlueprintRoleFragment(client, roleId))
              .filter(Boolean);
          }),
          makeDeleteQueriesByNameWithoutVariables(
            'customMilkingReports',
            queryVariables
          ),
        ]),
      });
    } else {
      await createCustomMilkingReport({
        variables: {
          input: form,
        },
        refetchQueries: ['customMilkingReports'],
      });
    }
    sendSuccessToast(isEditing ? 'Отчёт отредактирован' : 'Отчёт создан');

    close();
  };

  return (
    <Modal
      {...{
        className,
        title: isEditing ? 'Редактирование отчёта' : 'Создание отчёта',
        submitButtonProps: {
          form: FORM_ID,
          isLoading:
            isCreateCustomMilkingReportLoading ||
            isUpdateCustomMilkingReportLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          className: formStyles.singleColumnForm,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Input
          {...{
            name: 'name',
            label: 'Название отчёта',
            placeholder: 'Введите название',
          }}
        />
        <BlueprintRoleAsyncSelect
          {...{
            name: 'blueprintRoleIDs',
            label: 'Пользователь отчёта',
            isMulti: true,
          }}
        />
      </Form>
    </Modal>
  );
};
