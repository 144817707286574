import React from 'react';

import { useSystemInfoModal } from 'src/services/layouts/modals';

import { ContextMenu } from '~/shared/components/ContextMenu';
import { IconVariants } from '~/shared/components/Icon';
import { MenuItemVariants } from '~/shared/components/Menu';
import { PhoneLink } from '~/shared/components/PhoneLink';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatShortName } from '~/shared/helpers/nameFormat';

import { useAuth, useMyUser } from '~/services/auth';

import { NavigationMenuItem } from '../NavigationMenuItem';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, context menu is rendered in collapsed mode
   */
  isMenuCollapsed: boolean;
}

export const UserProfileMenuItem: React.FC<Props> = ({
  className,
  isMenuCollapsed = false,
}) => {
  const { logout } = useAuth();
  const { myUser } = useMyUser();

  const userName = formatShortName(myUser);

  const { open: openSystemInfoModal } = useSystemInfoModal();

  return (
    <ContextMenu
      {...{
        className,
        tooltipProps: {
          placement: 'right',
        },
        popoverProps: {
          placement: isMenuCollapsed ? 'right-start' : 'top-start',
        },
        items: [
          {
            isDisabled: true,
            renderContent: () => (
              <div className="py-4 px-12">
                <Typography variant={TypographyVariants.bodySmall} tag="div">
                  {userName}
                </Typography>
                <Typography
                  variant={TypographyVariants.bodySmall}
                  className="text-muted"
                >
                  {myUser.email}
                </Typography>
              </div>
            ),
          },
          {
            variant: MenuItemVariants.delimiter,
          },
          {
            content: 'Системная информация',
            onPress: openSystemInfoModal,
          },
          {
            content: 'Выйти из системы',
            onPress: logout,
          },
          {
            renderContent: () => (
              <PhoneLink
                {...{
                  className: 'block py-4 px-12',
                  phone: process.env.REACT_APP_SUPPORT_PHONE,
                  onPointerDown: e => {
                    e.stopPropagation();
                  },
                }}
              >
                {formattedPhone => (
                  <>
                    <Typography
                      variant={TypographyVariants.bodySmall}
                      className="text-muted"
                      tag="div"
                    >
                      Номер тех. поддержки
                    </Typography>

                    <Typography
                      variant={TypographyVariants.bodySmall}
                      tag="div"
                    >
                      {formattedPhone}
                    </Typography>
                  </>
                )}
              </PhoneLink>
            ),
          },
        ],
      }}
    >
      {({ isOpen: isContextMenuOpen, setIsOpen }) => {
        return (
          <NavigationMenuItem
            {...{
              label: userName,
              iconVariant: IconVariants.profile,
              isContextMenuOpen,
              onPress: () => {
                setIsOpen(current => !current);
              },
            }}
          />
        );
      }}
    </ContextMenu>
  );
};
