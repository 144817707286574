import React, { useMemo } from 'react';

import { ChartOptions } from 'chart.js';
import clsx from 'clsx';
import R from 'ramda';

import { ColoredDot, ColoredDotSizes } from '~/shared/components/ColoredDot';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatDate } from '~/shared/helpers/date';
import { formatInt, formatWithPercent } from '~/shared/helpers/number';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import {
  BarChart,
  CHART_DATASET_COLORS_ITERATOR,
  CHART_DATASET_HOVER_COLORS_ITERATOR,
  CHART_DATASET_PRESSED_COLORS_ITERATOR,
  CHART_DATASET_SKIPPED_COLORS_ITERATOR,
  CHART_X_SCALE_MIN_RANGE,
  CHART_Y_SCALE_MIN_RANGE,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
  getScaleOptions,
  LINEAR_Y_SCALE_OPTIONS,
  PERCENT_LINEAR_Y_SCALE_OPTIONS,
  ReactChartProps,
} from '~/features/charts';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import { SizeVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';
import panelStyles from '~/styles/modules/panel.module.scss';

import { SomaticCellsReportChartFragment } from '../../../../gql/fragments/somaticCellsReportChart.graphql';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Report data to render as chart
   */
  reportData: SomaticCellsReportChartFragment | SkeletonPlaceholder;
}

const CHART_HEIGHT_PX = 644;
const TOOLTIP_MAX_WIDTH_PX = 320;

export const SomaticCellsReportChart: React.FC<Props> = ({
  className,
  reportData,
}) => {
  const xAxisLabels = (reportData.dates ?? []).map(d => formatDate(d));

  const { datasets, chartOptions } = useMemo(() => {
    const reportDatasets = [
      {
        key: 'chronicallyIllCounts',
        label: 'Болеющие хронически',
        type: 'bar',
        tooltipDataKey: {
          percent: undefined,
          count: 'chronicallyIllCounts',
        },
        order: 4,
      },
      {
        key: 'illCounts',
        label: 'Заболевшие',
        type: 'bar',
        tooltipDataKey: {
          percent: undefined,
          count: 'illCounts',
        },
        order: 5,
      },
      {
        key: 'recoveredCounts',
        label: 'Выздоровевшие',
        type: 'bar',
        tooltipDataKey: {
          percent: undefined,
          count: 'recoveredCounts',
        },
        order: 6,
      },
      {
        key: 'probabilityOfRecoveryPercents',
        label: 'Коэффициент выздоровления',
        type: 'line',
        tooltipDataKey: {
          percent: 'probabilityOfRecoveryPercents',
          count: undefined,
        },
        order: 1,
      },
      {
        key: 'probabilityOfIllnessPercents',
        label: 'Коэффициент заболеваемости',
        type: 'line',
        tooltipDataKey: {
          percent: 'probabilityOfIllnessPercents',
          count: undefined,
        },
        order: 2,
      },
      {
        key: 'highValueFirstTestMilkingPercents',
        label: 'Высокое значение на первой КД',
        type: 'line',
        tooltipDataKey: {
          percent: 'highValueFirstTestMilkingPercents',
          count: 'highValueFirstTestMilkingCounts',
        },
        order: 3,
      },
    ] as const;

    const animalCountDataKeys = reportDatasets
      .filter(({ type }) => type === 'bar')
      .map(({ key }) => key);

    const probabilityDataKeys = reportDatasets
      .filter(({ type }) => type === 'line')
      .map(({ key }) => key);

    const totalAnimalCountsByXAxis = xAxisLabels.map((_date, dateIndex) =>
      R.sum(animalCountDataKeys.map(key => reportData[key]?.[dateIndex] ?? 0))
    );

    const maxProbabilityByXAxis = xAxisLabels.map((_date, dateIndex) =>
      Math.max(
        ...probabilityDataKeys.map(key => reportData[key]?.[dateIndex] ?? 0)
      )
    );

    const datasetsInternal = reportDatasets.map(
      ({ key, label, type, order, tooltipDataKey }, reportDatasetIndex) => {
        return {
          key,
          type,
          order,
          label,
          color: CHART_DATASET_COLORS_ITERATOR.next(!reportDatasetIndex).value,
          hoverColor:
            CHART_DATASET_HOVER_COLORS_ITERATOR.next(!reportDatasetIndex).value,
          pressedColor:
            CHART_DATASET_PRESSED_COLORS_ITERATOR.next(!reportDatasetIndex)
              .value,
          skippedColor:
            CHART_DATASET_SKIPPED_COLORS_ITERATOR.next(!reportDatasetIndex)
              .value,
          isTogglable: true,
          data: (reportData[key] ?? []).map(v => (R.isNil(v) ? NaN : v)),
          tooltipDataKey,
          ...wrapConditionalObjectElement(
            type === 'line' && {
              yAxisID: 'rightY',
            }
          ),
        };
      }
    );

    const chartOptionsInternal = {
      interaction: {
        mode: 'index',
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: true,
            text: 'Дата контрольной дойки',
          },
          grid: {
            display: true,
          },
        },
        y: getScaleOptions(LINEAR_Y_SCALE_OPTIONS, {
          stacked: true,
          title: { display: true, text: 'Животные' },
          min: 0,
          max: Math.max(0, ...totalAnimalCountsByXAxis) + 1,
        }),
        rightY: getScaleOptions(PERCENT_LINEAR_Y_SCALE_OPTIONS, {
          max: Math.max(100, ...maxProbabilityByXAxis),
          grid: {
            display: true,
            color: 'transparent',
            tickColor: TOKENS.colorBorderMuted,
            tickLength: NUMBER_TOKENS.size16 + NUMBER_TOKENS.spacing12,
            tickBorderDash: [NUMBER_TOKENS.size16, NUMBER_TOKENS.spacing12],
            // Offset by tick width to start drawing with left padding on the right axis
            tickBorderDashOffset: NUMBER_TOKENS.size16,
          },
          ticks: {
            stepSize: undefined,
            padding: NUMBER_TOKENS.spacing12,
          },
          position: 'right',
        }),
      },
      plugins: {
        zoom: {
          limits: {
            x: {
              min: 'original',
              max: 'original',
              minRange: CHART_X_SCALE_MIN_RANGE,
            },
            y: {
              min: 'original',
              max: 'original',
              minRange: CHART_Y_SCALE_MIN_RANGE,
            },
            rightY: {
              min: 'original',
              max: 'original',
              minRange: CHART_Y_SCALE_MIN_RANGE,
            },
          },
          zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
          pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
        },
      },
    } satisfies ChartOptions;

    return {
      datasets: datasetsInternal,
      chartOptions: chartOptionsInternal,
    };
  }, [reportData]);

  const renderTooltip: ReactChartProps['renderTooltip'] = dataPoints => {
    const firstPoint = dataPoints.at(0);
    const sortedPoints = R.sortBy(R.prop('datasetIndex'), dataPoints);
    return (
      <div>
        <Typography
          {...{
            variant: TypographyVariants.descriptionLargeStrong,
            tag: 'h4',
            className: 'mb-4',
          }}
        >
          {firstPoint && xAxisLabels.at(firstPoint.dataIndex)?.toString()}
        </Typography>
        <ul className={clsx('grid', styles.tooltipContent)}>
          {sortedPoints.map(point => {
            const currentChartConfig = datasets.at(point.datasetIndex);
            if (!currentChartConfig) return null;

            const { tooltipDataKey, label, data } = currentChartConfig;
            if (!data || !tooltipDataKey) return null;

            const { count: countKey, percent: percentKey } = tooltipDataKey;
            const originalValueInPercent = percentKey
              ? reportData[percentKey]?.[point.dataIndex]
              : null;
            const originalValueCount = countKey
              ? reportData[countKey]?.[point.dataIndex]
              : null;

            return (
              <Typography
                key={`${point.datasetIndex}__${point.dataIndex}`}
                {...{
                  tag: 'li',
                  variant: TypographyVariants.descriptionLarge,
                  className:
                    'grid grid-cols-subgrid col-span-full items-center',
                }}
              >
                <div className="flex items-center gap-4">
                  <ColoredDot
                    size={ColoredDotSizes.small10}
                    color={currentChartConfig.color}
                  />

                  <span className={clsx(styles.label, 'ellipsis')}>
                    {label}
                  </span>
                </div>

                <span className="text-right">
                  {!R.isNil(originalValueInPercent) &&
                    formatWithPercent(originalValueInPercent)}
                </span>

                <div className="flex items-center gap-2">
                  {!R.isNil(originalValueCount) && (
                    <>
                      <Icon
                        {...{
                          variant: IconVariants.cow,
                          size: SizeVariants.size12,
                          className: 'text-soft',
                        }}
                      />
                      {formatInt(originalValueCount)}
                    </>
                  )}
                </div>
              </Typography>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-24', className)}>
      <BarChart
        {...{
          height: CHART_HEIGHT_PX,
          chartOptions,
          legendClassName: 'mb-24',
          isStackedX: true,
          datasets,
          labels: xAxisLabels,
          renderTooltip,
          tooltipProps: {
            maxWidth: TOOLTIP_MAX_WIDTH_PX,
          },
        }}
      />
    </div>
  );
};
