import { CowRetirementReason } from '@graphql-types';

import { EnumStrings } from '~/~legacy/strings/enumStrings';

import { makeUseEnumSelect } from '~/shared/components/Select';

/**
 * Select for cow retirement reasons
 */
export const useCowRetirementReasonSelect = makeUseEnumSelect(
  CowRetirementReason,
  EnumStrings.cowRetirementReason
);
