import React, { useMemo } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { useCowEventsQuery } from '~/entities/cowEvents/gql/queries/cowEvents.graphql';
import { CowDetailedFragment } from '~/entities/cows/gql/fragments/cowDetailed.graphql';

import {
  CowCardEventsTable,
  CowCardEventsTableEntry,
  getCowCardEventsTableEntries,
} from '~/features/cowCardEvents';
import { CowPinnedFields } from '~/features/cowFields';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Cow to render
   */
  cow: CowDetailedFragment;
}

export const BullEventsTabContent: React.FC<Props> = ({ className, cow }) => {
  const { data: cowEventsData, loading: isLoading } = useCowEventsQuery({
    variables: {
      cowIDs: [cow.id],
      // TODO add pagination, when backend is ready to apply id filter to events
      // No we can't preload selected item, so we load al of them
      first: 1000,
    },
  });

  const cowEventsTableEntries: CowCardEventsTableEntry[] = useMemo(
    () =>
      getCowCardEventsTableEntries(
        (cowEventsData?.cowEvents.edges ?? []).map(R.prop('node'))
      ),
    [cowEventsData]
  );

  return (
    <div className={clsx('grid grid-cols-3 gap-24', className)}>
      <div className="col-span-2">
        <CowCardEventsTable
          {...{
            isLoading,
            cowEventsTableEntries,
          }}
        />
      </div>

      <CowPinnedFields cow={cow} />
    </div>
  );
};
