import React from 'react';

import R from 'ramda';

import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import {
  useEditCompanyModal,
  useUploadCowFileModal,
} from '~/entities/companies';
import { CompanyFragment } from '~/entities/companies/gql/fragments/company.graphql';
import { useCopyCompanyMutation } from '~/entities/companies/gql/mutations/copyCompany.graphql';
import { useDeleteCompanyMutation } from '~/entities/companies/gql/mutations/deleteCompany.graphql';
import {
  CompaniesDocument,
  CompaniesQueryVariables,
} from '~/entities/companies/gql/queries/companies.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Company to render
   */
  company: CompanyFragment;
  /**
   * Variables, used in companies query
   */
  queryVariables: CompaniesQueryVariables;
}

export const CompanyCardContextMenu: React.FC<Props> = ({
  className,
  company,
  queryVariables,
}) => {
  const { sendNeutralToast } = useNotifications();

  const [deleteCompany] = useDeleteCompanyMutation();

  const [copyCompany] = useCopyCompanyMutation();

  const confirmDelete = useConfirm();

  const { open: openEditCompanyModal } = useEditCompanyModal({
    company,
  });

  const { open: openUploadCowFileModal } = useUploadCowFileModal({
    company,
  });

  return (
    <ContextMenuButton
      {...{
        className,
        tooltip: 'Действия с компанией',
        items: [
          {
            content: 'Редактировать',
            onPress: openEditCompanyModal,
          },
          {
            content: 'Загрузить данные',
            onPress: openUploadCowFileModal,
          },
          {
            content: 'Копировать компанию',
            onPress: async () => {
              const { data: copyCompanyMutationData } = await copyCompany({
                variables: { id: company.id },
                refetchQueries: ['companies', 'myUser'],
              });
              const copiedCompany = copyCompanyMutationData?.copyCompany;
              if (!copiedCompany) {
                return;
              }

              sendNeutralToast({
                message: 'Компания скопирована',
                functionButtonProps: {
                  children: 'Редактировать информацию',
                  onPress: () =>
                    openEditCompanyModal({
                      company: copiedCompany,
                    }),
                },
              });
            },
          },
          {
            variant: MenuItemVariants.delimiter,
          },
          {
            variant: MenuItemVariants.destructive,
            content: 'Удалить',
            onPress: async () => {
              const isConfirmed = await confirmDelete({
                title: 'Удаление компании',
                message: (
                  <div className="grid gap-12">
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Вы хотите удалить компанию{' '}
                      <Typography variant={TypographyVariants.bodySmallStrong}>
                        {company.name}
                      </Typography>
                      ?
                    </Typography>
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Это действие невозможно отменить.
                    </Typography>
                  </div>
                ),
                isDelete: true,
              });

              if (!isConfirmed) {
                return;
              }

              deleteCompany({
                variables: { id: company.id },
                optimisticResponse: { deleteCompany: null },
                update: R.juxt([
                  makeDeleteFragmentFromQuery({
                    typeName: 'Company',
                    query: CompaniesDocument,
                    variables: queryVariables,
                    queryName: 'companies',
                  })(company.id),
                  makeDeleteQueriesByNameWithoutVariables(
                    'companies',
                    queryVariables
                  ),
                ]),
              }).then(() => {
                sendNeutralToast('Компания удалена');
              });
            },
          },
        ],
      }}
    />
  );
};
