import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type BlueprintRoleFragment = { __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor };

export const BlueprintRoleFragmentDoc = gql`
    fragment BlueprintRole on BlueprintRole {
  __typename
  id
  name
  color
}
    `;