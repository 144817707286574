import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import {
  getSkeletonPlaceholders,
  Skeleton,
} from '~/shared/components/Skeleton';
import { SkeletonBlock } from '~/shared/components/Skeleton/components/SkeletonBlock';
import { formatTime } from '~/shared/helpers/date';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { PageHeader } from '~/services/layouts';
import {
  Callout,
  CalloutSizes,
  NotificationVariants,
} from '~/services/notifications';

import {
  MilkingsScheduleTable,
  useEditMilkingStartModal,
} from '~/features/milkingParlors';
import { useMilkingParlorsDetailedQuery } from '~/features/milkingParlors/gql/queries/milkingParlorsDetailed.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

const CALLOUT_SKELETON_BLOCK_HEIGHT_PX = 36;
const CALLOUT_SKELETON_BLOCK_WIDTH_PX = 404;

const MILKING_PARLOR_COLUMNS_SKELETONS_COUNT = 2;

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/milking-parlors/$farmId/schedule'
)({
  component: MilkingsSchedulePage,
});

function MilkingsSchedulePage() {
  const { farmId } = Route.useParams();

  const { open: openEditMilkingStartModal } = useEditMilkingStartModal();

  const queryVariables = {
    farmIDs: normalizeToArrayOrUndefined(farmId),
  };

  const { data: milkingParlorsData, loading: isMilkingParlorsLoading } =
    useMilkingParlorsDetailedQuery({
      variables: queryVariables,
    });

  const milkingParlors =
    milkingParlorsData?.milkingParlors.edges.map(R.prop('node')) ??
    getSkeletonPlaceholders(MILKING_PARLOR_COLUMNS_SKELETONS_COUNT);

  const currentFarm = milkingParlors.at(0)?.farm;
  const firstMilkingPerDayStartOn =
    currentFarm?.milkingParlorSettings?.firstMilkingPerDayStartOn ?? '00:00:00';

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Планирование доений',
          isWithDescription: true,
          description: currentFarm?.name,
          isDescriptionLoading: isMilkingParlorsLoading,
          backLinkProps: {
            search: { farmId },
            from: Route.fullPath,
            to: '../..',
          },
          rightContent: isMilkingParlorsLoading ? (
            <SkeletonBlock
              height={CALLOUT_SKELETON_BLOCK_HEIGHT_PX}
              width={CALLOUT_SKELETON_BLOCK_WIDTH_PX}
            />
          ) : (
            <Callout
              {...{
                variant: NotificationVariants.info,
                size: CalloutSizes.small8,
                subtitle: `Новый день доения начинается с ${formatTime(firstMilkingPerDayStartOn)}`,
                functionButtonProps: {
                  children: 'Изменить',
                  onPress: () =>
                    openEditMilkingStartModal({
                      farmId,
                      firstMilkingPerDayStartOn,
                    }),
                },
              }}
            />
          ),
        }}
      />
      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <Skeleton isLoading={isMilkingParlorsLoading}>
          <MilkingsScheduleTable
            {...{
              milkingParlors,
            }}
          />
        </Skeleton>
      </div>
    </div>
  );
}
