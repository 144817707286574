import R from 'ramda';
import { match, P } from 'ts-pattern';

import { formatDate } from '~/shared/helpers/date';

import {
  getNormalizedCowEvent,
  isCowDefaultEvent,
  isCowDisease,
  isCowInjection,
  isCowProtocol,
} from '~/entities/cowEvents';
import { CowEventFragment } from '~/entities/cowEvents/gql/fragments/cowEvent.graphql';

import { CowCardEventsTableEntryEvent } from './types';

/**
 * Creates frontend representation of cow events with special logic
 * It groups injections by protocol days and adding a protocol stop event non-existing on backend
 */
export const getCowCardEventsTableEntries = (
  cowEvents: CowEventFragment[],
  {
    lactationId,
    isLastLactation = true,
  }: {
    lactationId?: string;
    isLastLactation?: boolean;
  } = {}
) => {
  const groupedCowEvents = R.groupBy(cowEvent => {
    if (!isCowInjection(cowEvent) || !cowEvent.cowProtocol) return cowEvent.id;

    return `${formatDate(cowEvent.happenedAt)}__${cowEvent.cowProtocol.id}`;
  }, cowEvents);

  const mappedEvents = Object.values(groupedCowEvents)
    .filter(Boolean)
    .reduce<CowCardEventsTableEntryEvent[]>((acc, groupedEvents) => {
      const [cowEvent] = groupedEvents;
      const normalizedEvent = getNormalizedCowEvent(cowEvent);

      acc.push({
        event: cowEvent,
        groupedEvents,
        normalizedEvent,
        lactationId,
        happenedAt: cowEvent.happenedAt,
      });

      if (isCowProtocol(cowEvent) && cowEvent.stoppedAt) {
        acc.push({
          event: cowEvent,
          normalizedEvent,
          lactationId,
          happenedAt: cowEvent.stoppedAt,
          isProtocolStop: true,
        });
      }

      return acc;
    }, []);

  return R.sortWith(
    [
      R.descend(R.prop('happenedAt')),
      // Protocols should be displayed before injections,
      // and diseases before protocols,
      // cause they are triggers for each other
      R.ascend(entry =>
        match(entry.event)
          .with(P.when(isCowDefaultEvent), R.always(3))
          .with(P.when(isCowDisease), R.always(2))
          .with(P.when(isCowProtocol), R.always(1))
          .with(P.when(isCowInjection), R.always(0))
          .otherwise(R.always(-1))
      ),
      R.descend(entry => entry.event.id),
      // Protocol stop should be displayed before new protocol start
      R.ascend(entry => (entry.isProtocolStop ? 0 : 1)),
    ],
    mappedEvents
  ).map((eventEntry, index) => ({
    ...eventEntry,
    isLastEvent: isLastLactation && index === 0,
  }));
};
