import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import clsx from 'clsx';
import R from 'ramda';

import {
  Button,
  ButtonProps,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Input } from '~/shared/components/Input';
import { Select } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { getTmpId, isTmpId } from '~/shared/helpers/string';

import { useNotifications } from '~/services/notifications';

import { USER_SCHEMA } from '~/entities/users';

import panelStyles from '~/styles/modules/panel.module.scss';

import { EditCompanyFormType } from '../..';
import companyFieldArraysStyles from '../../companyFieldArrays.module.scss';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditUsersArrayFieldsForm: React.FC<Props> = ({ className }) => {
  const { sendNeutralToast } = useNotifications();

  const formContext = useFormContext<EditCompanyFormType>();

  const {
    fields: usersArrayItems,
    remove: removeFromUsersArray,
    append: appendToUsersArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'users',
    keyName: 'uniqKey',
  });

  const createdFarms = formContext.watch('farms')?.filter(R.prop('name')) ?? [];
  const createdBlueprintRoles =
    formContext.watch('blueprintRoles')?.filter(R.prop('name')) ?? [];

  // If we removed some farm, we should make sure, that users don't have it selected
  useEffect(() => {
    const createdFarmIds = createdFarms.map(R.prop('id'));
    formContext.setValue(
      'users',
      (formContext.getValues('users') ?? []).map(userFields => {
        const filteredIds = userFields.farmIDs.filter(farmId =>
          createdFarmIds.includes(farmId)
        );

        if (filteredIds.length === userFields.farmIDs.length) {
          return userFields;
        }

        return {
          ...userFields,
          farmIDs: filteredIds,
        };
      })
    );
  }, [createdFarms.length]);

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    theme: ButtonThemes.accent,
    variant: ButtonVariants.secondary,
    onPress: () =>
      appendToUsersArray({
        ...USER_SCHEMA.getDefault(),
        id: getTmpId('user'),
      }),
    children: 'Добавить пользователя',
  } satisfies ButtonProps;

  if (!usersArrayItems.length) {
    return (
      <div className={clsx('p-24', className, panelStyles.panel)}>
        <DataBlockedMessage
          {...{
            className: 'p-24',
            message: 'Пользователи пока не добавлены',
            buttonProps: addButtonProps,
          }}
        />
      </div>
    );
  }

  return (
    <div className={clsx(styles.root, className)}>
      <div className={companyFieldArraysStyles.tableHeader}>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Фамилия
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Имя
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Отчество
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Электронная почта
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Должность
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Ферма
        </Typography>
      </div>
      {usersArrayItems.map((userFields, userIndex) => (
        <div key={userFields.id} className={companyFieldArraysStyles.tableRow}>
          <Input
            {...{
              name: `users.${userIndex}.lastName`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <Input
            {...{
              name: `users.${userIndex}.firstName`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <Input
            {...{
              name: `users.${userIndex}.middleName`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <Input
            {...{
              name: `users.${userIndex}.email`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <Select
            {...{
              name: `users.${userIndex}.blueprintRoleIDs`,
              placeholder: 'Выберите должность',
              isMulti: true,
              items: createdBlueprintRoles,
            }}
          />
          <Select
            {...{
              name: `users.${userIndex}.farmIDs`,
              isMulti: true,
              isDisabled: !isTmpId(userFields.id),
              placeholder: 'Выберите ферму',
              items: createdFarms,
            }}
          />
          <FunctionButton
            {...{
              className: companyFieldArraysStyles.deleteButton,
              iconVariant: IconVariants.delete,
              onPress: () => {
                removeFromUsersArray(userIndex);
                sendNeutralToast('Пользователь удалён');
              },
            }}
          />
        </div>
      ))}
      <Button
        {...{
          ...addButtonProps,
          size: ButtonSizes.small24,
        }}
      />
    </div>
  );
};
