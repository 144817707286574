import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { Button } from '~/shared/components/Button';
import { Loader } from '~/shared/components/Loader';
import {
  BreadCrumbConfig,
  MBreadCrumbs,
} from '~/shared/components/MBreadCrumbs';
import { MErrorMessage } from '~/shared/components/MErrorMessage';

import { useArkaNavigation } from '~/services/navigation';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { BlueprintDetailsEditModel } from '../../../../types';
import { BlueprintDetailsForm } from '../../../BlueprintDetailsForm';
import { BlueprintDetailsRunSettings } from '../../../BlueprintDetailsRunSettings';
import styles from './index.module.scss';

interface Props {
  blueprint: BlueprintDetailsEditModel;

  loading: boolean;
  new: boolean;
  errors: string[];

  submit: (blueprint: BlueprintDetailsEditModel) => void;
}

export const BlueprintsDetailsUI: React.FC<Props> = ({
  blueprint,
  new: isNew,
  loading,
  errors,
  submit,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);
  const { urlCompanyId } = useArkaNavigation();
  const [blueprintUnderEdit, setState] = useState(blueprint);

  const getBreadcrumbsLinks = (id: string): BreadCrumbConfig[] => [
    {
      linkProps: {
        to: '/$companyId/user/production-calendar',
        params: { companyId: urlCompanyId },
        search: true,
      },
      value: t(IntegratorStrings.blueprintDetails.breadCrumbs.calendar),
    },
    {
      linkProps: {
        to: '/$companyId/integrator/blueprints/$blueprintId',
        params: {
          blueprintId: id,
          companyId: urlCompanyId,
        },
      },
      value:
        id === 'new'
          ? t(IntegratorStrings.blueprintDetails.breadCrumbs.new)
          : t(IntegratorStrings.blueprintDetails.breadCrumbs.existing, {
              id,
            }),
    },
  ];

  useEffect(() => {
    setState(blueprint);
  }, [blueprint]);

  const loader = loading ? (
    <Loader className={layoutStyles.fullHeightContainer} />
  ) : null;
  const errorMsg =
    errors.length > 0 ? <MErrorMessage>{errors}</MErrorMessage> : null;

  const submitCallback = useCallback(() => {
    submit(blueprintUnderEdit);
  }, [blueprintUnderEdit]);

  return (
    <>
      <MBreadCrumbs links={getBreadcrumbsLinks(isNew ? 'new' : blueprint.id)} />
      <>
        {errorMsg}
        {loader}
      </>

      <div className={styles.details}>
        <div className={styles.main}>
          {blueprintUnderEdit.blueprintRoles && (
            <BlueprintDetailsForm
              className={styles.form}
              blueprint={blueprintUnderEdit}
              onChange={setState}
            />
          )}
        </div>
        <div className={styles.aside}>
          {blueprintUnderEdit.runSettings && (
            <BlueprintDetailsRunSettings
              runSettings={blueprintUnderEdit.runSettings}
              onChange={setState}
            />
          )}
        </div>
      </div>

      <Button className={styles.submit} onPress={submitCallback}>
        {t(IntegratorStrings.blueprintDetails.continueButton)}
      </Button>
    </>
  );
};
