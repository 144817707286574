import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DiseaseFragmentDoc } from '../../../events/gql/fragments/disease.graphql';
import { InjectionFragmentDoc } from '../../../events/gql/fragments/injection.graphql';
import { InseminationSchemeFragmentDoc } from '../../../events/gql/fragments/inseminationScheme.graphql';
import { ProtocolFragmentDoc } from '../../../events/gql/fragments/protocol.graphql';
import { UserEventFragmentDoc } from '../../../events/gql/fragments/userEvent.graphql';
import { FarmFragmentDoc } from '../../../farms/gql/fragments/farm.graphql';
import { PenGroupShortFragmentDoc } from '../../../penGroups/gql/fragments/penGroupShort.graphql';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
import { BullFragmentDoc } from '../../../bulls/gql/fragments/bull.graphql';
import { EmployeeFragmentDoc } from '../../../employees/gql/fragments/employee.graphql';
import { CalvingFragmentDoc } from '../../../calvings/gql/fragments/calving.graphql';
import { InseminationShortFragmentDoc } from '../../../inseminations/gql/fragments/inseminationShort.graphql';
import { SemenDoseFragmentDoc } from '../../../semenDoses/gql/fragments/semenDose.graphql';
import { DateHardcodedValueFragmentDoc } from '../../../blueprintSourceFields/gql/fragments/dateHardcodedValue.graphql';
import { DatetimeHardcodedValueFragmentDoc } from '../../../blueprintSourceFields/gql/fragments/datetimeHardcodedValue.graphql';
import { FloatHardcodedValueFragmentDoc } from '../../../blueprintSourceFields/gql/fragments/floatHardcodedValue.graphql';
import { IntHardcodedValueFragmentDoc } from '../../../blueprintSourceFields/gql/fragments/intHardcodedValue.graphql';
import { StrHardcodedValueFragmentDoc } from '../../../blueprintSourceFields/gql/fragments/strHardcodedValue.graphql';
import { JsonHardcodedValueFragmentDoc } from './jsonHardcodedValue.graphql';
import { BlueprintInputFragmentDoc } from './blueprintInput.graphql';
import { SourceFieldFragmentDoc } from '../../../blueprintSourceFields/gql/fragments/sourceField.graphql';
export type BlueprintValue_Bull_Fragment = { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null };

export type BlueprintValue_Calving_Fragment = { __typename: 'Calving', id: string, happenedAt: string, isAbortion: boolean, difficultyScore: number, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, employee?: { __typename: 'Employee', id: string, firstName: string, lastName: string } | null };

export type BlueprintValue_Cow_Fragment = { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string };

export type BlueprintValue_Disease_Fragment = { __typename: 'Disease', id: string, name: string, kind: Types.EventKindEnum, protocols: Array<{ __typename: 'Protocol', id: string }> };

export type BlueprintValue_Employee_Fragment = { __typename: 'Employee', id: string, comment: string, number: string, firstName: string, middleName: string, lastName: string, company: { __typename: 'Company', id: string } };

export type BlueprintValue_Farm_Fragment = { __typename: 'Farm', id: string, name: string, number: string };

export type BlueprintValue_Injection_Fragment = { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean };

export type BlueprintValue_Insemination_Fragment = { __typename: 'Insemination', id: string, happenedAt: string, movedToGroupWithBullAt?: string | null, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } };

export type BlueprintValue_InseminationScheme_Fragment = { __typename: 'InseminationScheme', id: string, name: string, kind: Types.EventKindEnum, code: string, description: string };

export type BlueprintValue_PenGroup_Fragment = { __typename: 'PenGroup', id: string, name: string, identifier: number };

export type BlueprintValue_Protocol_Fragment = { __typename: 'Protocol', id: string, name: string, kind: Types.EventKindEnum, expectedDaysOnProtocol?: number | null, daysUntilCheckAfterLastThreatment?: number | null, daysMeatWithhold: number, daysMilkWithhold: number, totalTreatmentCost?: number | null, penGroup?: { __typename: 'PenGroup', id: string } | null, protocolInjections: Array<{ __typename: 'ProtocolInjection', id: string, comment: string, dayNumber: number, injection?: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } | null }> };

export type BlueprintValue_SemenDose_Fragment = { __typename: 'SemenDose', id: string, deliveryDate?: string | null, studCode: string, dosesCount: number, batchNumber: string, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } };

export type BlueprintValue_UserEvent_Fragment = { __typename: 'UserEvent', id: string, name: string, kind: Types.EventKindEnum };

export type BlueprintValue_StrHardcodedValue_Fragment = { __typename: 'StrHardcodedValue', strValue: string };

export type BlueprintValue_IntHardcodedValue_Fragment = { __typename: 'IntHardcodedValue', intValue: number };

export type BlueprintValue_FloatHardcodedValue_Fragment = { __typename: 'FloatHardcodedValue', floatValue: number };

export type BlueprintValue_DatetimeHardcodedValue_Fragment = { __typename: 'DatetimeHardcodedValue', datetimeValue: string };

export type BlueprintValue_DateHardcodedValue_Fragment = { __typename: 'DateHardcodedValue', dateValue: string };

export type BlueprintValue_JsonHardcodedValue_Fragment = { __typename: 'JSONHardcodedValue', jsonValue: string };

export type BlueprintValue_SourceField_Fragment = { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum };

export type BlueprintValue_BlueprintInput_Fragment = { __typename: 'BlueprintInput', id: string, name: string, prompt: string, valueKinds: Array<Types.ValueKindEnum> };

export type BlueprintValueFragment = BlueprintValue_Bull_Fragment | BlueprintValue_Calving_Fragment | BlueprintValue_Cow_Fragment | BlueprintValue_Disease_Fragment | BlueprintValue_Employee_Fragment | BlueprintValue_Farm_Fragment | BlueprintValue_Injection_Fragment | BlueprintValue_Insemination_Fragment | BlueprintValue_InseminationScheme_Fragment | BlueprintValue_PenGroup_Fragment | BlueprintValue_Protocol_Fragment | BlueprintValue_SemenDose_Fragment | BlueprintValue_UserEvent_Fragment | BlueprintValue_StrHardcodedValue_Fragment | BlueprintValue_IntHardcodedValue_Fragment | BlueprintValue_FloatHardcodedValue_Fragment | BlueprintValue_DatetimeHardcodedValue_Fragment | BlueprintValue_DateHardcodedValue_Fragment | BlueprintValue_JsonHardcodedValue_Fragment | BlueprintValue_SourceField_Fragment | BlueprintValue_BlueprintInput_Fragment;

export const BlueprintValueFragmentDoc = gql`
    fragment BlueprintValue on BlueprintValue {
  ...Disease
  ...Injection
  ...InseminationScheme
  ...Protocol
  ...UserEvent
  ...Farm
  ...PenGroupShort
  ...CowShort
  ...Bull
  ...Employee
  ...Calving
  ...InseminationShort
  ...SemenDose
  ...DateHardcodedValue
  ...DatetimeHardcodedValue
  ...FloatHardcodedValue
  ...IntHardcodedValue
  ...StrHardcodedValue
  ...JSONHardcodedValue
  ...BlueprintInput
  ...SourceField
}
    ${DiseaseFragmentDoc}
${InjectionFragmentDoc}
${InseminationSchemeFragmentDoc}
${ProtocolFragmentDoc}
${UserEventFragmentDoc}
${FarmFragmentDoc}
${PenGroupShortFragmentDoc}
${CowShortFragmentDoc}
${BullFragmentDoc}
${EmployeeFragmentDoc}
${CalvingFragmentDoc}
${InseminationShortFragmentDoc}
${SemenDoseFragmentDoc}
${DateHardcodedValueFragmentDoc}
${DatetimeHardcodedValueFragmentDoc}
${FloatHardcodedValueFragmentDoc}
${IntHardcodedValueFragmentDoc}
${StrHardcodedValueFragmentDoc}
${JsonHardcodedValueFragmentDoc}
${BlueprintInputFragmentDoc}
${SourceFieldFragmentDoc}`;