import { makeUpdateFragment } from '~/services/gql';

import {
  EmployeeFragment,
  EmployeeFragmentDoc,
} from './gql/fragments/employee.graphql';

/**
 * Update Employee in the cache
 */
export const updateEmployeeFragment = makeUpdateFragment<EmployeeFragment>({
  typeName: 'Employee',
  fragment: EmployeeFragmentDoc,
});
