import { AnalyticsGroupingPeriodEnum } from '@graphql-types';

import { AppAnalyticalTarget } from '~/~legacy/services/AnalyticsService';
import { ListItem } from '~/~legacy/types/keyValue';

export function getTargetsData(
  startDate: Date,
  endDate: Date,
  targets: AppAnalyticalTarget[]
) {
  const targetsData = targets
    .filter(item => {
      return new Date(item.validSince) > startDate;
    })
    .map(item => {
      return {
        x: item.validSince,
        y: item.value,
      };
    });

  const lastTargetVal =
    targets.length > 0 ? targets[targets.length - 1] : undefined;

  if (lastTargetVal) {
    // add start line

    if (targetsData.length < 2) {
      targetsData.push({
        x: startDate.toISOString(),
        y: lastTargetVal.value,
      });
    }

    // add last bar target
    if (new Date(lastTargetVal.validSince) < endDate) {
      targetsData.push({
        x: endDate.toISOString(),
        y: lastTargetVal.value,
      });
    }
  }

  return targetsData;
}

export const getPeriodItems = () => {
  const periodItems: ListItem<AnalyticsGroupingPeriodEnum>[] = [
    {
      value: AnalyticsGroupingPeriodEnum.Year,
      content: 'Годам',
    },
    {
      value: AnalyticsGroupingPeriodEnum.Month,
      content: 'Месяцам',
    },
    {
      value: AnalyticsGroupingPeriodEnum.Week,
      content: 'Неделям',
    },
    {
      value: AnalyticsGroupingPeriodEnum.Day,
      content: 'Дням',
    },
  ];
  return periodItems;
};

export function getFormInputVal(
  val: string | number | undefined | null,
  placeholder = '',
  unit = ''
) {
  return val === undefined || val === null ? placeholder : `${val}${unit}`;
}
