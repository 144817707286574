export enum StandardServices {
  Http = 'Http',
}

export enum MaslovServices {
  AnalyticsService = 'AnalyticsService',
  BlueprintsService = 'BlueprintsService',
  BlueprintEditService = 'BlueprintEditService',
  BlueprintExecutionService = 'BlueprintExecutionService',
}
