import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { RunType } from '@graphql-types';

import { useApiFeedback } from '~/~legacy/hooks/useApiFeedback';
import {
  BlueprintCycleInputField,
  BlueprintExecutionInfo,
  BlueprintInputField,
} from '~/~legacy/services/BlueprintExecutionService';

import { Button } from '~/shared/components/Button';

import { PageHeader } from '~/services/layouts';
import { useArkaNavigation } from '~/services/navigation';

import { BlueprintExecutionWrapper } from '../BlueprintExecutionWrapper';
import { BlueprintFormsInfoPanel } from '../BlueprintFormsInfoPanel';
import { UserCycleInput } from '../UserCycleInput';
import { UserInputs } from '../UserInputs';
import {
  BlueprintFormsActionTypes,
  blueprintFormsReducer,
  FocusChange,
  getBlueprintFormsInintialState,
} from './BlueprintForms.reducer';

interface Props {
  executionInfo: BlueprintExecutionInfo;
  errors: string[];
  start: (executionInfo: BlueprintExecutionInfo) => Promise<void>;
  cancel: () => void;
  canEdit?: boolean;
}

export const BlueprintForms: React.FC<Props> = ({
  executionInfo,
  start,
  errors,
}) => {
  const [state, dispatch] = useReducer(
    blueprintFormsReducer,
    getBlueprintFormsInintialState(executionInfo)
  );

  const [isSubmitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: BlueprintFormsActionTypes.Reinit,
      data: executionInfo,
    });
  }, [executionInfo]);

  const inputChange = useCallback(
    (input: BlueprintInputField, val: any) => {
      dispatch({
        type: BlueprintFormsActionTypes.ChangeInput,
        data: {
          input,
          val,
        },
      });
    },
    [dispatch]
  );

  const cycleInputChanged = useCallback(
    (
      cycleInput: BlueprintCycleInputField,
      rowIndex: number,
      fieldIndex: number,
      val: any
    ) => {
      dispatch({
        type: BlueprintFormsActionTypes.ChangeCycleInput,
        data: {
          cycleInput,
          rowIndex,
          fieldIndex,
          val,
        },
      });
    },
    [dispatch]
  );

  const pasteCallback = useCallback(
    (
      cycleInput: BlueprintCycleInputField,
      rowIndex: number,
      fieldIndex: number,
      event: React.ClipboardEvent
    ) => {
      const data = event.clipboardData
        .getData('text/plain')
        .replaceAll('\r', '')
        .split('\n');

      const shouldProcess = data.length > 1;

      if (shouldProcess) {
        event.stopPropagation();
        event.preventDefault();

        dispatch({
          type: BlueprintFormsActionTypes.PasteColumn,
          data: {
            cycleInput,
            rowIndex,
            fieldIndex,
            data,
          },
        });
      }
    },
    [dispatch]
  );

  const addRow = useCallback(
    (cycleInput: BlueprintCycleInputField) => {
      dispatch({
        type: BlueprintFormsActionTypes.AddCycleInputRow,
        data: cycleInput,
      });
    },
    [dispatch]
  );

  const delRow = useCallback(
    (cycleInput: BlueprintCycleInputField, index: number) => {
      dispatch({
        type: BlueprintFormsActionTypes.DelCycleInputRow,
        data: {
          cycleInput,
          index,
        },
      });
    },
    [dispatch]
  );

  const focusHandler = useCallback(
    (focusChangeEvent: FocusChange) => {
      dispatch({
        type: BlueprintFormsActionTypes.MoveFocus,
        data: focusChangeEvent,
      });
    },
    [dispatch]
  );

  const inputs =
    state.executionInfo.inputs.length > 0 ? (
      <UserInputs inputs={state.executionInfo.inputs} onChange={inputChange} />
    ) : null;

  const cycleInputs = state.executionInfo.cycleInputs.map(cycle => (
    <UserCycleInput
      key={cycle.id}
      addRow={addRow}
      delRow={delRow}
      cycleInput={cycle}
      onChange={cycleInputChanged}
      focusHandler={focusHandler}
      paste={pasteCallback}
      activeCell={state.activeCell}
    />
  ));

  const continueCallback = useCallback(() => {
    setSubmitLoading(true);
    start(state.executionInfo).finally(() => setSubmitLoading(false));
  }, [start, state.executionInfo]);

  const panel = (
    <BlueprintFormsInfoPanel>
      <div />
      <div>
        <Button isLoading={isSubmitLoading} onPress={continueCallback}>
          Завершить и сохранить
        </Button>
      </div>
    </BlueprintFormsInfoPanel>
  );

  const { errorMessage } = useApiFeedback(errors, undefined, 'my-16');

  const { urlCompanyId } = useArkaNavigation();

  return (
    <BlueprintExecutionWrapper panel={panel}>
      <PageHeader
        {...{
          title: executionInfo.name,
          backLinkProps: {
            to:
              executionInfo.runType === RunType.Schedule
                ? '/$companyId/user/production-calendar'
                : '/$companyId/user/incidents',
            params: {
              companyId: urlCompanyId,
            },
          },
        }}
      />
      {errorMessage}
      {inputs}
      {cycleInputs}
    </BlueprintExecutionWrapper>
  );
};
