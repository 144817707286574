import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportChartFragmentDoc } from '../../../customReportLaunch/gql/fragments/customReportChart.graphql';
import { CustomReportChartMultipleValuesFragmentDoc } from '../../../customReportLaunch/gql/fragments/customReportChartMultipleValues.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalculateCustomReportChartMutationVariables = Types.Exact<{
  customReportHashID: Types.Scalars['String']['input'];
  settingsInput?: Types.InputMaybe<Types.CalculateCustomReportChartSettingsInput>;
}>;


export type CalculateCustomReportChartMutation = { __typename?: 'Mutation', calculateCustomReportChart: { __typename: 'CustomReportChartEmptyReport' } | { __typename: 'CustomReportChart', xAxisLabels: Array<{ __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } | null>, yAxisDatasets: Array<Array<{ __typename: 'CustomReportChartValue', value?: number | null }>> } | { __typename: 'CustomReportChartEmptyChart' } | { __typename: 'CustomReportChartDirty' } | { __typename: 'CustomReportChartMultipleValues', xAxisLabels: Array<{ __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } | null>, yAxisMultiValueDatasets: Array<Array<Array<{ __typename: 'CustomReportChartValue', value?: number | null }>>> } };


export const CalculateCustomReportChartDocument = gql`
    mutation calculateCustomReportChart($customReportHashID: String!, $settingsInput: CalculateCustomReportChartSettingsInput) {
  calculateCustomReportChart(
    customReportHashID: $customReportHashID
    settingsInput: $settingsInput
  ) {
    __typename
    ...CustomReportChart
    ...CustomReportChartMultipleValues
  }
}
    ${CustomReportChartFragmentDoc}
${CustomReportChartMultipleValuesFragmentDoc}`;
export type CalculateCustomReportChartMutationFn = Apollo.MutationFunction<CalculateCustomReportChartMutation, CalculateCustomReportChartMutationVariables>;

/**
 * __useCalculateCustomReportChartMutation__
 *
 * To run a mutation, you first call `useCalculateCustomReportChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateCustomReportChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateCustomReportChartMutation, { data, loading, error }] = useCalculateCustomReportChartMutation({
 *   variables: {
 *      customReportHashID: // value for 'customReportHashID'
 *      settingsInput: // value for 'settingsInput'
 *   },
 * });
 */
export function useCalculateCustomReportChartMutation(baseOptions?: Apollo.MutationHookOptions<CalculateCustomReportChartMutation, CalculateCustomReportChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculateCustomReportChartMutation, CalculateCustomReportChartMutationVariables>(CalculateCustomReportChartDocument, options);
      }
export type CalculateCustomReportChartMutationHookResult = ReturnType<typeof useCalculateCustomReportChartMutation>;
export type CalculateCustomReportChartMutationResult = Apollo.MutationResult<CalculateCustomReportChartMutation>;
export type CalculateCustomReportChartMutationOptions = Apollo.BaseMutationOptions<CalculateCustomReportChartMutation, CalculateCustomReportChartMutationVariables>;