import { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';

import {
  CustomReportChartAggFormulaKindEnum,
  CustomReportChartKindEnum,
} from '@graphql-types';
import R from 'ramda';

import { MenuItemVariants } from '~/shared/components/Menu';

import { useForm } from '~/services/forms';
import { omitTypename } from '~/services/gql';
import { useReportCardBuilderForm } from '~/services/reportCardBuilder';

import { useBlueprintSourceFields } from '~/entities/blueprintSourceFields';

import {
  getCustomReportValueConfigKey,
  isCustomReportHeaderColumn,
  isValidPivotTable,
  mapCustomReportValueKeyToForm,
} from '~/features/customReportPivot';

import { CUSTOM_REPORT_CHART_FORM_SCHEMA } from '../../../constants';
import { mapCustomReportChartSettingsToForm } from '../../../helpers';
import { useCustomReportChartAxes } from '../../../hooks';
import { CustomReportChartSettingsFormType } from '../../../types';
import { CustomReportChartSettingsFormProps } from '../types';

export const useCustomReportChartSettingsForm = ({
  customReport,

  chartSettings,
  customReportLaunchResult,

  onSettingsFormChange,
}: CustomReportChartSettingsFormProps) => {
  const { blueprintLaunchResult } = customReportLaunchResult;
  const availableSourceFields = blueprintLaunchResult.columnSourceFields;
  const useBlueprintSourceFieldsInterface = useBlueprintSourceFields(
    availableSourceFields
  );

  const defaultValues = useMemo(
    () => mapCustomReportChartSettingsToForm(chartSettings),
    [chartSettings]
  );

  const formContext = useForm<CustomReportChartSettingsFormType>({
    schema: CUSTOM_REPORT_CHART_FORM_SCHEMA,
    defaultValues,
  });

  const {
    fields: yAxesArrayItems,
    remove: removeFromYAxesArray,
    append: appendToYAxesArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'yAxes',
  });

  const { formValues } = useReportCardBuilderForm({
    formContext,
    defaultValues,
    onSettingsFormChange,
    untrackedFields: ['name'],
  });

  const { pivotTable } = customReportLaunchResult;

  const launchHeaderColumn = isValidPivotTable(pivotTable)
    ? pivotTable.columns.find(isCustomReportHeaderColumn)
    : undefined;

  const launchHeaderColumnSourceFieldValues =
    launchHeaderColumn?.blueprintSourceFieldValues.filter(Boolean) ?? [];

  const availableForXAxisSelectItems = [
    ...customReport.settings.rows,
    ...customReport.settings.columns,
    ...customReport.settings.values.filter(
      valueConfig =>
        !yAxesArrayItems.some(
          yAxis =>
            yAxis.valueKey &&
            getCustomReportValueConfigKey(yAxis.valueKey) ===
              getCustomReportValueConfigKey(valueConfig)
        )
    ),
  ];

  const selectedXAxis = formContext.watch('xAxis');

  const useCustomReportChartAxesInterface = useCustomReportChartAxes({
    customReportLaunchResult,
  });
  const { getYAxisName } = useCustomReportChartAxesInterface;

  // We can' select date columns as yAxis
  const isDateColumnType = (columnIndex: number) => {
    // TODO rework to column types, when they will be done on backend
    const firstRowWithValue = isValidPivotTable(pivotTable)
      ? pivotTable.rows.find(row => {
          const rowValue = row.values[columnIndex];
          return (
            !R.isNil(rowValue?.datetimeValue) ||
            !R.isNil(rowValue?.floatValue) ||
            !R.isNil(rowValue?.intValue) ||
            !R.isNil(rowValue?.strValue)
          );
        })
      : undefined;

    return !!firstRowWithValue?.values[columnIndex]?.datetimeValue;
  };

  const availableForYAxisSourceFieldValues = launchHeaderColumnSourceFieldValues
    .filter(
      (sourceFieldValue, sourceFieldValueIndex) =>
        !isDateColumnType(sourceFieldValueIndex)
    )
    .map((sourceFieldValue, sourceFieldValueIndex) => {
      const blueprintSourceFieldValue = omitTypename(sourceFieldValue);
      return {
        key: sourceFieldValueIndex,
        content: getYAxisName({ blueprintSourceFieldValue }),
        blueprintSourceFieldValue,
      };
    });

  const availableForYAxisValues = customReport.settings.values
    .filter((valueConfig, valueIndex) => {
      if (isDateColumnType(valueIndex)) {
        return false;
      }
      // Filter out items selected for xAxis
      return (
        !selectedXAxis?.valueKey ||
        getCustomReportValueConfigKey(selectedXAxis.valueKey) !==
          getCustomReportValueConfigKey(valueConfig)
      );
    })
    .map(valueConfig => ({
      key: getCustomReportValueConfigKey(valueConfig),
      content: getYAxisName({ valueKey: valueConfig }),
      valueConfig,
    }));

  const availableForYAxisSingleMenuItems = [
    ...(launchHeaderColumn
      ? availableForYAxisSourceFieldValues
      : availableForYAxisValues),
  ].filter(availableYAxisItem => {
    // Filter out already selected for yAxes items
    if ('valueConfig' in availableYAxisItem) {
      const itemValueConfigKey = getCustomReportValueConfigKey(
        availableYAxisItem.valueConfig
      );
      return !yAxesArrayItems.some(
        item =>
          !!item.valueKey &&
          getCustomReportValueConfigKey(item.valueKey) === itemValueConfigKey
      );
    }

    return !yAxesArrayItems.some(
      item =>
        !!item.blueprintSourceFieldValue &&
        R.equals(
          item.blueprintSourceFieldValue,
          availableYAxisItem.blueprintSourceFieldValue
        )
    );
  });

  // Aggregations only applied for nested rows
  const shouldUseAggFormula = customReport.settings.rows.length > 1;

  const mapYAxisItemToYAxesForm = (
    item: (typeof availableForYAxisSingleMenuItems)[number]
  ) => {
    const aggFormula = shouldUseAggFormula
      ? CustomReportChartAggFormulaKindEnum.Average
      : null;

    if ('blueprintSourceFieldValue' in item) {
      return {
        blueprintSourceFieldValue: item.blueprintSourceFieldValue,
        valueKey: undefined,
        withRightScale: false,
        aggFormula,
      };
    }
    return {
      blueprintSourceFieldValue: undefined,
      valueKey: mapCustomReportValueKeyToForm(item.valueConfig),
      withRightScale: false,
      aggFormula,
    };
  };

  const isPieSelected = formValues.kind === CustomReportChartKindEnum.Pie;

  const availableForYAxisMenuItems =
    !availableForYAxisSingleMenuItems.length || isPieSelected
      ? availableForYAxisSingleMenuItems
      : [
          {
            key: 'selectAll',
            content: 'Все значения',
            onPress: () => {
              formContext.setValue('yAxes', [
                ...formContext.getValues('yAxes'),
                ...availableForYAxisSingleMenuItems.map(
                  mapYAxisItemToYAxesForm
                ),
              ]);
            },
          },
          {
            variant: MenuItemVariants.delimiter,
          },
          ...availableForYAxisSingleMenuItems,
        ];

  const addToYAxesArray = (
    item: (typeof availableForYAxisMenuItems)[number]
  ) => {
    if ('blueprintSourceFieldValue' in item || 'valueConfig' in item) {
      appendToYAxesArray(mapYAxisItemToYAxesForm(item));
    }
  };

  return {
    shouldUseAggFormula,

    useBlueprintSourceFieldsInterface,
    useCustomReportChartAxesInterface,

    formContext,

    yAxesArrayItems,
    removeFromYAxesArray,
    addToYAxesArray,

    availableForXAxisSelectItems,
    availableForYAxisMenuItems,
    availableForYAxisSingleMenuItems,
  };
};
