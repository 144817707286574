import { Dow } from '@graphql-types';

import { makeUseEnumSelect } from '~/shared/components/Select';

import { DAYS_OF_WEEK_DICT } from '../helpers/date';

/**
 * Select for days of week
 */
export const useDowSelect = makeUseEnumSelect(
  Dow,
  enumValue => DAYS_OF_WEEK_DICT[enumValue as Dow]
);
