import { createContext, ReactElement, useContext } from 'react';

/**
 * Context type for a router tabs context
 */
interface RouterTabsContextType {
  routerTabsRightContent?: ReactElement | null;
  setRouterTabsRightContent: (rightContent?: ReactElement | null) => void;
}

/**
 * Context for controlling upper level layout from the nested pages
 */
export const RouterTabsContext = createContext<RouterTabsContextType>({
  routerTabsRightContent: undefined,
  setRouterTabsRightContent: () => {},
});

/**
 * Hook for using the router tabs context, so the nested pages can control the layout
 */
export const useRouterTabsContext = () => useContext(RouterTabsContext);
