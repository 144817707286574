import { useMemo } from 'react';
import {
  FieldValues,
  useForm as useFormVendor,
  UseFormProps as UseFormPropsVendor,
  UseFormReturn,
} from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

/**
 * Gets the type of default schema values
 */
type InferSchemaDefault<T extends yup.ISchema<any, any>> = T['__default'];

/**
 * Creates a form type from schema like yup.Asserts, but allows default values,
 * that shouldn't pass validation, but can be used as initial values
 */
export type InferSchemaWithDefaults<T extends yup.ISchema<any, any>> = {
  [K in keyof yup.Asserts<T>]: yup.Asserts<T>[K] | InferSchemaDefault<T>[K];
};

/**
 * Shortcut for getting type of validated schema like yup.Asserts
 */
export type InferValidatedSchema<T extends yup.ISchema<any, any>> =
  yup.Asserts<T>;

/**
 * Extended props of react-hook-form hook with added validation schema
 */
interface UseFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends any = any,
> extends UseFormPropsVendor<TFieldValues, TContext> {
  schema: yup.ObjectSchema<TFieldValues>;
}

/**
 Interface of the form hook
 */
export type UseFormInterface<
  TFieldValues extends FieldValues = FieldValues,
  TTransformedValues extends FieldValues | undefined = TFieldValues,
  TContext extends any = any,
> = UseFormReturn<TFieldValues, TContext, TTransformedValues>;

/**
 * Vendor wrapper for react-hook-form for using with validation schema
 */
export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TTransformedValues extends FieldValues | undefined = TFieldValues,
  TContext extends object = object,
>({
  schema,
  ...useFormProps
}: UseFormProps<TFieldValues, TContext>): UseFormInterface<
  TFieldValues,
  TTransformedValues,
  TContext
> => {
  const resolver = useMemo(() => yupResolver(schema), [schema]);
  return useFormVendor<TFieldValues, TContext, TTransformedValues>({
    ...useFormProps,
    resolver,
  });
};
