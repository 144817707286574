// We're importing this setup file before importing ChicherinApp,
// cause we need yup locale to been set before we import some schemas,
// defined in the components files - https://github.com/jquense/yup/issues/293
import './setup'; // eslint-disable-line simple-import-sort/imports

import React from 'react';
import ReactDOM from 'react-dom/client';

import { ChicherinApp } from '~/apps/chicherin';

import reportWebVitals from './reportWebVitals';

import '~/styles/app.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<ChicherinApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
