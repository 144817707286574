import { AnalyticalMetricEnum } from '@graphql-types';

import { METRIC_CONFIGS } from '~/features/analytics';
import { BarChartDataPoint, ReactChartDatasetConfig } from '~/features/charts';

import TOKENS from '~/styles/__generated__/tokens.json';

import { ReproductionChartTypes } from './types';

/**
 * Configs for reproduction charts
 */
export const REPRODUCTION_CHART_CONFIGS: Record<
  ReproductionChartTypes,
  Omit<ReactChartDatasetConfig<'bar', BarChartDataPoint>, 'data'>
> = {
  [ReproductionChartTypes.heatDetectionRateChart]: {
    key: TOKENS.colorSuccessDefault,
    label: METRIC_CONFIGS[AnalyticalMetricEnum.HeatDetectionRate].name,
    color: TOKENS.colorSuccessDefault,
    order: 3,
  },
  [ReproductionChartTypes.pregnancyRateChart]: {
    key: TOKENS.colorWarningDefault,
    label: METRIC_CONFIGS[AnalyticalMetricEnum.PregnancyRate].name,
    color: TOKENS.colorWarningDefault,
    order: 1,
  },
  [ReproductionChartTypes.conceptionRateChart]: {
    key: TOKENS.colorInfoDefault,
    label: METRIC_CONFIGS[AnalyticalMetricEnum.ConceptionRate].name,
    color: TOKENS.colorInfoDefault,
    order: 2,
  },
};
