import React, { useEffect, useState } from 'react';

import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';
import { EditorHoC } from '~/~legacy/value-editors/EditorHoC';
import {
  ValueEditorArgs,
  ValueEditorStandardProps,
} from '~/~legacy/value-editors/types/valueEditorTypes';

import {
  BlueprintEditService,
  BlueprintInputNode,
  BlueprintNodeType,
  BlueprintSourceSectionField,
  ValueEditorKind,
} from '~/features/blueprintEdit';

import { VariableEdit } from './VariableEdit';
import { VariableView } from './VariableView';

import './VariableEditor.scss';

export interface VariableEditProps<T> extends ValueEditorStandardProps<T> {
  inputs: BlueprintInputNode[];
  fields: BlueprintSourceSectionField[];
  masterFields: BlueprintSourceSectionField[];

  isMaster?: boolean;
  parentNodeId: string;
  expectedValueKind: ValueEditorKind;
}

export const VariableEditor: React.FC<ValueEditorArgs<string>> = props => {
  const {
    name,
    submit,
    value,
    edit,
    cancel,
    parentNodeId,
    parentNodeType,
    returnValueKind,
  } = props;

  const [inputs, setInputs] = useState<BlueprintInputNode[]>([]);
  const [fields, setFields] = useState<BlueprintSourceSectionField[]>([]);
  const [masterFields, setMasterFields] = useState<
    BlueprintSourceSectionField[]
  >([]);
  const [master, setMaster] = useState<boolean>();

  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );
  useEffect(() => {
    const inputsSubscription = bpEditSvc.blueprint?.inputNodes$.subscribe(
      bpInputs => {
        setInputs(bpInputs);
      }
    );

    const sourceSection$ = bpEditSvc.blueprint?.isMaster
      ? bpEditSvc.blueprint?.masterSourceNodes$
      : bpEditSvc.blueprint?.sourceNodes$;

    const fieldsSubscription = sourceSection$?.subscribe(sections => {
      const flatFields: BlueprintSourceSectionField[] = [];
      sections.forEach(section => {
        flatFields.push(...section.fields);
      });
      setFields(parentNodeType === BlueprintNodeType.Action ? [] : flatFields);
    });

    const masterFieldsSubscription =
      bpEditSvc.blueprint?.masterSourceNodes$.subscribe(sections => {
        const flatFields: BlueprintSourceSectionField[] = [];
        sections.forEach(section => {
          flatFields.push(...section.fields);
        });

        setMasterFields(
          parentNodeType === BlueprintNodeType.Action ? [] : flatFields
        );
      });

    setMaster(bpEditSvc.blueprint?.isMaster);

    return () => {
      inputsSubscription?.unsubscribe();
      fieldsSubscription?.unsubscribe();
      masterFieldsSubscription?.unsubscribe();
    };
  }, [bpEditSvc, setInputs]);

  const Component = EditorHoC<VariableEditProps<string>>(
    VariableView,
    VariableEdit,
    {
      inputs,
      fields,
      masterFields,
      parentNodeId,
      expectedValueKind: returnValueKind,
      isMaster: master,
    }
  );

  return (
    <Component
      name={name}
      submit={submit}
      value={value}
      edit={edit}
      cancel={cancel}
    />
  );
};
