import { PartialSelectProps, SelectItem } from '../types';

/**
 * Fabric for creating a Select component, using a hook, that returns renderSelectElement
 */
export const makeSelectComponentFromHook = <
  UseSelectProps extends any,
  I extends SelectItem = SelectItem,
>(
  useSelect: (props: UseSelectProps) => {
    renderSelectElement: (
      innerProps?: PartialSelectProps<I>
    ) => React.JSX.Element;
  }
) => {
  return (props: UseSelectProps) => {
    const { renderSelectElement } = useSelect(props);

    return renderSelectElement({});
  };
};
