import React, { useMemo } from 'react';

import { useApolloClient } from '@apollo/client';

import { ChartOptions, Scriptable, ScriptableScaleContext } from 'chart.js';
import clsx from 'clsx';
import R from 'ramda';

import { Badge, BadgeSizes } from '~/shared/components/Badge';
import { ColoredDot, ColoredDotSizes } from '~/shared/components/ColoredDot';
import { IconVariants } from '~/shared/components/Icon';
import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatInt, formatNumber } from '~/shared/helpers/number';

import { readSourceFieldFragment } from '~/entities/blueprintSourceFields';
import { CustomMilkingReportChartFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportChart.graphql';

import {
  CHART_DATASET_COLORS_ITERATOR,
  CHART_DATASET_HOVER_COLORS_ITERATOR,
  CHART_DATASET_PRESSED_COLORS_ITERATOR,
  CHART_DATASET_SKIPPED_COLORS_ITERATOR,
  CHART_X_SCALE_MIN_RANGE,
  CHART_Y_SCALE_MIN_RANGE,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
  getScaleOptions,
  LINEAR_Y_SCALE_OPTIONS,
  LineChart,
  ReactChartProps,
} from '~/features/charts';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import TOKENS from '~/styles/__generated__/tokens.json';
import panelStyles from '~/styles/modules/panel.module.scss';

import { CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS } from '../../../../constants';
import { formatDatasetLabel, formatYAxisFieldName } from '../../../../helpers';
import { CustomMilkingReportSettingsFormType } from '../../../../types';
import { getReportDatasets, getXAxisLabels, getXAxisName } from '../../helpers';
import { CustomMilkingReportRow } from '../../types';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Report data to render as chart
   */
  reportData: CustomMilkingReportChartFragment | SkeletonPlaceholder;
  /**
   * Current settings of the report
   */
  requestedSettings: CustomMilkingReportSettingsFormType;
}

const CHART_HEIGHT_PX = 420;
const TOOLTIP_MAX_WIDTH_PX = 340;

export const CustomMilkingReportChart: React.FC<Props> = ({
  className,
  reportData,
  requestedSettings,
}) => {
  const xAxisName = getXAxisName(requestedSettings);

  const xAxisLabels = getXAxisLabels(reportData);

  const { datasets, chartOptions } = useMemo(() => {
    const reportDatasets = getReportDatasets(reportData);

    const datasetsInternal = reportDatasets
      .map((reportDataset, reportDatasetIndex) => {
        const yAxisField = reportDataset.datasetLabel?.field;
        if (!reportDataset.datasetLabel || !yAxisField) {
          return null;
        }
        return {
          reportDataset,
          key: reportDatasetIndex.toString(),
          label: formatDatasetLabel(reportDataset.datasetLabel),
          color: CHART_DATASET_COLORS_ITERATOR.next(!reportDatasetIndex).value,
          hoverColor:
            CHART_DATASET_HOVER_COLORS_ITERATOR.next(!reportDatasetIndex).value,
          pressedColor:
            CHART_DATASET_PRESSED_COLORS_ITERATOR.next(!reportDatasetIndex)
              .value,
          skippedColor:
            CHART_DATASET_SKIPPED_COLORS_ITERATOR.next(!reportDatasetIndex)
              .value,
          yAxisID: yAxisField.kind,
          isTogglable: true,
          data: reportDataset.dataPoints ?? [],
        };
      })
      .filter(Boolean);

    // If we have selected grouping or historic comparison,
    // we will receive same field kind for different datasets,
    // so we should group them to consider all possible values for a single axis config
    const datasetsByKind = R.groupBy<
      CustomMilkingReportRow | SkeletonPlaceholder,
      string
    >(
      reportDataset => reportDataset.datasetLabel?.field.kind ?? '',
      reportDatasets
    );
    const chartScaleOptionEntries = Object.entries(datasetsByKind)
      .map(([, groupedDatasets], reportDatasetIndex) => {
        if (!groupedDatasets) {
          return null;
        }
        const existingDataPoints = groupedDatasets
          .flatMap(ds => ds.dataPoints ?? [])
          .filter(Boolean);

        // We can just use the first dataset for most of the config
        const firstDataset = groupedDatasets[0];

        const yAxisField = firstDataset.datasetLabel?.field;
        if (!yAxisField) {
          return null;
        }

        const makeGetScriptableOptionForFirstVisibleScale =
          <T extends any>(
            visibleValue: T,
            otherValue: T,
            firstVisibleWithDifferentPositionValue: T = otherValue
          ): Scriptable<T, ScriptableScaleContext> =>
          context => {
            if (reportDatasetIndex === 0) {
              return visibleValue;
            }

            const isDatasetVisible = (
              dataset: CustomMilkingReportRow | SkeletonPlaceholder,
              datasetIndex: number
            ) =>
              dataset.datasetLabel &&
              reportDatasetIndex > datasetIndex &&
              // eslint-disable-next-line no-underscore-dangle -- access private chart.js scale method
              (
                context.scale.chart.scales[
                  dataset.datasetLabel.field.kind
                ] as any
              )?._isVisible();

            const isPrevScaleVisible = reportDatasets.some(isDatasetVisible);

            if (!isPrevScaleVisible) {
              return visibleValue;
            }

            // Case for the first visible axis with position different from the first visible axis
            const isFirstVisibleScaleWithDifferentPosition =
              reportDatasets.find(
                (dataset, datasetIndex) =>
                  yAxisField.withRightScale ===
                    dataset?.datasetLabel?.field.withRightScale &&
                  isDatasetVisible(dataset, datasetIndex)
              );

            if (!isFirstVisibleScaleWithDifferentPosition) {
              return firstVisibleWithDifferentPositionValue;
            }

            return otherValue;
          };

        return [
          firstDataset.datasetLabel?.field.kind,
          getScaleOptions(LINEAR_Y_SCALE_OPTIONS, {
            type: 'linear',
            display: 'auto',
            title: {
              display: true,
              text: formatYAxisFieldName(yAxisField.kind),
              padding: 0,
            },
            position: yAxisField.withRightScale ? 'right' : 'left',
            min: Math.min(0, ...existingDataPoints),
            max: Math.max(0, ...existingDataPoints) + 1,
            ticks: {
              count: undefined,
              padding: NUMBER_TOKENS.spacing12,
            },
            grid: {
              display: true,
              // We display all grids, but here we have logic for
              // displaying grid lines only for the first visible reportDataset, other datasets have ticks
              tickColor: makeGetScriptableOptionForFirstVisibleScale(
                'transparent',
                TOKENS.colorBorderMuted
              ),
              color: makeGetScriptableOptionForFirstVisibleScale(
                TOKENS.colorBorderMuted,
                'transparent'
              ),
              // We use ticks with dash to imitate spacing
              tickLength: makeGetScriptableOptionForFirstVisibleScale(
                0,
                NUMBER_TOKENS.size16,
                NUMBER_TOKENS.size16 + NUMBER_TOKENS.spacing12
              ) as any, // Chart.js doesn't have typings for passing callback here, but it works,
              tickBorderDash: [NUMBER_TOKENS.size16, NUMBER_TOKENS.spacing12],
              tickBorderDashOffset: makeGetScriptableOptionForFirstVisibleScale(
                0,
                0,
                // Offset by tick width to start drawing with left padding on the right axis
                yAxisField.withRightScale ? NUMBER_TOKENS.size16 : 0
              ),
            },
          }),
        ];
      })
      .filter(Boolean);

    const chartOptionsInternal = {
      interaction: {
        mode: 'index',
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: true,
            text: xAxisName,
          },
        },
        y: {
          // Hide default y scale, cause we have custom y scales
          display: false,
        },
        ...Object.fromEntries(chartScaleOptionEntries),
      },
      plugins: {
        zoom: {
          limits: {
            x: {
              min: 'original',
              max: 'original',
              minRange: CHART_X_SCALE_MIN_RANGE,
            },
            ...Object.fromEntries(
              chartScaleOptionEntries.map(([key]) => [
                key,
                {
                  min: 'original',
                  max: 'original',
                  minRange: CHART_Y_SCALE_MIN_RANGE,
                },
              ])
            ),
          },
          zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
          pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
        },
      },
    } satisfies ChartOptions;

    return {
      datasets: datasetsInternal,
      chartOptions: chartOptionsInternal,
    };
  }, [reportData]);

  const renderTooltip: ReactChartProps['renderTooltip'] = dataPoints => {
    const firstPoint = dataPoints.at(0);

    return (
      <div>
        <Typography
          {...{
            variant: TypographyVariants.descriptionLargeStrong,
            tag: 'h4',
            className: 'mb-4',
          }}
        >
          {xAxisName}
          &nbsp;
          {firstPoint && xAxisLabels.at(firstPoint.dataIndex)?.toString()}
        </Typography>
        <ul className={clsx('grid gap-4', styles.tooltipContent)}>
          {dataPoints.map(point => {
            const currentChartConfig = datasets.at(point.datasetIndex);
            if (!currentChartConfig) return null;
            const { datasetLabel, cowsCounts } =
              currentChartConfig.reportDataset;
            if (!datasetLabel || !cowsCounts) return null;

            const originalValue = currentChartConfig.data[point.dataIndex];

            const formattedValue = formatNumber(
              originalValue,
              CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS[
                datasetLabel.field.kind
              ].precision
            );

            return (
              <Typography
                key={`${point.datasetIndex}__${point.dataIndex}`}
                {...{
                  tag: 'li',
                  variant: TypographyVariants.descriptionLarge,
                  className:
                    'grid grid-cols-subgrid col-span-full items-center',
                }}
              >
                <ColoredDot
                  size={ColoredDotSizes.small10}
                  color={currentChartConfig.color}
                />

                <span className={clsx(styles.label, 'ellipsis')}>
                  {formatDatasetLabel(datasetLabel, false)}
                </span>

                <Badge
                  {...{
                    size: BadgeSizes.small16,
                    iconVariant: IconVariants.cow,
                    isPill: true,
                  }}
                >
                  {formatInt(cowsCounts.at(point.dataIndex))}
                </Badge>

                <span className="ml-8 text-right">{formattedValue}</span>

                <span>
                  {
                    CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS[
                      datasetLabel.field.kind
                    ].measurementUnit
                  }
                </span>
              </Typography>
            );
          })}
        </ul>
      </div>
    );
  };

  const client = useApolloClient();

  const groupingSourceField = readSourceFieldFragment(
    client,
    requestedSettings.grouping?.masterBlueprintSourceFieldID
  );

  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-24', className)}>
      <LineChart
        {...{
          datasets,
          labels: xAxisLabels,
          legendClassName: 'mb-32',
          legendTitle: groupingSourceField?.name,
          height: CHART_HEIGHT_PX,
          renderTooltip,
          chartOptions,
          tooltipProps: {
            maxWidth: TOOLTIP_MAX_WIDTH_PX,
          },
        }}
      />
    </div>
  );
};
