import {
  CustomReportChartAggFormulaKindEnum,
  CustomReportChartKindEnum,
} from '@graphql-types';
import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { BLUEPRINT_SOURCE_FIELD_VALUE_SCHEMA } from '~/entities/blueprintSourceFields';

import { PIVOT_TABLE_VALUE_SCHEMA } from '~/features/customReportPivot';
/**
 * Form id for custom report chart edit form
 */
export const CUSTOM_REPORT_CHART_SETTINGS_FORM_ID =
  'CustomReportChartSettingsForm';

/**
 * Dict with possible chart kind names
 */
export const CUSTOM_REPORT_CHART_KINDS_DICT: Record<
  CustomReportChartKindEnum,
  string
> = {
  [CustomReportChartKindEnum.Bar]: 'Столбчатая обыкновенная',
  [CustomReportChartKindEnum.StackedBar]: 'Столбчатая с накоплением',
  [CustomReportChartKindEnum.PercentBar]: 'Столбчатая 100%',
  [CustomReportChartKindEnum.Pie]: 'Круговая',
  [CustomReportChartKindEnum.Scatter]: 'Точечная',
  [CustomReportChartKindEnum.Line]: 'Линейный график',
};

/**
 * Dict with possible aggregation formulas for custom report charts
 */
export const CUSTOM_REPORT_CHART_AGG_FORMULA_KINDS_DICT: Record<
  CustomReportChartAggFormulaKindEnum,
  string
> = {
  [CustomReportChartAggFormulaKindEnum.Average]: 'Среднее',
  [CustomReportChartAggFormulaKindEnum.Count]: 'Количество',
  [CustomReportChartAggFormulaKindEnum.Max]: 'Максимум',
  [CustomReportChartAggFormulaKindEnum.Median]: 'Медиана',
  [CustomReportChartAggFormulaKindEnum.Min]: 'Минимум',
  [CustomReportChartAggFormulaKindEnum.Sum]: 'Сумма',
};

/**
 * Form schema for custom report chart x axis
 */
export const CUSTOM_REPORT_X_AXIS_CONFIG_SCHEMA = yup.object({
  blueprintSourceFieldID: yup.string().optional().default(undefined), // ID!
  valueKey: PIVOT_TABLE_VALUE_SCHEMA.optional().default(undefined),
});

/**
 * Form schema for custom report chart y axis
 */
export const CUSTOM_REPORT_Y_AXIS_CONFIG_SCHEMA = yup.object({
  valueKey: PIVOT_TABLE_VALUE_SCHEMA.optional().default(undefined),
  blueprintSourceFieldValue:
    BLUEPRINT_SOURCE_FIELD_VALUE_SCHEMA.optional().default(undefined),
  aggFormula: oneOfEnum(CustomReportChartAggFormulaKindEnum)
    .nullable()
    .default(null),
  withRightScale: yup.boolean().default(false),
});

/**
 * Form schema for custom report chart settings
 */
export const CUSTOM_REPORT_CHART_FORM_SCHEMA = yup.object({
  name: yup.string().required().default(''),
  kind: oneOfEnum(CustomReportChartKindEnum)
    .default(CustomReportChartKindEnum.Bar)
    .required(),
  xAxis: CUSTOM_REPORT_X_AXIS_CONFIG_SCHEMA.nullable().default(null),
  yAxes: yup.array(CUSTOM_REPORT_Y_AXIS_CONFIG_SCHEMA).default([]),
});
