import React from 'react';

import { createFileRoute, Navigate } from '@tanstack/react-router';
import { match, P } from 'ts-pattern';

import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { PageHeader } from '~/services/layouts';
import { WithSearchParamsValidation } from '~/services/navigation';

import {
  AnalyticsSearchParams,
  METRIC_CATEGORIES_DICT,
  METRIC_CONFIGS,
  ReproductionDataFields,
  useAnalyticsFilters,
} from '~/features/analytics';
import { useReproductionAnalyticsQuery } from '~/features/analyticsReproduction/gql/queries/reproductionAnalytics.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { CRTable } from './components/CRTable';
import { HDRTable } from './components/HDRTable';
import { PRTable } from './components/PRTable';

const TABLE_COMPONENTS_BY_FIELD = {
  hdrCowsData: HDRTable,
  hdrHeifersData: HDRTable,
  prCowsData: PRTable,
  prHeifersData: PRTable,
  crCowsData: CRTable,
  crHeifersData: CRTable,
} satisfies Record<ReproductionDataFields, React.ComponentType<any>>;

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/reproduction/$reproductionTableUrl/'
)({
  component: ReproductionTablePage,
  validateSearch: ({
    cowIds,
    farmId,
  }: WithSearchParamsValidation<AnalyticsSearchParams>) => ({
    cowIds: Array.isArray(cowIds) ? cowIds : [],
    farmId: farmId ?? undefined,
  }),
});

function ReproductionTablePage() {
  const { reproductionTableUrl } = Route.useParams();

  const currentMetricConfig = Object.entries(METRIC_CONFIGS).find(
    ([, value]) => value.detailDataPageUrl === reproductionTableUrl
  )?.[1];

  if (!currentMetricConfig) {
    return (
      <Navigate
        {...{
          to: '/$companyId/user/analytics/reproduction',
          from: Route.fullPath,
          replace: true,
        }}
      />
    );
  }
  const {
    farmId,
    renderFarmsSelectElement,

    cowIds,
    renderCowSelectElement,
  } = useAnalyticsFilters();

  const { data: reproductionAnalyticsQueryData, loading: isLoading } =
    useReproductionAnalyticsQuery({
      variables: {
        farmIDs: normalizeToArrayOrUndefined(farmId),
        cowIDs: normalizeToArrayOrUndefined(cowIds),
      },
    });

  const dataField = currentMetricConfig.dataField ?? 'hdrCowsData';
  const TableComponent = TABLE_COMPONENTS_BY_FIELD[dataField];
  const tableData =
    reproductionAnalyticsQueryData?.analytics.reproduction[dataField] ?? [];

  const tableProps = match(tableData)
    .with(
      P.array({ __typename: 'HDRData' }),
      matchedData =>
        ({
          hdrData: matchedData,
        }) as const
    )
    .with(
      P.array({ __typename: 'PRData' }),
      matchedData =>
        ({
          prData: matchedData,
        }) as const
    )
    .with(
      P.array({ __typename: 'CRData' }),
      matchedData =>
        ({
          crData: matchedData,
        }) as const
    )
    .exhaustive();

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: `${METRIC_CATEGORIES_DICT[currentMetricConfig.category]}. ${currentMetricConfig.name}`,
          backLinkProps: {
            from: Route.fullPath,
            to: '..',
            search: true,
            params: true,
          },
        }}
      />
      <div className="flex gap-16 items-center py-24">
        {renderFarmsSelectElement()}
        {renderCowSelectElement()}
      </div>
      <TableComponent
        {...{
          ...(tableProps as any),
          isLoading,
          category: currentMetricConfig.category,
        }}
      />
    </div>
  );
}
