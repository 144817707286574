import React, { useMemo } from 'react';

import { match, P } from 'ts-pattern';

import { formatFullNameWithInitials } from '~/shared/helpers/nameFormat';

import { AnyFragment } from '~/services/gql';

import { formatBull } from '~/entities/bulls';

import {
  CandleChart,
  CandleChartDataPoint,
  ChartCard,
  ChartDataset,
} from '~/features/charts';

import TOKENS from '~/styles/__generated__/tokens.json';

import { ConceptionRateChartBullCandleFragment } from '../../gql/fragments/conceptionRateChartBullCandle.graphql';
import { ConceptionRateChartDaysInMilkCandleFragment } from '../../gql/fragments/conceptionRateChartDaysInMilkCandle.graphql';
import { ConceptionRateChartEmployeeCandleFragment } from '../../gql/fragments/conceptionRateChartEmployeeCandle.graphql';
import { ConceptionRateChartInseminationNumberCandleFragment } from '../../gql/fragments/conceptionRateChartInseminationNumberCandle.graphql';
import { ConceptionRateChartInseminationSchemeCandleFragment } from '../../gql/fragments/conceptionRateChartInseminationSchemeCandle.graphql';
import { ConceptionRateChartStudCodeCandleFragment } from '../../gql/fragments/conceptionRateChartStudCodeCandle.graphql';
import { ConceptionRateByParameterChartQuery } from '../../gql/queries/conceptionRateByParameterChart.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * CR chart data
   */
  conceptionRateChart?: ConceptionRateByParameterChartQuery['analytics']['conceptionRate'];
  /**
   * Is chart loading
   */
  isLoading?: boolean;
}

const CONCEPTION_RATE_CHART_HEIGHT_PX = 332;

const isConceptionRateChartBullCandle = (
  candle: AnyFragment
): candle is ConceptionRateChartBullCandleFragment =>
  candle.__typename === 'ConceptionRateChartBullCandle';

const isConceptionRateChartDaysInMilkCandle = (
  candle: AnyFragment
): candle is ConceptionRateChartDaysInMilkCandleFragment =>
  candle.__typename === 'ConceptionRateChartDaysInMilkCandle';

const isConceptionRateChartInseminationNumberCandle = (
  candle: AnyFragment
): candle is ConceptionRateChartInseminationNumberCandleFragment =>
  candle.__typename === 'ConceptionRateChartInseminationNumberCandle';

const isConceptionRateChartStudCodeCandle = (
  candle: AnyFragment
): candle is ConceptionRateChartStudCodeCandleFragment =>
  candle.__typename === 'ConceptionRateChartStudCodeCandle';

const isConceptionRateChartInseminationSchemeCandle = (
  candle: AnyFragment
): candle is ConceptionRateChartInseminationSchemeCandleFragment =>
  candle.__typename === 'ConceptionRateChartInseminationSchemeCandle';

const isConceptionRateChartEmployeeCandle = (
  candle: AnyFragment
): candle is ConceptionRateChartEmployeeCandleFragment =>
  candle.__typename === 'ConceptionRateChartEmployeeCandle';

export const ConceptionRateChart: React.FC<Props> = ({
  className,
  conceptionRateChart,
  isLoading,
}) => {
  const datasets = useMemo<ChartDataset<CandleChartDataPoint>[]>(() => {
    return [
      {
        label: 'conception rate',
        data: (conceptionRateChart?.candles ?? []).map(candle => ({
          x: match(candle)
            .with(P.when(isConceptionRateChartBullCandle), ({ bull }) =>
              formatBull(bull)
            )
            .with(
              P.when(isConceptionRateChartDaysInMilkCandle),
              matchedCandle => matchedCandle.daysInMilk.toString()
            )
            .with(
              P.when(isConceptionRateChartInseminationNumberCandle),
              matchedCandle => matchedCandle.inseminationNumber.toString()
            )
            .with(
              P.when(isConceptionRateChartStudCodeCandle),
              matchedCandle => matchedCandle.studCode
            )
            .with(
              P.when(isConceptionRateChartInseminationSchemeCandle),
              matchedCandle => matchedCandle.inseminationScheme?.name
            )
            .with(P.when(isConceptionRateChartEmployeeCandle), matchedCandle =>
              formatFullNameWithInitials(matchedCandle.employee)
            )
            .exhaustive(),
          y: candle.meanValue,
          max: candle.maxValue,
        })),
        color: TOKENS.colorSuccessDefault,
      },
    ];
  }, [conceptionRateChart]);

  return (
    <ChartCard
      {...{
        className,
        datasets,
        title: 'График Conception Rate',
        isLoading,
      }}
    >
      <CandleChart
        {...{
          legendClassName: 'my-16',
          datasets,
          height: CONCEPTION_RATE_CHART_HEIGHT_PX,
        }}
      />
    </ChartCard>
  );
};
