import { useCallback, useEffect, useMemo, useState } from 'react';

import { AnalyticalMetricEnum } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useApiFeedback } from '~/~legacy/hooks/useApiFeedback';
import { useService } from '~/~legacy/hooks/useService';
import {
  AnalyticsService,
  HerdMetricData,
  MetricFilters,
} from '~/~legacy/services/AnalyticsService';
import { MaslovServices } from '~/~legacy/types/services';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { MChartModal } from '~/shared/components/MChartModal';
import { AnalyticsGroupingPeriodEnum } from '~/shared/graphql';

import { useMyUser } from '~/services/auth';
import { PageHeader } from '~/services/layouts';
import { useArkaNavigation } from '~/services/navigation';

import {
  ChartDashboardCard,
  ExpandedChart,
  METRIC_CONFIGS,
  MetricPageFilters,
  useSetMetricTargetModal,
} from '~/features/analytics';

import layoutStyles from '~/styles/modules/layout.module.scss';

import styles from './index.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/metric/$metric'
)({
  wrapInSuspense: true,
  component: HerdStateMetricPage,
  params: {
    parse: rawParams => {
      const metric = Object.values(AnalyticalMetricEnum).includes(
        rawParams.metric
      )
        ? (rawParams.metric as AnalyticalMetricEnum)
        : AnalyticalMetricEnum.CowsCount;

      return { metric };
    },
    stringify: ({ metric }) => ({
      metric: metric.toString(),
    }),
  },
});

function HerdStateMetricPage() {
  const { metric } = Route.useParams();

  const analyticsSvc = useService<AnalyticsService>(
    MaslovServices.AnalyticsService
  );

  const { name } = METRIC_CONFIGS[metric];

  const [filters, setFilters] = useState<MetricFilters>({
    metric,
    groupingPeriod: AnalyticsGroupingPeriodEnum.Year,
  });

  const [data, setData] = useState<HerdMetricData>({
    metricChart: {
      bars: [],
      targets: [],
    },
  });

  const memoizedGet = useMemo(() => {
    return analyticsSvc.getHerdMetricDetails.bind(analyticsSvc);
  }, [analyticsSvc]);

  const { errors, loading, reload: loadData } = useApiData(memoizedGet);

  const loadMetricData = useCallback(
    async (newFilters: MetricFilters) => {
      const result = await loadData(newFilters);

      if (result.success) {
        const herdMerticData = result.data;

        setData(herdMerticData);
      }
    },
    [loadData, setData]
  );
  const refresh = useCallback(() => {
    loadData(filters);
  }, [filters, loadData]);

  useEffect(() => {
    loadMetricData(filters);
  }, [filters, loadMetricData]);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const { open: openSetMetricTargetModal } = useSetMetricTargetModal({
    metric,
    refresh,
  });

  const [chartiInfo, setChartInfo] = useState<ExpandedChart>();

  const openChartModal = useCallback(
    (cInfo: ExpandedChart) => {
      setChartInfo(cInfo);
    },
    [setChartInfo]
  );

  const closeChartModal = useCallback(() => {
    setChartInfo(undefined);
  }, [setChartInfo]);

  const { myUser } = useMyUser();
  const { selectedCompanyId } = useArkaNavigation();

  const farms = myUser.farms.filter(
    farm => farm.company.id === selectedCompanyId
  );

  const filtersComponent = (
    <MetricPageFilters filters={filters} farms={farms} onCahnge={setFilters} />
  );

  const chartModal = chartiInfo ? (
    <MChartModal close={closeChartModal} title={name}>
      {filtersComponent}
      <div className="my-24" />
      {chartiInfo.node}
    </MChartModal>
  ) : undefined;

  return (
    <div className={layoutStyles.limitedContainer}>
      {loader}
      <PageHeader
        {...{
          title: name,
          backLinkProps: {
            from: Route.fullPath,
            to: '/$companyId/user/analytics/herd',
            params: true,
          },
        }}
      />

      <div className={styles.filters}>
        {filtersComponent}
        <Button
          onPress={() => openSetMetricTargetModal()}
          className="mb-8"
          variant={ButtonVariants.secondary}
        >
          Установить цель
        </Button>
      </div>
      {errorMessage}
      <ChartDashboardCard
        chart={data.metricChart}
        groupingPeriod={filters.groupingPeriod}
        metric={metric}
        hideHeader
        className="my-8"
        expand={openChartModal}
        expanded={Boolean(chartiInfo)}
      />
      {chartModal}
    </div>
  );
}
