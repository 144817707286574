import React, { useState } from 'react';

import { createFileRoute } from '@tanstack/react-router';

import {
  getSingleSkeletonPlaceholder,
  Skeleton,
} from '~/shared/components/Skeleton';

import { useLayoutState } from '~/services/navigation';

import {
  isSomaticCellsReportDataEmpty,
  SomaticCellsReportCardBuilderCard,
  UpdateReportButton,
} from '~/features/somaticCells';
import { useSomaticCellsReportQuery } from '~/features/somaticCells/gql/queries/somaticCellsReport.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/milking/somatic-cells/'
)({
  wrapInSuspense: true,
  component: AnalyticsMilkingSomaticCellsPage,
});

function AnalyticsMilkingSomaticCellsPage() {
  const {
    data: somaticCellsReportData,
    refetch,
    loading: isSomaticCellsReportLoading,
  } = useSomaticCellsReportQuery();

  const somaticCellsReport =
    somaticCellsReportData?.somaticCellsReport ??
    getSingleSkeletonPlaceholder();

  const [reportKey, setReportKey] = useState(Math.random());

  useLayoutState({
    dependencies: [isSomaticCellsReportLoading],
    rightContent: isSomaticCellsReportDataEmpty(
      somaticCellsReport?.calculatedReport
    ) ? null : (
      <UpdateReportButton
        {...{
          createdAt: somaticCellsReport.calculatedReport?.cacheCreatedAt,
          refetch: () => refetch().then(() => setReportKey(Math.random())),
        }}
      />
    ),
  });

  return (
    <div className={layoutStyles.limitedContainer}>
      <Skeleton isLoading={isSomaticCellsReportLoading}>
        <SomaticCellsReportCardBuilderCard
          key={reportKey}
          somaticCellsReport={somaticCellsReport}
        />
      </Skeleton>
    </div>
  );
}
