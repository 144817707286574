import {
  CowState,
  LactationState,
  SourceFieldKindEnum,
  SourceSectionKindEnum,
} from '@graphql-types';

import { CowStaticFieldsSections, CowStaticSectionConfigs } from './types';

/**
 * Regexp for a herriot code validation
 */
export const HERRIOT_CODE_REGEXP = /(^RU.{9}$)|(^$)/;

/**
 * User error message for invalid herriot code
 */
export const HERRIOT_CODE_ERROR_MESSAGE =
  'В коде Хорриот должно быть 11 символов, первые два — RU';

/**
 * Dict with cow states human names
 */
export const COW_STATES_DICT: Record<CowState, string> = {
  [CowState.Bred]: 'Осеменённая',
  [CowState.Bull]: 'Бык',
  [CowState.Calf]: 'Тёлка',
  [CowState.Dead]: 'Мёртвое',
  [CowState.Dnb]: 'Брак',
  [CowState.Dry]: 'В сухостое',
  [CowState.Fresh]: 'Новотельная',
  [CowState.LateDry]: 'В позднем сухостое',
  [CowState.Open]: 'Готова к осеменению',
  [CowState.Preg]: 'Стельная',
  [CowState.Sold]: 'Продано',
  [CowState.Moved]: 'Перемещено',
};

/**
 * Dict with abbreviated cow states human names (useful for tables)
 */
export const COW_STATE_ABBREVIATIONS_DICT: Record<CowState, string> = {
  [CowState.Bred]: 'Осемен.',
  [CowState.Bull]: 'Бык',
  [CowState.Calf]: 'Тёлка',
  [CowState.Dead]: 'Мёртвое',
  [CowState.Dnb]: 'Брак',
  [CowState.Dry]: 'В сухостое',
  [CowState.Fresh]: 'Новотел.',
  [CowState.LateDry]: 'В поздн. сухост.',
  [CowState.Open]: 'Готова к осемен.',
  [CowState.Preg]: 'Стельная',
  [CowState.Sold]: 'Продано',
  [CowState.Moved]: 'Перемещено',
};

/**
 * Dict with cow lactation states
 */
export const LACTATION_STATE_DICT: Record<LactationState, string> = {
  [LactationState.Dry]: 'Сухостой',
  [LactationState.Early]: 'Начальная',
  [LactationState.Late]: 'Поздняя',
  [LactationState.LateDry]: 'Поздний сухостой',
  [LactationState.Mid]: 'Средняя',
  [LactationState.Zero]: 'Нулевая',
};

/**
 * Rendering configs for static fields hard coded sections
 */
export const COW_SECTIONS_CONFIGS: CowStaticSectionConfigs = {
  [CowStaticFieldsSections.status]: {
    name: 'Статус',
    fieldKinds: [SourceFieldKindEnum.CowState, SourceFieldKindEnum.CowComment],
    calculatedSectionKinds: [],
  },
  [CowStaticFieldsSections.identification]: {
    name: 'Идентификация',
    fieldKinds: [
      SourceFieldKindEnum.CowName,
      SourceFieldKindEnum.CowUsdaNumber,
      SourceFieldKindEnum.CowIdentifier,
      SourceFieldKindEnum.CowRegistrationNumber,
      SourceFieldKindEnum.CowElectronicTagNumber,
      SourceFieldKindEnum.CowCollarNumber,
      SourceFieldKindEnum.CowEarTagNumber,
      SourceFieldKindEnum.CowFarmId,
      SourceFieldKindEnum.CowPenGroupId,
      SourceFieldKindEnum.CowHerriotCode,
      SourceFieldKindEnum.CowHerriotCodeSetAt,
    ],
    calculatedSectionKinds: [],
  },
  [CowStaticFieldsSections.identificationHistory]: {
    name: 'История идентификации',
    fieldKinds: [
      SourceFieldKindEnum.CowOldIdentifier,
      SourceFieldKindEnum.CowNumberOnPreviousFarm,
      SourceFieldKindEnum.CowDcId,
      SourceFieldKindEnum.CowHerdEntryDate,
      SourceFieldKindEnum.CowRetirementReason,
      SourceFieldKindEnum.CowRetiredAt,
    ],
    calculatedSectionKinds: [],
  },
  [CowStaticFieldsSections.pedigree]: {
    name: 'Родословная',
    fieldKinds: [
      SourceFieldKindEnum.CowCalvingId,
      SourceFieldKindEnum.CowMotherId,
      SourceFieldKindEnum.CowFatherId,
      SourceFieldKindEnum.CowMotherDcId,
      SourceFieldKindEnum.CowFatherDcId,
      SourceFieldKindEnum.CowMotherName,
      SourceFieldKindEnum.CowFatherName,
      SourceFieldKindEnum.CowMotherUsdaNumber,
      SourceFieldKindEnum.CowFatherUsdaNumber,
    ],
    calculatedSectionKinds: [],
  },
  [CowStaticFieldsSections.animalData]: {
    name: 'Данные животного',
    fieldKinds: [
      SourceFieldKindEnum.CowDateOfBirth,
      SourceFieldKindEnum.CowBirthWeightKilograms,
      SourceFieldKindEnum.CowBreed,
      SourceFieldKindEnum.CowCurrentWeightKilograms,
      SourceFieldKindEnum.CowCurrentHeightCentimeters,
    ],
    calculatedSectionKinds: [],
  },
  [CowStaticFieldsSections.milk]: {
    name: 'Производство молока',
    fieldKinds: [
      SourceFieldKindEnum.CowCompNumber,
      SourceFieldKindEnum.CowTransponderNumber,
      SourceFieldKindEnum.CowNipplesCount,
    ],
    calculatedSectionKinds: [],
  },
  [CowStaticFieldsSections.health]: {
    name: 'Здоровье',
    fieldKinds: [SourceFieldKindEnum.CowLamenessScore],
    calculatedSectionKinds: [],
  },
  [CowStaticFieldsSections.common]: {
    name: 'Общая',
    fieldKinds: [
      SourceFieldKindEnum.CowPreviousState,
      SourceFieldKindEnum.CowPreviousPenGroupId,
      SourceFieldKindEnum.CowBloodProteinTestValue,
      SourceFieldKindEnum.CowBloodProteinTestHappenedAt,
      SourceFieldKindEnum.CowSelexBreedId,
    ],
    calculatedSectionKinds: [SourceSectionKindEnum.Common],
  },
};
