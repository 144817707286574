import React from 'react';

import { Link } from '@tanstack/react-router';
import clsx from 'clsx';

import { Skeleton } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import panelStyles from '~/styles/modules/panel.module.scss';

import 'chartjs-adapter-date-fns';

interface Props extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Chart title
   */
  title: string;
  /**
   * Is chart loading
   */
  isLoading?: boolean;

  /**
   * Optional url for detailed page
   */
  moreUrl?: string;
}

export const ChartCard: React.FC<Props> = ({
  className,
  title,
  isLoading,
  moreUrl,

  children,
}) => {
  return (
    <div className={clsx('p-16', panelStyles.panel, className)}>
      <div className="flex items-center justify-between mb-8">
        <Typography variant={TypographyVariants.bodyMediumStrong}>
          {title}
        </Typography>
        {!!moreUrl && (
          <Link
            {...{
              to: moreUrl,
              params: true,
              className: 'text-muted mx-8',
            }}
          >
            Подробнее
          </Link>
        )}
      </div>

      <Skeleton isLoading={isLoading}>
        <div className="position-relative">{children}</div>
      </Skeleton>
    </div>
  );
};
