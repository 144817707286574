import React from 'react';

import clsx from 'clsx';

import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { BlueprintRoleColor } from '~/shared/graphql';
import { ColorShades, getColorTokenValue } from '~/shared/helpers/color';
import { isTmpId } from '~/shared/helpers/string';

import { useArkaNavigation } from '~/services/navigation';

import { BLUEPRINT_ROLE_COLORS_CONFIGS } from '~/entities/blueprintRoles';
import { VIEW_KINDS_DICT } from '~/entities/blueprints';
import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';

import { BlueprintCardMenu } from '../BlueprintCardMenu';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Blueprint to render
   */
  blueprint: BlueprintFragment;

  /**
   * Called, when a blueprint is run
   */
  onRun?: () => void;
  /**
   * Called, when user clicks a button to delete blueprint
   */
  onDelete?: () => void;
}

export const BlueprintCard: React.FC<Props> = ({
  className,
  blueprint,
  onRun,
  onDelete,
}) => {
  const { isIntegratorInSelectedCompany: canEdit } = useArkaNavigation();

  const role = blueprint.blueprintRoles.at(0);
  const roleColor = role?.color ?? BlueprintRoleColor.Grey;
  const viewKind = blueprint.viewSettings.kind;

  return (
    <div
      {...{
        className: clsx('m-hover', styles.root, className),
        onClick: onRun,
      }}
    >
      <div className="flex">
        <Typography
          {...{
            variant: TypographyVariants.bodySmall,
            tag: 'div',
            className: 'mr-8',
            style: {
              color: getColorTokenValue(
                BLUEPRINT_ROLE_COLORS_CONFIGS[roleColor].colorVariant,
                ColorShades.default
              ),
            },
            title: role?.name,
          }}
        >
          {role?.name}
        </Typography>
        {canEdit && !isTmpId(blueprint.id) && (
          <BlueprintCardMenu
            {...{
              className: 'ml-a',
              onDelete,
              blueprint,
            }}
          />
        )}
      </div>
      <Typography
        variant={TypographyVariants.heading4}
        title={blueprint.name}
        className={styles.clampLines}
      >
        {blueprint.name}
      </Typography>
      <Typography
        variant={TypographyVariants.bodySmall}
        tag="div"
        className={clsx('-mt-4', styles.clampLines)}
      >
        {blueprint.description}
      </Typography>

      {viewKind && (
        <Typography
          variant={TypographyVariants.bodySmall}
          tag="div"
          className="text-muted mt-a"
        >
          {VIEW_KINDS_DICT[viewKind]}
        </Typography>
      )}
    </div>
  );
};
