import { CompareToPreviousPeriodMode } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { CUSTOM_MILKING_REPORT_PERIODS_DICT } from '../../../../constants';

const useCustomMilkingReportPeriodSelect = makeUseEnumSelect(
  CompareToPreviousPeriodMode,
  enumValue =>
    CUSTOM_MILKING_REPORT_PERIODS_DICT[enumValue as CompareToPreviousPeriodMode]
);

export const CustomMilkingReportPeriodSelect = makeSelectComponentFromHook(
  useCustomMilkingReportPeriodSelect
);
