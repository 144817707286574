import React from 'react';

import { CrData, FemaleAnimalKind } from '@graphql-types';
import clsx from 'clsx';

import { AsyncListProps } from '~/shared/components/AsyncList';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { formatDateRange } from '~/shared/helpers/date';
import { formatInt, formatWithPercent } from '~/shared/helpers/number';

import { InseminationsTable } from '../InseminationsTable';

interface Props extends Partial<AsyncListProps<CrData>> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Pregnancy rate table data
   */
  crData: CrData[];
  /**
   * Metric category filter
   */
  category: FemaleAnimalKind;
}

const PERIOD_COLUMN_WIDTH_PX = 204;
const CR_COLUMN_WIDTH_PX = 212;

const DEFAULT_COLUMN_WIDTH_PX = 108;

export const CRTable: React.FC<Props> = ({
  className,
  crData,
  category,
  ...asyncProps
}) => {
  const columnConfigs: TableColumnConfig<CrData>[] = [
    {
      title: 'Период',
      key: 'period',
      renderCellContent: ({ since, till }) => formatDateRange(since, till),
      width: PERIOD_COLUMN_WIDTH_PX,
    },
    {
      title: '% плодотворных осемен. (CR)',
      key: 'cr',
      renderCellContent: ({ cr }) => formatWithPercent(cr),
      width: CR_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Стельные',
      key: 'preg',
      renderCellContent: ({ preg }) => formatInt(preg),
      width: DEFAULT_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Нестельные',
      key: 'open',
      renderCellContent: ({ open }) => formatInt(open),
      width: DEFAULT_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Прочие',
      key: 'other',
      renderCellContent: ({ other }) => formatInt(other),
      width: DEFAULT_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Прерванные',
      key: 'abort',
      renderCellContent: ({ abort }) => formatInt(abort),
      width: DEFAULT_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Итого',
      key: 'total',
      renderCellContent: ({ total }) => formatInt(total),
      width: DEFAULT_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: '% к итогу',
      key: 'totalPct',
      renderCellContent: ({ totalPct }) => formatWithPercent(totalPct),
      width: DEFAULT_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
  ];

  return (
    <Table<CrData>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: crData,
        columnConfigs,
        getItemKey: ({ since, till }) => `${since}__${till}`,
        noItemsMessage: 'Нет данных для отображения',
        renderExpandableRowContent: ({ since, till }) => (
          <InseminationsTable
            {...{
              since,
              till,
              withRetiredAtColumn: false,
              category,
            }}
          />
        ),
        ...asyncProps,
      }}
    />
  );
};
