import React from 'react';

import { Checkbox } from '~/shared/components/Checkbox';
import { SelectThemes } from '~/shared/components/Select';

import { ReportCardBuilderFormArrayItem } from '~/services/reportCardBuilder';

import { CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS } from '../../../../constants';
import { formatYAxisFieldName } from '../../../../helpers';
import { CustomMilkingReportYAxisFieldFormType } from '../../../../types';
import { CustomMilkingReportYAxisFieldGroupingSelect } from '../CustomMilkingReportYAxisFieldGroupingSelect';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Field name prefix to use for form fields in array
   */
  fieldPrefix: `yAxisFields.${number}.`;
  /**
   * Y axis field config to render
   */
  yAxisFieldConfig: CustomMilkingReportYAxisFieldFormType;
  /**
   * Called, when user pressed delete button to remove the config
   */
  onDelete?: () => void;
}

export const YAxisFieldConfigArrayItemCard: React.FC<Props> = ({
  className,
  fieldPrefix,
  yAxisFieldConfig,
  onDelete,
}) => {
  return (
    <ReportCardBuilderFormArrayItem
      {...{
        className,
        title: formatYAxisFieldName(yAxisFieldConfig.kind),
        onDelete,
      }}
    >
      {CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS[yAxisFieldConfig.kind]
        .shouldHaveGroupingFormula && (
        <CustomMilkingReportYAxisFieldGroupingSelect
          {...{
            name: `${fieldPrefix}groupBy`,
            label: 'Формула группировки',
            theme: SelectThemes.light,
          }}
        />
      )}

      <Checkbox
        {...{
          name: `${fieldPrefix}withRightScale`,
          label: 'Отобразить на шкале справа',
        }}
      />
    </ReportCardBuilderFormArrayItem>
  );
};
