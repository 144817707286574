import React from 'react';
import { useFormContext } from 'react-hook-form';

import { MilkingHistoricFilterKind } from '@graphql-types';
import R from 'ramda';
import { match } from 'ts-pattern';

import { Select, SelectThemes } from '~/shared/components/Select';

import { useArkaNavigation } from '~/services/navigation';
import { ReportCardBuilderFormArrayItem } from '~/services/reportCardBuilder';

import { useDetailedCompany } from '~/entities/companies';
import { PenGroupAsyncSelect } from '~/entities/penGroups';

import {
  LactationGroupNumberSelect,
  MilkingHistoricFilterKindSelect,
} from '~/features/customMilkingReport';

import {
  SomaticCellsReportFilterFormType,
  SomaticCellsReportSettingsFormType,
} from '../../../../types';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Field name prefix to use for form fields in array
   */
  fieldPrefix: `filters.${number}.`;
  /**
   * Y axis field config to render
   */
  filterConfig: SomaticCellsReportFilterFormType;
  /**
   * Called, when user pressed delete button to remove the config
   */
  onDelete?: () => void;
}

export const SomaticCellsFilterConfigArrayItemCard: React.FC<Props> = ({
  className,
  fieldPrefix,
  filterConfig,
  onDelete,
}) => {
  const formContext = useFormContext<SomaticCellsReportSettingsFormType>();

  const { selectedCompany } = useArkaNavigation();

  const { isDetailedCompanyLoading, companyDetailed } =
    useDetailedCompany(selectedCompany);

  const commonHistoricFilterValueSelectProps = {
    label: 'Значение',
    isMulti: true,
    theme: SelectThemes.light,
  } as const;

  return (
    <ReportCardBuilderFormArrayItem
      {...{
        className,
        title: 'Фильтр по историческим значениям',
        onDelete,
      }}
    >
      <MilkingHistoricFilterKindSelect
        {...{
          name: `${fieldPrefix}kind`,
          label: 'Параметр фильтрации',
          theme: SelectThemes.light,
          onValueChange: newValue => {
            if (!newValue?.id) return;

            const valueToSet = match(newValue.id as MilkingHistoricFilterKind)
              .with(MilkingHistoricFilterKind.Lactation, () => ({
                lactationNumbers: [],
                lactationGroupNumbers: null,
                penGroupIDs: null,
              }))
              .with(MilkingHistoricFilterKind.LactationGroup, () => ({
                lactationNumbers: null,
                lactationGroupNumbers: [],
                penGroupIDs: null,
              }))
              .with(MilkingHistoricFilterKind.PenGroup, () => ({
                lactationNumbers: null,
                lactationGroupNumbers: null,
                penGroupIDs: [],
              }))
              .exhaustive();

            formContext.setValue(`${fieldPrefix}value`, valueToSet);
          },
        }}
      />
      {!!filterConfig.kind &&
        match(filterConfig.kind)
          .with(MilkingHistoricFilterKind.Lactation, () => (
            <Select
              {...{
                name: `${fieldPrefix}value.lactationNumbers`,
                ...commonHistoricFilterValueSelectProps,
                items: R.range(1, companyDetailed?.maxLactationNumber ?? 1).map(
                  lactationNumber => ({
                    id: lactationNumber,
                    name: `${lactationNumber}-я`,
                  })
                ),
                asyncProps: {
                  isLoading: isDetailedCompanyLoading,
                },
              }}
            />
          ))
          .with(MilkingHistoricFilterKind.PenGroup, () => {
            const fieldName = `${fieldPrefix}value.penGroupIDs` as const;
            return (
              <PenGroupAsyncSelect
                {...{
                  name: fieldName,
                  ...commonHistoricFilterValueSelectProps,
                }}
              />
            );
          })
          .with(MilkingHistoricFilterKind.LactationGroup, () => (
            <LactationGroupNumberSelect
              {...{
                name: `${fieldPrefix}value.lactationGroupNumbers`,
                ...commonHistoricFilterValueSelectProps,
              }}
            />
          ))
          .exhaustive()}
    </ReportCardBuilderFormArrayItem>
  );
};
