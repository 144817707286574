import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReproductionAnalyticsQueryVariables = Types.Exact<{
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  cowIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type ReproductionAnalyticsQuery = { __typename?: 'Query', analytics: { __typename?: 'Analytics', reproduction: { __typename?: 'Reproduction', hdrCowsMetric: { __typename: 'AnalyticalMetric', metric: Types.AnalyticalMetricEnum, target?: number | null, value?: number | null, delta?: number | null }, prCowsMetric: { __typename: 'AnalyticalMetric', metric: Types.AnalyticalMetricEnum, target?: number | null, value?: number | null, delta?: number | null }, crCowsMetric: { __typename: 'AnalyticalMetric', metric: Types.AnalyticalMetricEnum, target?: number | null, value?: number | null, delta?: number | null }, hdrHeifersMetric: { __typename: 'AnalyticalMetric', metric: Types.AnalyticalMetricEnum, target?: number | null, value?: number | null, delta?: number | null }, prHeifersMetric: { __typename: 'AnalyticalMetric', metric: Types.AnalyticalMetricEnum, target?: number | null, value?: number | null, delta?: number | null }, crHeifersMetric: { __typename: 'AnalyticalMetric', metric: Types.AnalyticalMetricEnum, target?: number | null, value?: number | null, delta?: number | null }, hdrCowsData: Array<{ __typename: 'HDRData', since: string, till: string, hdr: number, brElig: number, bred: number }>, prCowsData: Array<{ __typename: 'PRData', since: string, till: string, pr: number, prElig: number, preg: number, abort: number }>, crCowsData: Array<{ __typename: 'CRData', since: string, till: string, cr: number, preg: number, open: number, other: number, abort: number, total: number, totalPct: number }>, hdrHeifersData: Array<{ __typename: 'HDRData', since: string, till: string, hdr: number, brElig: number, bred: number }>, prHeifersData: Array<{ __typename: 'PRData', since: string, till: string, pr: number, prElig: number, preg: number, abort: number }>, crHeifersData: Array<{ __typename: 'CRData', since: string, till: string, cr: number, preg: number, open: number, other: number, abort: number, total: number, totalPct: number }> } } };


export const ReproductionAnalyticsDocument = gql`
    query reproductionAnalytics($farmIDs: [ID!], $cowIDs: [ID!]) {
  analytics(farmIDs: $farmIDs) {
    reproduction(cowIDs: $cowIDs) {
      hdrCowsMetric {
        __typename
        metric
        target
        value
        delta
      }
      prCowsMetric {
        __typename
        metric
        target
        value
        delta
      }
      crCowsMetric {
        __typename
        metric
        target
        value
        delta
      }
      hdrHeifersMetric {
        __typename
        metric
        target
        value
        delta
      }
      prHeifersMetric {
        __typename
        metric
        target
        value
        delta
      }
      crHeifersMetric {
        __typename
        metric
        target
        value
        delta
      }
      hdrCowsData {
        __typename
        since
        till
        hdr
        brElig
        bred
      }
      prCowsData {
        __typename
        since
        till
        pr
        prElig
        preg
        abort
      }
      crCowsData {
        __typename
        since
        till
        cr
        preg
        open
        other
        abort
        total
        totalPct
      }
      hdrHeifersData {
        __typename
        since
        till
        hdr
        brElig
        bred
      }
      prHeifersData {
        __typename
        since
        till
        pr
        prElig
        preg
        abort
      }
      crHeifersData {
        __typename
        since
        till
        cr
        preg
        open
        other
        abort
        total
        totalPct
      }
    }
  }
}
    `;

/**
 * __useReproductionAnalyticsQuery__
 *
 * To run a query within a React component, call `useReproductionAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReproductionAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReproductionAnalyticsQuery({
 *   variables: {
 *      farmIDs: // value for 'farmIDs'
 *      cowIDs: // value for 'cowIDs'
 *   },
 * });
 */
export function useReproductionAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<ReproductionAnalyticsQuery, ReproductionAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReproductionAnalyticsQuery, ReproductionAnalyticsQueryVariables>(ReproductionAnalyticsDocument, options);
      }
export function useReproductionAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReproductionAnalyticsQuery, ReproductionAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReproductionAnalyticsQuery, ReproductionAnalyticsQueryVariables>(ReproductionAnalyticsDocument, options);
        }
export function useReproductionAnalyticsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReproductionAnalyticsQuery, ReproductionAnalyticsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReproductionAnalyticsQuery, ReproductionAnalyticsQueryVariables>(ReproductionAnalyticsDocument, options);
        }
export type ReproductionAnalyticsQueryHookResult = ReturnType<typeof useReproductionAnalyticsQuery>;
export type ReproductionAnalyticsLazyQueryHookResult = ReturnType<typeof useReproductionAnalyticsLazyQuery>;
export type ReproductionAnalyticsSuspenseQueryHookResult = ReturnType<typeof useReproductionAnalyticsSuspenseQuery>;
export type ReproductionAnalyticsQueryResult = Apollo.QueryResult<ReproductionAnalyticsQuery, ReproductionAnalyticsQueryVariables>;