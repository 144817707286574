import React, { useEffect } from 'react';

import { createFileRoute, Outlet } from '@tanstack/react-router';

import { useService } from '~/~legacy/hooks/useService';
import { useServiceInitialization } from '~/~legacy/hooks/useServiceInitialization';
import { HTTPTransport } from '~/~legacy/services/HttpTransport';
import { MaslovServices, StandardServices } from '~/~legacy/types/services';

import { Loader } from '~/shared/components/Loader';

import { AppAnalyticsService } from '~/features/analytics';

import layoutStyles from '~/styles/modules/layout.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout'
)({
  wrapInSuspense: true,
  component: AnalyticsPageContainer,
});

function AnalyticsPageContainer() {
  const { initService, initialized } = useServiceInitialization();

  const http = useService<HTTPTransport>(StandardServices.Http);

  useEffect(() => {
    const analyticsSvc = new AppAnalyticsService(http);

    initService(MaslovServices.AnalyticsService, analyticsSvc);
  }, []);

  return initialized ? (
    <Outlet />
  ) : (
    <Loader className={layoutStyles.fullHeightContainer} />
  );
}
