import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { CloseButton, CloseButtonSizes } from '~/shared/components/CloseButton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import styles from './index.module.scss';

interface Props extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Title of the card
   */
  title?: string;
  /**
   * Called, when the remove button is pressed
   */
  onDelete?: () => void;
}

export const ReportCardBuilderFormArrayItem: React.FC<Props> = ({
  className,

  title,
  onDelete,

  children,
}) => {
  const hasChildren = Array.isArray(children)
    ? children.some(R.identity)
    : !!children;

  return (
    <div className={clsx(styles.root, className)}>
      <div className="flex items-start">
        <Typography
          className="pr-4"
          variant={TypographyVariants.bodySmallStrong}
        >
          {title}
        </Typography>
        <div className={clsx('flex items-center ml-a', styles.closeButton)}>
          <CloseButton
            {...{
              size: CloseButtonSizes.medium20,
              onPress: onDelete,
            }}
          />
        </div>
      </div>
      {hasChildren && (
        <div className="flex flex-col gap-12 mt-12">{children}</div>
      )}
    </div>
  );
};
