import { Chart, ChartOptions, ScaleOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ColorShades, makeColorIterator } from '~/shared/helpers/color';
import { formatInt, formatWithPercent } from '~/shared/helpers/number';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import { ColorVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';

import { getChartOptions, getScaleOptions } from './helpers';

Chart.register(ChartDataLabels);

export const BARS_BORDER_RADIUS_PX = NUMBER_TOKENS.borderRadius4;

const CHART_DATASET_COLOR_VARIANTS = [
  ColorVariants.status05,
  ColorVariants.info,
  ColorVariants.success,
  ColorVariants.accent,
  ColorVariants.status04,
  ColorVariants.status01,
  ColorVariants.status06,
  ColorVariants.error,
  ColorVariants.status02,
  ColorVariants.status03,
];

/**
 * Iterator for chart colors
 */
export const CHART_DATASET_COLORS_ITERATOR = makeColorIterator(
  CHART_DATASET_COLOR_VARIANTS,
  [ColorShades.soft, ColorShades.muted, ColorShades.default]
);

/**
 * Iterator for chart hover colors
 */
export const CHART_DATASET_HOVER_COLORS_ITERATOR = makeColorIterator(
  CHART_DATASET_COLOR_VARIANTS,
  [ColorShades.hover]
);

/**
 * Iterator for chart pressed colors
 */
export const CHART_DATASET_PRESSED_COLORS_ITERATOR = makeColorIterator(
  CHART_DATASET_COLOR_VARIANTS,
  [ColorShades.active]
);

/**
 * Iterator for chart skipped segment colors
 */
export const CHART_DATASET_SKIPPED_COLORS_ITERATOR = makeColorIterator(
  CHART_DATASET_COLOR_VARIANTS,
  [100]
);

/**
 * Dash config, used to display segments without data
 */
export const MISSING_SEGMENTS_DASH = [6, 6];

/**
 * Zoom plugin min range for x axis
 */
export const CHART_X_SCALE_MIN_RANGE = 5;

/**
 * Zoom plugin min range for y axis
 */
export const CHART_Y_SCALE_MIN_RANGE = 10;

/**
 * Default options to enable zoom on a chart
 */
export const DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS = {
  wheel: {
    enabled: true,
    speed: 0.01,
  },
  pinch: {
    enabled: true,
  },
  mode: 'xy',
} as const;

/**
 * Default options to enable pan on a chart
 */
export const DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS = {
  enabled: true,
  mode: 'xy',
} as const;

/**
 * Options, applied to each chart
 */
export const GLOBAL_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'nearest',
  },
  layout: {
    autoPadding: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      position: 'cursor',
    },
    datalabels: {
      display: false,
    },
  },
} satisfies ChartOptions;

/**
 * Options, applied to each scale
 */
export const GLOBAL_SCALE_OPTIONS = {
  ticks: {
    color: TOKENS.colorFgMuted,
    count: 5,
    precision: 0,
  },
  grid: {
    display: false,
    color: TOKENS.colorBorderMuted,
    tickColor: 'transparent',
  },
  border: {
    display: false,
    color: TOKENS.colorBorderMuted,
  },
  title: {
    color: TOKENS.colorFgMuted,
  },
} satisfies ScaleOptions;

/**
 * Default options for a scale on a bar chart
 */
export const LINEAR_Y_SCALE_OPTIONS = {
  stacked: false,
  grid: {
    display: true,
  },
  ticks: {
    callback: value => formatInt(value),
  },
  // Needed as a workaround to apply paddings to ticks
  // https://github.com/chartjs/Chart.js/issues/9531#issuecomment-2153035775
  afterUpdate: axis => {
    // eslint-disable-next-line no-param-reassign
    axis.paddingTop = NUMBER_TOKENS.size12;
    // eslint-disable-next-line no-param-reassign
    axis.paddingBottom = NUMBER_TOKENS.size12;
  },
} satisfies ScaleOptions;

/**
 * Default options for a scale on a percent bar chart
 */
export const PERCENT_LINEAR_Y_SCALE_OPTIONS = getScaleOptions(
  LINEAR_Y_SCALE_OPTIONS,
  {
    min: 0,
    max: 100,
    ticks: {
      stepSize: 20,
      callback: value => formatWithPercent(value),
    },
  }
) satisfies ScaleOptions;

/**
 * Options for a chart with bars
 */
export const BARS_CHART_OPTIONS = {
  scales: {
    x: getScaleOptions(),
    y: getScaleOptions(LINEAR_Y_SCALE_OPTIONS),
  },
} satisfies ChartOptions;

/**
 * Options for a chart with bars and percentage values
 */
export const PERCENT_BARS_CHART_OPTIONS = getChartOptions(BARS_CHART_OPTIONS, {
  plugins: {
    tooltip: {
      callbacks: {
        label: context => formatWithPercent(context.parsed.y),
      },
    },
  },
  scales: {
    y: PERCENT_LINEAR_Y_SCALE_OPTIONS,
  },
} satisfies ChartOptions);

/**
 * Options for a chart with dots
 */
export const SCATTER_CHART_OPTIONS = {
  interaction: {
    mode: 'point',
  },
  scales: {
    x: getScaleOptions({
      grid: {
        display: true,
      },
    }),
    y: getScaleOptions(LINEAR_Y_SCALE_OPTIONS),
  },
} satisfies ChartOptions;

/**
 * Options for a chart with lines
 */
export const LINE_CHART_OPTIONS = {
  interaction: {
    mode: 'point',
  },
  scales: {
    x: getScaleOptions({
      grid: {
        display: true,
      },
    }),
    y: getScaleOptions(LINEAR_Y_SCALE_OPTIONS),
  },
} satisfies ChartOptions;

/**
 * Options for a chart with pie
 */
export const PIE_CHART_OPTIONS = {
  interaction: {
    mode: 'point',
  },
  layout: {
    padding: NUMBER_TOKENS.spacing40,
  },
  plugins: {
    datalabels: {
      display: context => {
        const value = context.dataset.data[context.dataIndex] ?? 0;
        return Number.isNaN(value) ? false : 'auto';
      },
      color: TOKENS.colorFgSoft,
      align: 'end',
      anchor: 'end',
      offset: NUMBER_TOKENS.spacing12,
    },
  },
} satisfies ChartOptions;
