import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useApiFeedback } from '~/~legacy/hooks/useApiFeedback';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import { Button, ButtonVariants } from '~/shared/components/Button';

import {
  ActionNode,
  BlueprintActionNode,
  BlueprintEditService,
  BlueprintFilterGroupNode,
  BlueprintNodeType,
  BlueprintUnionNode,
  FilterGroupNode,
  getEditElement,
  UnionNode,
} from '~/features/blueprintEdit';

import styles from './index.module.scss';

export const ActionsSection: React.FC = () => {
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const [state, setState] = useState({
    addBlockType: BlueprintNodeType.None,
    filterGroups: [] as BlueprintFilterGroupNode[],
    actions: [] as BlueprintActionNode[],
    unions: [] as BlueprintUnionNode[],
    hasUnion: false,
    hasAction: false,
    hasManyFilterGroups: false,
  });

  useEffect(() => {
    const filtersSubscription = bpEditSvc.blueprint?.filterNodes$.subscribe(
      filterGroups => {
        const newFilterGroups = filterGroups.filter(
          filterGroup => !filterGroup.parentFilterGroupId
        );

        setState(prev => {
          return {
            ...prev,
            filterGroups: newFilterGroups,
            hasManyFilterGroups:
              newFilterGroups.filter(item => !item.unionId).length > 1,
          };
        });
      }
    );

    const actionsSubscription = bpEditSvc.blueprint?.actionNodes$.subscribe(
      actions => {
        const hasAction = actions.length > 0;

        setState(prev => {
          return {
            ...prev,
            actions: actions.filter(action => !action.filterGroupId),
            hasAction,
          };
        });
      }
    );

    const unionsSubscription = bpEditSvc.blueprint?.unionNodes$.subscribe(
      unions => {
        const hasUnion = unions.length > 0;

        setState(prev => {
          return {
            ...prev,
            unions,
            hasUnion,
          };
        });
      }
    );

    return () => {
      actionsSubscription?.unsubscribe();
      filtersSubscription?.unsubscribe();
      unionsSubscription?.unsubscribe();
    };
  }, [bpEditSvc, setState]);

  const memoizedCreateUnion = useMemo(() => {
    return bpEditSvc.createUnion.bind(bpEditSvc);
  }, [bpEditSvc.createUnion]);

  const {
    errors,
    loading,
    reload: createUnion,
  } = useApiData(memoizedCreateUnion);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const addBlock = (type: BlueprintNodeType) => {
    setState({
      ...state,
      addBlockType: type,
    });
  };

  const unionGroups = state.unions.map(union => (
    <UnionNode
      key={union.id}
      union={union}
      filterGroup={state.filterGroups.find(x => x.unionId === union.id)}
    />
  ));

  const filterGroups = state.filterGroups
    .filter(filter => !filter.unionId)
    .map(filter => (
      <FilterGroupNode
        key={filter.id}
        filterGroup={filter}
        topLevel={Boolean(!filter.parentFilterGroupId)}
      />
    ));
  const actions = state.actions.map(action => (
    <ActionNode key={action.id} action={action} />
  ));

  const cancelCallback = useCallback(() => {
    setState(prevState => {
      return {
        ...prevState,
        addBlockType: BlueprintNodeType.None,
      };
    });
  }, [setState]);

  const editElement = getEditElement(state.addBlockType, cancelCallback);

  const getUnionTooltip = useCallback(
    (hasAction: boolean, hasManyFilterGroups: boolean) => {
      if (hasAction)
        return "Оператор 'Группирока' нельзя использовать вместе с 'Действием' в одном блюпринте. После удаления 'Действия' вы сможете добавить 'Группировку'";
      if (hasManyFilterGroups)
        return 'Для добавления UNION оставьте только одну группу фильтров';
      return undefined;
    },
    []
  );

  const unionButtonTooltip = getUnionTooltip(
    state.hasAction,
    state.hasManyFilterGroups
  );
  const filterFroupButtonTooltip = state.hasUnion
    ? "Для добавления новой группы фильтров нужно удалить 'Группировку' или создать группу фильтров внутри одного из созданных блоков 'Группировка'"
    : undefined;
  const actionButtonTooltip = state.hasUnion
    ? "'Действие' нельзя использовать вместе с оператором Группировка в одном блюпринте. После удаления 'Группировки' вы сможете добавить 'Действие'"
    : undefined;

  return (
    <fieldset className={styles.root}>
      <legend>Действия</legend>
      <Suspense>
        <div className={styles.sectionItems}>
          {unionGroups}
          {filterGroups}
          {actions}
          {editElement}

          {errorMessage}
          {loader}
        </div>
        <div className={styles.controls}>
          <Button
            className={styles.button}
            isDisabled={state.hasUnion}
            tooltip={filterFroupButtonTooltip}
            variant={ButtonVariants.secondary}
            onPress={() => {
              addBlock(BlueprintNodeType.FilterGroup);
            }}
          >
            Фильтр
          </Button>
          <Button
            isDisabled={state.hasAction || state.hasManyFilterGroups}
            tooltip={unionButtonTooltip}
            variant={ButtonVariants.secondary}
            className={styles.button}
            onPress={() => createUnion(undefined)}
          >
            Группировка
          </Button>
          <Button
            className={styles.button}
            isDisabled={state.hasUnion}
            tooltip={actionButtonTooltip}
            variant={ButtonVariants.secondary}
            onPress={() => {
              addBlock(BlueprintNodeType.Action);
            }}
          >
            Действие
          </Button>
        </div>
      </Suspense>
    </fieldset>
  );
};
