import { IconVariants } from '~/shared/components/Icon';

import { NavigationMenuSectionConfig } from './types';

/**
 * Main app navigation menu configuration
 */
export const ARKA_NAVIGATION_MENU_CONFIG: NavigationMenuSectionConfig[] = [
  {
    path: '/$companyId/user/production-calendar',
    label: 'Календарь',
    iconVariant: IconVariants.calendar,
  },
  {
    path: '/$companyId/user/incidents',
    label: 'Команды',
    iconVariant: IconVariants.warningCircle,
  },
  {
    path: '/$companyId/user/analytics',
    label: 'Аналитика',
    iconVariant: IconVariants.analytics,
    children: [
      {
        path: '/$companyId/user/analytics/livestock-forecast',
        label: 'Прогноз поголовья',
      },
      {
        path: '/$companyId/user/analytics/herd',
        label: 'Статус стада',
      },
      {
        path: '/$companyId/user/analytics/reproduction',
        label: 'Воспроизводство',
      },
      {
        path: '/$companyId/user/analytics/milking',
        label: 'Надои',
      },
      {
        path: '/$companyId/user/analytics/monitor',
        label: 'Показатели',
      },
      {
        path: '/$companyId/user/analytics/custom-reports',
        label: 'Отчёты',
      },
    ],
  },
  {
    path: '/$companyId/user/herd',
    label: 'Стадо',
    iconVariant: IconVariants.cow,
    children: [
      {
        path: '/$companyId/user/herd/cows',
        label: 'Животные',
      },
      {
        path: '/$companyId/user/herd/penGroups',
        label: 'Группы животных',
      },
      {
        path: '/$companyId/user/herd/bulls',
        label: 'Генетика',
      },
    ],
  },
  {
    path: '/$companyId/user/entities',
    label: 'Управление',
    iconVariant: IconVariants.clipboardList,
    children: [
      {
        path: '/$companyId/user/entities/milking-parlors',
        label: 'Доильные залы',
      },
      {
        path: '/$companyId/user/entities/milkings',
        label: 'Контрольные дойки',
      },
      {
        path: '/$companyId/user/entities/semenDoses',
        label: 'Поставка семени',
      },
    ],
  },
  {
    path: '/$companyId/user/events',
    label: 'События',
    iconVariant: IconVariants.clock,
    children: [
      {
        path: '/$companyId/user/events/all',
        label: 'Все события',
      },
      {
        path: '/$companyId/user/events/cow-events',
        label: 'История событий',
      },
      {
        path: '/$companyId/user/events/commands',
        label: 'История команд',
      },
    ],
  },
];
