import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { isCompanyDetailed } from '../helpers';
import { AnyCompanyFragment } from '../types';
import { useCompaniesDetailedPaginatedQuery } from './useCompaniesDetailedPaginatedQuery';

/**
 * Gets a company from query, if passed company is not detailed
 */
export const useDetailedCompany = (company?: AnyCompanyFragment) => {
  const {
    items: companiesDetailedItems,
    itemsPromise: companiesDetailedItemsPromise,
    isLoading: isDetailedCompanyLoading,
  } = useCompaniesDetailedPaginatedQuery({
    variables: { ids: normalizeToArrayOrUndefined(company?.id) },
    skip: !company || isCompanyDetailed(company),
  });

  const companyDetailed = isCompanyDetailed(company)
    ? company
    : companiesDetailedItems.at(0);

  const companyDetailedPromise = isCompanyDetailed(company)
    ? Promise.resolve(company)
    : companiesDetailedItemsPromise.then(res => res.at(0));

  return {
    isDetailedCompanyLoading,
    companyDetailed,
    companyDetailedPromise,
  };
};
