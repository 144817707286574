import React, { useState } from 'react';

import {
  AnalyticalMetricEnum,
  ConceptionRateParameterEnum,
} from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { EnumStrings } from '~/~legacy/strings/enumStrings';

import { makeUseEnumSelect, SelectThemes } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';
import { capitalize } from '~/shared/helpers/string';

import { PageHeader } from '~/services/layouts';
import { WithSearchParamsValidation } from '~/services/navigation';

import {
  AnalyticsSearchParams,
  useAnalyticsFilters,
} from '~/features/analytics';
import { useReproductionAnalyticsQuery } from '~/features/analyticsReproduction/gql/queries/reproductionAnalytics.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { ConceptionRateChart } from './components/ConceptionRateChart';
import { ReproductionChartBlock } from './components/ReproductionChartBlock';
import { REPRODUCTION_CHART_CONFIGS } from './constants';
import { useConceptionRateByParameterChartQuery } from './gql/queries/conceptionRateByParameterChart.graphql';
import { ReproductionChartTypes } from './types';

const REPRODUCTION_METRICS_COWS = [
  AnalyticalMetricEnum.HeatDetectionRate,
  AnalyticalMetricEnum.PregnancyRate,
  AnalyticalMetricEnum.ConceptionRate,
];

const REPRODUCTION_METRICS_HEIFERS = [
  AnalyticalMetricEnum.HeatDetectionRateHeifers,
  AnalyticalMetricEnum.PregnancyRateHeifers,
  AnalyticalMetricEnum.ConceptionRateHeifers,
];

const useConceptionRateParameterSelect = makeUseEnumSelect(
  ConceptionRateParameterEnum,
  EnumStrings.conceptionRateParameter
);

const useReproductionChartTypesSelect = makeUseEnumSelect(
  ReproductionChartTypes,
  enumValue => capitalize(REPRODUCTION_CHART_CONFIGS[enumValue].label)
);

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/reproduction/'
)({
  wrapInSuspense: true,
  component: AnalyticsReproductionPage,
  validateSearch: ({
    cowIds,
    farmId,
  }: WithSearchParamsValidation<AnalyticsSearchParams>) => ({
    cowIds: Array.isArray(cowIds) ? cowIds : [],
    farmId: farmId ?? undefined,
  }),
});

function AnalyticsReproductionPage() {
  const {
    farmId,
    renderFarmsSelectElement,

    cowIds,
    renderCowSelectElement,
  } = useAnalyticsFilters();

  // Chart types filter
  const [chartTypes, setChartTypes] = useState(
    Object.values(ReproductionChartTypes)
  );
  const { renderSelectElement: renderChartTypesSelectElement } =
    useReproductionChartTypesSelect({
      label: 'Фильтр по графикам',
      className: 'default-control',
      placeholder: 'Выберите график',
      name: 'chartTypes',
      theme: SelectThemes.light,
      isMulti: true,
      rawValue: chartTypes,
      onValueChange: newValue =>
        setChartTypes(newValue.map(R.prop('id')) as ReproductionChartTypes[]),
    });

  const {
    data: reproductionAnalyticsQueryData,
    loading: isReproductionLoading,
  } = useReproductionAnalyticsQuery({
    variables: {
      farmIDs: normalizeToArrayOrUndefined(farmId),
      cowIDs: normalizeToArrayOrUndefined(cowIds),
    },
  });

  const {
    hdrHeifersMetric,
    prHeifersMetric,
    crHeifersMetric,

    hdrHeifersData = [],
    prHeifersData = [],
    crHeifersData = [],

    hdrCowsMetric,
    prCowsMetric,
    crCowsMetric,

    hdrCowsData = [],
    prCowsData = [],
    crCowsData = [],
  } = reproductionAnalyticsQueryData?.analytics.reproduction ?? {};

  // CR by parameters
  const [conceptionRateParameter, setConceptionRateParameter] =
    useState<ConceptionRateParameterEnum>(ConceptionRateParameterEnum.Bull);

  const { renderSelectElement: renderConceptionRateParameterSelectElement } =
    useConceptionRateParameterSelect({
      rawValue: conceptionRateParameter,
      className: 'default-control',
      label: 'Параметры',
      name: 'conceptionRateParameter',
      theme: SelectThemes.light,
      onValueChange: parameter =>
        setConceptionRateParameter(
          (parameter?.id as ConceptionRateParameterEnum) ??
            ConceptionRateParameterEnum.Bull
        ),
    });

  const {
    data: conceptionRateByParameterChartData,
    loading: isConceptionRateLoading,
  } = useConceptionRateByParameterChartQuery({
    variables: {
      farmIDs: normalizeToArrayOrUndefined(farmId),
      parameter: conceptionRateParameter,
    },
  });
  const conceptionRateByParameterChart =
    conceptionRateByParameterChartData?.analytics.conceptionRate;

  return (
    <>
      <PageHeader
        className={layoutStyles.limitedContainer}
        title="Воспроизводство"
      />

      <div
        className={clsx(
          layoutStyles.limitedContainer,
          'flex gap-16 items-center mb-24'
        )}
      >
        {renderFarmsSelectElement()}
        {renderCowSelectElement()}
        {renderChartTypesSelectElement()}
      </div>
      <div className={layoutStyles.limitedContainer}>
        <Typography variant={TypographyVariants.heading2} className="mb-16">
          Тёлки
        </Typography>
        <ReproductionChartBlock
          {...{
            className: 'mb-40',
            chartTypes,
            hdrData: hdrHeifersData,
            prData: prHeifersData,
            crData: crHeifersData,
            metricsToDisplay: REPRODUCTION_METRICS_HEIFERS,
            metrics: [hdrHeifersMetric, prHeifersMetric, crHeifersMetric],
            farmId,
            isLoading: isReproductionLoading,
          }}
        />
        <Typography variant={TypographyVariants.heading2} className="mb-16">
          Коровы
        </Typography>
        <ReproductionChartBlock
          {...{
            className: 'mb-40',
            chartTypes,
            hdrData: hdrCowsData,
            prData: prCowsData,
            crData: crCowsData,
            metricsToDisplay: REPRODUCTION_METRICS_COWS,
            metrics: [hdrCowsMetric, prCowsMetric, crCowsMetric],
            farmId,
            isLoading: isReproductionLoading,
          }}
        />
      </div>
      <div className={layoutStyles.limitedContainer}>
        <Typography variant={TypographyVariants.heading2} className="mb-16">
          Все животные
        </Typography>
        <div className="flex items-center mb-16">
          {renderConceptionRateParameterSelectElement()}
        </div>
        <ConceptionRateChart
          {...{
            conceptionRateChart: conceptionRateByParameterChart,
            isLoading: isConceptionRateLoading,
          }}
        />
      </div>
    </>
  );
}
