import React, { useMemo } from 'react';

import {
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  TimeScale,
  Tooltip,
} from 'chart.js';

import { Skeleton } from '~/shared/components/Skeleton';

import {
  BARS_BORDER_RADIUS_PX,
  PERCENT_BARS_CHART_OPTIONS,
} from '../../constants';
import { getChartOptions } from '../../helpers';
import { ChartDataset } from '../../types';
import {
  ReactChart,
  ReactChartDatasetConfig,
  ReactChartProps,
} from '../ReactChart';
import { CandleController } from './controller';

Chart.register(...registerables);
Chart.register(LineController);
Chart.register(Tooltip);
Chart.register(Filler);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(CategoryScale);
Chart.register(TimeScale);
Chart.register(LinearScale);
Chart.register(BarElement);

Chart.register(CandleController);

const CHART_OPTIONS = getChartOptions<'candle'>(PERCENT_BARS_CHART_OPTIONS, {
  datasets: {
    candle: { borderRadius: BARS_BORDER_RADIUS_PX },
  },
  animations: {
    candleHeight: {
      from: 0,
    },
  },
});

/**
 * Data item for the candle chart
 */
export interface CandleChartDataPoint {
  x: string | undefined;
  y: number;
  max: number;
}

interface Props
  extends Partial<ReactChartProps<'candle', CandleChartDataPoint[]>> {
  /**
   * Chart data
   */
  datasets: ChartDataset<CandleChartDataPoint>[];
}

export const CandleChart: React.FC<Props> = ({ datasets, ...chartProps }) => {
  const datasetConfigs = useMemo<
    ReactChartDatasetConfig<'candle', CandleChartDataPoint[]>[]
  >(
    () =>
      datasets.map(dataset => ({
        label: dataset.label,
        type: 'candle',
        data: dataset.data,
        key: dataset.color,
        color: dataset.color,
        backgroundColor: dataset.color,
      })),
    [datasets]
  );

  return (
    <ReactChart
      {...{
        options: CHART_OPTIONS,
        type: 'candle',
        datasetIdKey: 'first',
        datasetConfigs,
        skeleton: <Skeleton.BarChart />,
        ...chartProps,
      }}
    />
  );
};
