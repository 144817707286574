import React from 'react';

import { createFileRoute, Outlet } from '@tanstack/react-router';

import { AuthBaseLayout } from '~/services/layouts';

export const Route = createFileRoute('/$companyId/_layout')({
  component: LayoutComponent,
});

function LayoutComponent() {
  return (
    <AuthBaseLayout>
      <Outlet />
    </AuthBaseLayout>
  );
}
