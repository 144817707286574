import R from 'ramda';
import { match, P } from 'ts-pattern';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import { omitTypename } from '~/services/gql';

import { CustomReportChartSettingsFragment } from '~/entities/customReports/gql/fragments/customReportChartSettings.graphql';
import { CustomReportChartXAxisFragment } from '~/entities/customReports/gql/fragments/customReportChartXAxis.graphql';
import { CustomReportChartYAxisFragment } from '~/entities/customReports/gql/fragments/customReportChartYAxis.graphql';

import { mapCustomReportValueKeyToForm } from '~/features/customReportPivot';

import { CUSTOM_REPORT_CHART_FORM_SCHEMA } from './constants';
import {
  CustomReportChartData,
  CustomReportChartSettingsFormType,
} from './types';

/**
 * Checks, if the calculated chart data returned actual chart
 */
export const isValidCustomReportChart = (
  chartData?: CustomReportChartData | null
) =>
  chartData?.__typename === 'CustomReportChart' ||
  chartData?.__typename === 'CustomReportChartMultipleValues';

/**
 * Checks, if the chart is default chart
 */
export const isCustomReportChart = (
  chartData?: CustomReportChartData | SkeletonPlaceholder
) => chartData?.__typename === 'CustomReportChart';

const mapCustomReportXAxisToForm = (
  xAxis: CustomReportChartXAxisFragment | undefined | null
) =>
  match(xAxis)
    .with(
      { __typename: 'CustomReportChartFieldAxis' },
      ({ blueprintSourceField }) => ({
        blueprintSourceFieldID: blueprintSourceField.id,
        valueKey: undefined,
      })
    )
    .with({ __typename: 'CustomReportChartValueAxis' }, ({ valueKey }) => ({
      blueprintSourceFieldID: undefined,
      valueKey: mapCustomReportValueKeyToForm(valueKey),
    }))
    .with(P.nullish, R.always(null))
    .exhaustive();

const mapCustomReportYAxisToForm = (yAxis: CustomReportChartYAxisFragment) =>
  match(yAxis)
    .with(
      { __typename: 'CustomReportChartFieldValueAxis' },
      ({ blueprintSourceFieldValue, aggFormula = null, withRightScale }) => ({
        blueprintSourceFieldValue: omitTypename(blueprintSourceFieldValue),
        valueKey: undefined,
        aggFormula,
        withRightScale,
      })
    )
    .with(
      { __typename: 'CustomReportChartAggValueAxis' },
      ({ valueKey, aggFormula = null, withRightScale }) => ({
        blueprintSourceFieldValue: undefined,
        valueKey: mapCustomReportValueKeyToForm(valueKey),
        aggFormula,
        withRightScale,
      })
    )
    .exhaustive();

/**
 * Maps custom report chart settings fragment into settings form fields representation
 */
export const mapCustomReportChartSettingsToForm = (
  chartSettings:
    | CustomReportChartSettingsFragment
    | SkeletonPlaceholder
    | undefined
    | null
): CustomReportChartSettingsFormType => ({
  ...CUSTOM_REPORT_CHART_FORM_SCHEMA.getDefault(),
  ...(!chartSettings || isSkeletonPlaceholder(chartSettings)
    ? {}
    : R.pick(['name', 'kind'], chartSettings)),
  ...wrapConditionalObjectElement(
    !!chartSettings &&
      !isSkeletonPlaceholder(chartSettings) && {
        xAxis: mapCustomReportXAxisToForm(chartSettings.xAxis),
        yAxes: chartSettings.yAxes.map(mapCustomReportYAxisToForm),
      }
  ),
});
