import {
  CustomMilkingReportXAxisMode,
  CustomMilkingReportXAxisStep,
} from '@graphql-types';
import R from 'ramda';
import { match } from 'ts-pattern';

import {
  getSkeletonPlaceholders,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { formatDate } from '~/shared/helpers/date';
import { formatInt } from '~/shared/helpers/number';

import { CustomMilkingReportChartFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportChart.graphql';

import { CUSTOM_MILKING_REPORT_X_AXIS_STEPS_DICT } from '../../constants';
import { CustomMilkingReportSettingsFormType } from '../../types';

const SKELETON_NESTED_COLUMNS_COUNT = 2;
const SKELETON_ROWS_COUNT = 8;

/**
 * Gets the chart x axis name or table main header for the custom milking report
 */
export const getXAxisName = (
  requestedSettings: CustomMilkingReportSettingsFormType
) =>
  match(requestedSettings.mode)
    .with(
      CustomMilkingReportXAxisMode.TestMilkingNumber,
      R.always('Номер контрольной дойки')
    )
    .with(CustomMilkingReportXAxisMode.ByDate, R.always('Дата'))
    .with(
      CustomMilkingReportXAxisMode.ByDayInMilk,
      () =>
        CUSTOM_MILKING_REPORT_X_AXIS_STEPS_DICT[
          requestedSettings.step ?? CustomMilkingReportXAxisStep.Day
        ]
    )
    .exhaustive();

/**
 * Gets the chart x axis labels or table nested column names
 */
export const getXAxisLabels = (
  reportData: CustomMilkingReportChartFragment | SkeletonPlaceholder
) =>
  match(reportData.xAxisLabels)
    .with(
      { __typename: 'CustomMilkingReportChartXAxisDateLabels' },
      ({ xAxisDateLabels }) =>
        xAxisDateLabels.map(dateLabel => formatDate(dateLabel))
    )
    .with(
      { __typename: 'CustomMilkingReportChartXAxisIntLabels' },
      ({ xAxisIntLabels }) =>
        xAxisIntLabels.map(intLabel => formatInt(intLabel))
    )
    .otherwise(
      R.always(
        getSkeletonPlaceholders(SKELETON_NESTED_COLUMNS_COUNT).map(R.prop('id'))
      )
    );

/**
 * Gets report datasets prepared for rendering as chart datasets or table rows
 */
export const getReportDatasets = (
  reportData: CustomMilkingReportChartFragment | SkeletonPlaceholder
) =>
  reportData.yAxisDatasetLabels?.map((datasetLabel, datasetIndex) => ({
    datasetLabel,
    dataPoints: reportData.yAxisDatasets?.[datasetIndex] ?? [],
    cowsCounts: reportData.yAxisCowCounts?.[datasetIndex] ?? [],
  })) ?? getSkeletonPlaceholders(SKELETON_ROWS_COUNT);
