import React from 'react';

import clsx from 'clsx';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import {
  isSkeletonPlaceholder,
  Skeleton,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import {
  Callout,
  getNotificationPropsFromGQLError,
  NotificationVariants,
} from '~/services/notifications';
import {
  ReportCardBuilderCard,
  useReportCardBuilder,
} from '~/services/reportCardBuilder';

import { useBlueprintSourceFields } from '~/entities/blueprintSourceFields';
import { CustomReportDetailedFragment } from '~/entities/customReports/gql/fragments/customReportDetailed.graphql';
import { useUpdateCustomReportMutation } from '~/entities/customReports/gql/mutations/updateCustomReport.graphql';

import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';

import panelStyles from '~/styles/modules/panel.module.scss';

import { CUSTOM_REPORT_PIVOT_SETTINGS_FORM_ID } from '../../constants';
import { useCalculateCustomReportPivotTableMutation } from '../../gql/mutations/calculateCustomReportPivotTable.graphql';
import {
  getValueColumnName,
  isDirtyPivotTable,
  isEmptyPivotTable,
  isValidPivotTable,
  mapCustomReportSettingsToForm,
} from '../../helpers';
import { CustomReportPivotSettingsForm } from '../CustomReportPivotSettingsForm';
import { CustomReportPivotTable } from '../CustomReportPivotTable';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to take settings from
   */
  customReport: CustomReportDetailedFragment | SkeletonPlaceholder;
  /**
   * Launch result to render pivot table
   */
  customReportLaunchResult:
    | CustomReportLaunchResultFragment
    | SkeletonPlaceholder;
}

const EMPTY_PIVOT_DATA = {
  __typename: 'CustomReportPivotTable' as const,
  rows: [],
  columns: [],
};

export const CustomReportPivotCard: React.FC<Props> = ({
  className,
  customReport,
  customReportLaunchResult,
}) => {
  const [
    calculateCustomReportPivotTable,
    {
      error: calculateCustomReportPivotTableError,
      reset: resetCalculateCustomReportPivotTableMutation,
    },
  ] = useCalculateCustomReportPivotTableMutation();

  const [updateCustomReport, { loading: isUpdateCustomReportLoading }] =
    useUpdateCustomReportMutation();

  const {
    currentReportData,

    requestedSettings,

    setCurrentReportData,

    customReportCardProps,
    customReportSettingsFormProps,
  } = useReportCardBuilder({
    reportDeps: [customReport, customReportLaunchResult],

    getReportData: ([, customReportLaunchResultDep]) =>
      customReportLaunchResultDep.pivotTable,
    getReportSettings: ([customReportDep]) => customReportDep,
    mapReportSettingsToForm: mapCustomReportSettingsToForm,

    getIsReportEmpty: (reportData, [customReportDep]) =>
      !customReportDep.settings?.rows.length &&
      !customReportDep.settings?.columns.length &&
      !customReportDep.settings?.values.length,
    emptyReportData: EMPTY_PIVOT_DATA,

    calculateReport: (settingsInput, mutationContext) => {
      if (!customReportLaunchResult.hashID) {
        return Promise.reject(
          new Error(
            `Can't call calculateCustomReportPivotTable without launch hashID`
          )
        );
      }

      return calculateCustomReportPivotTable({
        variables: {
          customReportHashID: customReportLaunchResult.hashID,
          settingsInput,
        },
        context: {
          ...mutationContext,
          shouldSkipErrorNotifications: true,
        },
      }).then(({ data }) => {
        if (data?.calculateCustomReportPivotTable) {
          setCurrentReportData(data.calculateCustomReportPivotTable);
        }
      });
    },

    setReportSettings: settingsForm =>
      updateCustomReport({
        variables: {
          id: customReport.id,
          input: {
            settings: settingsForm,
          },
        },
        refetchQueries: ['customReportsDetailed'],
      }),

    onCardEditCancel: resetCalculateCustomReportPivotTableMutation,
  });

  const firstValueConfig = customReport.settings?.values.at(0);

  const { getSourceFieldById } = useBlueprintSourceFields(
    customReportLaunchResult.blueprintLaunchResult?.columnSourceFields ?? []
  );

  const tableTitle =
    customReport.settings?.values.length === 1 && firstValueConfig
      ? getValueColumnName(firstValueConfig, getSourceFieldById, false)
      : 'Сводная таблица';

  return (
    <ReportCardBuilderCard
      {...{
        title: tableTitle,
        updatingTitle: 'Настройки сводной таблицы',
        creatingTitle: 'Создание сводной таблицы',
        shouldRenderCancelButtonForCreate: false,
        className,
        ...customReportCardProps,
        submitButtonProps: {
          form: CUSTOM_REPORT_PIVOT_SETTINGS_FORM_ID,
          isLoading: isUpdateCustomReportLoading,
        },
        settingsButtonProps: {
          isDisabled: isDirtyPivotTable(currentReportData),
        },
        renderEditingContent: () =>
          !isSkeletonPlaceholder(customReport) &&
          !isSkeletonPlaceholder(customReportLaunchResult) &&
          !isDirtyPivotTable(currentReportData) && (
            <CustomReportPivotSettingsForm
              {...{
                ...customReportSettingsFormProps,
                customReport,
                blueprintLaunchResult:
                  customReportLaunchResult.blueprintLaunchResult,
                pivotData: isValidPivotTable(currentReportData)
                  ? currentReportData
                  : EMPTY_PIVOT_DATA,
              }}
            />
          ),
      }}
    >
      {isEditing => {
        if (calculateCustomReportPivotTableError) {
          return (
            <div className="flex flex-col gap-16">
              {calculateCustomReportPivotTableError.graphQLErrors.map(
                (error, index) => (
                  <Callout
                    key={`${error.message}__${index}`}
                    {...{
                      variant: NotificationVariants.error,
                      ...getNotificationPropsFromGQLError(error),
                    }}
                  />
                )
              )}
            </div>
          );
        }

        if (
          isEmptyPivotTable(currentReportData) ||
          isDirtyPivotTable(currentReportData)
        ) {
          return (
            <div className={clsx('p-24', panelStyles.borderedPanel)}>
              <DataBlockedMessage
                {...{
                  className: 'p-24',
                  message: isEmptyPivotTable(currentReportData)
                    ? 'Нет данных для отображения'
                    : 'Недостаточно данных для отображения, проверьте настройки списка',
                }}
              />
            </div>
          );
        }

        return (
          <Skeleton isLoading={isSkeletonPlaceholder(currentReportData)}>
            <CustomReportPivotTable
              {...{
                isEditing,
                requestedSettings,
                pivotData: currentReportData,
              }}
            />
          </Skeleton>
        );
      }}
    </ReportCardBuilderCard>
  );
};
