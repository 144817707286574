import { Bull } from '@graphql-types';

import { HALF_SPACE, INTERPUNCT, MDASH } from '~/shared/constants';

import {
  getNumberPartFromGlobalId,
  makeReadFragment,
  makeUpdateFragment,
} from '~/services/gql';

import { BullFragment, BullFragmentDoc } from './gql/fragments/bull.graphql';
import {
  BullDetailedFragment,
  BullDetailedFragmentDoc,
} from './gql/fragments/bullDetailed.graphql';
import { BullFormatOptions } from './types';

/**
 * Returns normalized bull identifier
 */
export const getBullIdentifier = (
  bull?: Pick<Bull, 'id' | 'registrationNumber'>
) => bull?.registrationNumber ?? getNumberPartFromGlobalId(bull?.id);

/**
 * Returns bull identifier formatted for rendering
 */
export const formatBull = (
  bull?: Pick<Bull, 'id' | 'registrationNumber' | 'name'> | null,
  { withNumberSign = true, prefix = 'Бык' }: BullFormatOptions = {}
) => {
  if (!bull) return MDASH;

  const prefixWithSpace = prefix ? `${prefix} ` : '';
  const numberSign = withNumberSign ? `№${HALF_SPACE}` : '';
  const bullName = bull.name ? ` ${INTERPUNCT} ${bull.name}` : '';
  return `${prefixWithSpace}${numberSign}${getBullIdentifier(bull)}${bullName}`;
};

/**
 * Reads bull fragment from cache by id
 */
export const readBullFragment = makeReadFragment<BullFragment>({
  typeName: 'Bull',
  fragment: BullFragmentDoc,
});

/**
 * Update BullDetailedFragment in the cache
 */
export const updateBullDetailedFragment =
  makeUpdateFragment<BullDetailedFragment>({
    typeName: 'Bull',
    fragment: BullDetailedFragmentDoc,
  });
