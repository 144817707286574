import {
  AppAnalyticalTarget,
  AppAnalyticTarget,
  AppHerdState,
  HerdMetricData,
} from '~/~legacy/services/AnalyticsService';

import {
  GetAnalyticTargetsQuery,
  GetHerdMetricQuery,
  GetHerdStateQuery,
  PageInfo,
} from '~/shared/graphql';

export class AnalyticsResponseConvertor {
  fromGetHerdStateQuery(response: GetHerdStateQuery): AppHerdState {
    const altMetrics = response.analytics.herdStateMetrics;

    const result: AppHerdState = {
      cowCountChart: {
        bars: response.analytics.herdState.herdStateMetricChart.bars,
        targets: response.analytics.herdState.herdStateMetricChart.targets.map(
          item => {
            const transformed: AppAnalyticalTarget = {
              id: item.id,
              metric: item.metric,
              validSince: item.validSince,
              validTill: item.validTill,
              value: item.value,
            };
            return transformed;
          }
        ),
      },
      metrics: altMetrics,
    };

    return result;
  }

  fromGetHerdMetricQuery(response: GetHerdMetricQuery): HerdMetricData {
    const metricData: HerdMetricData = {
      metricChart: {
        bars: response.analytics.herdState.herdStateMetricChart.bars,
        targets: response.analytics.herdState.herdStateMetricChart.targets,
      },
    };

    return metricData;
  }

  fromGetAnalyticTargets(
    response: GetAnalyticTargetsQuery
  ): [AppAnalyticTarget[], PageInfo] {
    const { pageInfo } = response.analyticalTargets;
    const targets = response.analyticalTargets.nodes.map(item => {
      const target: AppAnalyticTarget = {
        id: item.id,
        metric: item.metric,
        validSince: item.validSince,
        validTill: item.validTill,
        value: item.value,
      };
      return target;
    });

    return [targets, pageInfo];
  }
}
