import R from 'ramda';
import { match, P } from 'ts-pattern';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';

import { SOMATIC_CELLS_REPORT_SETTINGS_FORM_SCHEMA } from './constants';
import { SomaticCellsReportFragment } from './gql/fragments/somaticCellsReport.graphql';
import { SomaticCellsReportSettingsFragment } from './gql/fragments/somaticCellsReportSettings.graphql';
import { SomaticCellsReportSettingsHistoricValueFragment } from './gql/fragments/SomaticCellsReportSettingsHistoricValue.graphql';
import { SomaticCellsReportSettingsFormType } from './types';

/**
 * Checks, if a calculated somatic cells report has actual data and not empty
 */
export const isSomaticCellsReportDataEmpty = (
  calculatedReport:
    | SomaticCellsReportFragment['calculatedReport']
    | SkeletonPlaceholder
    | undefined
) => calculatedReport?.__typename === 'SomaticCellsReportChartEmpty';

const isSomaticCellsReportSettingsHistoricValueLactationNumbers = (
  historicValue: SomaticCellsReportSettingsHistoricValueFragment
) =>
  historicValue.__typename ===
  'SomaticCellsReportSettingsHistoricValueLactationNumbers';

const isSomaticCellsReportSettingsHistoricValueLactationGroupNumbers = (
  historicValue: SomaticCellsReportSettingsHistoricValueFragment
) =>
  historicValue.__typename ===
  'SomaticCellsReportSettingsHistoricValueLactationGroupNumbers';

const isSomaticCellsReportSettingsHistoricValuePenGroups = (
  historicValue: SomaticCellsReportSettingsHistoricValueFragment
) =>
  historicValue.__typename ===
  'SomaticCellsReportSettingsHistoricValuePenGroups';

const mapSomaticCellsReportHistoricValueToForm = (
  historicValue:
    | SomaticCellsReportSettingsHistoricValueFragment
    | null
    | undefined
) =>
  match(historicValue)
    .with(P.nullish, R.always(null))
    .with(
      P.when(isSomaticCellsReportSettingsHistoricValueLactationNumbers),
      matchedValue => ({
        lactationNumbers: matchedValue.lactationNumbers ?? [],
        lactationGroupNumbers: null,
        penGroupIDs: null,
      })
    )
    .with(
      P.when(isSomaticCellsReportSettingsHistoricValueLactationGroupNumbers),
      matchedValue => ({
        lactationNumbers: null,
        lactationGroupNumbers: matchedValue.lactationGroupNumbers ?? [],
        penGroupIDs: null,
      })
    )
    .with(
      P.when(isSomaticCellsReportSettingsHistoricValuePenGroups),
      matchedValue => ({
        lactationNumbers: null,
        lactationGroupNumbers: null,
        penGroupIDs: matchedValue.penGroups.map(R.prop('id')) ?? [],
      })
    )
    .exhaustive();

/**
 * Maps somatic cells report settings fragment into settings form fields representation
 */
export const mapSomaticCellsReportSettingsToForm = (
  reportSettings: SomaticCellsReportSettingsFragment | SkeletonPlaceholder
): SomaticCellsReportSettingsFormType => ({
  ...SOMATIC_CELLS_REPORT_SETTINGS_FORM_SCHEMA.getDefault(),
  period: {
    since: reportSettings.since ?? '',
    till: reportSettings.till ?? '',
  },
  sccThousandsPerMl: reportSettings.sccThousandsPerMl ?? null,
  blueprintID: reportSettings.blueprint?.id ?? null,
  filters:
    reportSettings.filters?.map(filterConfig => {
      return {
        kind: filterConfig.kind ?? null,
        value:
          mapSomaticCellsReportHistoricValueToForm(filterConfig.value) ?? null,
      };
    }) ?? [],
});
