import { CowsCopyReasonEnum } from '@graphql-types';
import dayjs from 'dayjs';
import R from 'ramda';
import { match, P } from 'ts-pattern';
import * as yup from 'yup';

import { formatDateForBackend } from '~/shared/helpers/date';
import { oneOfEnum } from '~/shared/helpers/yup';

/**
 * Helper function for validation INN number
 */
const formatCheckDigit = (inn: string, coefficients: number[]) => {
  const arrLengthToCalculate = coefficients.length;
  const sumOfMultiplicationsByCoefficients = Array.from(inn)
    .slice(0, arrLengthToCalculate)
    .reduce((acc, item, index) => {
      return acc + +item * coefficients[index];
    }, 0);

  return ((sumOfMultiplicationsByCoefficients % 11) % 10).toString();
};

const COEFFICIENTS_TO_CHECK_10_DIGITS_INN = [2, 4, 10, 3, 5, 9, 4, 6, 8];
const COEFFICIENTS_TO_FIRST_CHECK_12_DIGITS_INN = [
  7, 2, 4, 10, 3, 5, 9, 4, 6, 8,
];
const COEFFICIENTS_TO_SECOND_CHECK_12_DIGITS_INN = [
  3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8,
];

/**
 * Function for validation INN number
 */
const validateInn = (inn: string) =>
  match(inn.length)
    .with(10, () => {
      const checkDigit = formatCheckDigit(
        inn,
        COEFFICIENTS_TO_CHECK_10_DIGITS_INN
      );

      return checkDigit === inn.at(-1);
    })
    .with(12, () => {
      const firstCheckDigit = formatCheckDigit(
        inn,
        COEFFICIENTS_TO_FIRST_CHECK_12_DIGITS_INN
      );

      const secondCheckDigit = formatCheckDigit(
        inn,
        COEFFICIENTS_TO_SECOND_CHECK_12_DIGITS_INN
      );

      return firstCheckDigit === inn.at(-2) && secondCheckDigit === inn.at(-1);
    })
    .otherwise(R.always(false));

/**
 * Schema for move cow form
 */
export const getMoveCowsSchema = () =>
  yup.object({
    reason: oneOfEnum(CowsCopyReasonEnum).default(CowsCopyReasonEnum.Sell),
    cowIDs: yup
      .array(yup.string().required())
      .default([])
      .min(1, 'Чтобы перейти на следующий шаг, добавьте хотя бы одно животное'),
    diseaseIDs: yup.array(yup.string().required()).default([]),
    userEventIDs: yup.array(yup.string().required()).default([]),
    departDate: yup
      .string()
      .default(formatDateForBackend(dayjs().startOf('day')))
      .required(), // Datetime
    toCompanyName: yup
      .string()
      .default(null)
      .nullable()
      .when(
        ['reason', 'shouldUseCustomCompany'],
        ([reason, shouldUseCustomCompany], schema) => {
          if (reason === CowsCopyReasonEnum.Move && shouldUseCustomCompany) {
            return schema.required();
          }
          return schema;
        }
      ),
    toCompanyID: yup
      .string()
      .nullable()
      .default(null)
      .when(
        ['reason', 'shouldUseCustomCompany'],
        ([reason, shouldUseCustomCompany], schema) => {
          if (reason === CowsCopyReasonEnum.Move && !shouldUseCustomCompany) {
            return schema.required();
          }
          return schema;
        }
      ),
    shouldUseCustomCompany: yup.boolean().default(false),
    toCompanyInn: yup
      .string()
      .default(null)
      .nullable()
      .test(
        'checkINN',
        'Введённый ИНН некорректен',
        (innProp, { parent: { reason } }) =>
          match(innProp)
            .with(P.nullish, () => reason === CowsCopyReasonEnum.Move)
            .with(P.string, inn => validateInn(inn))
            .exhaustive()
      ),
  });
