import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { Button } from '~/shared/components/Button';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputVariants } from '~/shared/components/Input';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';

import { PageHeader } from '~/services/layouts';

import { useBullsDetailedPaginatedQuery } from '~/entities/bulls';
import { BullDetailedFragment } from '~/entities/bulls/gql/fragments/bullDetailed.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './index.module.scss';
import { useDeleteBullModal, useEditBullModal } from './modals';

const BULL_ROWS_SKELETON_COUNT = 8;

const BASE_COLUMN_WIDTH_PX = 204;
const BREED_COLUMN_WIDTH_PX = 120;

export const Route = createFileRoute('/$companyId/_layout/user/herd/bulls/')({
  wrapInSuspense: true,
  component: BullsPage,
});

function BullsPage() {
  const { search, setSearch, debouncedSearch, isSearchActive } =
    useDebouncedSearch();

  const { open: openEditBullModal } = useEditBullModal();
  const { open: openDeleteBullModal } = useDeleteBullModal();

  const queryVariables = {
    search: debouncedSearch,
  };

  const { items, ...asyncProps } = useBullsDetailedPaginatedQuery({
    variables: queryVariables,
  });

  const columnConfigs: TableColumnConfig<BullDetailedFragment>[] = [
    {
      title: 'Рег. номер',
      key: 'registrationNumber',
      itemField: 'registrationNumber',
      width: BASE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Кличка',
      key: 'name',
      itemField: 'name',
      width: BASE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Межд. номер',
      key: 'usdaNumber',
      itemField: 'usdaNumber',
      width: BASE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Порода',
      key: 'breed',
      itemField: 'breed',
      width: BREED_COLUMN_WIDTH_PX,
    },
    {
      title: 'Комментарий',
      key: 'comment',
      itemField: 'comment',
      width: '1fr',
    },
  ];

  return (
    <div className={clsx(layoutStyles.limitedContainer, styles.root)}>
      <PageHeader
        {...{
          title: 'Генетика',
          rightContent: (
            <Button
              iconVariant={IconVariants.plus}
              onPress={() => openEditBullModal()}
            >
              Добавить быка
            </Button>
          ),
        }}
      />

      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <Input
          {...{
            name: 'search',
            className: clsx(styles.search, 'mb-24'),
            placeholder: 'Поиск',
            value: search,
            onValueChange: setSearch,
            variant: InputVariants.search,
          }}
        />
        <Table<BullDetailedFragment>
          {...{
            theme: TableThemes.largeSecondary,
            className: 'min-w-full w-min',
            items,
            columnConfigs,
            noItemsMessage: 'Нет данных для отображения',
            noSearchItemsMessage: 'Быки не найдены',
            noSearchItemsDescription:
              'По вашему запросу нет подходящих быков. Используйте другой рег. номер или кличку',
            isSearchActive,
            skeletonItemsCount: BULL_ROWS_SKELETON_COUNT,
            renderItemActions: bull => (
              <div className="flex gap-8">
                <FunctionButton
                  {...{
                    iconVariant: IconVariants.edit,
                    onPress: () => {
                      openEditBullModal({
                        bull,
                      });
                    },
                  }}
                />
                <FunctionButton
                  {...{
                    iconVariant: IconVariants.delete,
                    onPress: () =>
                      openDeleteBullModal({
                        bull,
                        queryVariables,
                      }),
                  }}
                />
              </div>
            ),
            ...asyncProps,
          }}
        />
      </div>
    </div>
  );
}
