import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBlueprintRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateBlueprintRoleInput;
}>;


export type UpdateBlueprintRoleMutation = { __typename?: 'Mutation', updateBlueprintRole?: any | null };


export const UpdateBlueprintRoleDocument = gql`
    mutation updateBlueprintRole($id: ID!, $input: UpdateBlueprintRoleInput!) {
  updateBlueprintRole(id: $id, input: $input)
}
    `;
export type UpdateBlueprintRoleMutationFn = Apollo.MutationFunction<UpdateBlueprintRoleMutation, UpdateBlueprintRoleMutationVariables>;

/**
 * __useUpdateBlueprintRoleMutation__
 *
 * To run a mutation, you first call `useUpdateBlueprintRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlueprintRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlueprintRoleMutation, { data, loading, error }] = useUpdateBlueprintRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlueprintRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlueprintRoleMutation, UpdateBlueprintRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlueprintRoleMutation, UpdateBlueprintRoleMutationVariables>(UpdateBlueprintRoleDocument, options);
      }
export type UpdateBlueprintRoleMutationHookResult = ReturnType<typeof useUpdateBlueprintRoleMutation>;
export type UpdateBlueprintRoleMutationResult = Apollo.MutationResult<UpdateBlueprintRoleMutation>;
export type UpdateBlueprintRoleMutationOptions = Apollo.BaseMutationOptions<UpdateBlueprintRoleMutation, UpdateBlueprintRoleMutationVariables>;