import React, { useEffect, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import { Loader } from '~/shared/components/Loader';
import { MErrorMessage } from '~/shared/components/MErrorMessage';

import {
  BlueprintEditCanvas,
  BlueprintEditService,
  SourceSection,
} from '~/features/blueprintEdit';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { ActionsSection } from '../ActionsSection';
import { BlueprintEditHeader } from '../BlueprintEditHeader';
import { ViewSection } from '../ViewSection';

interface Props {
  blueprintId: string;
  run: () => void;
}

export const BlueprintEdit: React.FC<Props> = ({ blueprintId, run }) => {
  const bulueprintEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const memoizedInit = useMemo(() => {
    return bulueprintEditSvc.initEditing.bind(bulueprintEditSvc);
  }, [bulueprintEditSvc.initEditing]);

  const { data, loading, errors, reload } = useApiData(memoizedInit);

  useEffect(() => {
    const loadData = async () => {
      await reload(blueprintId);
    };

    loadData();
  }, []);

  const content =
    errors.length > 0 ? (
      <MErrorMessage>{errors.join(' ')}</MErrorMessage>
    ) : (
      <>
        <BlueprintEditHeader
          blueprintName={data?.name || ''}
          blueprintId={data?.id || blueprintId}
          generalSettings={data?.generalSettings}
          canRun
          run={run}
        />
        <BlueprintEditCanvas>
          <SourceSection />
          <ActionsSection />
          <ViewSection blueprintId={data?.id || blueprintId} />
        </BlueprintEditCanvas>
      </>
    );

  return loading ? (
    <Loader className={layoutStyles.fullHeightContainer} />
  ) : (
    content
  );
};
