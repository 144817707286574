import React, { useMemo } from 'react';

import { ChartType } from 'chart.js';
import clsx from 'clsx';
import R from 'ramda';

import { ColoredDot, ColoredDotSizes } from '~/shared/components/ColoredDot';
import {
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { DateFormats, formatDate } from '~/shared/helpers/date';
import { formatInt, formatNumber } from '~/shared/helpers/number';
import { capitalize } from '~/shared/helpers/string';

import {
  BarChart,
  BarChartDataPoint,
  CHART_DATASET_COLORS_ITERATOR,
  CHART_DATASET_HOVER_COLORS_ITERATOR,
  CHART_DATASET_PRESSED_COLORS_ITERATOR,
  getScaleOptions,
  ReactChartDatasetConfig,
} from '~/features/charts';

import panelStyles from '~/styles/modules/panel.module.scss';

import { LivestockForecastMonthFragment } from '../../gql/fragments/livestockForecastMonth.graphql';
import { getMilkValue } from '../../helpers';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Data for the chart
   */
  livestockForecastMonths: (
    | LivestockForecastMonthFragment
    | SkeletonPlaceholder
  )[];
}

const CHART_HEIGHT_PX = 180;

enum CowsAndMilkChartTypes {
  firstLactationChart = 'firstLactationChart',
  otherLactationsChart = 'otherLactationsChart',
  milkTotalChart = 'milkTotalChart',
}

type CowsAndMilkChartConfig = ReactChartDatasetConfig<
  ChartType,
  BarChartDataPoint[]
> & {
  tooltipLabel: string;
  milkField: string;
};

export const CowsAndMilkChart: React.FC<Props> = ({
  className,
  livestockForecastMonths,
}) => {
  const { getSkeletonClassNames } = useSkeletonContext();

  const { chartConfigs, datasets, labels } = useMemo(() => {
    const configs = [
      {
        key: CowsAndMilkChartTypes.firstLactationChart,
        label: 'Первотёлки',
        color: CHART_DATASET_COLORS_ITERATOR.next().value,
        hoverColor: CHART_DATASET_HOVER_COLORS_ITERATOR.next().value,
        pressedColor: CHART_DATASET_PRESSED_COLORS_ITERATOR.next().value,
        order: 2,
        data: livestockForecastMonths.map(
          m => m.livestockCows?.firstLactation.total ?? 0
        ),
        tooltipLabel: 'голов',
        milkField: 'firstLactation' as const,
      },
      {
        key: CowsAndMilkChartTypes.otherLactationsChart,
        label: 'Коровы, лактация ≥ 2',
        color: CHART_DATASET_COLORS_ITERATOR.next().value,
        hoverColor: CHART_DATASET_HOVER_COLORS_ITERATOR.next().value,
        pressedColor: CHART_DATASET_PRESSED_COLORS_ITERATOR.next().value,
        order: 3,
        data: livestockForecastMonths.map(
          m => m.livestockCows?.otherLactations.total ?? 0
        ),
        tooltipLabel: 'голов',
        milkField: 'otherLactations' as const,
      },
      {
        key: CowsAndMilkChartTypes.milkTotalChart,
        label: 'Валовое значение молока',
        color: CHART_DATASET_COLORS_ITERATOR.next().value,
        hoverColor: CHART_DATASET_HOVER_COLORS_ITERATOR.next().value,
        pressedColor: CHART_DATASET_PRESSED_COLORS_ITERATOR.next().value,
        type: 'line',
        yAxisID: 'milkTotal',
        order: 1,
        data: livestockForecastMonths.map(
          m => getMilkValue(m?.milk, 'total') ?? 0
        ),
        tooltipLabel: 'т',
        milkField: '' as const,
      },
    ] satisfies CowsAndMilkChartConfig[];

    return {
      chartConfigs: configs,
      datasets: configs,
      labels: livestockForecastMonths.map(month =>
        formatDate(month?.forecastAt, DateFormats.onlyMonthShort)
      ),
    };
  }, [livestockForecastMonths]);

  return (
    <div className={clsx('p-24', panelStyles.largePanel, className)}>
      <Typography
        {...{
          variant: TypographyVariants.bodyLargeStrong,
          isStaticContent: true,
        }}
      >
        Коровы и молоко
      </Typography>

      <BarChart
        {...{
          legendClassName: clsx(
            'mb-24',
            getSkeletonClassNames('mt-16', 'mt-24')
          ),
          isStackedX: true,
          height: CHART_HEIGHT_PX,
          datasets,
          labels,
          renderTooltip: dataPoints => {
            const sortedPoints = R.sortBy(R.prop('datasetIndex'), dataPoints);

            const monthIndex = sortedPoints[0].parsed.x;
            const currentMonth = livestockForecastMonths[monthIndex];

            return (
              <div>
                <Typography
                  {...{
                    variant: TypographyVariants.descriptionLarge,
                    tag: 'h4',
                    className: 'mb-8',
                  }}
                >
                  {capitalize(
                    formatDate(
                      currentMonth?.forecastAt,
                      DateFormats.monthAndYear
                    )
                  )}
                </Typography>
                <ul className="grid gap-4">
                  {sortedPoints.map(point => {
                    const currentChartConfig = chartConfigs[point.datasetIndex];

                    const milkValue = currentChartConfig.milkField
                      ? getMilkValue(
                          currentMonth?.milk,
                          currentChartConfig.milkField
                        )
                      : undefined;

                    const formatter = currentChartConfig.milkField
                      ? formatInt
                      : formatNumber;

                    const milkInfo = R.isNil(milkValue)
                      ? ''
                      : `, ${formatNumber(milkValue)} т`;
                    return (
                      <Typography
                        key={point.datasetIndex}
                        {...{
                          tag: 'li',
                          variant: TypographyVariants.descriptionLarge,
                          className: 'flex gap-8 items-center',
                        }}
                      >
                        <ColoredDot
                          size={ColoredDotSizes.small10}
                          color={currentChartConfig.color}
                        />
                        {`${formatter(point.parsed.y)} ${
                          currentChartConfig.tooltipLabel
                        }${milkInfo}`}
                      </Typography>
                    );
                  })}
                </ul>
              </div>
            );
          },
          chartOptions: {
            interaction: {
              mode: 'index',
            },
            scales: {
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: 'Коровы',
                },
              },
              milkTotal: getScaleOptions({
                position: 'right',
                title: {
                  display: true,
                  text: 'Молоко, т',
                },
                ticks: {
                  callback: value => formatInt(value),
                },
              }),
            },
          },
        }}
      />
    </div>
  );
};
