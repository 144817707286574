import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type EmployeeShortFragment = { __typename: 'Employee', id: string, firstName: string, lastName: string };

export const EmployeeShortFragmentDoc = gql`
    fragment EmployeeShort on Employee {
  __typename
  id
  firstName
  lastName
}
    `;