import React from 'react';

import { FemaleAnimalKind } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import { formatDate, formatDateForBackend } from '~/shared/helpers/date';
import { formatFullNameWithInitials } from '~/shared/helpers/nameFormat';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';
import { formatInt } from '~/shared/helpers/number';

import { getBullIdentifier } from '~/entities/bulls';
import { getCowIdentifier } from '~/entities/cows';
import { INSEMINATION_RESULTS_DICT } from '~/entities/inseminations';
import { InseminationFragment } from '~/entities/inseminations/gql/fragments/insemination.graphql';
import { useInseminationsQuery } from '~/entities/inseminations/gql/queries/inseminations.graphql';

import { useAnalyticsFilters } from '~/features/analytics';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Since when to display inseminations
   */
  since: string;
  /**
   * Till when to display inseminations
   */
  till: string;
  /**
   * Metric category filter
   */
  category: FemaleAnimalKind;
  /**
   * If true, retiredAt column is shown (default - true)
   */
  withRetiredAtColumn?: boolean;
}

const COW_ID_COLUMN_WIDTH_PX = 116;

const DAYS_IN_MILK_COLUMN_WIDTH_PX = 180;
const HEIFER_AGE_COLUMN_WIDTH_PX = 116;

const RESULT_AT_COLUMN_WIDTH_PX = 100;
const RESULT_COLUMN_WIDTH_PX = 188;
const RETIRED_AT_COLUMN_WIDTH_PX = 112;
const EMPLOYEE_COLUMN_WIDTH_PX = 116;
const SCHEME_COLUMN_WIDTH_PX = 120;

const BULL_COLUMN_WIDTH_PX = 128;
const HEIFERS_BULL_COLUMN_WIDTH_PX = 192;

export const InseminationsTable: React.FC<Props> = ({
  className,
  since,
  till,
  category,
  withRetiredAtColumn = true,
}) => {
  const { farmId, cowIds } = useAnalyticsFilters();

  const { data, loading: isLoading } = useInseminationsQuery({
    variables: {
      since: formatDateForBackend(since, true),
      till: formatDateForBackend(till, true),
      cowIDs: normalizeToArrayOrUndefined(cowIds),
      farmIDs: normalizeToArrayOrUndefined(farmId),
      category,
    },
  });

  const inseminations = data?.inseminations.edges.map(R.prop('node'));

  const isHeifersTable = category === FemaleAnimalKind.Heifer;

  const columnConfigs: TableColumnConfig<InseminationFragment>[] = [
    {
      title: 'Номер животн.',
      key: 'cowId',
      renderCellContent: ({ cow }) => (
        <TextLink
          to="/$companyId/user/cows/$cowId"
          params={prev => ({ ...prev, cowId: cow.id })}
        >
          {getCowIdentifier(cow)}
        </TextLink>
      ),
      width: COW_ID_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: isHeifersTable ? 'Возраст в днях' : 'Дни доен. на нач. периода',
      key: 'daysInMilk',
      renderCellContent: ({ daysInMilk }) => formatInt(daysInMilk),
      width: isHeifersTable
        ? HEIFER_AGE_COLUMN_WIDTH_PX
        : DAYS_IN_MILK_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Дата осем.',
      key: 'happenedAt',
      renderCellContent: ({ happenedAt }) => formatDate(happenedAt),
      width: RESULT_AT_COLUMN_WIDTH_PX,
    },
    {
      title: 'Результат',
      key: 'result',
      renderCellContent: ({ result }) => INSEMINATION_RESULTS_DICT[result],
      width: RESULT_COLUMN_WIDTH_PX,
    },
    ...wrapConditionalArrayElement<TableColumnConfig<InseminationFragment>>(
      withRetiredAtColumn && {
        title: 'Дата выбытия',
        key: 'retiredAt',
        renderCellContent: ({ cow }) => formatDate(cow.retiredAt),
        width: RETIRED_AT_COLUMN_WIDTH_PX,
      }
    ),
    {
      title: 'Техник',
      key: 'employee',
      renderCellContent: ({ employee }) => formatFullNameWithInitials(employee),
      width: EMPLOYEE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Схема',
      key: 'scheme',
      renderCellContent: ({ scheme }) => scheme?.name,
      width: SCHEME_COLUMN_WIDTH_PX,
    },
    {
      title: 'Бык',
      key: 'bull',
      renderCellContent: ({ bull }) => getBullIdentifier(bull),
      width: isHeifersTable
        ? HEIFERS_BULL_COLUMN_WIDTH_PX
        : BULL_COLUMN_WIDTH_PX,
    },
  ];

  return (
    <Table<InseminationFragment>
      {...{
        theme: TableThemes.smallSecondary,
        className: clsx(className, 'col-span-full '),
        items: inseminations,
        withCustomScroll: false,
        withBorder: true,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        isLoading,
      }}
    />
  );
};
