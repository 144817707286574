export const EnumStrings = {
  // need to have . at the end since it used as hierarchical key root
  cowRetirementReason: 'cowRetirementReason.',
  bullState: 'bullState.',
  blueprintRoleColor: 'blueprintRoleColor.',
  eventKinds: 'eventKinds.',
  calculationMethod: 'calculationMethod.',
  conceptionRateParameter: 'conceptionRateParameter.',
  vitalityFilter: 'vitalityFilter.',
  monitorScheduleInterval: 'monitorScheduleInterval.',
  testMilkingFields: 'testMilkingFields.',
  milkingParlorKind: 'milkingParlorKind.',
};
