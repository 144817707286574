import React from 'react';

import { FemaleAnimalKind, HdrData } from '@graphql-types';
import clsx from 'clsx';

import { AsyncListProps } from '~/shared/components/AsyncList';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { formatDateRange } from '~/shared/helpers/date';
import { formatInt, formatWithPercent } from '~/shared/helpers/number';

import { InseminationsTable } from '../InseminationsTable';

interface Props extends Partial<AsyncListProps<HdrData>> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Heat detection rate table data
   */
  hdrData: HdrData[];
  /**
   * Metric category filter
   */
  category: FemaleAnimalKind;
}

const PERIOD_COLUMN_WIDTH_PX = 204;
const HDR_COLUMN_WIDTH_PX = 148;
const BR_ELIG_COLUMN_WIDTH_PX = 160;
const BRED_COLUMN_WIDTH_PX = 92;

export const HDRTable: React.FC<Props> = ({
  className,
  hdrData,
  category,
  ...asyncProps
}) => {
  const columnConfigs: TableColumnConfig<HdrData>[] = [
    {
      title: 'Период',
      key: 'period',
      renderCellContent: ({ since, till }) => formatDateRange(since, till),
      width: PERIOD_COLUMN_WIDTH_PX,
    },
    {
      title: '% выявления (HDR)',
      key: 'hdr',
      renderCellContent: ({ hdr }) => formatWithPercent(hdr),
      width: HDR_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Пригодные к осемен.',
      key: 'brElig',
      renderCellContent: ({ brElig }) => formatInt(brElig),
      width: BR_ELIG_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Осемен.',
      key: 'bred',
      renderCellContent: ({ bred }) => formatInt(bred),
      width: BRED_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    FILLER_COLUMN_CONFIG,
  ];

  return (
    <Table<HdrData>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: hdrData,
        columnConfigs,
        getItemKey: ({ since, till }) => `${since}__${till}`,
        noItemsMessage: 'Нет данных для отображения',
        renderExpandableRowContent: ({ since, till }) => (
          <InseminationsTable
            {...{
              since,
              till,
              category,
            }}
          />
        ),
        ...asyncProps,
      }}
    />
  );
};
