import { useEffect, useState } from 'react';

import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { Button, ButtonVariants } from '~/shared/components/Button';
import {
  DateRangePicker,
  DEFAULT_DATE_RANGE_VALUE,
} from '~/shared/components/DateRangePicker';
import { EmptyState } from '~/shared/components/EmptyState';
import { IconVariants } from '~/shared/components/Icon';
import { Skeleton } from '~/shared/components/Skeleton';
import { normalizeToValueOrUndefined } from '~/shared/helpers/normalize';
import { useCustomScrollWrapper } from '~/shared/hooks/useCustomScrollWrapper';

import { PageHeader } from '~/services/layouts';
import { useArkaNavigation } from '~/services/navigation';

import {
  isMonitorEntry,
  isMonitorLaunchCalculatingValue,
  MonitorTable,
  useCalculateMonitorModal,
  useEditMonitorEntryModal,
  useMonitorScheduleSettingsModal,
} from '~/features/monitor';
import { useCompanyMonitorScheduleQuery } from '~/features/monitor/gql/queries/companyMonitorSchedule.graphql';
import { useMonitorQuery } from '~/features/monitor/gql/queries/monitor.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './index.module.scss';

const MONITOR_POLLING_INTERVAL_MS = 15 * 1000;

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/monitor/'
)({
  wrapInSuspense: true,
  component: AnalyticsMonitorPage,
});

function AnalyticsMonitorPage() {
  const [period, setPeriod] = useState(DEFAULT_DATE_RANGE_VALUE);

  const { open: openEditMonitorEntryModal } = useEditMonitorEntryModal();

  const { open: openMonitorScheduleSettingsModal } =
    useMonitorScheduleSettingsModal();

  const { open: openCalculateMonitorModal } = useCalculateMonitorModal();

  const {
    data: monitorData,
    loading: isLoading,
    startPolling,
    stopPolling,
  } = useMonitorQuery({
    variables: {
      since: normalizeToValueOrUndefined(period.since),
      till: normalizeToValueOrUndefined(period.till),
    },
  });
  const monitorEntries = monitorData?.monitor.edges.map(R.prop('node'));

  const { selectedCompanyId } = useArkaNavigation();
  const { data: monitorScheduleSettingsData } = useCompanyMonitorScheduleQuery({
    variables: {
      id: selectedCompanyId ?? '',
    },
    skip: !selectedCompanyId,
  });
  const monitorSettings = monitorScheduleSettingsData?.company?.monitorSchedule;

  const shouldRefetch = (monitorEntries ?? [])
    .filter(isMonitorEntry)
    .flatMap(R.prop('values'))
    .some(isMonitorLaunchCalculatingValue);

  const { useScrollMeasureRef, renderCustomScrollWrapper } =
    useCustomScrollWrapper({
      autoScrollDeps: [monitorData],
      shouldHideScrollBarsForNoOverflow: false,
    });

  // Refetch monitor entries in case of pending calculation
  useEffect(() => {
    if (shouldRefetch) {
      startPolling(MONITOR_POLLING_INTERVAL_MS);
    }

    return stopPolling;
  }, [shouldRefetch]);

  const shouldShowEmptyState = !isLoading && !monitorEntries?.length;

  return (
    <div className={clsx(layoutStyles.limitedContainer)}>
      {shouldShowEmptyState && (
        <EmptyState
          {...{
            message:
              'Чтобы начать работать с показателями, их необходимо добавить на этот экран',
            buttonProps: {
              children: 'Добавить показатель',
              onPress: () => openEditMonitorEntryModal(),
            },
          }}
        />
      )}
      {!shouldShowEmptyState && (
        <>
          <PageHeader
            {...{
              title: 'Показатели',
              rightContent: (
                <>
                  <Button
                    {...{
                      variant: ButtonVariants.secondary,
                      onPress: () => openEditMonitorEntryModal(),
                      iconVariant: IconVariants.plus,
                    }}
                  >
                    Добавить показатель
                  </Button>
                  <Button
                    {...{
                      variant: ButtonVariants.secondary,
                      iconVariant: IconVariants.settings,
                      onPress: () =>
                        openMonitorScheduleSettingsModal({ monitorSettings }),
                    }}
                  >
                    Настроить авторасчёт
                  </Button>
                  <Button
                    {...{
                      onPress: () => openCalculateMonitorModal(),
                      iconVariant: IconVariants.calendarCheck,
                    }}
                  >
                    Рассчитать
                  </Button>
                </>
              ),
            }}
          />
          <div
            className={clsx(
              panelStyles.largePanel,
              'flex flex-col gap-24 p-24'
            )}
          >
            <DateRangePicker
              {...{
                label: 'Период',
                name: 'period',
                value: period,
                className: styles.dateRangePickerItem,
                onValueChange: setPeriod,
              }}
            />
            <Skeleton isLoading={isLoading}>
              {renderCustomScrollWrapper({
                contentClassName: 'shadow-border',
                scrollBarKey: monitorEntries?.length ?? 0,
                children: (
                  <MonitorTable
                    ref={useScrollMeasureRef}
                    entries={monitorEntries ?? []}
                  />
                ),
              })}
            </Skeleton>
          </div>
        </>
      )}
    </div>
  );
}
