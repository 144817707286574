import { gql } from '~/fakeGql';

export const GetBlueprintsListGQL = gql`
  query GetBlueprints {
    blueprints {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        cursor
        node {
          name
          id
          company {
            id
            name
          }
        }
      }

      nodes {
        name
        id
        company {
          id
          name
        }
      }
    }
  }
`;

export const CreateBlueprintGQL = gql`
  mutation CreateBlueprint($input: CreateBlueprintInput!) {
    createBlueprint(input: $input) {
      id
      name
      description
      isTemplate
      isForIntegratorOnly
      priority
      blueprintRoles {
        id
        name
        color
      }

      runSettings {
        runType
        repeatInterval
        dows
        interval
        startOn
      }
    }
  }
`;

export const GetBlueprintByIdGQL = gql`
  query GetBlueprintById($id: ID!) {
    blueprint(id: $id) {
      id
      name
      description
      isTemplate
      isForIntegratorOnly
      priority
      blueprintRoles {
        id
        name
        color
      }

      runSettings {
        runType
        repeatInterval
        dows
        interval
        startOn
      }
    }
  }
`;

export const UpdateBlueprintGQL = gql`
  mutation UpdateBlueprint($id: ID!, $input: UpdateBlueprintInput!) {
    updateBlueprint(id: $id, input: $input)
  }
`;

export const DeleteBlueprintGQL = gql`
  mutation DeleteBlueprint($id: ID!) {
    deleteBlueprint(id: $id)
  }
`;

export const GetTemplatesGQL = gql`
  query GetTemplates($cursor: String, $first: Int) {
    templates: blueprints(isTemplate: true, after: $cursor, first: $first) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;
