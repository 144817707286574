import React, { Children } from 'react';

import { useFocusRing } from '@react-aria/focus';
import clsx from 'clsx';
import R from 'ramda';

import { Badge, BadgeSizes } from '~/shared/components/Badge';
import {
  ContextMenuButton,
  ContextMenuButtonProps,
} from '~/shared/components/ContextMenuButton';
import {
  FunctionButton,
  FunctionButtonProps,
} from '~/shared/components/FunctionButton';
import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { MenuItemType } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';
import { useAccordion, UseAccordionProps } from '~/shared/hooks/useAccordion';

import { SizeVariants } from '~/styles/__generated__/token-variants';
import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './index.module.scss';

interface Props<ItemType extends MenuItemType = MenuItemType>
  extends UseAccordionProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Title for the array
   */
  title: string;
  /**
   * Props for context menu button, used to add new array entities
   */
  contextMenuButtonProps?: ContextMenuButtonProps<ItemType>;
  /**
   * Props for function button, used to add new array entities
   */
  functionButtonProps?: FunctionButtonProps;
  /**
   * If true, displays a badge with added children count
   */
  withChildrenCount?: boolean;
  /**
   * If passed, displays an empty message without children
   */
  noChildrenMessage?: string;
}

export const ReportCardBuilderFormSection = <
  ItemType extends MenuItemType = MenuItemType,
>({
  className,
  title,
  contextMenuButtonProps,
  functionButtonProps,
  withChildrenCount = false,
  noChildrenMessage,

  children: childrenProp,
  defaultIsOpen = true,
  ...useAccordionProps
}: Props<ItemType>) => {
  const hasChildrenProp = Array.isArray(childrenProp)
    ? childrenProp.some(R.identity)
    : !!childrenProp;

  const children = hasChildrenProp
    ? childrenProp
    : noChildrenMessage && (
        <div className={styles.noChildrenMessage}>
          <Typography variant={TypographyVariants.bodySmallStrong}>
            {noChildrenMessage}
          </Typography>
        </div>
      );

  const {
    isOpen,
    setIsOpen,

    shouldRenderContent,

    childrenContainerRef,

    openButtonProps,
    childrenWrapperProps,
  } = useAccordion({ ...useAccordionProps, defaultIsOpen });

  // :focus-visible is not working with usePress correctly, so we use react-aria solution
  const { isFocusVisible, focusProps } = useFocusRing();

  const shouldBeOpenable = !!noChildrenMessage || hasChildrenProp;

  return (
    <div
      className={clsx(panelStyles.panel, styles.root, 'grid py-16', className)}
    >
      <div className="flex items-start gap-8 px-16">
        <div
          {...{
            className: clsx(
              styles.titleWrapper,
              shouldBeOpenable && 'cursor-pointer',
              isFocusVisible && styles.focused
            ),
            ...mergeProps(openButtonProps, focusProps),
          }}
        >
          {shouldBeOpenable && (
            <Icon
              {...{
                size: SizeVariants.size20,
                variant: IconVariants.chevronDown,
                rotate: isOpen ? RotateVariants.up : RotateVariants.left,
              }}
            />
          )}
          <Typography variant={TypographyVariants.bodySmallStrong}>
            {title}
          </Typography>
          {withChildrenCount && (
            <Badge className="self-center" size={BadgeSizes.small16} isPill>
              {hasChildrenProp ? Children.toArray(children).length : 0}
            </Badge>
          )}
        </div>

        {contextMenuButtonProps && (
          <ContextMenuButton<ItemType>
            {...mergeProps(
              {
                className: 'ml-a flex-none',
                functionButtonProps: {
                  children: 'Добавить',
                  iconVariant: IconVariants.plus,
                },
                menuProps: {
                  onItemPress: () => {
                    setIsOpen(true);
                  },
                },
              } satisfies Partial<ContextMenuButtonProps<ItemType>>,
              contextMenuButtonProps
            )}
          />
        )}
        {functionButtonProps && (
          <FunctionButton
            {...mergeProps(
              {
                className: 'ml-a flex-none',
                children: 'Добавить',
                iconVariant: IconVariants.plus,
              } satisfies Partial<FunctionButtonProps>,
              functionButtonProps
            )}
          />
        )}
      </div>
      {shouldBeOpenable && (
        <div
          {...{
            className: styles.children,
            ...childrenWrapperProps,
          }}
        >
          <div ref={childrenContainerRef}>
            {shouldRenderContent && (
              <div className="grid gap-12 mt-12">{children}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
