import * as Types from '../../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ConceptionRateChartStudCodeCandleFragment = { __typename: 'ConceptionRateChartStudCodeCandle', meanValue: number, maxValue: number, studCode: string };

export const ConceptionRateChartStudCodeCandleFragmentDoc = gql`
    fragment ConceptionRateChartStudCodeCandle on ConceptionRateChartStudCodeCandle {
  __typename
  meanValue
  maxValue
  studCode
}
    `;