import { match, P } from 'ts-pattern';

import { formatDate } from '~/shared/helpers/date';
import {
  formatInt,
  formatNumber,
  formatWithPercent,
} from '~/shared/helpers/number';

import { CustomReportBlueprintSourceFieldValueFragment } from '~/entities/customReports/gql/fragments/customReportBlueprintSourceFieldValue.graphql';

/**
 * Formats custom report row value for display.
 */
export const formatCustomReportRowValue = (
  sourceFieldValue: Omit<
    CustomReportBlueprintSourceFieldValueFragment,
    '__typename'
  >,
  shouldFormatNumberWithPercent = false
) =>
  match(sourceFieldValue)
    .with({ intValue: P.not(P.nullish) }, matchedValue =>
      formatInt(matchedValue.intValue)
    )
    .with({ floatValue: P.not(P.nullish) }, matchedValue =>
      shouldFormatNumberWithPercent
        ? formatWithPercent(matchedValue.floatValue)
        : formatNumber(matchedValue.floatValue)
    )
    .with({ datetimeValue: P.not(P.nullish) }, matchedValue =>
      formatDate(matchedValue.datetimeValue)
    )
    .otherwise(matchedValue => matchedValue.strValue);
