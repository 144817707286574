import React, { useCallback } from 'react';

import clsx from 'clsx';

import { MInput } from '~/shared/components/MInput';

import styles from './index.module.scss';

interface Props {
  lable: string;
  value: string;
  onChange: (val: string) => void;
}

export const MetricParameterSection: React.FC<Props> = props => {
  const { lable, value, onChange } = props;

  const changeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      onChange(val);
    },
    [onChange]
  );

  return (
    <div className={clsx(styles.input, 'my-16')}>
      <MInput
        name="metric"
        label={lable}
        value={value}
        onChange={changeCallback}
      />
    </div>
  );
};
