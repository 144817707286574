import React from 'react';

import clsx from 'clsx';

import { mergeProps } from '~/shared/helpers/mergeProps';

import { Tab } from './components/Tab';
import styles from './index.module.scss';
import { TabsProps, TabsVariants } from './types';

export const Tabs: React.FC<TabsProps> = ({
  className,
  variant = TabsVariants.primary,
  children,
  tabs,
  rightContent,
}) => {
  let tabsElement = (
    <div className={clsx(styles.root, styles[variant])}>
      {tabs.map(tabConfig => {
        return (
          <Tab
            key={tabConfig.href}
            {...{
              variant,
              tabConfig,
            }}
          />
        );
      })}
      {!!rightContent &&
        React.cloneElement(
          rightContent,
          mergeProps(rightContent.props, { className: 'ml-a' })
        )}
    </div>
  );

  if (variant === TabsVariants.page) {
    tabsElement = (
      <div className={className} data-sticky-tabs>
        {tabsElement}
      </div>
    );
  }

  return (
    <>
      {tabsElement}
      {children}
    </>
  );
};

export * from './types';
