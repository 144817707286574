import {
  ChartOptions,
  ChartType,
  ScaleOptions,
  ScaleType,
  ScriptableLineSegmentContext,
} from 'chart.js';
import R from 'ramda';

import { Falsy } from '~/shared/types/utility';

import { GLOBAL_CHART_OPTIONS, GLOBAL_SCALE_OPTIONS } from './constants';

/**
 * Combines several chart options to generate a complete options config
 */
export const getChartOptions = <TType extends ChartType = ChartType>(
  ...optionsArray: (ChartOptions<ChartType> | Falsy)[]
) => {
  const optionsToMerge: ChartOptions<ChartType>[] = [
    GLOBAL_CHART_OPTIONS as ChartOptions<ChartType>,
    ...optionsArray.filter(Boolean),
  ];
  return optionsToMerge.reduce((acc, options) => {
    return R.mergeDeepRight<object, object>(
      acc as object,
      R.clone(options) as object
    ) as ChartOptions<TType>;
  }, {} as ChartOptions<TType>);
};

/**
 * Combines several scales options to generate a scale config
 */
export const getScaleOptions = <TScale extends ScaleType = ScaleType>(
  ...optionsArray: (ScaleOptions<ScaleType> | Falsy)[]
) => {
  const optionsToMerge: ScaleOptions<ScaleType>[] = [
    GLOBAL_SCALE_OPTIONS as ScaleOptions<ScaleType>,
    ...optionsArray.filter(Boolean),
  ];
  return optionsToMerge.reduce((acc, options) => {
    return R.mergeDeepRight<object, object>(
      acc as object,
      R.clone(options) as object
    ) as ScaleOptions<TScale>;
  }, {} as ScaleOptions<TScale>);
};

/**
 * Checks, if a segment of a chart has no data and should be displayed with dash
 */
export const isSegmentSkipped = (ctx: ScriptableLineSegmentContext) =>
  ctx.p0.skip || ctx.p1.skip;
