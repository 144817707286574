import React from 'react';

import { useApolloClient } from '@apollo/client';

import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatDate } from '~/shared/helpers/date';

import TOKENS from '~/styles/__generated__/tokens.json';

import { MoveCowsKeyInfoIcons } from '../../../../components/MoveCowsKeyInfoIcons';
import { readCowsCopyKeyFragment } from '../../../../helpers';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * moveKey to get cowsCopyKey from cache
   */
  moveKey?: string;
}

export const ActivatedKeyInfoCard: React.FC<Props> = ({
  moveKey,
  className,
}) => {
  const client = useApolloClient();

  const { departDate, company, cows } =
    readCowsCopyKeyFragment(client, moveKey) ?? {};

  return (
    <div className={clsx(styles.root, className)}>
      <Typography
        {...{
          className: 'flex gap-8 items-center',
          variant: TypographyVariants.bodyMediumStrong,
        }}
      >
        {moveKey}
        <Icon
          {...{
            variant: IconVariants.checkCircleFilled,
            color: TOKENS.colorSuccessDefault,
          }}
        />
      </Typography>
      <MoveCowsKeyInfoIcons
        {...{
          cowsAmount: cows?.length ?? 0,
          companyTitle: company?.name ?? '',
          departDate: formatDate(departDate),
        }}
      />
    </div>
  );
};
